/* src\components\ApplicationStarter\ApplicationStarter.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	attribute_to_object,
	detach_dev,
	dispatch_dev,
	element,
	flush,
	globals,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots
} from "../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import { applicationReady } from "../../utilities/store.js";
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount } from '../../../web_modules/svelte.js';
import { getDomains, getTypeDomains } from "../../utilities/getterUtils.js";
const file = "src\\components\\ApplicationStarter\\ApplicationStarter.svelte";

function create_fragment(ctx) {
	let user_rights;

	const block = {
		c: function create() {
			user_rights = element("user-rights");
			this.c = noop;
			add_location(user_rights, file, 2222, 0, 41561);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, user_rights, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(user_rights);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('application-starter', slots, []);
	let { backend = 'false' } = $$props;

	// Base Variables
	//===========================================================
	let componentId = uuidv4();

	let userRightsLoaded = false;
	let clientParametersLoaded = false;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// getComplexAttributeTypes()
		// getArtifactMimeTypes()
		//getCoreDataviewGuids()
		//getMetamodelDataviewGuids()
		getDomains();

		getTypeDomains();
		userRightsLoaded = bc.parameterService.get('RIGHTS_LOADED');

		if (!userRightsLoaded) {
			bc.messageService.subscribe('RIGHTS_LOADED', componentId, setUserRightsLoaded);
		}

		clientParametersLoaded = bc.parameterService.get('PARAMS_READY');

		if (!clientParametersLoaded) {
			bc.messageService.subscribe('PARAMS_READY', componentId, clientParamsReady);
		}

		checkApplicationReady();

		return () => {
			bc.messageService.unsubscribeComponent(componentId);
		};
	});

	// Component functions
	//================================================================
	const setUserRightsLoaded = () => {
		bc.messageService.unsubscribe('RIGHTS_LOADED', componentId);
		userRightsLoaded = true;
		checkApplicationReady();
	};

	const clientParamsReady = () => {
		bc.messageService.unsubscribe('PARAMS_READY', componentId);
		clientParametersLoaded = true;
		checkApplicationReady();
	};

	const checkApplicationReady = () => {
		if (clientParametersLoaded && userRightsLoaded || backend === 'true') {
			bc.messageService.unsubscribeComponent(componentId);
			applicationReady.set(true);
			bc.parameterService.set('APPLICATION_READY', true);
			console.log("Application ready");
		} else {
			console.log("Application NOT ready");
		}
	};

	const writable_props = ['backend'];

	

	$$self.$$set = $$props => {
		if ('backend' in $$props) $$invalidate(0, backend = $$props.backend);
	};

	$$self.$capture_state = () => ({
		applicationReady,
		bc,
		uuidv4,
		onMount,
		getDomains,
		getTypeDomains,
		backend,
		componentId,
		userRightsLoaded,
		clientParametersLoaded,
		setUserRightsLoaded,
		clientParamsReady,
		checkApplicationReady
	});

	$$self.$inject_state = $$props => {
		if ('backend' in $$props) $$invalidate(0, backend = $$props.backend);
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('userRightsLoaded' in $$props) userRightsLoaded = $$props.userRightsLoaded;
		if ('clientParametersLoaded' in $$props) clientParametersLoaded = $$props.clientParametersLoaded;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [backend];
}

class ApplicationStarter extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `*,::before,::after{-webkit-box-sizing:border-box;box-sizing:border-box;border-width:0;border-style:solid;border-color:#e5e7eb}*{--tw-ring-inset:var(--tw-empty,/*!*/ /*!*/);--tw-ring-offset-width:0px;--tw-ring-offset-color:#fff;--tw-ring-color:rgba(59, 130, 246, 0.5);--tw-ring-offset-shadow:0 0 #0000;--tw-ring-shadow:0 0 #0000;--tw-shadow:0 0 #0000}:root{-moz-tab-size:4;-o-tab-size:4;tab-size:4}:-moz-focusring{outline:1px dotted ButtonText}:-moz-ui-invalid{box-shadow:none}::moz-focus-inner{border-style:none;padding:0}::-webkit-inner-spin-button,::-webkit-outer-spin-button{height:auto}::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ backend: 0 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["backend"];
	}

	get backend() {
		return this.$$.ctx[0];
	}

	set backend(backend) {
		this.$$set({ backend });
		flush();
	}
}

customElements.define("application-starter", ApplicationStarter);
export default ApplicationStarter;