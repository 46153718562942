/* src\components\ObjectForm\UploadContainer.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	toggle_class,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute,
	singletonComponentExists
} from "../../utilities/componentUtils.js";

import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\ObjectForm\\UploadContainer.svelte";

// (418:4) {#if ready && showForm}
function create_if_block(ctx) {
	let full_overlay;
	let drag_and_drop_upload;
	let drag_and_drop_upload_dataviewguid_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			full_overlay = element_1("full-overlay");
			drag_and_drop_upload = element_1("drag-and-drop-upload");
			set_custom_element_data(drag_and_drop_upload, "class", "h-full");
			set_custom_element_data(drag_and_drop_upload, "slot", "content");
			set_custom_element_data(drag_and_drop_upload, "show", /*showForm*/ ctx[2]);
			set_custom_element_data(drag_and_drop_upload, "init", /*initForm*/ ctx[3]);
			set_custom_element_data(drag_and_drop_upload, "uploadedevent", /*uploadedevent*/ ctx[5]);
			set_custom_element_data(drag_and_drop_upload, "dataviewguid", drag_and_drop_upload_dataviewguid_value = 'dca0dbb5-be88-40a8-a609-d16c7e5bf578');
			set_custom_element_data(drag_and_drop_upload, "entityguid", /*entityguid*/ ctx[4]);
			add_location(drag_and_drop_upload, file, 427, 12, 9890);
			set_custom_element_data(full_overlay, "show", /*showForm*/ ctx[2]);
			set_custom_element_data(full_overlay, "init", /*initForm*/ ctx[3]);
			set_custom_element_data(full_overlay, "title", /*title*/ ctx[6]);
			set_custom_element_data(full_overlay, "fullscreen", "false");
			add_location(full_overlay, file, 418, 8, 9593);
		},
		m: function mount(target, anchor) {
			insert_dev(target, full_overlay, anchor);
			append_dev(full_overlay, drag_and_drop_upload);

			if (!mounted) {
				dispose = listen_dev(full_overlay, "close", /*close_handler*/ ctx[14], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty & /*showForm*/ 4) {
				set_custom_element_data(drag_and_drop_upload, "show", /*showForm*/ ctx[2]);
			}

			if (dirty & /*initForm*/ 8) {
				set_custom_element_data(drag_and_drop_upload, "init", /*initForm*/ ctx[3]);
			}

			if (dirty & /*uploadedevent*/ 32) {
				set_custom_element_data(drag_and_drop_upload, "uploadedevent", /*uploadedevent*/ ctx[5]);
			}

			if (dirty & /*entityguid*/ 16) {
				set_custom_element_data(drag_and_drop_upload, "entityguid", /*entityguid*/ ctx[4]);
			}

			if (dirty & /*showForm*/ 4) {
				set_custom_element_data(full_overlay, "show", /*showForm*/ ctx[2]);
			}

			if (dirty & /*initForm*/ 8) {
				set_custom_element_data(full_overlay, "init", /*initForm*/ ctx[3]);
			}

			if (dirty & /*title*/ 64) {
				set_custom_element_data(full_overlay, "title", /*title*/ ctx[6]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(full_overlay);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(418:4) {#if ready && showForm}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[0] && /*showForm*/ ctx[2] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			toggle_class(div, "hidden", /*showForm*/ ctx[2] === 'false');
			add_location(div, file, 416, 0, 9494);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[15](div);
		},
		p: function update(ctx, [dirty]) {
			if (/*ready*/ ctx[0] && /*showForm*/ ctx[2]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*showForm*/ 4) {
				toggle_class(div, "hidden", /*showForm*/ ctx[2] === 'false');
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[15](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(13, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('upload-container', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let showForm = 'false';
	let initForm = 'false';
	let entityguid = '';
	let uploadedevent = '';
	let title = '';

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(12, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(11, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(10, firstInitialized = true);

		if (!singletonComponentExists('UPLOAD-CONTAINER', true)) {
			bc.messageService.subscribe('SET_UPLOAD_CONTAINER', subscribeGuid, updateVariables);
		}
	};

	// Component functions
	//================================================================
	const updateVariables = args => {
		;
		$$invalidate(4, { entityguid, uploadedevent, title } = args, entityguid, $$invalidate(5, uploadedevent), $$invalidate(6, title));

		if (!title || title === '') {
			$$invalidate(6, title = "Dokumenten-Upload");
		}

		$$invalidate(2, showForm = 'true');
		$$invalidate(3, initForm = 'true');
		$$invalidate(0, ready = true);
	};

	const hideFormContainer = () => {
		$$invalidate(0, ready = false);
		$$invalidate(2, showForm = 'false');
		$$invalidate(3, initForm = 'false');
	};

	const writable_props = ['show', 'init'];

	

	const close_handler = e => {
		e.stopPropagation();
		hideFormContainer();
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(8, show = $$props.show);
		if ('init' in $$props) $$invalidate(9, init = $$props.init);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		singletonComponentExists,
		applicationReady,
		getComponentText,
		show,
		init,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		component,
		element,
		showForm,
		initForm,
		entityguid,
		uploadedevent,
		title,
		initComponent,
		updateVariables,
		hideFormContainer,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(8, show = $$props.show);
		if ('init' in $$props) $$invalidate(9, init = $$props.init);
		if ('firstInitialized' in $$props) $$invalidate(10, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(11, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(18, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(12, component = $$props.component);
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
		if ('showForm' in $$props) $$invalidate(2, showForm = $$props.showForm);
		if ('initForm' in $$props) $$invalidate(3, initForm = $$props.initForm);
		if ('entityguid' in $$props) $$invalidate(4, entityguid = $$props.entityguid);
		if ('uploadedevent' in $$props) $$invalidate(5, uploadedevent = $$props.uploadedevent);
		if ('title' in $$props) $$invalidate(6, title = $$props.title);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 4352) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 11776) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		ready,
		element,
		showForm,
		initForm,
		entityguid,
		uploadedevent,
		title,
		hideFormContainer,
		show,
		init,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		close_handler,
		div_binding
	];
}

class UploadContainer extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}.hidden{display:none}.h-full{height:100%}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 8, init: 9 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init"];
	}

	get show() {
		return this.$$.ctx[8];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[9];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}
}

customElements.define("upload-container", UploadContainer);
export default UploadContainer;