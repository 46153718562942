/* src\components\SettingsMigrator\SettingsMigrator.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';
import { requestHeaders } from '../../services/ajaxService.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { handleErrorInAlert } from "../../utilities/alertNotificationUtils.js";
import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\SettingsMigrator\\SettingsMigrator.svelte";

// (2167:4) {:else}
function create_else_block(ctx) {
	let loading_spinner;

	const block = {
		c: function create() {
			loading_spinner = element_1("loading-spinner");
			set_custom_element_data(loading_spinner, "text", "Analysieren der Attribute in der Datenbank...");
			add_location(loading_spinner, file, 2167, 8, 44492);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2167:4) {:else}",
		ctx
	});

	return block;
}

// (2163:4) {#if ready}
function create_if_block(ctx) {
	let button;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			button = element_1("button");
			button.textContent = "Usersettings\r\n            migrieren";
			attr_dev(button, "class", "px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-sm cursor-pointer");
			add_location(button, file, 2163, 8, 44303);
		},
		m: function mount(target, anchor) {
			insert_dev(target, button, anchor);

			if (!mounted) {
				dispose = listen_dev(button, "click", /*migrate*/ ctx[2], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2163:4) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*ready*/ ctx[0]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if_block.c();
			this.c = noop;
			add_location(div, file, 2161, 0, 44251);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if_block.m(div, null);
			/*div_binding*/ ctx[9](div);
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
			/*div_binding*/ ctx[9](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(8, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('settings-migrator', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(7, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(6, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(5, firstInitialized = true);
		$$invalidate(0, ready = true);
	};

	// Component functions
	//================================================================
	const migrate = async e => {
		try {
			const allUserClaimsRequest = await bc.metadataManager.getAllUserClaims();

			if (allUserClaimsRequest && allUserClaimsRequest['value'] && allUserClaimsRequest['value'].length > 0) {
				let allUserClaims = allUserClaimsRequest['value'];

				for (let i = 0; i < allUserClaims.length; i++) {
					let claim = allUserClaims[i];

					// console.log(claim)
					let guid = '';

					let type = '';
					let value = '';

					if (claim.ClaimType.endsWith('_FILTER') || claim.ClaimType.endsWith('_ORDER_BY')) {
						guid = claim.ClaimType.substring(0, 36);
						type = claim.ClaimType.substring(37);
						value = btoa('[{ "name": "default", "filter":' + claim.ClaimValue + '}]');
					}

					if (claim.ClaimType.endsWith('_DISPLAY')) {
						guid = claim.ClaimType.substring(0, 36);
						type = claim.ClaimType.substring(37);
						value = btoa(claim.ClaimValue);
					}

					if (claim.ClaimType.startsWith('title_kanban_board')) {
						type = 'TITLE_KANBAN_BOARD';
						guid = claim.ClaimType.substring(19);
						value = btoa(claim.ClaimValue);
					}

					if (claim.ClaimType.startsWith('color_end_date_today_kanban_board')) {
						type = 'COLOR_END_DATE_TODAY_KANBAN_BOARD';
						guid = claim.ClaimType.substring(34);
						value = btoa(claim.ClaimValue);
					}

					if (claim.ClaimType.startsWith('subtitle_kanban_board')) {
						type = 'SUBTITLE_KANBAN_BOARD';
						guid = claim.ClaimType.substring(22);
						value = btoa(claim.ClaimValue);
					}

					if (claim.ClaimType.startsWith('color_no_end_date_kanban_board')) {
						type = 'COLOR_NO_END_DATE_KANBAN_BOARD';
						guid = claim.ClaimType.substring(31);
						value = btoa(claim.ClaimValue);
					}

					if (claim.ClaimType.startsWith('color_before_end_date_kanban_board')) {
						type = 'COLOR_BEFORE_END_DATE_KANBAN_BOARD';
						guid = claim.ClaimType.substring(35);
						value = btoa(claim.ClaimValue);
					}

					if (claim.ClaimType.startsWith('color_past_end_date_kanban_board')) {
						type = 'COLOR_PAST_END_DATE_KANBAN_BOARD';
						guid = claim.ClaimType.substring(33);
						value = btoa(claim.ClaimValue);
					}

					if (claim.ClaimType.startsWith('color_futur_start_date_kanban_board')) {
						type = 'COLOR_FUTURE_START_DATE_KANBAN_BOARD';
						guid = claim.ClaimType.substring(36);
						value = btoa(claim.ClaimValue);
					}

					if (claim.ClaimType.startsWith('description_kanban_board')) {
						type = 'DESCRIPTION_KANBAN_BOARD';
						guid = claim.ClaimType.substring(25);
						value = btoa(claim.ClaimValue);
					}

					if (claim.ClaimType.startsWith('KANBAN_column_display')) {
						type = 'KANBAN_COLUMN_DISPLAY';
						guid = claim.ClaimType.substring(22);
						value = btoa(claim.ClaimValue);
					}

					if (claim.ClaimType.startsWith('layer-compositions')) {
						type = 'LAYER-COMPOSITIONS';
						let id = claim.UserId;
						let layerCompArray = JSON.parse(claim.ClaimValue);
						let finalArray = [];

						for (let j = 0; j < layerCompArray.length; j++) {
							let tempValue = allUserClaims.find(c => c.ClaimType === layerCompArray[j]).ClaimValue;
							tempValue = tempValue.split(String.fromCharCode(92)).join('');
							let layerComp = JSON.parse(tempValue.substring(1, tempValue.length - 1));

							finalArray.push({
								name: layerCompArray[j],
								value: layerComp
							});
						}

						value = btoa(JSON.stringify(finalArray));
					}

					if (claim.ClaimType === 'FAVORITES') {
						type = 'FAVORITES';
						guid = '';
						value = btoa(claim.ClaimValue);
					}

					if (value !== '') {
						// console.log(claim.ClaimType, claim.ClaimValue, type, guid, value)
						let userSetting = {
							UG_ID: claim.ClaimId,
							UG_NAME: type,
							UG_VALUE: value,
							UG_TYPE: guid !== '' ? guid : null,
							UserId: claim.UserId,
							UG_CREATED: null,
							UG_MODIFIED: null,
							UG_ITEM: null
						};

						const urlAndResourceParams = bc.metadataManager.getResourceUrlAndParameters('UgdmUserSettings', '');
						let url = urlAndResourceParams.url;
						await bc.ajaxService.fetchPost(requestHeaders.jsonPost, url, JSON.stringify(userSetting));
					}
				}

				alert('Migration beendet.');
			}
		} catch(error) {
			handleErrorInAlert(error, 'Fehler bei der Migration der Settings.');
			console.error('Fehler bei der Migration der Settings.', error);
		}
	};

	const writable_props = ['show', 'init'];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(3, show = $$props.show);
		if ('init' in $$props) $$invalidate(4, init = $$props.init);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		requestHeaders,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		handleErrorInAlert,
		applicationReady,
		getComponentText,
		show,
		init,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		component,
		element,
		initComponent,
		migrate,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(3, show = $$props.show);
		if ('init' in $$props) $$invalidate(4, init = $$props.init);
		if ('firstInitialized' in $$props) $$invalidate(5, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(6, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(12, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(7, component = $$props.component);
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 136) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 368) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		ready,
		element,
		migrate,
		show,
		init,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		div_binding
	];
}

class SettingsMigrator extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.bg-blue-500{--tw-bg-opacity:1;background-color:rgba(59, 130, 246, var(--tw-bg-opacity))}.hover\\:bg-blue-600:hover{--tw-bg-opacity:1;background-color:rgba(37, 99, 235, var(--tw-bg-opacity))}.rounded-sm{border-radius:0.125rem}.cursor-pointer{cursor:pointer}.px-4{padding-left:1rem;padding-right:1rem}.py-2{padding-top:0.5rem;padding-bottom:0.5rem}.text-white{--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 3, init: 4 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init"];
	}

	get show() {
		return this.$$.ctx[3];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[4];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}
}

customElements.define("settings-migrator", SettingsMigrator);
export default SettingsMigrator;