/* src\components\ObjectTable\FilterDropDown\DropDownList\DropDownList.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text,
	validate_each_argument,
	validate_slots,
	validate_store
} from "../../../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../../../utilities/componentUtils.js";

import { applicationReady } from "../../../../utilities/store.js";
import { createEvent, getComponentText } from "../../../../utilities/generalUtils.js";
const file = "src\\components\\ObjectTable\\FilterDropDown\\DropDownList\\DropDownList.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[23] = list[i].value;
	child_ctx[24] = list[i].guid;
	return child_ctx;
}

// (2099:8) {#if bc.parameterService.appParameters.userId && isactorlist === 'true'}
function create_if_block_1(ctx) {
	let li;
	let span;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			li = element_1("li");
			span = element_1("span");
			span.textContent = `${/*generalText*/ ctx[4].loggedInUser}`;
			attr_dev(span, "class", "font-medium roboto-medium");
			add_location(span, file, 2105, 16, 40076);
			add_location(li, file, 2099, 12, 39849);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, span);

			if (!mounted) {
				dispose = listen_dev(li, "click", /*click_handler_1*/ ctx[15], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2099:8) {#if bc.parameterService.appParameters.userId && isactorlist === 'true'}",
		ctx
	});

	return block;
}

// (2110:8) {#if isloading === 'true'}
function create_if_block(ctx) {
	let li;
	let span;
	let t0_value = /*generalText*/ ctx[4].loading + "";
	let t0;
	let t1;
	let fa_icon;

	const block = {
		c: function create() {
			li = element_1("li");
			span = element_1("span");
			t0 = text(t0_value);
			t1 = space();
			fa_icon = element_1("fa-icon");
			set_custom_element_data(fa_icon, "icon", "far fa-circle-notch fa-spin");
			set_custom_element_data(fa_icon, "size", "0.9");
			set_custom_element_data(fa_icon, "unit", "rem");
			set_custom_element_data(fa_icon, "color", "#817f7f");
			add_location(fa_icon, file, 2112, 40, 40381);
			attr_dev(span, "class", "font-medium roboto-medium");
			add_location(span, file, 2111, 36, 40278);
			add_location(li, file, 2110, 12, 40236);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, span);
			append_dev(span, t0);
			append_dev(span, t1);
			append_dev(span, fa_icon);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2110:8) {#if isloading === 'true'}",
		ctx
	});

	return block;
}

// (2116:8) {#each list as {value, guid}}
function create_each_block(ctx) {
	let li;
	let t0_value = /*value*/ ctx[23] + "";
	let t0;
	let t1;
	let mounted;
	let dispose;

	function click_handler_2(...args) {
		return /*click_handler_2*/ ctx[16](/*value*/ ctx[23], /*guid*/ ctx[24], ...args);
	}

	const block = {
		c: function create() {
			li = element_1("li");
			t0 = text(t0_value);
			t1 = space();
			add_location(li, file, 2116, 12, 40567);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, t0);
			append_dev(li, t1);

			if (!mounted) {
				dispose = listen_dev(li, "click", click_handler_2, false, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*list*/ 8 && t0_value !== (t0_value = /*value*/ ctx[23] + "")) set_data_dev(t0, t0_value);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(2116:8) {#each list as {value, guid}}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let ul;
	let li;
	let t1;
	let t2;
	let t3;
	let mounted;
	let dispose;
	let if_block0 = bc.parameterService.appParameters.userId && /*isactorlist*/ ctx[0] === 'true' && create_if_block_1(ctx);
	let if_block1 = /*isloading*/ ctx[1] === 'true' && create_if_block(ctx);
	let each_value = /*list*/ ctx[3];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			div = element_1("div");
			ul = element_1("ul");
			li = element_1("li");
			li.textContent = `${/*componentText*/ ctx[5].noFilter}`;
			t1 = space();
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			t3 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			this.c = noop;
			add_location(li, file, 2090, 8, 39545);
			attr_dev(ul, "class", "text-left");
			add_location(ul, file, 2089, 4, 39513);
			attr_dev(div, "class", "drop-down-list border border-solid border-gray-300 rounded mt-1");
			add_location(div, file, 2087, 0, 39408);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, ul);
			append_dev(ul, li);
			append_dev(ul, t1);
			if (if_block0) if_block0.m(ul, null);
			append_dev(ul, t2);
			if (if_block1) if_block1.m(ul, null);
			append_dev(ul, t3);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(ul, null);
				}
			}

			/*div_binding*/ ctx[17](div);

			if (!mounted) {
				dispose = listen_dev(li, "click", /*click_handler*/ ctx[14], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (bc.parameterService.appParameters.userId && /*isactorlist*/ ctx[0] === 'true') {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(ul, t2);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*isloading*/ ctx[1] === 'true') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(ul, t3);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (dirty & /*dispatchSelect, list*/ 72) {
				each_value = /*list*/ ctx[3];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			destroy_each(each_blocks, detaching);
			/*div_binding*/ ctx[17](null);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(13, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('drop-down-list', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { listvalues = '' } = $$props;
	let { isactorlist = 'false' } = $$props;
	let { isloading = 'false' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	const componentText = getComponentText('filterDropDown');
	let mounted = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let list = [];

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(12, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(11, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
			window.removeEventListener('click', handleClickOutSide, false);
		};
	});

	const initComponent = () => {
		$$invalidate(10, firstInitialized = true);
		ready = true;
		window.addEventListener('click', handleClickOutSide, false);
	};

	// Component functions
	//================================================================
	const dispatchSelect = (value, guid) => {
		console.log('value', value, 'guid', guid);

		let event = createEvent('select', { value, guid }, {
			bubbles: false,
			cancelable: true,
			composed: false
		});

		component.dispatchEvent(event);
	};

	function handleClickOutSide(e) {
		if (!component.contains(e.target)) {
			let event = createEvent('clickoutside', null, {
				bubbles: false,
				cancelable: true,
				composed: false
			});

			component.dispatchEvent(event);
		}
	}

	const writable_props = ['show', 'init', 'listvalues', 'isactorlist', 'isloading'];

	

	const click_handler = e => {
		e.stopPropagation();
		dispatchSelect('', '');
	};

	const click_handler_1 = e => {
		e.stopPropagation();
		dispatchSelect(generalText.loggedInUser, 'current user');
	};

	const click_handler_2 = (value, guid, e) => {
		e.stopPropagation();
		dispatchSelect(value, guid);
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(2, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(7, show = $$props.show);
		if ('init' in $$props) $$invalidate(8, init = $$props.init);
		if ('listvalues' in $$props) $$invalidate(9, listvalues = $$props.listvalues);
		if ('isactorlist' in $$props) $$invalidate(0, isactorlist = $$props.isactorlist);
		if ('isloading' in $$props) $$invalidate(1, isloading = $$props.isloading);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		applicationReady,
		createEvent,
		getComponentText,
		show,
		init,
		listvalues,
		isactorlist,
		isloading,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		componentText,
		mounted,
		canReload,
		component,
		element,
		list,
		initComponent,
		dispatchSelect,
		handleClickOutSide,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(7, show = $$props.show);
		if ('init' in $$props) $$invalidate(8, init = $$props.init);
		if ('listvalues' in $$props) $$invalidate(9, listvalues = $$props.listvalues);
		if ('isactorlist' in $$props) $$invalidate(0, isactorlist = $$props.isactorlist);
		if ('isloading' in $$props) $$invalidate(1, isloading = $$props.isloading);
		if ('firstInitialized' in $$props) $$invalidate(10, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) ready = $$props.ready;
		if ('mounted' in $$props) $$invalidate(11, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(20, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(12, component = $$props.component);
		if ('element' in $$props) $$invalidate(2, element = $$props.element);
		if ('list' in $$props) $$invalidate(3, list = $$props.list);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 4224) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 11520) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}

		if ($$self.$$.dirty & /*listvalues*/ 512) {
			$: if (listvalues) {
				$$invalidate(3, list = JSON.parse(listvalues));
			}
		}
	};

	return [
		isactorlist,
		isloading,
		element,
		list,
		generalText,
		componentText,
		dispatchSelect,
		show,
		init,
		listvalues,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		click_handler,
		click_handler_1,
		click_handler_2,
		div_binding
	];
}

class DropDownList extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.drop-down-list{background:white;max-height:250px;overflow:auto;width:230px;box-shadow:4px 5px 7px 0px #0000001c}.drop-down-list ul{margin:0px;padding:0px}.drop-down-list ul li{list-style:none;padding:10px;margin:0;cursor:pointer}.drop-down-list ul li:hover{background-color:aliceblue}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.font-medium{font-family:Roboto-Medium}.mt-1{margin-top:0.25rem}.text-left{text-align:left}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 7,
				init: 8,
				listvalues: 9,
				isactorlist: 0,
				isloading: 1
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "listvalues", "isactorlist", "isloading"];
	}

	get show() {
		return this.$$.ctx[7];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[8];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get listvalues() {
		return this.$$.ctx[9];
	}

	set listvalues(listvalues) {
		this.$$set({ listvalues });
		flush();
	}

	get isactorlist() {
		return this.$$.ctx[0];
	}

	set isactorlist(isactorlist) {
		this.$$set({ isactorlist });
		flush();
	}

	get isloading() {
		return this.$$.ctx[1];
	}

	set isloading(isloading) {
		this.$$set({ isloading });
		flush();
	}
}

customElements.define("drop-down-list", DropDownList);
export default DropDownList;