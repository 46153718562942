import {register} from "../../../web_modules/ol/proj/proj4.js";
import proj4 from "../../../web_modules/proj4.js";
import Projection from "../../../web_modules/ol/proj/Projection.js";
import View from "../../../web_modules/ol/View.js";
import VectorLayer from "../../../web_modules/ol/layer/Vector.js";
import {getPointResolution} from "../../../web_modules/ol/proj.js";
import bc from "../../bcShim.js";
import Style from "../../../web_modules/ol/style/Style.js";
import Fill from "../../../web_modules/ol/style/Fill.js";
import Stroke from "../../../web_modules/ol/style/Stroke.js";
import VectorSource from "../../../web_modules/ol/source/Vector.js";
import {Circle as CircleStyle} from "../../../web_modules/ol/style.js";
import {GeometryType} from "../../interfaces.js";
export const setupProjection = (maxExtent, units) => {
  let mapProj = bc.parameterService.get("PROJECTION");
  let projString = "";
  switch (mapProj) {
    case "EPSG:2056": {
      projString = "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:4326": {
      projString = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
      break;
    }
    case "EPSG:4258": {
      projString = "+proj=longlat +ellps=GRS80 +no_defs";
      break;
    }
    case "EPSG:21780": {
      projString = "+proj=somerc +lat_0=46.95240555555556 +lon_0=0 +k_0=1 +x_0=0 +y_0=0 +ellps=bessel +pm=bern +units=m +no_defs";
      break;
    }
    case "EPSG:21781": {
      projString = "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:31466": {
      projString = "+proj=tmerc +lat_0=0 +lon_0=6 +k=1 +x_0=2500000 +y_0=0 +ellps=bessel +datum=potsdam +units=m +no_defs";
      break;
    }
    case "EPSG:31467": {
      projString = "+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=bessel +datum=potsdam +units=m +no_defs";
      break;
    }
    case "EPSG:31468": {
      projString = "+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +datum=potsdam +units=m +no_defs";
      break;
    }
    case "EPSG:31469": {
      projString = "+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=bessel +datum=potsdam +units=m +no_defs";
      break;
    }
    case "EPSG:4839": {
      projString = "+proj=lcc +lat_1=48.66666666666666 +lat_2=53.66666666666666 +lat_0=51 +lon_0=10.5 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:28992": {
      projString = "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +no_defs";
      break;
    }
    case "EPSG:7415": {
      projString = "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +vunits=m +no_defs";
      break;
    }
    case "EPSG:31254": {
      projString = "+proj=tmerc +lat_0=0 +lon_0=10.33333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs";
      break;
    }
    case "EPSG:31255": {
      projString = "+proj=tmerc +lat_0=0 +lon_0=13.33333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs";
      break;
    }
    case "EPSG:31256": {
      projString = "+proj=tmerc +lat_0=0 +lon_0=16.33333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs";
      break;
    }
    case "EPSG:3416": {
      projString = "+proj=lcc +lat_1=49 +lat_2=46 +lat_0=47.5 +lon_0=13.33333333333333 +x_0=400000 +y_0=400000 +ellps=GRS80 +units=m +no_defs ";
      break;
    }
    case "EPSG:4230": {
      projString = "+proj=longlat +ellps=intl +no_defs";
      break;
    }
    case "EPSG:3003": {
      projString = "+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=1500000 +y_0=0 +ellps=intl +units=m +no_defs";
      break;
    }
    case "EPSG:3004": {
      projString = "+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9996 +x_0=2520000 +y_0=0 +ellps=intl +units=m +no_defs";
      break;
    }
    case "EPSG:23032": {
      projString = "+proj=utm +zone=32 +ellps=intl +units=m +no_defs";
      break;
    }
    case "EPSG:23033": {
      projString = "+proj=utm +zone=33 +ellps=intl +units=m +no_defs";
      break;
    }
    case "EPSG:23034": {
      projString = "+proj=utm +zone=34 +ellps=intl +units=m +no_defs ";
      break;
    }
    case "EPSG:2154": {
      projString = "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:3942": {
      projString = "+proj=lcc +lat_1=41.25 +lat_2=42.75 +lat_0=42 +lon_0=3 +x_0=1700000 +y_0=1200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:3943": {
      projString = "+proj=lcc +lat_1=42.25 +lat_2=43.75 +lat_0=43 +lon_0=3 +x_0=1700000 +y_0=2200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:3944": {
      projString = "+proj=lcc +lat_1=43.25 +lat_2=44.75 +lat_0=44 +lon_0=3 +x_0=1700000 +y_0=3200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:3945": {
      projString = "+proj=lcc +lat_1=44.25 +lat_2=45.75 +lat_0=45 +lon_0=3 +x_0=1700000 +y_0=4200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:3946": {
      projString = "+proj=lcc +lat_1=45.25 +lat_2=46.75 +lat_0=46 +lon_0=3 +x_0=1700000 +y_0=5200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:3947": {
      projString = "+proj=lcc +lat_1=46.25 +lat_2=47.75 +lat_0=47 +lon_0=3 +x_0=1700000 +y_0=6200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:3948": {
      projString = "+proj=lcc +lat_1=47.25 +lat_2=48.75 +lat_0=48 +lon_0=3 +x_0=1700000 +y_0=7200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:3949": {
      projString = "+proj=lcc +lat_1=48.25 +lat_2=49.75 +lat_0=49 +lon_0=3 +x_0=1700000 +y_0=8200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
      break;
    }
    case "EPSG:3950": {
      projString = "+proj=lcc +lat_1=49.25 +lat_2=50.75 +lat_0=50 +lon_0=3 +x_0=1700000 +y_0=9200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ";
      break;
    }
  }
  proj4.defs(mapProj, projString);
  register(proj4);
  return new Projection({
    code: mapProj,
    extent: maxExtent,
    units
  });
};
export const setupView = (scales, x, y, extent, projection, initialExtent, size) => {
  let viewResolutions = [];
  let maxResolution;
  for (let i = 0; i < scales.length; i++) {
    const scaleResolution = scales[i] / 1e3 / getPointResolution(projection, 72 / 25.4, [(extent[2] + extent[0]) / 2, (extent[1] + extent[3]) / 2], "METERS");
    if (i === 0) {
      maxResolution = scaleResolution;
    }
    viewResolutions.push(scaleResolution);
  }
  let viewToReturn = new View();
  maxResolution = viewToReturn.getResolutionForExtent(extent);
  viewResolutions = [maxResolution, ...viewResolutions];
  if (initialExtent && size) {
    viewToReturn = new View({
      center: [x, y],
      extent,
      resolutions: viewResolutions,
      projection,
      enableRotation: false
    });
    let startRes = viewToReturn.getResolutionForExtent(initialExtent, size);
    viewToReturn.setResolution(startRes);
  } else {
    viewToReturn = new View({
      center: [x, y],
      extent,
      resolution: maxResolution,
      resolutions: viewResolutions,
      projection,
      enableRotation: false
    });
  }
  return viewToReturn;
};
export const setupDrawLayer = () => {
  if (bc.parameterService.get("MAP_VECTOR_LAYER")) {
    const vectorLayer = bc.parameterService.get("MAP_VECTOR_LAYER");
    return bc.parameterService.get("MAP_VECTOR_LAYER");
  } else {
    const width = 2;
    const styles = [];
    styles[GeometryType.POLYGON] = [
      new Style({
        fill: new Fill({
          color: "#1976d217"
        }),
        stroke: new Stroke({
          color: "#0039dc",
          width: 2
        })
      })
    ];
    styles[GeometryType.CIRCLE] = [
      new Style({
        fill: new Fill({
          color: "#1976d217"
        }),
        stroke: new Stroke({
          color: "#0039dc",
          width: 2
        })
      })
    ];
    styles[GeometryType.MULTI_POLYGON] = styles[GeometryType.POLYGON];
    styles[GeometryType.LINE_STRING] = [
      new Style({
        stroke: new Stroke({
          color: "#0039dc",
          lineDash: [10, 10],
          width
        })
      })
    ];
    styles[GeometryType.MULTI_LINE_STRING] = styles[GeometryType.LINE_STRING];
    styles[GeometryType.POINT] = [
      new Style({
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({
            color: "#ffaa117d"
          }),
          stroke: new Stroke({
            color: "#ffaa11",
            width: 2
          })
        })
      })
    ];
    styles[GeometryType.MULTI_POINT] = styles[GeometryType.POINT];
    styles[GeometryType.GEOMETRY_COLLECTION] = styles[GeometryType.POLYGON].concat(styles[GeometryType.LINE_STRING], styles[GeometryType.POINT]);
    const styleFunction = function(feature, resolution) {
      const featureStyleFunction = feature.getStyleFunction();
      if (featureStyleFunction) {
        return featureStyleFunction.call(feature, resolution);
      } else {
        return styles[feature.getGeometry().getType()];
      }
    };
    let vectorSource = new VectorSource();
    return new VectorLayer({
      source: vectorSource,
      style: styleFunction
    });
  }
};
const createStandardVectorLayer = () => {
  let styleFunction = constructGeneralStyleFunction();
  let editSource = new VectorSource();
  return new VectorLayer({
    source: editSource,
    style: styleFunction
  });
};
export const setupVectorLayer = (layerName) => {
  if (bc.parameterService.get(layerName)) {
    return bc.parameterService.get("layerName");
  } else {
    let vectorLayer = createStandardVectorLayer();
    bc.parameterService.set(layerName, vectorLayer);
    return vectorLayer;
  }
};
const constructGeneralStyleFunction = () => {
  const width = 2;
  const styles = [];
  styles[GeometryType.POLYGON] = [
    new Style({
      fill: new Fill({
        color: "#e91d1e1f"
      }),
      stroke: new Stroke({
        color: "#e91d1e",
        width: 2
      })
    })
  ];
  styles[GeometryType.MULTI_POLYGON] = styles[GeometryType.POLYGON];
  styles[GeometryType.LINE_STRING] = [
    new Style({
      stroke: new Stroke({
        color: "#e91d1e",
        lineDash: [10, 10],
        width
      })
    })
  ];
  styles[GeometryType.MULTI_LINE_STRING] = styles[GeometryType.LINE_STRING];
  styles[GeometryType.POINT] = [
    new Style({
      image: new CircleStyle({
        radius: 10,
        fill: new Fill({
          color: "#e91d1e1f"
        }),
        stroke: new Stroke({
          color: "#e91d1e",
          width: 2
        })
      })
    })
  ];
  styles[GeometryType.MULTI_POINT] = styles[GeometryType.POINT];
  styles[GeometryType.GEOMETRY_COLLECTION] = styles[GeometryType.POLYGON].concat(styles[GeometryType.LINE_STRING], styles[GeometryType.POINT]);
  return function(feature, resolution) {
    const featureStyleFunction = feature.getStyleFunction();
    if (featureStyleFunction) {
      return featureStyleFunction.call(feature, resolution);
    } else {
      return styles[feature.getGeometry().getType()];
    }
  };
};
