/* src\components\Icon\FaIcon.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { v4 as uuidv4 } from "../../../web_modules/uuid.js";
import { onMount, tick } from '../../../web_modules/svelte.js';
import { getSharedStyleSheet } from "../../utilities/iconUtils.js";

import {
	attachNormalize,
	dispatchMountedEvent,
	componentInitAndAttributeHandler
} from "../../utilities/componentUtils.js";

import { applicationReady } from "../../utilities/store.js";
const file = "src\\components\\Icon\\FaIcon.svelte";

function create_fragment(ctx) {
	let i;
	let i_class_value;
	let i_style_value;

	const block = {
		c: function create() {
			i = element_1("i");
			this.c = noop;
			attr_dev(i, "class", i_class_value = /*icon*/ ctx[0] + ' ');

			attr_dev(i, "style", i_style_value = /*lineheight*/ ctx[4] !== ''
			? `display: inline-block; font-size: ${/*size*/ ctx[1]}${/*unit*/ ctx[2]}; color: ${/*color*/ ctx[3]}; line-height:${/*lineheight*/ ctx[4]}${/*unit*/ ctx[2]}`
			: `display: inline-block; font-size: ${/*size*/ ctx[1]}${/*unit*/ ctx[2]}; color: ${/*color*/ ctx[3]};`);

			add_location(i, file, 79, 0, 2771);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, i, anchor);
			/*i_binding*/ ctx[11](i);
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*icon*/ 1 && i_class_value !== (i_class_value = /*icon*/ ctx[0] + ' ')) {
				attr_dev(i, "class", i_class_value);
			}

			if (dirty & /*lineheight, size, unit, color*/ 30 && i_style_value !== (i_style_value = /*lineheight*/ ctx[4] !== ''
			? `display: inline-block; font-size: ${/*size*/ ctx[1]}${/*unit*/ ctx[2]}; color: ${/*color*/ ctx[3]}; line-height:${/*lineheight*/ ctx[4]}${/*unit*/ ctx[2]}`
			: `display: inline-block; font-size: ${/*size*/ ctx[1]}${/*unit*/ ctx[2]}; color: ${/*color*/ ctx[3]};`)) {
				attr_dev(i, "style", i_style_value);
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(i);
			/*i_binding*/ ctx[11](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(10, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('fa-icon', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { icon = '' } = $$props;
	let { size = 1 } = $$props;
	let { unit = 'rem' } = $$props;
	let { color = 'black' } = $$props;
	let { hovercolor = '' } = $$props;
	let { lineheight = '' } = $$props;

	// Base variables
	//================================================================
	let subscribeGuid = uuidv4();

	let firstInitialized = false;
	let ready = false;
	let mounted = false;
	let canReload = true;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			component = element.getRootNode().host || element.parentNode; // get the component/host

			// typed: (element.getRootNode() as ShadowRoot).host as HTMLElement
			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(9, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		if (component && component.shadowRoot) {
			getSharedStyleSheet().then(value => component.shadowRoot.adoptedStyleSheets = [value]);
		}

		ready = true;
	};

	const mouseOverHandler = e => {
		$$invalidate(5, element.style.color = hovercolor, element);
		$$invalidate(5, element.style.cursor = 'pointer', element);
	};

	const mouseLeaveHandler = e => {
		$$invalidate(5, element.style.color = color, element);
		$$invalidate(5, element.style.cursor = 'default', element);
	};

	if (hovercolor !== '' && element) {
		element.removeEventListener('mouseover', mouseOverHandler);
		element.addEventListener('mouseover', mouseOverHandler);
		element.removeEventListener('mouseleave', mouseLeaveHandler);
		element.addEventListener('mouseleave', mouseLeaveHandler);
	}

	const writable_props = ['show', 'init', 'icon', 'size', 'unit', 'color', 'hovercolor', 'lineheight'];

	

	function i_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(5, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(6, show = $$props.show);
		if ('init' in $$props) $$invalidate(7, init = $$props.init);
		if ('icon' in $$props) $$invalidate(0, icon = $$props.icon);
		if ('size' in $$props) $$invalidate(1, size = $$props.size);
		if ('unit' in $$props) $$invalidate(2, unit = $$props.unit);
		if ('color' in $$props) $$invalidate(3, color = $$props.color);
		if ('hovercolor' in $$props) $$invalidate(8, hovercolor = $$props.hovercolor);
		if ('lineheight' in $$props) $$invalidate(4, lineheight = $$props.lineheight);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		getSharedStyleSheet,
		attachNormalize,
		dispatchMountedEvent,
		componentInitAndAttributeHandler,
		applicationReady,
		show,
		init,
		icon,
		size,
		unit,
		color,
		hovercolor,
		lineheight,
		subscribeGuid,
		firstInitialized,
		ready,
		mounted,
		canReload,
		component,
		element,
		initComponent,
		mouseOverHandler,
		mouseLeaveHandler,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(6, show = $$props.show);
		if ('init' in $$props) $$invalidate(7, init = $$props.init);
		if ('icon' in $$props) $$invalidate(0, icon = $$props.icon);
		if ('size' in $$props) $$invalidate(1, size = $$props.size);
		if ('unit' in $$props) $$invalidate(2, unit = $$props.unit);
		if ('color' in $$props) $$invalidate(3, color = $$props.color);
		if ('hovercolor' in $$props) $$invalidate(8, hovercolor = $$props.hovercolor);
		if ('lineheight' in $$props) $$invalidate(4, lineheight = $$props.lineheight);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('firstInitialized' in $$props) $$invalidate(15, firstInitialized = $$props.firstInitialized);
		if ('ready' in $$props) ready = $$props.ready;
		if ('mounted' in $$props) $$invalidate(9, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(16, canReload = $$props.canReload);
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) $$invalidate(5, element = $$props.element);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$applicationReady, mounted, init*/ 1664) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		icon,
		size,
		unit,
		color,
		lineheight,
		element,
		show,
		init,
		hovercolor,
		mounted,
		$applicationReady,
		i_binding
	];
}

class FaIcon extends SvelteElement {
	constructor(options) {
		super();

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 6,
				init: 7,
				icon: 0,
				size: 1,
				unit: 2,
				color: 3,
				hovercolor: 8,
				lineheight: 4
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "icon", "size", "unit", "color", "hovercolor", "lineheight"];
	}

	get show() {
		return this.$$.ctx[6];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[7];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get icon() {
		return this.$$.ctx[0];
	}

	set icon(icon) {
		this.$$set({ icon });
		flush();
	}

	get size() {
		return this.$$.ctx[1];
	}

	set size(size) {
		this.$$set({ size });
		flush();
	}

	get unit() {
		return this.$$.ctx[2];
	}

	set unit(unit) {
		this.$$set({ unit });
		flush();
	}

	get color() {
		return this.$$.ctx[3];
	}

	set color(color) {
		this.$$set({ color });
		flush();
	}

	get hovercolor() {
		return this.$$.ctx[8];
	}

	set hovercolor(hovercolor) {
		this.$$set({ hovercolor });
		flush();
	}

	get lineheight() {
		return this.$$.ctx[4];
	}

	set lineheight(lineheight) {
		this.$$set({ lineheight });
		flush();
	}
}

customElements.define("fa-icon", FaIcon);
export default FaIcon;