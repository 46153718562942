/* src\components\Router\Router.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	globals,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

const { Object: Object_1, console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { applicationReady } from "../../utilities/store.js";
import { history } from "../../utilities/historyUtils.js";
import { notify } from "../../utilities/alertNotificationUtils.js";
import { infoLog } from "../../utilities/consoleUtils.js";
import qs from '../../../web_modules/qs.js';

import {
	addValuesToAdvancedFilter,
	createFilter
} from "../../utilities/filterUtils.js";

import "../../services/metadataManager.js";
const file = "src\\components\\Router\\Router.svelte";

function create_fragment(ctx) {
	let div;

	const block = {
		c: function create() {
			div = element_1("div");
			this.c = noop;
			add_location(div, file, 351, 0, 13032);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			/*div_binding*/ ctx[13](div);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			/*div_binding*/ ctx[13](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(12, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('bc-router', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { homeURL = '' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = bc.utils.getComponentText('general');
	let mounted = false;
	let canReload = false;
	let routes = [];
	let latestURL = new Map();
	let allURLS = [];
	let parameterHandler = null;
	let breadCrumb = null;
	let breadCrumbMobile = null;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(11, component = element.getRootNode().host || element.parentNode); // get the component/host

			// typed: (element.getRootNode() as ShadowRoot).host as HTMLElement
			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(10, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		var _a, _b;
		$$invalidate(9, firstInitialized = true);

		// ROUTER  is handed over to parameter service, utils function navigateTo fetches router and calls its navigateTo function
		// fetch router: let router = bc.parameterService.get('ROUTER')
		bc.parameterService.set('ROUTER', component);

		bc.parameterService.subscribe('BREADCRUMB', subscribeGuid, () => {
			var _a;

			breadCrumb = (_a = bc.parameterService.get('BREADCRUMB')) !== null && _a !== void 0
			? _a
			: null;

			bc.parameterService.unsubscribe('BREADCRUMB', subscribeGuid);
		});

		bc.parameterService.subscribe('BREADCRUMB_MOBILE', subscribeGuid, () => {
			var _a;

			breadCrumbMobile = (_a = bc.parameterService.get('BREADCRUMB_MOBILE')) !== null && _a !== void 0
			? _a
			: null;

			bc.parameterService.unsubscribe('BREADCRUMB_MOBILE', subscribeGuid);
		});

		routes = (_a = bc.parameterService.get('ROUTES')) !== null && _a !== void 0
		? _a
		: [];

		parameterHandler = (_b = bc.parameterService.get('URL_PARAMETER_HANDLER')) !== null && _b !== void 0
		? _b
		: null;

		routes.sort((a, b) => {
			return b.path.length - a.path.length;
		});

		if (routes.length > 0) {
			let unlisten = history.listen(routesHandler);
			let urlObj = new URL(location.href);
			let newURL = urlObj.href.replace(urlObj.origin, '');
			let fullHomeURL = new URL(bc.parameterService.appParameters.pageName);
			$$invalidate(1, homeURL = fullHomeURL.href.replace(fullHomeURL.origin, ''));

			if (!homeURL.endsWith('/')) {
				$$invalidate(1, homeURL = homeURL + '/');
			}

			history.push(newURL);
		}

		console.log('ROUTES', routes);
		ready = true;
	};

	const getRoutes = () => {
		return routes;
	};

	const navigateTo = (url, state) => {
		// for all open forms with changes, check if user wants to save
		let openForms = bc.parameterService.get("OPEN_FORMS");

		if (openForms) {
			let hadCallback = false;

			for (let i = 0; i < openForms.length; i++) {
				let hasChanged = openForms[i].hasChanged();

				if (hasChanged) {
					hadCallback = true;

					bc.utils.showConfirm({
						icon: 'warning',
						cancelText: generalText['cancel'],
						confirmText: generalText['next'],
						text: generalText['unsavedChanges'],
						title: generalText['navigate'],
						successCallback: () => {
							openForms[i].closeForm();
							pushHistory(url, state);
						},
						cancelCallback: () => {
							return;
						}
					});
				}
			}

			if (!hadCallback) {
				pushHistory(url, state);
			}
		} else {
			pushHistory(url, state);
		}
	};

	const pushHistory = (url, state) => {
		if (!url.endsWith('/')) {
			url = url + '/';
		}

		if (!state) {
			if (allURLS[allURLS.length - 1] === url) {
				return;
			}

			history.push(url);
		} else {
			history.push(url, state);
		}
	};

	const routesHandler = async ({ location, action }) => {
		if (bc.utils.mustHandleLink()) {
			infoLog('ROUTER', 'URL', location.pathname);
			let handled = false;
			let searchString = location.search;

			if (searchString) {
				await handleQueryParams(searchString);
			} else {
				if (!location.pathname.endsWith('/')) {
					location.pathname = location.pathname + '/';
				}

				if (allURLS[allURLS.length - 1] === location.pathname) {
					return;
				}

				allURLS.push(location.pathname);

				// First handle static routes without params
				for (let i = 0; i < routes.length; i++) {
					if (routes[i].path.indexOf(':') === -1) {
						if (routes[i].path === location.pathname) {
							await routes[i].callback(null, location);

							if (breadCrumb) {
								handleBreadCrumb(routes[i].type, null);
							}

							handled = true;
							break;
						}
					}
				}

				// Then handle routes with params
				if (!handled) {
					for (let i = 0; i < routes.length; i++) {
						if (routes[i].path.indexOf(':') !== -1) {
							let routeMatch = routes[i].parse(location.pathname);

							if (routeMatch) {
								latestURL.set(routes[i].path, location.pathname);
								await routes[i].callback(routeMatch, location);

								if (breadCrumb) {
									handleBreadCrumb(routes[i].type, routeMatch);
								}

								handled = true;
								break;
							}
						}
					}
				}

				if (!handled) {
					notify('Unknow route, redirecting to home');
					history.push(homeURL);
				}
			}
		}
	};

	const handleBreadCrumb = (type, match) => {
		switch (type) {
			case 'map':
				{
					breadCrumb.setCurrentState({ map: true });

					if (breadCrumbMobile) {
						breadCrumbMobile.setCurrentState({ map: true });
					}

					break;
				}
			case 'dashboard':
				{
					breadCrumb.setCurrentState({ dashboard: true });

					if (breadCrumbMobile) {
						breadCrumbMobile.setCurrentState({ dashboard: true });
					}

					break;
				}
			case 'search':
				{
					breadCrumb.setCurrentState({ search: true });

					if (breadCrumbMobile) {
						breadCrumbMobile.setCurrentState({ search: true });
					}

					break;
				}
			case 'entity':
				{
					let viewName = match.params.viewName;
					let entityId = match.params.entityId;
					let viewId = bc.utils.getViewId(viewName);

					if (viewId) {
						breadCrumb.setCurrentState({
							dataviewGuid: viewId,
							entityGuid: entityId
						});

						if (breadCrumbMobile) {
							breadCrumbMobile.setCurrentState({
								dataviewGuid: viewId,
								entityGuid: entityId
							});
						}
					}

					break;
				}
			case 'view':
				{
					let viewName = match.params.viewName;
					let viewId = bc.utils.getViewId(viewName);

					if (viewId) {
						breadCrumb.setCurrentState({ dataviewGuid: viewId });

						if (breadCrumbMobile) {
							breadCrumbMobile.setCurrentState({ dataviewGuid: viewId });
						}
					}

					break;
				}
			case 'viewTable':
				{
					let viewName = match.params.viewName;
					let viewId = bc.utils.getViewId(viewName);

					if (viewId) {
						breadCrumb.setCurrentState({ dataviewGuid: viewId });

						if (breadCrumbMobile) {
							breadCrumbMobile.setCurrentState({ dataviewGuid: viewId });
						}
					}

					break;
				}
			case 'viewKanban':
				{
					let viewName = match.params.viewName;
					let viewId = bc.utils.getViewId(viewName);

					if (viewId) {
						breadCrumb.setCurrentState({ dataviewGuid: viewId });

						if (breadCrumbMobile) {
							breadCrumbMobile.setCurrentState({ dataviewGuid: viewId });
						}
					}

					break;
				}
			case 'viewStatistics':
				{
					let viewName = match.params.viewName;
					let viewId = bc.utils.getViewId(viewName);

					if (viewId) {
						breadCrumb.setCurrentState({ dataviewGuid: viewId });

						if (breadCrumbMobile) {
							breadCrumbMobile.setCurrentState({ dataviewGuid: viewId });
						}
					}

					break;
				}
			case 'viewTimeline':
				{
					let viewName = match.params.viewName;
					let viewId = bc.utils.getViewId(viewName);

					if (viewId) {
						breadCrumb.setCurrentState({ dataviewGuid: viewId });

						if (breadCrumbMobile) {
							breadCrumbMobile.setCurrentState({ dataviewGuid: viewId });
						}
					}

					break;
				}
			case 'viewKalendar':
				{
					let viewName = match.params.viewName;
					let viewId = bc.utils.getViewId(viewName);

					if (viewId) {
						breadCrumb.setCurrentState({ dataviewGuid: viewId });

						if (breadCrumbMobile) {
							breadCrumbMobile.setCurrentState({ dataviewGuid: viewId });
						}
					}

					break;
				}
		}
	};

	const handleQueryParams = async searchString => {
		if (searchString) {
			try {
				searchString = searchString.substring(1);
				let urlObject = qs.parse(searchString, { comma: true });
				let keys = Object.keys(urlObject);

				if (parameterHandler) {
					await parameterHandler(urlObject);
				} else {
					navigateTo(homeURL);
				}
			} catch(e) {
				console.error(e);
			}
		}
	};

	const getLatestRouteURL = type => {
		let route = routes.find(r => r.type === type);
		return latestURL.get(route.path);
	};

	const getLatestURL = searchedFor => {
		// console.log('searchedFor', searchedFor)
		// console.log('allURLS', allURLS)
		let filtred = allURLS.filter(url => url.indexOf(searchedFor) !== -1);

		if (filtred.length > 0) {
			return filtred[filtred.length - 1];
		} else {
			return null;
		}
	};

	const createSearchFilter = (options, values) => {
		let filter = createFilter(Object.assign({}, options));
		addValuesToAdvancedFilter(filter, values);
		return filter;
	};

	const writable_props = ['show', 'init', 'homeURL'];

	Object_1.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console_1.warn(`<bc-router> was created with unknown prop '${key}'`);
	});

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(0, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(2, show = $$props.show);
		if ('init' in $$props) $$invalidate(3, init = $$props.init);
		if ('homeURL' in $$props) $$invalidate(1, homeURL = $$props.homeURL);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		applicationReady,
		history,
		notify,
		infoLog,
		qs,
		addValuesToAdvancedFilter,
		createFilter,
		show,
		init,
		homeURL,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		routes,
		latestURL,
		allURLS,
		parameterHandler,
		breadCrumb,
		breadCrumbMobile,
		component,
		element,
		initComponent,
		getRoutes,
		navigateTo,
		pushHistory,
		routesHandler,
		handleBreadCrumb,
		handleQueryParams,
		getLatestRouteURL,
		getLatestURL,
		createSearchFilter,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(2, show = $$props.show);
		if ('init' in $$props) $$invalidate(3, init = $$props.init);
		if ('homeURL' in $$props) $$invalidate(1, homeURL = $$props.homeURL);
		if ('firstInitialized' in $$props) $$invalidate(9, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) ready = $$props.ready;
		if ('mounted' in $$props) $$invalidate(10, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(21, canReload = $$props.canReload);
		if ('routes' in $$props) routes = $$props.routes;
		if ('latestURL' in $$props) latestURL = $$props.latestURL;
		if ('allURLS' in $$props) allURLS = $$props.allURLS;
		if ('parameterHandler' in $$props) parameterHandler = $$props.parameterHandler;
		if ('breadCrumb' in $$props) breadCrumb = $$props.breadCrumb;
		if ('breadCrumbMobile' in $$props) breadCrumbMobile = $$props.breadCrumbMobile;
		if ('component' in $$props) $$invalidate(11, component = $$props.component);
		if ('element' in $$props) $$invalidate(0, element = $$props.element);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 2052) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 5640) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		element,
		homeURL,
		show,
		init,
		getRoutes,
		navigateTo,
		getLatestRouteURL,
		getLatestURL,
		createSearchFilter,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		div_binding
	];
}

class Router extends SvelteElement {
	constructor(options) {
		super();

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 2,
				init: 3,
				homeURL: 1,
				getRoutes: 4,
				navigateTo: 5,
				getLatestRouteURL: 6,
				getLatestURL: 7,
				createSearchFilter: 8
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return [
			"show",
			"init",
			"homeURL",
			"getRoutes",
			"navigateTo",
			"getLatestRouteURL",
			"getLatestURL",
			"createSearchFilter"
		];
	}

	get show() {
		return this.$$.ctx[2];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[3];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get homeURL() {
		return this.$$.ctx[1];
	}

	set homeURL(homeURL) {
		this.$$set({ homeURL });
		flush();
	}

	get getRoutes() {
		return this.$$.ctx[4];
	}

	set getRoutes(value) {
		throw new Error("<bc-router>: Cannot set read-only property 'getRoutes'");
	}

	get navigateTo() {
		return this.$$.ctx[5];
	}

	set navigateTo(value) {
		throw new Error("<bc-router>: Cannot set read-only property 'navigateTo'");
	}

	get getLatestRouteURL() {
		return this.$$.ctx[6];
	}

	set getLatestRouteURL(value) {
		throw new Error("<bc-router>: Cannot set read-only property 'getLatestRouteURL'");
	}

	get getLatestURL() {
		return this.$$.ctx[7];
	}

	set getLatestURL(value) {
		throw new Error("<bc-router>: Cannot set read-only property 'getLatestURL'");
	}

	get createSearchFilter() {
		return this.$$.ctx[8];
	}

	set createSearchFilter(value) {
		throw new Error("<bc-router>: Cannot set read-only property 'createSearchFilter'");
	}
}

customElements.define("bc-router", Router);
export default Router;