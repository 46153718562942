export var Colors;
(function(Colors2) {
  Colors2["white"] = "white";
  Colors2["black"] = "black";
  Colors2["green"] = "green";
  Colors2["red"] = "red";
  Colors2["yellow"] = "yellow";
  Colors2["blue"] = "blue";
  Colors2["violet"] = "violet";
  Colors2["orange"] = "orange";
  Colors2["gray"] = "gray";
})(Colors || (Colors = {}));
export const constants = {
  base: {
    emptyGuid: "00000000-0000-0000-0000-000000000000",
    nullGuid: null,
    emptyString: "",
    nullString: null,
    nullNumber: null,
    nanNumber: NaN,
    nullBoolean: null
  },
  domain: {
    UGDM_ARTIFACT: "520055ae-bb5a-4f4f-bb3b-56d40b36fcdc",
    APPLICATION: "a1218e56-8953-4dc5-9bfd-246aef6abd4b",
    REFERENCE_ARTIFACT: "1123f953-d137-41f7-a01d-d4348cb1ae59",
    CULTURE: "f1644f5b-f6c2-4ba5-b38a-86f6cb059cab",
    TYPE_RELATIONSHIP_TYPE: "c35dc6e8-676c-4106-aa2a-a5bbea15edee",
    TIME_ZONE: "724076a5-6143-4a89-9cf8-98d9a50f8376",
    MIME_TYPE: "8eeaec50-5a6a-453e-aa22-d54aac5b0657",
    DATA_FORMAT: "b22713f0-dbea-43f1-9b62-90bef8084a54",
    SRS: "f87e462f-918f-4fa5-9026-a97d16a43897",
    LOCATION_TYPE: "aca13b3c-85b1-4ae6-bd1d-baa5ad55829b",
    PRODUCT: "7251b61e-e715-4360-aa1b-2cc753fed339",
    AUTHENTICATION_METHOD: "a67ef8b6-c76c-4f63-85c0-6eae0447dbfd",
    MEASUREMENT_DESCRIPTION_ARTIFACT: "806956b0-2848-4866-aa51-292be3dec460",
    MEASURAND: "1e68ffd6-aaaa-4558-94e1-23df53e5a6c6",
    UNIT: "3d1c7ddf-6338-4b07-bbe0-3abd4a0ba298",
    MEASUREMENT_TYPE: "51f6c68e-ba0a-4a1a-a86b-16d37a4df483",
    MEASUREMENT_PROGRAMME: "91fb9623-8ef1-4d2f-8f66-b2e0496b99e2",
    METHOD: "ed616654-61bb-4424-8cbf-9b333b1beb0d",
    DATA_MODEL_ARTIFACT: "30fdcce6-1c63-4932-a21c-de7ce19c7a3c",
    ASSOCIATION: "bf16ae03-ab27-4f71-b170-6420b22308ac",
    RELATIONSHIP_ASSOCIATION: "71AB4076-DACB-4536-8DEF-F6783D358691",
    ATTRIBUTE: "adae0818-b21a-439e-8712-1c7ca826bc8a",
    CARDINALITY: "91f554a6-ac43-4be5-bef5-947f9ee98148",
    COMPLEX_ATTRIBUTE_TYPE: "c700260b-7d84-44b7-982b-87423f2b3f28",
    DATA_MODEL_TEMPLATE: "3ceb77d4-838b-4493-9e7a-3f68b947478a",
    ROLE: "65d0d04a-c07b-4911-8a96-818c0a687f8c",
    CODE_LIST: "caaf9a99-335d-467b-bf0c-037e9001c729",
    STATUS_LIST: "a8aa60f4-57c2-43fc-9a11-cc01924696b3",
    DATA_TYPE: "060004dd-4f7c-4257-ac7d-071259b45edb",
    ENTITY_TYPE: "e96eb635-5250-421b-85db-379f52c3371e",
    DATA_VIEW: "bcea0912-b0a4-4d69-a494-491183485064",
    STEREOTYPE: "7ea92a7a-1322-431f-bb5c-bea208f2e896",
    DATABASE_ARTIFACT: "d4fc7efd-005d-4ac6-8f29-db78be9543f8",
    DATABASE_CATALOG: "97ff6f89-8f9a-4303-9b7a-d11c2254138b",
    DATABASE_SCHEMA: "657125ef-c046-47df-a7fc-afff0fe8759b",
    DATABASE_TABLE: "35b5091f-babf-424b-a81e-f71604638b65",
    DATABASE_VIEW: "babbdb82-60fa-43fc-b092-e7bc21f4a34b",
    DATABASE_PROCEDURE: "e871aaab-aaae-4888-a12b-18309598bc39",
    APPLICATION_ARTIFACT: "d9aacfd2-be93-471d-bc7b-b9a72e023beb",
    HTML_PAGE: "95950799-10c7-4cfd-8bf3-93cad1b47f1f",
    HTML_HEAD_ELEMENT: "f4ec2813-bef1-430c-bd8d-f343d75fd6ba",
    HTML_ELEMENT: "3254ce41-4756-45df-bb72-a660fe02439c",
    QUERY: "f400a189-59a2-4456-8db2-539ef9269882",
    MAP: "92d81d4e-271f-464a-8459-db50ac37db04",
    GRID: "21050872-9730-482b-9c4c-34a4d231cb28",
    INFORMATION_WINDOW: "e00d4431-142c-4d0c-984e-d86eb52e9c1a",
    TREE: "b2e111f3-8289-4c27-935f-88840aa0da3f",
    CLASSIFICATION_TREE: "2dd81fb7-43de-4040-940c-0df8a4a3efae",
    LAYER_TREE: "061cc2d4-a6e1-4b1e-9dba-f5110d571d81",
    TOOLBOX: "775eed0a-cc69-4ddc-9589-e26913a09340",
    TOOL: "1c60803a-b358-4b27-8b85-10450b205ad9",
    NAVIGATION_OBJECT: "f527993d-9424-484a-8dc3-5f60cdcf7576",
    COORDINATE_SEARCH: "64765a03-08ba-4fa9-844a-9ee9765fa360",
    SCRIPT: "8ccb09b6-d22f-451e-b4d7-c88202fc0016",
    SYSTEM: "10ea404a-2646-47ed-8eea-5f4e92e740a2",
    DATA_SOURCE_SYSTEM: "dd20126a-cea8-4004-80cf-208a279fc243",
    DATABASE_SOURCE_SYSTEM: "11935376-0187-4e66-b15a-6a04f20391ca",
    DATA_SERVICE_SOURCE_SYSTEM: "24a5c4a0-ca6e-4bd4-b868-38347e782879",
    FILE_SOURCE_SYSTEM: "ff9a8f4b-e53f-453d-93eb-3e6bdc957190",
    DATA_SERVICE: "1740ff2b-9317-4017-9bf0-e66258c02842",
    MAP_SERVICE: "98e01450-ee1e-490b-a32a-9ba9f21785ee",
    APPLICATION_MAP_SERVICE: "6d6ae9e9-133f-4d39-b671-0621c89a31ff",
    EXTERNAL_MAP_SERVICE: "140bea69-b779-461c-b975-0b1eeb0ba709",
    PROXY_MAP_SERVICE: "bbe01d63-c7d5-4883-b80a-d390b417d63d",
    LAYER: "151dbf10-2622-4183-a596-24b173203cff",
    APPLICATION_ENTITY_TYPE: "948d3f18-8010-4aae-8aef-e32802d4cde4",
    EY_APP_ENTITY_TYPE: "e612a1cf-bcfb-4271-8879-1a24934c455d",
    RL_APP_ENTITY_TYPE: "7116c6ce-bf23-43a7-80f5-bf3f08ae91f7",
    LOCATION_APP_ENTITY_TYPE: "b93b97a4-36a5-4145-9b5a-4897514664fa",
    SUBTABLE_APP_ENTITY_TYPE: "d2526380-ea95-44e5-ae91-4b56b264908c",
    APPLICATION_DATA_VIEW: "a36d9706-5a36-4bd7-8fe8-d0c0e4bfa9e9",
    EY_APP_DATA_VIEW: "e3d0b716-0220-4fb7-8fe6-ba43076ee7d1",
    RL_APP_DATA_VIEW: "f4c3d940-2f97-4632-9c65-db1353605d6e",
    SUBTABLE_APP_DATA_VIEW: "11d001e7-d370-4b3a-8b59-9f8350bf27d8",
    CC_UGDM_ENTITY_TYPE: "c955521a-888d-4a86-8b15-e624e27b8268",
    CC_UGDM_COREMODEL_ENTITY_TYPE: "e3294380-af29-48e4-88a2-76e0314d5684",
    CC_UGDM_METAMODEL_ENTITY_TYPE: "a66abed3-bfaf-430f-aff7-0c5dee8cc111",
    CC_UGDM_DATA_VIEW: "924728fd-8910-4477-bc02-b832b57de74d",
    CC_UGDM_COREMODEL_DATA_VIEW: "1d9a079a-9200-4f6b-84e7-d3a83abca965",
    CC_UGDM_METAMODEL_DATA_VIEW: "ba547e3d-0ae7-4a01-a3fb-555f3dbd987a",
    ATTRIBUTE_GROUP: "a16203e2-cfc8-42d1-97cb-9edea459e2a1",
    TRIGGER: "220c6ecf-152f-4096-b6da-65f215a2b384",
    INSTEAD_OF_INSERT_TRIGGER: "705bcbbb-6fff-403f-8856-79cb3732554e",
    INSTEAD_OF_UPDATE_TRIGGER: "b64c267d-96c8-401b-99c9-2a0d4ec90596",
    INSTEAD_OF_DELETE_TRIGGER: "68d5f599-00e5-464e-86b8-f415198c5350",
    DOCUMENT_TEMPLATE: "56e6aadd-4ee3-422e-85fb-7f9360951074",
    WORD_TEMPLATE: "a3c2a235-d91e-468f-8d7d-9494c8807f2c",
    QR_BILL_TEMPLATE: "c8ccb8b5-c4d2-47d1-8612-f731bfdfb5d1",
    WORD_TEMPLATE_4_QR_BILL: "83b7314b-794a-4721-a337-b1ad1826085c",
    QGIS_LAYER_LAYOUT: "e57eca79-89eb-4815-9ae2-9c2d4a8b6134"
  },
  type: {
    UgdmEntities: "54eef4e6-fe59-4761-95d1-3848ecb34ef8",
    UgdmRelationships: "82d1f486-ff59-4e0c-a6eb-9487cab2fefd",
    UgdmMeasurements: "4b98d3fd-d9b6-41a1-9190-df5922e3b57a",
    UgdmArtifacts: "dca0dbb5-be88-40a8-a609-d16c7e5bf578",
    UgdmAttachments: "9ecf1656-ad6b-46b5-a9c1-7f58c5e9a3f5",
    UgdmActions: "8ba03c3e-429c-479b-bfa8-8df102adec48",
    UgdmReferences: "e591cc04-961c-40a0-8eb4-e94d6dd4e0eb",
    UgdmEntityDomains: "1a2a8f1e-2cc4-47b8-9c47-921aec30185c",
    UgdmEntityLocations: "a7a5a5d6-d10c-419e-9075-48fca000abf7",
    UgdmRouteEvents: "afa927ae-17ac-4ce3-9397-8d036ff38c9f",
    UgdmEntityLocs: "4cb2ec07-606f-41f2-993f-b9dbce70df99",
    UgdmEntityMeasurementTypes: "4ef9118f-5193-492b-ba59-99876debbcea",
    ENTITY: "d7ed2ac1-55cd-46fc-853a-cfdeb1fc4e7f",
    ENTITY_LOC: "c2c5a64c-f13d-4cde-bea4-b39260db0867",
    RELATIONSHIP: "749f6b5d-d509-41f4-9232-20458f153228",
    ENTITY_LOCATION: "439b5f3e-0bad-40f9-8b20-31de63d54f6a",
    MEASUREMENT: "873ae219-93f7-4978-aa36-8b0254b8a603",
    ACTION: "66b83abf-1367-46a3-bc82-2803904a168c",
    REFERENCE: "242faef8-af54-4ef2-a5c4-9896d6c3dc3d",
    ARTIFACT: "5d944694-18ce-4233-b5cc-2fe0c28e2f89",
    ENTITY_DOMAIN: "3dbd9be9-3748-44e4-b000-aefa856baf63",
    EY_TYPE: "9b48cc39-4bd8-4337-a7c3-4ab79f2bd3f8",
    UgdmAssociations: "767ec466-e5b9-4a69-bfb3-32f5e5cdc8d3",
    UgdmAttributes: "f2c29445-bddf-48c5-a6d5-e0c0433ce398",
    UgdmDomains: "d1b3059e-1e52-4bf8-9170-4beab145ea61",
    UgdmLocationTypes: "ba0d9618-0cec-4e44-922a-35e4c3949007",
    UgdmMeasurementTypes: "4e8ebadf-51aa-4bea-b44a-daae07ce4437",
    UgdmSrs: "5dc02239-d1a8-4ef9-b616-04d45e1b2fbb",
    UgdmSystems: "1c8aee01-d564-4840-8c1a-552f913d33f7",
    UgdmTypes: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    UgdmTypeDomains: "cb0a84f2-114c-49b8-adb6-34b88267ee19",
    UgdmTypeRelationships: "163782d5-65b8-48f3-92df-1d9e4f138970",
    UgdmUnits: "3300cba4-4cb7-4437-a2a4-992ba3d08715",
    UgdmEntityTypes: "354c18b6-11c6-4bf5-b18e-90721a5e49f8",
    UgdmEntityTypeAttributes: "a5bb434d-0c6f-4e62-9154-c025e31ea7cd",
    UgdmEntityViews: "5602ef82-f66d-416f-adfa-0f13f7ea2b56",
    UgdmEntityViewAttributes: "13db04ad-be18-478a-a42e-c37d9477c3d6",
    TYPE: "7ce3b429-c119-4fee-8747-d7c468443a20",
    TYPE_LOC: "7a4e8d12-f39c-4e16-ba53-48ab5b7e7ac8",
    ASSOCIATION: "bcad13e5-e125-4899-a4f1-922facc4e044",
    ATTRIBUTE: "643bd901-09b1-4026-9184-6da2e8659f0b",
    LOCATION_TYPE: "06a491d2-e565-48ff-b98a-90027d96f29d",
    MEASUREMENT_TYPE: "1f89d734-b251-4790-ac66-c35f912ad753",
    SRS: "063dcc97-bc5c-4444-9e33-0e1af2493542",
    SYSTEM: "355048dc-344b-4efb-870d-ad690c25b20d",
    UNIT: "542bd269-b5d8-42ab-98cd-9b7b749f546b",
    DOMAIN: "838d7a8b-19ef-4b13-b805-b4475ba70264",
    DOMAIN_LOC: "38bd9714-78d3-4ff5-a2ed-51318f0697dd",
    TYPE_RELATIONSHIP: "7819e233-4323-4f8f-9fa2-17c3d190cce9",
    TYPE_DOMAIN: "c2c1738d-2841-4bb3-b2f6-7a824d07f56c",
    UgdmDomainRoles: "02e49062-d3c6-4633-9d71-b2683d708daa",
    UgdmEntityUsers: "4d68c265-fdb6-448a-9be5-463206d9bff8",
    UgdmRoles: "042446d8-c7f3-42de-bf14-9c6054e2b1d6",
    UgdmTypeRoles: "f6e2b2fc-034d-49a3-9b6e-35042e227882",
    UgdmUsers: "a7957856-903d-42c7-b01b-bee705d54a8a",
    UgdmUserClaims: "ad717b7f-71c2-48f3-9d2c-120d5ef8244c",
    UgdmUserLogins: "4ce60ebf-184f-4b91-aa6d-8cb30c654682",
    UgdmUserRoles: "5ddeb68c-8aaf-4fe9-a6c9-ff408686414a",
    IM_USER: "ea1ca61a-c622-4697-a8fd-b5811fec909e",
    IM_ROLE: "9e02f304-3422-4282-bbee-0e0a1559f3b3",
    IM_USER_ROLE: "a7942d8c-042d-43ea-b66e-70ebbb3b39d4",
    IM_TYPE_ROLE: "ff3c6b6e-0d95-42b6-a1b1-728b6baec5c1",
    IM_DOMAIN_ROLE: "c0a2caf0-5314-4633-8057-43834ac9b9c1",
    IM_ENTITY_USER: "6f42aa94-348a-407a-ab89-1077292e7e21",
    IM_USER_CLAIM: "c5624e77-c2fd-49f3-b4b9-07bb6ccfc789",
    IM_USER_LOGIN: "0e18ea57-3870-4d69-8902-b1bb6d6685dd"
  },
  dataType: {
    Binary: "cf5692e2-995d-4fef-a0fb-ff5ad2fa539f",
    Boolean: "3f57a243-2101-42a7-aaf0-c44ee1711959",
    DateTime: "fdc9c7f4-f51f-401c-8bbd-3b110075cd42",
    DateTimeOffset: "5126592b-4556-43f5-bcc8-c43ccff8f603",
    Decimal: "6c94ce5d-7797-4982-904c-a4f23fe172d6",
    Double: "9f3c00ed-fe47-43e0-9d39-04166de0e388",
    Guid: "90d61d2d-d160-4b3d-bf25-d86ec7c7568f",
    Int16: "11911b7b-c524-409b-8dc6-8d6030a5fa8e",
    Int32: "3f178485-728e-47a0-a765-12ef0993e2e4",
    Int64: "6445ba77-1b3c-48d3-b1d4-4688f1ca10f2",
    String: "eb2d052b-d5ab-4eaf-bada-70d65634efc4",
    Time: "ab63be43-18aa-42fd-af86-74ac97b5a438",
    Geometry: "c3b3732f-d7e0-4b5c-b1c9-6c473f64f63d",
    GeometryCollection: "cfc5eb8e-f4ee-45ff-b8ca-e6818bec155c",
    Point: "466b3237-7d19-4158-aade-972a90f2fb75",
    LineString: "35f61c67-43f0-4f67-bf47-3d8631024ce7",
    Polygon: "0d2320ac-8ff0-422e-b0ce-212bfa72139a",
    MultiPoint: "0c720b57-d363-4ca6-968d-87dd7ea4a3b5",
    MultiLineString: "2743d3e3-d01d-4e7e-9570-cb4e9f77b512",
    MultiPolygon: "3af88494-75ce-4270-82ae-bb9d1e5585cf"
  },
  culture: {
    en: "71ba727b-70e9-49c7-90dd-11051bc9621f",
    de: "46ba9cdc-f416-421b-a38f-75715f6a1554",
    fr: "7ea5ba17-21b3-4184-a8ab-4c7b92b04928",
    it: "1e3539b7-4c23-4f9b-b77d-d5a6414a8e84",
    es: "caef8ede-cc06-4236-8328-5e8833453d5a"
  },
  role: {
    Ugdm_Anonymous: "7e36d072-9e21-437e-b77d-da9366b679ee",
    Ugdm_Administrator: "f9267cfc-911f-448f-87e2-e7098d41f3ba",
    Ugdm_Application_Administrator: "0ca5e15d-a145-40d7-a191-84dba9d8d7da",
    Ugdm_Application_Meta_Data_Read: "6d3a983d-ae11-4960-afa5-9481daacde5e",
    Ugdm_Application_Meta_Data_Modify: "c884b7d4-4954-417f-8a50-7c4b1e480040",
    Ugdm_Application_Meta_Data_Create: "cd7aeec8-9e00-4d99-88ed-9dc8125bbb2c",
    Ugdm_Application_Meta_Data_Update: "3937ff60-dcf5-4e25-96ab-8f3a9bbbc35a",
    Ugdm_Application_Meta_Data_Delete: "1d2b1ec6-7243-4f2f-8bfc-e7cb323e6848",
    Ugdm_Application_Data_Read: "3b05736e-843b-4095-a053-e2f5c033c81c",
    Ugdm_Application_Data_Modify: "7ab38998-1e1e-48fc-8d4c-e07402345dff",
    Ugdm_Application_Data_Create: "b22fdec7-f546-433b-93f0-2042eec86b6f",
    Ugdm_Application_Data_Update: "fba8c669-e2f0-4634-96da-2579e4ae8b68",
    Ugdm_Application_Data_Delete: "358e1fe8-d4f8-4a6e-8ae5-242300fe3d22"
  },
  product: {
    PRODUCT: "7251b61e-e715-4360-aa1b-2cc753fed339",
    UGDM_GENERIC_PRODUCT: "2119969d-ecf6-4486-af95-70a6aa6b078d",
    APPLICATION_SERVER_PRODUCT: "55a101ff-8ea7-406e-a60d-409aff697876",
    IIS: "22046372-3469-4308-a4bd-120c73fa849a",
    DBMS_PRODUCT: "102f08fe-90d6-4d5a-9d30-9081235e5d5c",
    SQLITE: "4b8daa9f-4561-4ac7-bde3-32c0cf0e26fa",
    SQLSERVER: "00101118-424e-4104-85a6-0fc8ecd96f51",
    ORACLE: "395a71d7-de7c-4c54-b0a2-56f0a2b94ce1",
    SQLSERVER2014: "01b63fdf-6404-489e-97e0-a34909ff9a7b",
    SQLSERVER2008: "22ec306e-da2a-4007-a720-c694c04a1bea",
    SQLSERVER2012: "7be4e4eb-a451-461a-bba2-f359b2633140",
    ORACLE12: "326a6e4f-fd25-4928-a740-72e16e041b30",
    ORACLE11: "520a54bc-62eb-45ee-aba6-a4681c4b853e",
    ORACLE10: "77ee367c-348f-4a0e-8e33-d57c99dbaa65",
    WEB_MAPPING_SERVER_PRODUCT: "9a47dbf2-68d1-405d-8488-75b3988d684a",
    WMS_1_3_0: "d4fdfb09-9d40-4513-b92a-2783910a8660",
    WMS_1_1_1: "f1ca2eb9-ccce-42b1-80ad-32356bc774c8",
    WMTS_1_0_0: "667d862c-45cd-4f56-8aff-5ebc7cfd809f",
    GEO_JSON_1_0: "e21f5a49-533d-4338-8230-9fbe5aa032c0",
    WFS_2_0: "1d63d76c-be30-4dd7-9593-50180a7e3cf8",
    BING_MAPS_1: "7d940ab9-1cb8-4c5c-ae31-b82152ab56a8",
    GOOGLE_MAPS: "cbbee68e-1c9a-4716-9574-97b1b0656fd7"
  },
  view: {
    DOMAIN: "d1b3059e-1e52-4bf8-9170-4beab145ea61",
    TYPE: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    ATTRIBUTE: "f2c29445-bddf-48c5-a6d5-e0c0433ce398",
    ASSOCIATION: "767ec466-e5b9-4a69-bfb3-32f5e5cdc8d3",
    LOCATION_TYPE: "ba0d9618-0cec-4e44-922a-35e4c3949007",
    SRS: "5dc02239-d1a8-4ef9-b616-04d45e1b2fbb",
    SYSTEM: "1c8aee01-d564-4840-8c1a-552f913d33f7",
    MEASUREMENT_TYPE: "4e8ebadf-51aa-4bea-b44a-daae07ce4437",
    UNIT: "3300cba4-4cb7-4437-a2a4-992ba3d08715",
    RELATIONSHIP_ASSOCIATION: "c6285393-70a0-41ee-b01d-bedaf7757e2d",
    EY_APP_ENTITY_TYPE: "354c18b6-11c6-4bf5-b18e-90721a5e49f8",
    LOCATION_APP_ENTITY_TYPE: "354c18b6-11c6-4bf5-b18e-90721a5e49f8",
    RL_APP_ENTITY_TYPE: "354c18b6-11c6-4bf5-b18e-90721a5e49f8",
    SUBTABLE_APP_ENTITY_TYPE: "354c18b6-11c6-4bf5-b18e-90721a5e49f8",
    APPLICATION_DATA_VIEW: "5602ef82-f66d-416f-adfa-0f13f7ea2b56",
    EY_APP_DATA_VIEW: "5602ef82-f66d-416f-adfa-0f13f7ea2b56",
    RL_APP_DATA_VIEW: "5602ef82-f66d-416f-adfa-0f13f7ea2b56",
    SUBTABLE_APP_DATA_VIEW: "5602ef82-f66d-416f-adfa-0f13f7ea2b56",
    CC_UGDM_COREMODEL_ENTITY_TYPE: "354c18b6-11c6-4bf5-b18e-90721a5e49f8",
    CC_UGDM_METAMODEL_ENTITY_TYPE: "354c18b6-11c6-4bf5-b18e-90721a5e49f8",
    CC_UGDM_DATA_VIEW: "5602ef82-f66d-416f-adfa-0f13f7ea2b56",
    CC_UGDM_COREMODEL_DATA_VIEW: "5602ef82-f66d-416f-adfa-0f13f7ea2b56",
    CC_UGDM_METAMODEL_DATA_VIEW: "5602ef82-f66d-416f-adfa-0f13f7ea2b56",
    ENTITY_TYPE: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    CULTURE: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    TIME_ZONE: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    DATA_TYPE: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    CARDINALITY: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    RELATIONSHIP_ROLE: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    CODE_LIST: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    STATUS_LIST: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    MIME_TYPE: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    COMPLEX_ATTRIBUTE_TYPE: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    MEASURAND: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    METHOD: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    MEASUREMENT_PROGRAMME: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    DATABASE_CATALOG: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    DATABASE_SCHEMA: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    DATABASE_TABLE: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    DATABASE_VIEW: "820cedc2-9bce-4b26-a5f2-22c152e526f9",
    USER: "a7957856-903d-42c7-b01b-bee705d54a8a",
    ROLE: "042446d8-c7f3-42de-bf14-9c6054e2b1d6",
    TYPE_ROLE: "f6e2b2fc-034d-49a3-9b6e-35042e227882",
    DOMAIN_ROLE: "02e49062-d3c6-4633-9d71-b2683d708daa",
    ENTITY_USER: "4d68c265-fdb6-448a-9be5-463206d9bff8",
    LOGIN: "4ce60ebf-184f-4b91-aa6d-8cb30c654682",
    CLAIM: "ad717b7f-71c2-48f3-9d2c-120d5ef8244c"
  },
  typeRelationshipType: {
    APPLICATION_VIEW_2_ET: "5a5c8b7d-67d3-4e60-a5c2-0bd201766ffe",
    FROM_RL_ET: "2145d80b-dd7a-4a28-b526-87e34204f2cc",
    TO_RL_ET: "ff2d2e51-6bd3-4c20-a5cf-8bfeebac7565",
    FROM_RL_EV: "431d34fa-0bf5-47f6-8308-905709ea6c63",
    TO_RL_EV: "372dd9d5-184c-45a7-bf38-f5d82a477c64",
    RT_2_ET: "bfaac854-c6f9-42c6-b3f9-af9e75f90c0b",
    RA_2_RT: "40915410-34a1-488e-9648-c24640a633db",
    ATTRIBUTE_MAPPING: "accb18fc-51b3-4b28-9a2a-0cc1b5855d77",
    DATABASE_TABLE_2_ET: "2b5a009d-5fb1-4482-a61f-9a6d1bb1c11e",
    DATABASE_VIEW_2_DATABASE_TABLE: "b749ed9a-da10-4ab3-b090-06c7b4d79495",
    DATABASE_PROCEDURE_2_DATABASE_VIEW: "f8cc5b92-a18e-4aac-93e2-9d930c25c44a",
    DATABASE_VIEW_2_APPLICATION_VIEW: "c41d58ea-b17b-499c-aa44-eeab8b6cbdfd",
    SCHEMA_2_CATALOG: "ce37ab1e-c567-4b1b-a2f7-0699c75d5800",
    DATABASE_ARTIFACT_2_SCHEMA: "40d0a4a9-f681-4a28-b79e-2b5cfa16c569",
    SERVICE_2_APPLICATION_VIEW: "94b92c3f-7214-4e48-b671-fb6880a8c9c2",
    DT_DT: "fe641102-3ecc-4392-b76b-85bb37f119e7",
    MP_MT: "525044d8-2933-4572-a9f7-f3fe64b82f4d",
    ET_MT: "c31ab0ba-d87c-4f09-b172-3a5dad6685f1",
    MP_ET: "dac82600-b29a-4c1e-b10e-23ca84fb4207",
    ST_ST: "04755ad9-bd93-479b-8505-abb819dd64a1",
    LY_ET: "0766343d-d88b-4745-8cc7-6480cc4e46ad",
    GEO_TABLE_2_LT: "31ecbcb9-b9b3-43ba-b104-ed2dc0297421",
    HTML_PAGE_CONTENT: "3a0e0e81-ecdd-4eb7-a3e9-ece09506b928",
    APPLICATION_VIEW_2_AS: "7cc8f37d-7310-41b0-a95c-10c15e6378c9",
    ET_SYS_4_RERFERENCE: "67605ee9-f766-4420-9239-3aae14e70195",
    ET_EY_CODE_LIST: "21761eed-4ba2-4d18-93dd-aef7cc0fa6b8",
    ET_EY_STATUS_LIST: "39efaf71-7716-470e-895c-8f05de3b5e3d",
    ET_LOCATION: "e963f283-4e64-46bd-b18d-3010cd8d5dbc",
    CORE_FEATURE: "cc3bc30e-00fa-4833-bc6a-ba57c456788b",
    EV_ACTION_VIEW: "ae92ae4f-e973-437c-8788-c5c92b591022",
    LAYER_SERVICE: "9b79918f-e5dd-4de4-8e63-bb8c1c372ef1",
    LAYER_LOCATION_TYPE: "aa0f5ffb-d57d-43fd-9577-566a7041c363",
    LAYER_MIME_TYPE: "dba80d46-de21-4ac2-a748-d2b5d9b3dec8",
    ATTRIBUTE_2_ATTRIBUTE_GROUP: "83c2b1cb-6c58-4bf1-8d6b-f17f05d8e5e7",
    IIT_4_VIEW: "ef4a8d19-084d-4ccd-a852-4360c5f25fb5",
    IUT_4_VIEW: "85054de8-da04-4267-a1e0-d0d5362e955a",
    IDT_4_VIEW: "c7963f05-d368-4f9a-a0b6-1a83cd2218ec",
    EXTERNAL_QUERY_SERVICE: "6c2df20d-e70a-499b-b610-9544d4957f5c",
    PROCEDURE_4_VIEW: "d112d82e-9d8e-4252-831b-467787c1a8cd",
    WORD_TEMPLATE_4_VIEW: "c7bb2691-72e8-4135-b846-6144335b593c",
    QR_BILL_TEMPLATE_4_VIEW: "1bbf4fbf-3724-4095-80a3-3b8bcf6f4f11",
    WORD_TEMPLATE_4_QR_BILL: "526d4616-d90f-4227-afaa-51c9f9eaf0e4",
    LAYER_QGIS_LAYOUT: "84122fa0-4548-4693-8ee6-f7febf51b126"
  },
  complexAttributeType: {
    SimpleAttribute: "61754fb2-dbcc-4d25-a615-de1be8e62e96",
    CodeList: "7d627986-bbc3-435d-b5ac-999da9336538",
    CodeTree: "da5d9979-0386-41a4-aefe-26f2fca81050",
    StatusList: "f01d961e-2472-470c-9e27-0e8ce36d5720",
    StatusTree: "5cef109b-f4c5-41a1-a092-783e2e857688",
    MonovaluedGeometry: "770441e4-27fa-47a0-a481-07a364a9eff0",
    MultivaluedGeometry: "252f1c39-e2e0-4044-8060-cc5d6b2fe26a",
    SubTable: "fcbc797b-273d-4548-a433-4712b76ce66f",
    GeometryAttribute: "eda6f45e-7865-48f9-afd0-6d3f54e295de",
    DomainList: "9c4ea662-f6ef-4886-b61a-9ee06ba97212",
    ParentLink: "102ab2be-e124-4c6e-86b7-6ddb6ee7645a",
    MonovaluedRelationship: "540b1179-ef4e-4915-b4f2-d09abc0c351f",
    MultivaluedRelationship: "9db6851b-89e5-4f6a-a061-fb77a8a95ce5",
    ForeignKey: "4462f2f1-415d-4af1-a5d7-23e21c80e5e8",
    EntityList: "4aaaaf1e-53cd-41fe-b83e-b7802323af9a",
    EntityCodeList: "405e0ddc-1528-49ef-9ae5-8c05852e3614"
  },
  entityTypes: {
    Actor: "a7c2c35c-a1c6-4bb6-a6cb-63d25aeb0b46"
  },
  entityAttributeIds: {
    EY_ID: "4d59d150-a853-4f91-a6ac-a43b3db1bee7",
    EY_TYPE: "9b48cc39-4bd8-4337-a7c3-4ab79f2bd3f8",
    EY_NAME: "4370d07a-dcd4-41a1-8528-0fdbbc76444a",
    EY_LONGNAME: "3461f254-2618-42a1-b15f-30a25076b6f4",
    EY_DESCRIPTION: "c9bcb875-1362-463c-a343-326e72d1afe4",
    EY_REMARKS: "8383a5ef-d9cd-45df-821d-1b9b0b3a1acf",
    EY_CODE: "3c38f605-cf36-4dea-8b12-f29fbe197483",
    EY_STATUS: "30d79620-b131-4121-99f7-67dd6cc2d878",
    EY_START: "c76cabd0-ff26-4f7a-a68d-4624d9dba272",
    EY_END: "bbb99e79-be93-4fd6-ad04-6da1073e6c06",
    EY_CREATED: "dc5b6f7d-8dd0-4478-a550-30cfadaf32f7",
    EY_MODIFIED: "51ab3da9-1a5f-4c51-91e0-0782c1e895be",
    EY_CREATED_BY: "60bdcf47-7661-4ad7-8292-d5494838b2d6",
    EY_MODIFIED_BY: "08c4f32e-0f84-422a-8dc2-57394ec92b87",
    EY_OWNER: "1de79313-1883-46c8-9f4a-289c791bb9d6",
    EY_PRIVATE: "651ed8fe-2b69-41cc-af79-0b35ef47298d",
    EY_RESPONSIBLE: "0fb6217c-f849-4d5f-9588-76ffb4e0eb5a"
  },
  entityFieldNameEndings: [
    "ID",
    "EY_TYPE",
    "EY_NAME",
    "EY_LONGNAME",
    "EY_DESCRIPTION",
    "EY_REMARKS",
    "EY_CODE",
    "EY_STATUS",
    "EY_START",
    "EY_END",
    "EY_CREATED",
    "EY_MODIFIED",
    "EY_OWNER",
    "EY_PRIVATE",
    "EY_RESPONSIBLE",
    "EY_CREATED_BY",
    "EY_MODIFIED_BY"
  ],
  entityFieldTypes: [
    "Guid",
    "Guid",
    "String",
    "String",
    "String",
    "String",
    "Guid",
    "Guid",
    "DateTime",
    "DateTime",
    "DateTime",
    "DateTime",
    "Guid",
    "Boolean",
    "Guid",
    "Guid",
    "Guid"
  ]
};
export const numericFieldTypes = ["Int16", "Int32", "Int64", "Decimal", "Double"];
export const geometryFieldTypes = ["Point", "MultiPoint", "LineString", "MultiLineString", "Polygon", "MultiPolygon"];
export const geometryTypeNames = ["Point", "MultiPoint", "LineString", "MultiLineString", "Polygon", "MultiPolygon"];
export const logLevel = "INFO";
