/* src\components\UserRights\UserRights.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	attribute_to_object,
	binding_callbacks,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	globals,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots
} from "../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';
import { requestHeaders } from "../../services/ajaxService.js";
import { constants } from "../../constants.js";
import '../../services/parameterService.js';
import TileWMS from "../../../web_modules/ol/source/TileWMS.js";
import TileGrid from "../../../web_modules/ol/tilegrid/TileGrid.js";
import "../../../web_modules/ol/Map.js";
import WMTSCapabilities from "../../../web_modules/ol/format/WMTSCapabilities.js";
import WMTS, { optionsFromCapabilities } from "../../../web_modules/ol/source/WMTS.js";
import { getLayerSourceFunctionsMap } from "../Map/map-utils.js";
import ImageWMS from "../../../web_modules/ol/source/ImageWMS.js";
import { dispatchMountedEvent } from "../../utilities/componentUtils.js";
import { handleErrorInAlert } from "../../utilities/alertNotificationUtils.js";
import { ongoingRequestHandler } from "../../utilities/requestUtils.js";
import "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\UserRights\\UserRights.svelte";

function create_fragment(ctx) {
	let div;

	const block = {
		c: function create() {
			div = element_1("div");
			this.c = noop;
			add_location(div, file, 242, 0, 14046);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			/*div_binding*/ ctx[4](div);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			/*div_binding*/ ctx[4](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('user-rights', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { readyevent = "RIGHTS_LOADED" } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			component = element.getRootNode().host || element.parentNode; // get the component/host

			// typed: (element.getRootNode() as ShadowRoot).host as HTMLElement
			tick().then(() => {
				dispatchMountedEvent(component);
				mounted = true;
				initComponent();
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	// This is the MOST IMPORTANT STARTUP FUNCTION to fetch and set all view metadata, layers and user rights
	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	const initComponent = async () => {
		firstInitialized = true;

		try {
			// get all views and layers with the rights
			// extract rights, dataViews, layers and views with layers
			// adds LAYER_OBJECT_+layerGuid
			let MetadataPathPrefix = "/BcOdata/WebApi/ResourceMetadata/AllViews/" + bc.parameterService.appParameters.languageGuid;

			// TODO change for new AllViews query
			// TY_CULTURE eq guid'46ba9cdc-f416-421b-a38f-75715f6a1554' and (TY_DOMAIN eq guid'e3d0b716-0220-4fb7-8fe6-ba43076ee7d1' or TY_DOMAIN eq guid'151dbf10-2622-4183-a596-24b173203cff')
			if (bc.parameterService.appParameters.serverVersion >= "4.0") {
				MetadataPathPrefix = "Main/Metadata/UgdmTypes?$filter=(TY_DOMAIN eq " + constants.domain.EY_APP_DATA_VIEW + " or TY_DOMAIN eq " + constants.domain.LAYER + ") and TY_CULTURE eq " + bc.parameterService.appParameters.languageGuid;
			}

			let url = bc.parameterService.appParameters.applicationBaseUrl + MetadataPathPrefix; // '46ba9cdc-f416-421b-a38f-75715f6a1554'
			let request = await bc.ajaxService.fetchRaw(requestHeaders.jsonGet, url);

			if (request) {
				let response = await request.json();

				// TODO remove when services are available
				if (bc.parameterService.appParameters.serverVersion >= "4.0" && response && response.value) {
					response = response.value.filter(t => t['TY_DOMAIN'] == constants.domain.EY_APP_DATA_VIEW || t['TY_DOMAIN'] == constants.domain.LAYER);
				}

				let rights = [];
				let listOfViewsWithLayer = [];
				let dataViews = [];
				let udgmLayers = [];
				let dataviewsToLayers = [];

				for (let i = 0; i < response.length; i++) {
					let element = response[i];

					if (element['TY_DOMAIN'] === constants.domain.EY_APP_DATA_VIEW) {
						// TODO take real values instead of dummy
						if (bc.parameterService.appParameters.serverVersion >= "4.0") {
							rights[element['TY_ID']] = {
								canRead: true,
								canModify: true,
								canDelete: true,
								canCreate: true
							};
						} else {
							rights[element['TY_ID']] = {
								canRead: element['canRead'],
								canModify: element['canModify'],
								canDelete: element['canDelete'],
								canCreate: element['canCreate']
							};
						}

						dataViews.push(element);
					}

					if (element['TY_DOMAIN'] === constants.domain.LAYER) {
						udgmLayers.push(element);

						// console.log('LAYER TO ADD', element['TY_LONGNAME'], element['TY_ID'])
						bc.parameterService.set('LAYER_OBJECT_' + element['TY_ID'], element);

						// TODO make a general function to get the layer source
						// caution: function uses "element" from outside -> pass it in as a parameter
						let sourceF = async function getLayerSource(layerObject, map) {
							let dummy = element.TY_NAME;

							if (bc.parameterService.get('LAYER_SOURCE_' + layerObject.TY_ID)) {
								return bc.parameterService.get('LAYER_SOURCE_' + layerObject.TY_ID);
							} else {
								if (layerObject['LY_MS_SERVICE'] === 'WMS') {
									let source = null;

									// tiles 256 not documented -> set as explicit metadata
									if (layerObject.TY_REMARKS && layerObject.TY_REMARKS.indexOf('tiles') !== -1) {
										let tileSize = 256;

										if (layerObject.TY_REMARKS.indexOf('256') !== -1) {
											tileSize = 256;
										}

										if (layerObject.TY_REMARKS.indexOf('512') !== -1) {
											tileSize = 512;
										}

										if (layerObject.TY_REMARKS.indexOf('1024') !== -1) {
											tileSize = 1024;
										}

										source = new TileWMS({
												cacheSize: 250,
												transition: 0,
												url: element.LY_MS_URI,
												crossOrigin: 'anonymous',
												// url: 'http://127.0.0.1:8011/service?',
												params: {
													LAYERS: element.TY_NAME,
													FORMAT: element.LY_MT_NAME,
													VERSION: element.LY_MS_VERSION,
													TILED: true
												},
												tileGrid: new TileGrid({
														extent: [
															element.LY_LT_X_MIN,
															element.LY_LT_Y_MIN,
															element.LY_LT_X_MAX,
															element.LY_LT_Y_MAX
														],
														tileSize,
														resolutions: map.getView().getResolutions()
													}),
												serverType: 'mapserver'
											});
									} else {
										source = new ImageWMS({
												// cacheSize: 250,
												// transition: 0,
												url: layerObject.LY_MS_URI,
												crossOrigin: 'anonymous',
												// url: 'http://127.0.0.1:8011/service?',
												params: {
													LAYERS: layerObject.TY_NAME,
													FORMAT: layerObject.LY_MT_NAME,
													VERSION: layerObject.LY_MS_VERSION
												}, // TILED: true,
												serverType: 'mapserver'
											});
									}

									bc.parameterService.set('LAYER_SOURCE_' + layerObject.TY_ID, source);
									return source;
								} else if (layerObject.LY_MS_SERVICE === 'WMTS') {
									const parser = new WMTSCapabilities();
									let capabilitesResult;
									let requestData = await ongoingRequestHandler(layerObject.LY_MS_URI, requestHeaders.xmlGet, 0, 'text');
									capabilitesResult = parser.read(requestData);
									const searchedLayer = capabilitesResult.Contents.Layer.find(l => l.Identifier === layerObject.TY_NAME);
									let matrixSetName = searchedLayer.TileMatrixSetLink[0].TileMatrixSet;
									const matrixSet = capabilitesResult.Contents.TileMatrixSet.find(tms => tms.Identifier === matrixSetName);

									var options = optionsFromCapabilities(capabilitesResult, {
										layer: layerObject.TY_NAME,
										matrixSet: matrixSet.Identifier,
										crossOrigin: 'anonymous'
									});

									const source = new WMTS(options);

									// source.tileGrid = tileGrid
									source.crossOrigin = 'anonymous';

									bc.parameterService.set('LAYER_SOURCE_' + layerObject.TY_ID, source);
									return source;
								}

								return null;
							}
						};

						// makes not much sense because the same function is registered for all layers
						getLayerSourceFunctionsMap.set(element.TY_ID, sourceF);

						// check if there is a view for the layer (same name of layer and view)
						const view = response.find(r => r['TY_NAME'] === element['TY_NAME'] && r['TY_DOMAIN'] === constants.domain.EY_APP_DATA_VIEW);

						bc.messageService.publish('ADD_LAYER', {
							bcLayerId: element['TY_ID'],
							opacity: element['TY_DECIMAL'],
							viewId: view ? view['TY_ID'] : '',
							active: element['TY_REMARKS'] && element['TY_REMARKS'].indexOf('START') !== -1
						});

						if (view) {
							// if(!listOfViewsWithLayer.find(l=> l.TY_ID === view.TY_ID)){
							listOfViewsWithLayer.push(view);

							dataviewsToLayers.push({ dataview: view, layer: element });
						} // }
					}
				}

				bc.parameterService.set('RIGHTS', rights);
				bc.parameterService.set('VIEWS_WITH_LAYERS', listOfViewsWithLayer);
				bc.parameterService.set('VIEWS_WITH_LAYER_SET', true);
				bc.parameterService.set('DATAVIEWS', dataViews);
				bc.parameterService.set('LAYERS', udgmLayers);
				bc.parameterService.set('DATAVIEW_TO_LAYER', dataviewsToLayers);
				bc.parameterService.set(readyevent, true);
				bc.messageService.publish(readyevent);
				console.log('RIGHTS', rights);
				console.log('DATAVIEWS', dataViews);
				console.log('LAYERS', udgmLayers);
			}
		} catch(error) {
			handleErrorInAlert(error, generalText.componentInitError + ': User-Rights');
			console.error(generalText.componentInitError + ': User-Rights', error);
		}

		console.log("USER_RIGHTS initialized", bc.parameterService.get('RIGHTS'), bc.parameterService.get('DATAVIEWS'), bc.parameterService.get('LAYERS'));
	}; // console.log(getLayerSourceFunctionsMap);

	const writable_props = ['show', 'init', 'readyevent'];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(0, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(1, show = $$props.show);
		if ('init' in $$props) $$invalidate(2, init = $$props.init);
		if ('readyevent' in $$props) $$invalidate(3, readyevent = $$props.readyevent);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		requestHeaders,
		constants,
		TileWMS,
		TileGrid,
		WMTSCapabilities,
		WMTS,
		optionsFromCapabilities,
		getLayerSourceFunctionsMap,
		ImageWMS,
		dispatchMountedEvent,
		handleErrorInAlert,
		ongoingRequestHandler,
		getComponentText,
		show,
		init,
		readyevent,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		component,
		element,
		initComponent
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(1, show = $$props.show);
		if ('init' in $$props) $$invalidate(2, init = $$props.init);
		if ('readyevent' in $$props) $$invalidate(3, readyevent = $$props.readyevent);
		if ('firstInitialized' in $$props) firstInitialized = $$props.firstInitialized;
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) ready = $$props.ready;
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) $$invalidate(0, element = $$props.element);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [element, show, init, readyevent, div_binding];
}

class UserRights extends SvelteElement {
	constructor(options) {
		super();

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 1, init: 2, readyevent: 3 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "readyevent"];
	}

	get show() {
		return this.$$.ctx[1];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[2];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get readyevent() {
		return this.$$.ctx[3];
	}

	set readyevent(readyevent) {
		this.$$set({ readyevent });
		flush();
	}
}

customElements.define("user-rights", UserRights);
export default UserRights;