/* src\components\Map\MapTools\MapShareMapLocation\MapShareMapLocation.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	globals,
	init,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	validate_slots
} from "../../../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../../../bcShim.js';
import '../../../../../web_modules/dom-to-image.js';
import { v4 as uuidv4 } from "../../../../../web_modules/uuid.js";
import { onMount, tick } from "../../../../../web_modules/svelte.js";

import {
	attachNormalize,
	dispatchMountedEvent
} from "../../../../utilities/componentUtils.js";

import {
	copyToClipboard,
	getComponentText
} from "../../../../utilities/generalUtils.js";

import qs from '../../../../../web_modules/qs.js';
import { notify } from "../../../../utilities/alertNotificationUtils.js";
const file = "src\\components\\Map\\MapTools\\MapShareMapLocation\\MapShareMapLocation.svelte";

// (1777:4) {#if ready}
function create_if_block(ctx) {
	let div;
	let fa_icon;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div = element_1("div");
			fa_icon = element_1("fa-icon");
			set_custom_element_data(fa_icon, "icon", "far fa-share-nodes");
			set_custom_element_data(fa_icon, "color", "white");
			set_custom_element_data(fa_icon, "size", "1.5");
			set_custom_element_data(fa_icon, "hovercolor", "orange");
			add_location(fa_icon, file, 1778, 12, 32822);
			attr_dev(div, "class", "relative cursor-pointer !h-10 !w-10 flex items-center justify-center");
			add_location(div, file, 1777, 8, 32726);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, fa_icon);

			if (!mounted) {
				dispose = listen_dev(fa_icon, "click", /*sharelocation*/ ctx[2], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(1777:4) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[0] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 1775, 0, 32674);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[4](div);
		},
		p: function update(ctx, [dirty]) {
			if (/*ready*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[4](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('map-tool-share-map-location', slots, []);
	let ready = false;
	const generalText = getComponentText('general');
	const componentText = getComponentText('mapComponent');
	let mounted = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let map;
	let componentId = uuidv4();

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			component = element.getRootNode().host || element.parentNode; // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				mounted = true;
			});
		});

		return () => {
			
		};
	});

	const initComponent = newMap => {
		map = newMap;
		$$invalidate(0, ready = true);
	};

	const sharelocation = e => {
		let mapRoute = bc.utils.getRoute('map');

		if (mapRoute) {
			let link = mapRoute.create();
			let center = map.getView().getCenter();
			let zoom = map.getView().getZoom();
			let component = map.get('HTML');
			let activeLayers = component.getActiveLayers();
			let mapObject = { center, zoom, layers: activeLayers };
			let urlObj = new URL(bc.parameterService.appParameters.pageName);
			link += `?${qs.stringify(mapObject)}`;
			copyToClipboard(urlObj.origin + link);
			console.log('link', urlObj.origin + link);
			notify(componentText.mapSettingsShared);
		}
	};

	const writable_props = [];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		copyToClipboard,
		getComponentText,
		qs,
		notify,
		ready,
		generalText,
		componentText,
		mounted,
		component,
		element,
		map,
		componentId,
		initComponent,
		sharelocation
	});

	$$self.$inject_state = $$props => {
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
		if ('map' in $$props) map = $$props.map;
		if ('componentId' in $$props) componentId = $$props.componentId;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [ready, element, sharelocation, initComponent, div_binding];
}

class MapShareMapLocation extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{left:0;right:0}.cursor-pointer{cursor:pointer}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.\\!h-10{height:2.5rem !important}.relative{position:relative}.\\!w-10{width:2.5rem !important}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ initComponent: 3 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["initComponent"];
	}

	get initComponent() {
		return this.$$.ctx[3];
	}

	set initComponent(value) {
		throw new Error("<map-tool-share-map-location>: Cannot set read-only property 'initComponent'");
	}
}

customElements.define("map-tool-share-map-location", MapShareMapLocation);
export default MapShareMapLocation;