/* src\components\DocumentationHelper\DataviewSelector\DataviewSelector.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	prop_dev,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text,
	validate_each_argument,
	validate_slots,
	validate_store
} from "../../../../web_modules/svelte/internal.js";

import bc from '../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../web_modules/svelte.js';
import { constants } from "../../../constants.js";

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../../utilities/componentUtils.js";

import { applicationReady } from "../../../utilities/store.js";
import { getComponentText } from "../../../utilities/generalUtils.js";
const file = "src\\components\\DocumentationHelper\\DataviewSelector\\DataviewSelector.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[18] = list[i];
	return child_ctx;
}

// (2098:4) {:else}
function create_else_block(ctx) {
	let loading_spinner;
	let loading_spinner_text_value;

	const block = {
		c: function create() {
			loading_spinner = element_1("loading-spinner");
			set_custom_element_data(loading_spinner, "text", loading_spinner_text_value = /*generalText*/ ctx[4].loading);
			add_location(loading_spinner, file, 2098, 8, 39786);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2098:4) {:else}",
		ctx
	});

	return block;
}

// (2088:4) {#if ready}
function create_if_block(ctx) {
	let div;
	let h3;
	let t1;
	let select;
	let mounted;
	let dispose;
	let each_value = /*allDataviews*/ ctx[2];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			div = element_1("div");
			h3 = element_1("h3");
			h3.textContent = "Datensicht auswählen";
			t1 = space();
			select = element_1("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr_dev(h3, "class", "text-lg");
			add_location(h3, file, 2089, 12, 39346);
			attr_dev(select, "class", "h-10 border border-solid border-gray-300 rounded p-2 focus:outline-none");
			add_location(select, file, 2090, 12, 39405);
			attr_dev(div, "class", "flex items-center gap-2");
			add_location(div, file, 2088, 8, 39295);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, h3);
			append_dev(div, t1);
			append_dev(div, select);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(select, null);
				}
			}

			/*select_binding*/ ctx[13](select);

			if (!mounted) {
				dispose = listen_dev(select, "change", /*publishSelectedDataview*/ ctx[5], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty & /*allDataviews*/ 4) {
				each_value = /*allDataviews*/ ctx[2];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			destroy_each(each_blocks, detaching);
			/*select_binding*/ ctx[13](null);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2088:4) {#if ready}",
		ctx
	});

	return block;
}

// (2093:16) {#each allDataviews as view}
function create_each_block(ctx) {
	let option;
	let t_value = /*view*/ ctx[18].TY_LONGNAME + "";
	let t;
	let option_value_value;

	const block = {
		c: function create() {
			option = element_1("option");
			t = text(t_value);
			option.__value = option_value_value = /*view*/ ctx[18].TY_ID;
			option.value = option.__value;
			add_location(option, file, 2093, 20, 39645);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
			append_dev(option, t);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*allDataviews*/ 4 && t_value !== (t_value = /*view*/ ctx[18].TY_LONGNAME + "")) set_data_dev(t, t_value);

			if (dirty & /*allDataviews*/ 4 && option_value_value !== (option_value_value = /*view*/ ctx[18].TY_ID)) {
				prop_dev(option, "__value", option_value_value);
				option.value = option.__value;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(2093:16) {#each allDataviews as view}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*ready*/ ctx[0]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if_block.c();
			this.c = noop;
			add_location(div, file, 2086, 0, 39243);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if_block.m(div, null);
			/*div_binding*/ ctx[14](div);
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
			/*div_binding*/ ctx[14](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(12, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('dataview-selector', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { eventname = "" } = $$props;

	// Base Variables
	//===========================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let allDataviews = [];
	let dataviewSelect;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(11, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(10, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = async () => {
		$$invalidate(9, firstInitialized = true);
		$$invalidate(2, allDataviews = await bc.metadataManager.getUgdmTypesByDomain(constants.domain.EY_APP_DATA_VIEW));

		allDataviews.sort((a, b) => {
			return a.TY_LONGNAME.localeCompare(b.TY_LONGNAME);
		});

		$$invalidate(0, ready = true);
	};

	// Component functions
	//================================================================
	const publishSelectedDataview = e => {
		e.stopPropagation();
		let value = dataviewSelect.value;

		if (eventname) {
			bc.messageService.publish(eventname, value);
		}

		let event = new Event('select',
		{
				bubbles: false,
				cancelable: false,
				composed: false
			});

		// @ts-ignore
		event.detail = value;

		component.dispatchEvent(event);
	};

	const writable_props = ['show', 'init', 'eventname'];

	

	function select_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			dataviewSelect = $$value;
			$$invalidate(3, dataviewSelect);
			$$invalidate(2, allDataviews);
		});
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(6, show = $$props.show);
		if ('init' in $$props) $$invalidate(7, init = $$props.init);
		if ('eventname' in $$props) $$invalidate(8, eventname = $$props.eventname);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		constants,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		applicationReady,
		getComponentText,
		show,
		init,
		eventname,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		component,
		element,
		allDataviews,
		dataviewSelect,
		initComponent,
		publishSelectedDataview,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(6, show = $$props.show);
		if ('init' in $$props) $$invalidate(7, init = $$props.init);
		if ('eventname' in $$props) $$invalidate(8, eventname = $$props.eventname);
		if ('firstInitialized' in $$props) $$invalidate(9, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(10, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(16, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(11, component = $$props.component);
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
		if ('allDataviews' in $$props) $$invalidate(2, allDataviews = $$props.allDataviews);
		if ('dataviewSelect' in $$props) $$invalidate(3, dataviewSelect = $$props.dataviewSelect);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 2112) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 5760) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		ready,
		element,
		allDataviews,
		dataviewSelect,
		generalText,
		publishSelectedDataview,
		show,
		init,
		eventname,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		select_binding,
		div_binding
	];
}

class DataviewSelector extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.h-10{height:2.5rem}.text-lg{font-size:1.125rem;line-height:1.75rem}.focus\\:outline-none:focus{outline:2px solid transparent;outline-offset:2px}.p-2{padding:0.5rem}.gap-2{grid-gap:0.5rem;gap:0.5rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 6, init: 7, eventname: 8 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "eventname"];
	}

	get show() {
		return this.$$.ctx[6];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[7];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get eventname() {
		return this.$$.ctx[8];
	}

	set eventname(eventname) {
		this.$$set({ eventname });
		flush();
	}
}

customElements.define("dataview-selector", DataviewSelector);
export default DataviewSelector;