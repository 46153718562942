/* src\components\Map\MapTools\MapBoxZoom\MapBoxZoom.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	toggle_class,
	validate_slots
} from "../../../../../web_modules/svelte/internal.js";

import '../../../../bcShim.js';
import '../../../../../web_modules/ol/geom/Polygon.js';
import { Draw } from '../../../../../web_modules/ol/interaction.js';
import { createBox } from '../../../../../web_modules/ol/interaction/Draw.js';
import VectorLayer from '../../../../../web_modules/ol/layer/Vector.js';
import VectorSource from '../../../../../web_modules/ol/source/Vector.js';
import { Fill, Stroke, Style } from '../../../../../web_modules/ol/style.js';
import CircleStyle from '../../../../../web_modules/ol/style/Circle.js';
import { v4 as uuidv4 } from "../../../../../web_modules/uuid.js";
import { onMount, tick } from "../../../../../web_modules/svelte.js";
import { GeometryType } from "../../../../interfaces.js";

import {
	attachNormalize,
	dispatchMountedEvent
} from "../../../../utilities/componentUtils.js";

import { getComponentText } from "../../../../utilities/generalUtils.js";
const file = "src\\components\\Map\\MapTools\\MapBoxZoom\\MapBoxZoom.svelte";

// (1924:4) {#if ready}
function create_if_block(ctx) {
	let div;
	let i;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div = element_1("div");
			i = element_1("i");
			attr_dev(i, "class", "far fa-lg fa-plus-square !h-10 !w-10 flex items-center justify-center relative text-white hover:text-orange-500 rounded");
			toggle_class(i, "bg-blue-500", /*maptool*/ ctx[0] === 'BoxZoom');
			add_location(i, file, 1926, 12, 37075);
			attr_dev(div, "class", "flex relative relative !h-10 !w-10 cursor-pointer");
			toggle_class(div, "underline-element", /*maptool*/ ctx[0] === 'BoxZoom');
			add_location(div, file, 1924, 8, 36936);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, i);

			if (!mounted) {
				dispose = listen_dev(i, "click", /*click_handler*/ ctx[5], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty & /*maptool*/ 1) {
				toggle_class(i, "bg-blue-500", /*maptool*/ ctx[0] === 'BoxZoom');
			}

			if (dirty & /*maptool*/ 1) {
				toggle_class(div, "underline-element", /*maptool*/ ctx[0] === 'BoxZoom');
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(1924:4) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[1] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 1922, 0, 36884);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[6](div);
		},
		p: function update(ctx, [dirty]) {
			if (/*ready*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[6](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('map-tool-box-zoom', slots, []);
	let { maptool = '' } = $$props;

	// Base variables
	//================================================================
	const generalText = getComponentText('general');

	let mounted = false;
	let ready = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let vectorLayer;
	let vectorSource;
	let drawInteraction;
	let map;
	let componentId = uuidv4();
	let setMapTool;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			component = element.getRootNode().host || element.parentNode; // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				mounted = true;
			});
		});

		return () => {
			
		};
	});

	const initComponent = (newMap, mapToolFunc) => {
		map = newMap;
		$$invalidate(3, setMapTool = mapToolFunc);
		$$invalidate(1, ready = true);
	};

	// Component functions
	//================================================================
	const handleMapTool = tool => {
		if (maptool !== 'BoxZoom') {
			cleanup();
		}

		if (maptool === 'BoxZoom') {
			vectorLayer = createVectorLayer();
			let zIndex = getMaxZIndex();
			vectorLayer.setZIndex(zIndex + 100);
			map.addLayer(vectorLayer);

			if (drawInteraction) {
				map.removeInteraction(drawInteraction);
			}

			drawInteraction = createBoxZoomInteraction();
			map.addInteraction(drawInteraction);
		}
	};

	const createBoxZoomInteraction = () => {
		const drawBoxZoomInteraction = new Draw({
				type: GeometryType.CIRCLE,
				source: vectorSource,
				geometryFunction: createBox(),
				style: new Style({
						stroke: new Stroke({ color: 'blue', width: 2, lineDash: null }),
						fill: new Fill({ color: '#1976d217' }),
						image: new CircleStyle({
								radius: 3,
								fill: new Fill({ color: '#ff9346' })
							})
					})
			});

		drawBoxZoomInteraction.on('drawend', function (evt) {
			const geometry = evt.feature.getGeometry();
			const extent = geometry.getExtent();
			map.getView().fit(extent, { size: map.getSize() });

			setTimeout(
				() => {
					vectorSource.clear();
				},
				50
			);
		});

		return drawBoxZoomInteraction;
	};

	const getMaxZIndex = () => {
		let zIndex = 0;

		map.getLayers().forEach(l => {
			if (l.getZIndex() > zIndex) {
				zIndex = l.getZIndex();
			}
		});

		return zIndex;
	};

	const cleanup = () => {
		if (vectorSource) {
			vectorSource.clear();
		}

		if (vectorLayer) {
			map.removeLayer(vectorLayer);
		}

		if (drawInteraction) {
			map.removeInteraction(drawInteraction);
		}
	};

	const createVectorLayer = () => {
		const width = 2;
		const styles = [];

		styles[GeometryType.POLYGON] = [
			new Style({
					fill: new Fill({ color: '#1976d217' }),
					stroke: new Stroke({ color: '#0039dc', width: 2 })
				})
		];

		styles[GeometryType.CIRCLE] = [
			new Style({
					fill: new Fill({ color: '#1976d217' }),
					stroke: new Stroke({ color: '#0039dc', width: 2 })
				})
		];

		styles[GeometryType.MULTI_POLYGON] = styles[GeometryType.POLYGON];

		styles[GeometryType.LINE_STRING] = [
			new Style({
					stroke: new Stroke({
							color: '#0039dc',
							lineDash: [10, 10],
							width
						})
				})
		];

		styles[GeometryType.MULTI_LINE_STRING] = styles[GeometryType.LINE_STRING];

		styles[GeometryType.POINT] = [
			new Style({
					image: new CircleStyle({
							radius: 10,
							fill: new Fill({ color: '#ffaa117d' }),
							stroke: new Stroke({ color: '#ffaa11', width: 2 })
						})
				})
		];

		styles[GeometryType.MULTI_POINT] = styles[GeometryType.POINT];
		styles[GeometryType.GEOMETRY_COLLECTION] = styles[GeometryType.POLYGON].concat(styles[GeometryType.LINE_STRING], styles[GeometryType.POINT]);

		const styleFunction = function (feature, resolution) {
			const featureStyleFunction = feature.getStyleFunction();

			if (featureStyleFunction) {
				return featureStyleFunction.call(feature, resolution);
			} else {
				return styles[feature.getGeometry().getType()];
			}
		};

		vectorSource = new VectorSource();

		return new VectorLayer({
				source: vectorSource,
				style: styleFunction
			});
	};

	const writable_props = ['maptool'];

	

	const click_handler = e => {
		maptool !== 'BoxZoom'
		? setMapTool('BoxZoom')
		: setMapTool('');
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(2, element);
		});
	}

	$$self.$$set = $$props => {
		if ('maptool' in $$props) $$invalidate(0, maptool = $$props.maptool);
	};

	$$self.$capture_state = () => ({
		Draw,
		createBox,
		VectorLayer,
		VectorSource,
		Fill,
		Stroke,
		Style,
		CircleStyle,
		uuidv4,
		onMount,
		tick,
		GeometryType,
		attachNormalize,
		dispatchMountedEvent,
		getComponentText,
		maptool,
		generalText,
		mounted,
		ready,
		component,
		element,
		vectorLayer,
		vectorSource,
		drawInteraction,
		map,
		componentId,
		setMapTool,
		initComponent,
		handleMapTool,
		createBoxZoomInteraction,
		getMaxZIndex,
		cleanup,
		createVectorLayer
	});

	$$self.$inject_state = $$props => {
		if ('maptool' in $$props) $$invalidate(0, maptool = $$props.maptool);
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('ready' in $$props) $$invalidate(1, ready = $$props.ready);
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) $$invalidate(2, element = $$props.element);
		if ('vectorLayer' in $$props) vectorLayer = $$props.vectorLayer;
		if ('vectorSource' in $$props) vectorSource = $$props.vectorSource;
		if ('drawInteraction' in $$props) drawInteraction = $$props.drawInteraction;
		if ('map' in $$props) map = $$props.map;
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('setMapTool' in $$props) $$invalidate(3, setMapTool = $$props.setMapTool);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*maptool*/ 1) {
			$: handleMapTool(maptool);
		}
	};

	return [maptool, ready, element, setMapTool, initComponent, click_handler, div_binding];
}

class MapBoxZoom extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `.fa-lg{font-size:1.25em;line-height:0.05em;vertical-align:-0.075em}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.fa-plus-square::before{content:""}.far{font-weight:400 !important}.far{font-family:"Font Awesome 6 Pro" !important;font-style:normal !important}.underline-element::before{content:"";position:absolute;z-index:-1;left:51%;right:51%;bottom:0;background:#2563eb;height:4px;animation-name:hint;animation-timing-function:ease-out;animation-duration:0.15s;animation-iteration-count:1;animation-fill-mode:forwards}100%{left:0;right:0}.bg-blue-500{--tw-bg-opacity:1;background-color:rgba(59, 130, 246, var(--tw-bg-opacity))}.rounded{border-radius:0.25rem}.cursor-pointer{cursor:pointer}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.\\!h-10{height:2.5rem !important}.relative{position:relative}.text-white{--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))}.hover\\:text-orange-500:hover{--tw-text-opacity:1;color:rgba(249, 115, 22, var(--tw-text-opacity))}.\\!w-10{width:2.5rem !important}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ maptool: 0, initComponent: 4 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["maptool", "initComponent"];
	}

	get maptool() {
		return this.$$.ctx[0];
	}

	set maptool(maptool) {
		this.$$set({ maptool });
		flush();
	}

	get initComponent() {
		return this.$$.ctx[4];
	}

	set initComponent(value) {
		throw new Error("<map-tool-box-zoom>: Cannot set read-only property 'initComponent'");
	}
}

customElements.define("map-tool-box-zoom", MapBoxZoom);
export default MapBoxZoom;