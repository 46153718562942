/* src\components\UserMenu\UserMenu.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	empty,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	space,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';
import { userSettingManager } from "../../utilities/userSettingsManager.js";
import { PARAMS } from "../../services/parameterService.js";
import { requestHeaders } from "../../services/ajaxService.js";
import { infoLog } from "../../utilities/consoleUtils.js";

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import {
	handleErrorInAlert,
	notify
} from "../../utilities/alertNotificationUtils.js";

import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\UserMenu\\UserMenu.svelte";

// (2128:4) {#if ready}
function create_if_block(ctx) {
	let if_block_anchor;
	let if_block = /*userName*/ ctx[4] && /*userId*/ ctx[3] && create_if_block_1(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*userName*/ ctx[4] && /*userId*/ ctx[3]) if_block.p(ctx, dirty);
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2128:4) {#if ready}",
		ctx
	});

	return block;
}

// (2129:8) {#if userName && userId}
function create_if_block_1(ctx) {
	let custom_pop_over;
	let bc_button;
	let t0;
	let div;
	let ul0;
	let li0;
	let t2;
	let hr;
	let t3;
	let ul1;
	let li1;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			custom_pop_over = element_1("custom-pop-over");
			bc_button = element_1("bc-button");
			t0 = space();
			div = element_1("div");
			ul0 = element_1("ul");
			li0 = element_1("li");
			li0.textContent = `${/*componentText*/ ctx[2].deleteUserSettings}`;
			t2 = space();
			hr = element_1("hr");
			t3 = space();
			ul1 = element_1("ul");
			li1 = element_1("li");
			li1.textContent = `${/*componentText*/ ctx[2].logout}`;
			set_custom_element_data(bc_button, "type", "secondary");
			set_custom_element_data(bc_button, "dropdown", "true");
			set_custom_element_data(bc_button, "color", "white");
			set_custom_element_data(bc_button, "text", /*userName*/ ctx[4]);
			set_custom_element_data(bc_button, "icon", "far fa-user");
			set_custom_element_data(bc_button, "slot", "open-close");
			set_custom_element_data(bc_button, "size", "small");
			add_location(bc_button, file, 2131, 16, 41123);
			attr_dev(li0, "class", "list-none h-10 hover:bg-gray-100 flex items-center px-1 cursor-pointer");
			add_location(li0, file, 2136, 24, 41424);
			attr_dev(ul0, "class", "m-0 p-0");
			add_location(ul0, file, 2135, 20, 41378);
			attr_dev(hr, "class", "w-full border-t border-solid border-gray-300 my-2");
			add_location(hr, file, 2139, 20, 41657);
			attr_dev(li1, "class", "list-none h-10 hover:bg-gray-100 flex items-center px-1 cursor-pointer");
			add_location(li1, file, 2141, 24, 41789);
			attr_dev(ul1, "class", "m-0 p-0 ");
			add_location(ul1, file, 2140, 20, 41742);
			attr_dev(div, "slot", "content");
			attr_dev(div, "class", "float-content text-gray-600 p-4");
			add_location(div, file, 2134, 16, 41296);
			set_custom_element_data(custom_pop_over, "title", "Benutzereinstellungen");
			set_custom_element_data(custom_pop_over, "padding", "false");
			add_location(custom_pop_over, file, 2129, 12, 41040);
		},
		m: function mount(target, anchor) {
			insert_dev(target, custom_pop_over, anchor);
			append_dev(custom_pop_over, bc_button);
			append_dev(custom_pop_over, t0);
			append_dev(custom_pop_over, div);
			append_dev(div, ul0);
			append_dev(ul0, li0);
			append_dev(div, t2);
			append_dev(div, hr);
			append_dev(div, t3);
			append_dev(div, ul1);
			append_dev(ul1, li1);

			if (!mounted) {
				dispose = [
					listen_dev(li0, "click", /*deleteAllUserSettings*/ ctx[5], false, false, false, false),
					listen_dev(li1, "click", /*logout*/ ctx[6], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(custom_pop_over);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2129:8) {#if userName && userId}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[0] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 2126, 0, 40950);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[15](div);
		},
		p: function update(ctx, [dirty]) {
			if (/*ready*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[15](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(14, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('user-menu', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { showheaderbar = 'NO' } = $$props;
	let { headerbarcolor = 'Blue' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	const componentText = getComponentText('userMenu');
	let mounted = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	const userId = bc.parameterService.appParameters.userId;
	const userName = bc.parameterService.get(PARAMS.user.userName);

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(13, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(12, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(11, firstInitialized = true);
		$$invalidate(0, ready = true);
	};

	// Component functions
	//================================================================
	const deleteAllUserSettings = async e => {
		try {
			if (confirm(componentText.questionDeleteAllUserSettings)) {
				await userSettingManager.getAllSettings();
				await userSettingManager.removeAllSettings();
				notify(componentText.settingsDeleted);
			}
		} catch(error) {
			console.error(componentText.errorDeletingSettings, error);
			handleErrorInAlert(error, componentText.errorDeletingSettings);
		}
	};

	const logout = async () => {
		let url = bc.parameterService.get(PARAMS.urls.applicationBaseUrl) + 'WebApi/Account/Logout';
		const request = await bc.ajaxService.fetchRaw(requestHeaders.jsonPost, url);

		if (request.status == 200) {
			// console.log('COOKIE', document.cookie)
			bc.parameterService.set(PARAMS.user.bearerToken, null);

			bc.parameterService.set(PARAMS.user.userId, null);
			bc.parameterService.set(PARAMS.user.userName, null);
			bc.parameterService.set(PARAMS.user.roleIds, null);
			bc.parameterService.set(PARAMS.user.roles, null);
			bc.parameterService.set(PARAMS.user.bearerToken, null);
			window['CcUgdmMetaEnvironment']['UserId'] = null;
			window['CcUgdmMetaEnvironment']['UserName'] = null;
			window['CcUgdmMetaEnvironment']['BearerToken'] = null;
			window['CcUgdmMetaEnvironment']['RoleIds'] = null;
			window['CcUgdmMetaEnvironment']['Roles'] = null;
			location.href = bc.parameterService.get('LOGOUT_URL');
		}

		infoLog('Content-Wrapper', 'request', request);
	};

	const writable_props = ['show', 'init', 'showheaderbar', 'headerbarcolor'];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(7, show = $$props.show);
		if ('init' in $$props) $$invalidate(8, init = $$props.init);
		if ('showheaderbar' in $$props) $$invalidate(9, showheaderbar = $$props.showheaderbar);
		if ('headerbarcolor' in $$props) $$invalidate(10, headerbarcolor = $$props.headerbarcolor);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		userSettingManager,
		PARAMS,
		requestHeaders,
		infoLog,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		handleErrorInAlert,
		notify,
		applicationReady,
		getComponentText,
		show,
		init,
		showheaderbar,
		headerbarcolor,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		componentText,
		mounted,
		canReload,
		component,
		element,
		userId,
		userName,
		initComponent,
		deleteAllUserSettings,
		logout,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(7, show = $$props.show);
		if ('init' in $$props) $$invalidate(8, init = $$props.init);
		if ('showheaderbar' in $$props) $$invalidate(9, showheaderbar = $$props.showheaderbar);
		if ('headerbarcolor' in $$props) $$invalidate(10, headerbarcolor = $$props.headerbarcolor);
		if ('firstInitialized' in $$props) $$invalidate(11, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(12, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(18, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(13, component = $$props.component);
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 8320) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 22784) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		ready,
		element,
		componentText,
		userId,
		userName,
		deleteAllUserSettings,
		logout,
		show,
		init,
		showheaderbar,
		headerbarcolor,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		div_binding
	];
}

class UserMenu extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.hover\\:bg-gray-100:hover{--tw-bg-opacity:1;background-color:rgba(243, 244, 246, var(--tw-bg-opacity))}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.border-solid{border-style:solid}.border-t{border-top-width:1px}.cursor-pointer{cursor:pointer}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.h-10{height:2.5rem}.list-none{list-style-type:none}.m-0{margin:0px}.my-2{margin-top:0.5rem;margin-bottom:0.5rem}.p-4{padding:1rem}.p-0{padding:0px}.px-1{padding-left:0.25rem;padding-right:0.25rem}.text-gray-600{--tw-text-opacity:1;color:rgba(75, 85, 99, var(--tw-text-opacity))}.w-full{width:100%}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 7,
				init: 8,
				showheaderbar: 9,
				headerbarcolor: 10
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "showheaderbar", "headerbarcolor"];
	}

	get show() {
		return this.$$.ctx[7];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[8];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get showheaderbar() {
		return this.$$.ctx[9];
	}

	set showheaderbar(showheaderbar) {
		this.$$set({ showheaderbar });
		flush();
	}

	get headerbarcolor() {
		return this.$$.ctx[10];
	}

	set headerbarcolor(headerbarcolor) {
		this.$$set({ headerbarcolor });
		flush();
	}
}

customElements.define("user-menu", UserMenu);
export default UserMenu;