/* src\components\EventExporter\EventExporter.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	prop_dev,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text,
	validate_each_argument,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';
import "../../../web_modules/svelte/internal.js";

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { getObjectItem } from "../../utilities/entityUtils.js";
import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\EventExporter\\EventExporter.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[41] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[41] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[41] = list[i];
	return child_ctx;
}

function get_each_context_3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[41] = list[i];
	return child_ctx;
}

// (2277:4) {#if ready}
function create_if_block(ctx) {
	let div6;
	let h3;
	let t1;
	let div5;
	let div0;
	let label0;
	let t3;
	let select0;
	let option0;
	let t5;
	let div1;
	let label1;
	let t7;
	let select1;
	let option1;
	let t9;
	let div2;
	let label2;
	let t11;
	let select2;
	let option2;
	let t13;
	let div3;
	let label3;
	let t15;
	let select3;
	let option3;
	let t17;
	let div4;
	let bc_button;
	let bc_button_text_value;
	let mounted;
	let dispose;
	let each_value_3 = /*otherFields*/ ctx[3];
	validate_each_argument(each_value_3);
	let each_blocks_3 = [];

	for (let i = 0; i < each_value_3.length; i += 1) {
		each_blocks_3[i] = create_each_block_3(get_each_context_3(ctx, each_value_3, i));
	}

	let each_value_2 = /*otherFields*/ ctx[3];
	validate_each_argument(each_value_2);
	let each_blocks_2 = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks_2[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	let each_value_1 = /*dateFields*/ ctx[2];
	validate_each_argument(each_value_1);
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = /*dateFields*/ ctx[2];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			div6 = element_1("div");
			h3 = element_1("h3");
			h3.textContent = `${/*componentText*/ ctx[8].exportEvent}`;
			t1 = space();
			div5 = element_1("div");
			div0 = element_1("div");
			label0 = element_1("label");
			label0.textContent = "Titel";
			t3 = space();
			select0 = element_1("select");
			option0 = element_1("option");
			option0.textContent = `${/*componentText*/ ctx[8].selectChooseTitleField}`;

			for (let i = 0; i < each_blocks_3.length; i += 1) {
				each_blocks_3[i].c();
			}

			t5 = space();
			div1 = element_1("div");
			label1 = element_1("label");
			label1.textContent = "Description";
			t7 = space();
			select1 = element_1("select");
			option1 = element_1("option");
			option1.textContent = `${/*componentText*/ ctx[8].selectChooseDescriptionField}`;

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].c();
			}

			t9 = space();
			div2 = element_1("div");
			label2 = element_1("label");
			label2.textContent = "Datum Start";
			t11 = space();
			select2 = element_1("select");
			option2 = element_1("option");
			option2.textContent = `${/*componentText*/ ctx[8].selectChooseStartDateField}`;

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t13 = space();
			div3 = element_1("div");
			label3 = element_1("label");
			label3.textContent = "Datum Ende";
			t15 = space();
			select3 = element_1("select");
			option3 = element_1("option");
			option3.textContent = `${/*componentText*/ ctx[8].selectChooseEndDateField}`;

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t17 = space();
			div4 = element_1("div");
			bc_button = element_1("bc-button");
			attr_dev(h3, "class", "text-2xl font-medium roboto-medium mb-4");
			add_location(h3, file, 2278, 12, 45061);
			attr_dev(label0, "class", "font-medium roboto-medium");
			add_location(label0, file, 2281, 20, 45265);
			option0.__value = "";
			option0.value = option0.__value;
			add_location(option0, file, 2285, 24, 45519);
			attr_dev(select0, "class", "border border-solid border-gray-300 h-10 rounded");
			add_location(select0, file, 2283, 20, 45343);
			attr_dev(div0, "class", "flex flex-col gap-2");
			add_location(div0, file, 2280, 16, 45210);
			attr_dev(label1, "class", "font-medium roboto-medium");
			add_location(label1, file, 2293, 20, 45882);
			option1.__value = "";
			option1.value = option1.__value;
			add_location(option1, file, 2296, 24, 46152);
			attr_dev(select1, "class", "border border-solid border-gray-300 h-10 rounded");
			add_location(select1, file, 2294, 20, 45964);
			attr_dev(div1, "class", "flex flex-col gap-2");
			add_location(div1, file, 2292, 16, 45827);
			attr_dev(label2, "class", "font-medium roboto-medium ");
			add_location(label2, file, 2303, 20, 46519);
			option2.__value = "";
			option2.value = option2.__value;
			add_location(option2, file, 2306, 24, 46786);
			attr_dev(select2, "class", "border border-solid border-gray-300 h-10 rounded");
			add_location(select2, file, 2304, 20, 46602);
			attr_dev(div2, "class", "flex flex-col gap-2");
			add_location(div2, file, 2302, 16, 46464);
			attr_dev(label3, "class", "font-medium roboto-medium");
			add_location(label3, file, 2313, 20, 47150);
			option3.__value = "";
			option3.value = option3.__value;
			add_location(option3, file, 2316, 24, 47411);
			attr_dev(select3, "class", "border border-solid border-gray-300 h-10 rounded");
			add_location(select3, file, 2314, 20, 47231);
			attr_dev(div3, "class", "flex flex-col gap-2");
			add_location(div3, file, 2312, 16, 47095);
			set_custom_element_data(bc_button, "color", "blue");
			set_custom_element_data(bc_button, "type", "primary");
			set_custom_element_data(bc_button, "icon", "fas fa-save");
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*componentText*/ ctx[8].createEvent);
			add_location(bc_button, file, 2323, 20, 47770);
			attr_dev(div4, "class", "flex justify-end");
			add_location(div4, file, 2322, 16, 47718);
			attr_dev(div5, "class", "flex flex-col gap-4");
			add_location(div5, file, 2279, 12, 45159);
			attr_dev(div6, "class", "mt-4 md:mt-0 px-2 md:px-0");
			add_location(div6, file, 2277, 8, 45008);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div6, anchor);
			append_dev(div6, h3);
			append_dev(div6, t1);
			append_dev(div6, div5);
			append_dev(div5, div0);
			append_dev(div0, label0);
			append_dev(div0, t3);
			append_dev(div0, select0);
			append_dev(select0, option0);

			for (let i = 0; i < each_blocks_3.length; i += 1) {
				if (each_blocks_3[i]) {
					each_blocks_3[i].m(select0, null);
				}
			}

			/*select0_binding*/ ctx[23](select0);
			append_dev(div5, t5);
			append_dev(div5, div1);
			append_dev(div1, label1);
			append_dev(div1, t7);
			append_dev(div1, select1);
			append_dev(select1, option1);

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				if (each_blocks_2[i]) {
					each_blocks_2[i].m(select1, null);
				}
			}

			/*select1_binding*/ ctx[24](select1);
			append_dev(div5, t9);
			append_dev(div5, div2);
			append_dev(div2, label2);
			append_dev(div2, t11);
			append_dev(div2, select2);
			append_dev(select2, option2);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				if (each_blocks_1[i]) {
					each_blocks_1[i].m(select2, null);
				}
			}

			/*select2_binding*/ ctx[25](select2);
			append_dev(div5, t13);
			append_dev(div5, div3);
			append_dev(div3, label3);
			append_dev(div3, t15);
			append_dev(div3, select3);
			append_dev(select3, option3);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(select3, null);
				}
			}

			/*select3_binding*/ ctx[26](select3);
			append_dev(div5, t17);
			append_dev(div5, div4);
			append_dev(div4, bc_button);

			if (!mounted) {
				dispose = [
					listen_dev(select0, "change", /*onTitleSelectChange*/ ctx[9], false, false, false, false),
					listen_dev(select1, "change", /*onDescriptionSelectChange*/ ctx[10], false, false, false, false),
					listen_dev(select2, "change", /*onDateStartSelectChange*/ ctx[11], false, false, false, false),
					listen_dev(select3, "change", /*onDateEndSelectChange*/ ctx[12], false, false, false, false),
					listen_dev(bc_button, "click", /*createIcal*/ ctx[13], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*otherFields*/ 8) {
				each_value_3 = /*otherFields*/ ctx[3];
				validate_each_argument(each_value_3);
				let i;

				for (i = 0; i < each_value_3.length; i += 1) {
					const child_ctx = get_each_context_3(ctx, each_value_3, i);

					if (each_blocks_3[i]) {
						each_blocks_3[i].p(child_ctx, dirty);
					} else {
						each_blocks_3[i] = create_each_block_3(child_ctx);
						each_blocks_3[i].c();
						each_blocks_3[i].m(select0, null);
					}
				}

				for (; i < each_blocks_3.length; i += 1) {
					each_blocks_3[i].d(1);
				}

				each_blocks_3.length = each_value_3.length;
			}

			if (dirty[0] & /*otherFields*/ 8) {
				each_value_2 = /*otherFields*/ ctx[3];
				validate_each_argument(each_value_2);
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks_2[i]) {
						each_blocks_2[i].p(child_ctx, dirty);
					} else {
						each_blocks_2[i] = create_each_block_2(child_ctx);
						each_blocks_2[i].c();
						each_blocks_2[i].m(select1, null);
					}
				}

				for (; i < each_blocks_2.length; i += 1) {
					each_blocks_2[i].d(1);
				}

				each_blocks_2.length = each_value_2.length;
			}

			if (dirty[0] & /*dateFields*/ 4) {
				each_value_1 = /*dateFields*/ ctx[2];
				validate_each_argument(each_value_1);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(select2, null);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (dirty[0] & /*dateFields*/ 4) {
				each_value = /*dateFields*/ ctx[2];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select3, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div6);
			destroy_each(each_blocks_3, detaching);
			/*select0_binding*/ ctx[23](null);
			destroy_each(each_blocks_2, detaching);
			/*select1_binding*/ ctx[24](null);
			destroy_each(each_blocks_1, detaching);
			/*select2_binding*/ ctx[25](null);
			destroy_each(each_blocks, detaching);
			/*select3_binding*/ ctx[26](null);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2277:4) {#if ready}",
		ctx
	});

	return block;
}

// (2287:24) {#each otherFields as field}
function create_each_block_3(ctx) {
	let option;
	let t_value = /*field*/ ctx[41].longName + "";
	let t;
	let option_value_value;

	const block = {
		c: function create() {
			option = element_1("option");
			t = text(t_value);
			option.__value = option_value_value = /*field*/ ctx[41].name;
			option.value = option.__value;
			add_location(option, file, 2287, 28, 45667);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
			append_dev(option, t);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*otherFields*/ 8 && t_value !== (t_value = /*field*/ ctx[41].longName + "")) set_data_dev(t, t_value);

			if (dirty[0] & /*otherFields*/ 8 && option_value_value !== (option_value_value = /*field*/ ctx[41].name)) {
				prop_dev(option, "__value", option_value_value);
				option.value = option.__value;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block_3.name,
		type: "each",
		source: "(2287:24) {#each otherFields as field}",
		ctx
	});

	return block;
}

// (2298:24) {#each otherFields as field}
function create_each_block_2(ctx) {
	let option;
	let t_value = /*field*/ ctx[41].longName + "";
	let t;
	let option_value_value;

	const block = {
		c: function create() {
			option = element_1("option");
			t = text(t_value);
			option.__value = option_value_value = /*field*/ ctx[41].name;
			option.value = option.__value;
			add_location(option, file, 2298, 28, 46306);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
			append_dev(option, t);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*otherFields*/ 8 && t_value !== (t_value = /*field*/ ctx[41].longName + "")) set_data_dev(t, t_value);

			if (dirty[0] & /*otherFields*/ 8 && option_value_value !== (option_value_value = /*field*/ ctx[41].name)) {
				prop_dev(option, "__value", option_value_value);
				option.value = option.__value;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block_2.name,
		type: "each",
		source: "(2298:24) {#each otherFields as field}",
		ctx
	});

	return block;
}

// (2308:24) {#each dateFields as field}
function create_each_block_1(ctx) {
	let option;
	let t_value = /*field*/ ctx[41].longName + "";
	let t;
	let option_value_value;

	const block = {
		c: function create() {
			option = element_1("option");
			t = text(t_value);
			option.__value = option_value_value = /*field*/ ctx[41].name;
			option.value = option.__value;
			add_location(option, file, 2308, 28, 46937);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
			append_dev(option, t);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*dateFields*/ 4 && t_value !== (t_value = /*field*/ ctx[41].longName + "")) set_data_dev(t, t_value);

			if (dirty[0] & /*dateFields*/ 4 && option_value_value !== (option_value_value = /*field*/ ctx[41].name)) {
				prop_dev(option, "__value", option_value_value);
				option.value = option.__value;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block_1.name,
		type: "each",
		source: "(2308:24) {#each dateFields as field}",
		ctx
	});

	return block;
}

// (2318:24) {#each dateFields as field}
function create_each_block(ctx) {
	let option;
	let t_value = /*field*/ ctx[41].longName + "";
	let t;
	let option_value_value;

	const block = {
		c: function create() {
			option = element_1("option");
			t = text(t_value);
			option.__value = option_value_value = /*field*/ ctx[41].name;
			option.value = option.__value;
			add_location(option, file, 2318, 28, 47560);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
			append_dev(option, t);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*dateFields*/ 4 && t_value !== (t_value = /*field*/ ctx[41].longName + "")) set_data_dev(t, t_value);

			if (dirty[0] & /*dateFields*/ 4 && option_value_value !== (option_value_value = /*field*/ ctx[41].name)) {
				prop_dev(option, "__value", option_value_value);
				option.value = option.__value;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(2318:24) {#each dateFields as field}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[0] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 2275, 0, 44956);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[27](div);
		},
		p: function update(ctx, dirty) {
			if (/*ready*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[27](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(22, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('event-exporter', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { dataviewguid = '' } = $$props;
	let { objectid = '' } = $$props;
	let { exportedevent = '' } = $$props;

	// Base Variables
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	let mounted = false;
	const generalText = getComponentText('general');
	const componentText = getComponentText('eventExporter');
	let canReload = true;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let componentId;
	let objectMetadata;
	let objectToRender;
	let dateFields = [];
	let otherFields = [];
	let titleSelect;
	let descriptionSelect;
	let startDateSelect;
	let endDateSelect;
	let title = '';
	let description = '';
	let startDate = '';
	let endDate = '';

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(21, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(20, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initColumnMapping = () => {
		// Set up mappings to initialize input fields
		let keys = objectMetadata.properties.columns.map(c => c.field);

		for (const key of keys) {
			const field = objectMetadata.getField(key);
			const column = objectMetadata.properties.columns.find(c => c.field === key);

			if (objectToRender[field.name] !== undefined && objectToRender[field.name] !== null) {
				if (field.dataType === 'DateTime' && (field.visibleShort === true || field.visibleTable === true || field.visibleKanban === true || field.visible === true)) {
					$$invalidate(2, dateFields = [...dateFields, field]);
				} else {
					if ((field.dataType === 'String' || field.dataType === 'Int16' || field.dataType === 'Int32' || field.dataType === 'Int64' || field.dataType === 'Double' || field.dataType === 'Decimal') && field.visibleShort === true) {
						$$invalidate(3, otherFields = [...otherFields, field]);
					}
				}
			}
		}
	};

	const initComponent = async (dataviewguid, objectid) => {
		$$invalidate(19, firstInitialized = true);

		if (dataviewguid !== '' && objectid !== '') {
			componentId = 'calendar_form_' + dataviewguid;
			$$invalidate(0, ready = false);

			// Get metadata of objectType
			objectMetadata = await bc.parameterService.getMetadata(dataviewguid);

			// Get Object to Render
			objectToRender = await getObjectItem(objectMetadata, objectid);

			initColumnMapping();
			$$invalidate(0, ready = true);
		}
	};

	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				dataviewguid,
				objectid
			);
		}
	};

	const onTitleSelectChange = e => {
		let value = titleSelect.value;

		if (value !== '') {
			title = objectToRender[value];
		} else {
			title = '';
		}
	};

	const onDescriptionSelectChange = e => {
		let value = descriptionSelect.value;

		if (value !== '') {
			description = objectToRender[value];
		} else {
			description = '';
		}
	};

	const onDateStartSelectChange = e => {
		let value = startDateSelect.value;

		if (value !== '') {
			startDate = objectToRender[value];
		} else {
			startDate = '';
		}
	};

	const onDateEndSelectChange = e => {
		let value = endDateSelect.value;

		if (value !== '') {
			endDate = objectToRender[value];
		} else {
			endDate = '';
		}
	};

	const createIcal = e => {
		if (title !== '' && startDate !== '' && endDate !== '') {
			// let dateStartIcal = startDate.substring(0, startDate.indexOf('.'))
			let dateStartIcal = startDate;

			dateStartIcal = dateStartIcal.replace(/-/g, '');
			dateStartIcal = dateStartIcal.replace(/:/g, '');
			dateStartIcal = dateStartIcal;
			let dateEndIcal = endDate;
			dateEndIcal = dateEndIcal.replace(/-/g, '');
			dateEndIcal = dateEndIcal.replace(/:/g, '');
			dateEndIcal = dateEndIcal;
			let date = new Date();
			let month = date.getMonth() + 1 + '';

			if (+month < 10) {
				month = '0' + month;
			}

			let day = date.getDate();

			if (day < 10) {
				day = '0' + day;
			}

			let hours = date.getHours() + '';
			let minutes = date.getMinutes() + '';
			let seconds = date.getSeconds() + '';

			if (+hours < 10) {
				hours = '0' + hours;
			}

			if (+minutes < 10) {
				minutes = '0' + minutes;
			}

			if (+seconds < 10) {
				seconds = '0' + seconds;
			}

			let dateString = date.getFullYear() + month + day + 'T' + hours + minutes + seconds;

			let text = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:BryteCube
METHOD:PUBLISH
BEGIN:VTIMEZONE
TZID:W. Europe Standard Time
BEGIN:STANDARD
DTSTART:16011028T030000
RRULE:FREQ=YEARLY;BYDAY=-1SU;BYMONTH=10
TZOFFSETFROM:+0200
TZOFFSETTO:+0100
END:STANDARD
BEGIN:DAYLIGHT
DTSTART:16010325T020000
RRULE:FREQ=YEARLY;BYDAY=-1SU;BYMONTH=3
TZOFFSETFROM:+0100
TZOFFSETTO:+0200
END:DAYLIGHT
END:VTIMEZONE
BEGIN:VEVENT
UID:${uuidv4()}
SUMMARY:${title}
DESCRIPTION:${description}
CLASS:PUBLIC
DTSTART;TZID="W. Europe Standard Time":${dateStartIcal}
DTEND;TZID="W. Europe Standard Time":${dateEndIcal}
DTSTAMP:${dateString}Z
END:VEVENT
END:VCALENDAR
`;

			let element = document.createElement('a');
			element.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(text));
			element.setAttribute('download', 'calendar-event.ics');
			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
			element = undefined;
			bc.messageService.publish('CLOSE_EVENT_EXPORTER', 'EVENT_EXPORTER');

			if (exportedevent !== '') {
				bc.messageService.publish(exportedevent);
			}

			let event = new Event('save',
			{
					bubbles: false,
					composed: true,
					cancelable: true
				});

			component.dispatchEvent(event);
		} else {
			alert(componentText.requiredFields);
		}
	};

	const writable_props = ['show', 'init', 'dataviewguid', 'objectid', 'exportedevent'];

	

	function select0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			titleSelect = $$value;
			$$invalidate(4, titleSelect);
			$$invalidate(3, otherFields);
		});
	}

	function select1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			descriptionSelect = $$value;
			$$invalidate(5, descriptionSelect);
			$$invalidate(3, otherFields);
		});
	}

	function select2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			startDateSelect = $$value;
			$$invalidate(6, startDateSelect);
			$$invalidate(2, dateFields);
		});
	}

	function select3_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			endDateSelect = $$value;
			$$invalidate(7, endDateSelect);
			$$invalidate(2, dateFields);
		});
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(14, show = $$props.show);
		if ('init' in $$props) $$invalidate(15, init = $$props.init);
		if ('dataviewguid' in $$props) $$invalidate(16, dataviewguid = $$props.dataviewguid);
		if ('objectid' in $$props) $$invalidate(17, objectid = $$props.objectid);
		if ('exportedevent' in $$props) $$invalidate(18, exportedevent = $$props.exportedevent);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		getObjectItem,
		applicationReady,
		getComponentText,
		show,
		init,
		dataviewguid,
		objectid,
		exportedevent,
		firstInitialized,
		subscribeGuid,
		ready,
		mounted,
		generalText,
		componentText,
		canReload,
		component,
		element,
		componentId,
		objectMetadata,
		objectToRender,
		dateFields,
		otherFields,
		titleSelect,
		descriptionSelect,
		startDateSelect,
		endDateSelect,
		title,
		description,
		startDate,
		endDate,
		initColumnMapping,
		initComponent,
		applyProps,
		onTitleSelectChange,
		onDescriptionSelectChange,
		onDateStartSelectChange,
		onDateEndSelectChange,
		createIcal,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(14, show = $$props.show);
		if ('init' in $$props) $$invalidate(15, init = $$props.init);
		if ('dataviewguid' in $$props) $$invalidate(16, dataviewguid = $$props.dataviewguid);
		if ('objectid' in $$props) $$invalidate(17, objectid = $$props.objectid);
		if ('exportedevent' in $$props) $$invalidate(18, exportedevent = $$props.exportedevent);
		if ('firstInitialized' in $$props) $$invalidate(19, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(20, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('component' in $$props) $$invalidate(21, component = $$props.component);
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('objectMetadata' in $$props) objectMetadata = $$props.objectMetadata;
		if ('objectToRender' in $$props) objectToRender = $$props.objectToRender;
		if ('dateFields' in $$props) $$invalidate(2, dateFields = $$props.dateFields);
		if ('otherFields' in $$props) $$invalidate(3, otherFields = $$props.otherFields);
		if ('titleSelect' in $$props) $$invalidate(4, titleSelect = $$props.titleSelect);
		if ('descriptionSelect' in $$props) $$invalidate(5, descriptionSelect = $$props.descriptionSelect);
		if ('startDateSelect' in $$props) $$invalidate(6, startDateSelect = $$props.startDateSelect);
		if ('endDateSelect' in $$props) $$invalidate(7, endDateSelect = $$props.endDateSelect);
		if ('title' in $$props) title = $$props.title;
		if ('description' in $$props) description = $$props.description;
		if ('startDate' in $$props) startDate = $$props.startDate;
		if ('endDate' in $$props) endDate = $$props.endDate;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*show, component*/ 2113536) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[0] & /*init, firstInitialized, mounted, $applicationReady*/ 5799936) {
			/**
 * Handle the component init attribute, for the case that the component has not been initialized yet.
 */
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty[0] & /*dataviewguid, objectid*/ 196608) {
			/**
 * Handles any component attribute changes after the component is initialized.
 */
			$: (dataviewguid, objectid, applyProps(true));
		}
	};

	return [
		ready,
		element,
		dateFields,
		otherFields,
		titleSelect,
		descriptionSelect,
		startDateSelect,
		endDateSelect,
		componentText,
		onTitleSelectChange,
		onDescriptionSelectChange,
		onDateStartSelectChange,
		onDateEndSelectChange,
		createIcal,
		show,
		init,
		dataviewguid,
		objectid,
		exportedevent,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		select0_binding,
		select1_binding,
		select2_binding,
		select3_binding,
		div_binding
	];
}

class EventExporter extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.justify-end{-webkit-box-pack:end;-ms-flex-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end}.font-medium{font-family:Roboto-Medium}.h-10{height:2.5rem}.text-2xl{font-size:1.5rem;line-height:2rem}.mt-4{margin-top:1rem}.mb-4{margin-bottom:1rem}.px-2{padding-left:0.5rem;padding-right:0.5rem}.gap-4{grid-gap:1rem;gap:1rem}.gap-2{grid-gap:0.5rem;gap:0.5rem}@media(min-width: 768px){.md\\:mt-0{margin-top:0px}.md\\:px-0{padding-left:0px;padding-right:0px}}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 14,
				init: 15,
				dataviewguid: 16,
				objectid: 17,
				exportedevent: 18
			},
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "dataviewguid", "objectid", "exportedevent"];
	}

	get show() {
		return this.$$.ctx[14];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[15];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get dataviewguid() {
		return this.$$.ctx[16];
	}

	set dataviewguid(dataviewguid) {
		this.$$set({ dataviewguid });
		flush();
	}

	get objectid() {
		return this.$$.ctx[17];
	}

	set objectid(objectid) {
		this.$$set({ objectid });
		flush();
	}

	get exportedevent() {
		return this.$$.ctx[18];
	}

	set exportedevent(exportedevent) {
		this.$$set({ exportedevent });
		flush();
	}
}

customElements.define("event-exporter", EventExporter);
export default EventExporter;