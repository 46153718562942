/* src\components\Backend\UploadFile\UploadFile.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	space,
	validate_slots,
	validate_store
} from "../../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../../utilities/componentUtils.js";

import { applicationReady } from "../../../utilities/store.js";
import "../../../services/ajaxService.js";
import { PARAMS } from "../../../services/parameterService.js";
import { errorToast, success } from "../../../utilities/alertNotificationUtils.js";
import { getComponentText } from "../../../utilities/generalUtils.js";
const file = "src\\components\\Backend\\UploadFile\\UploadFile.svelte";

// (188:4) {:else}
function create_else_block(ctx) {
	let loading_spinner;

	const block = {
		c: function create() {
			loading_spinner = element_1("loading-spinner");
			set_custom_element_data(loading_spinner, "text", "Lade Daten...");
			add_location(loading_spinner, file, 188, 8, 5822);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(188:4) {:else}",
		ctx
	});

	return block;
}

// (175:4) {#if ready}
function create_if_block(ctx) {
	let h30;
	let t1;
	let p;
	let t3;
	let div0;
	let h31;
	let t5;
	let input;
	let t6;
	let div1;
	let bc_button;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			h30 = element_1("h3");
			h30.textContent = "Datei hochladen";
			t1 = space();
			p = element_1("p");
			p.textContent = "Hochladen von Daten";
			t3 = space();
			div0 = element_1("div");
			h31 = element_1("h3");
			h31.textContent = "Daten hochladen";
			t5 = space();
			input = element_1("input");
			t6 = space();
			div1 = element_1("div");
			bc_button = element_1("bc-button");
			attr_dev(h30, "class", "text-2xl font-medium roboto-medium");
			add_location(h30, file, 175, 8, 5302);
			add_location(p, file, 177, 8, 5381);
			attr_dev(h31, "class", "text-lg font-medium roboto-medium");
			add_location(h31, file, 180, 12, 5486);
			attr_dev(input, "type", "file");
			attr_dev(input, "class", "h-8 rounded");
			add_location(input, file, 182, 12, 5568);
			attr_dev(div0, "class", "flex items-baseline gap-2 mt-2 flex-col");
			add_location(div0, file, 179, 8, 5419);
			set_custom_element_data(bc_button, "color", "blue");
			set_custom_element_data(bc_button, "text", "Datei hochladen");
			add_location(bc_button, file, 185, 12, 5702);
			attr_dev(div1, "class", "flex mt-4 mb-4");
			add_location(div1, file, 184, 8, 5660);
		},
		m: function mount(target, anchor) {
			insert_dev(target, h30, anchor);
			insert_dev(target, t1, anchor);
			insert_dev(target, p, anchor);
			insert_dev(target, t3, anchor);
			insert_dev(target, div0, anchor);
			append_dev(div0, h31);
			append_dev(div0, t5);
			append_dev(div0, input);
			/*input_binding*/ ctx[10](input);
			insert_dev(target, t6, anchor);
			insert_dev(target, div1, anchor);
			append_dev(div1, bc_button);

			if (!mounted) {
				dispose = listen_dev(bc_button, "click", /*uploadData*/ ctx[3], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(h30);
			if (detaching) detach_dev(t1);
			if (detaching) detach_dev(p);
			if (detaching) detach_dev(t3);
			if (detaching) detach_dev(div0);
			/*input_binding*/ ctx[10](null);
			if (detaching) detach_dev(t6);
			if (detaching) detach_dev(div1);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(175:4) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*ready*/ ctx[0]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if_block.c();
			this.c = noop;
			attr_dev(div, "class", "");
			add_location(div, file, 173, 0, 5241);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if_block.m(div, null);
			/*div_binding*/ ctx[11](div);
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
			/*div_binding*/ ctx[11](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(9, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('upload-data-metadata', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let datafileInput;
	let metadatafileInput;
	let requestAnswer = '';
	let isfetchingOdata = false;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(8, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(7, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(6, firstInitialized = true);
		$$invalidate(0, ready = true);
	};

	// Component functions
	//================================================================
	const uploadData = async e => {
		const selectedFiles = [...datafileInput.files];

		if (selectedFiles.length > 0) {
			let request = prepareUploadRequest(selectedFiles[0]);
			let response = await fetch(bc.parameterService.appParameters.applicationBaseUrl + 'Administration/UploadDataFile', request);

			if (response.status === 200) {
				success('Daten erfolgreich hochgeladen');
				$$invalidate(2, datafileInput.value = null, datafileInput);
			} else {
				console.log(response);
				errorToast('Fehler beim Hochladen der Daten', 'Request in der Konsole');
			}
		}
	};

	const uploadMetaData = async e => {
		const selectedFiles = [...metadatafileInput.files];

		if (selectedFiles.length > 0) {
			let request = prepareUploadRequest(selectedFiles[0]);
			let response = await fetch(bc.parameterService.appParameters.applicationBaseUrl + 'Administration/UploadMetadataFile', request);

			if (response.status === 200) {
				success('Daten erfolgreich hochgeladen');
				metadatafileInput.value = null;
			} else {
				console.log(response);
				errorToast('Fehler beim Hochladen der Daten', 'Request in der Konsole');
			}
		}
	};

	const prepareUploadRequest = file => {
		let formData = new FormData();
		formData.append('file', file);
		let token = bc.parameterService.get(PARAMS.user.bearerToken);

		let request = {
			method: 'POST',
			body: formData,
			headers: {}
		};

		if (token) {
			if (!request.headers) {
				request.headers = {};
			}

			request.headers.Authorization = 'Bearer ' + token;
		}

		// Chrome does not allow CORS on localhost sites
		request.headers.origin = '';

		return request;
	};

	const writable_props = ['show', 'init'];

	

	function input_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			datafileInput = $$value;
			$$invalidate(2, datafileInput);
		});
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(4, show = $$props.show);
		if ('init' in $$props) $$invalidate(5, init = $$props.init);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		applicationReady,
		PARAMS,
		errorToast,
		success,
		getComponentText,
		show,
		init,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		component,
		element,
		datafileInput,
		metadatafileInput,
		requestAnswer,
		isfetchingOdata,
		initComponent,
		uploadData,
		uploadMetaData,
		prepareUploadRequest,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(4, show = $$props.show);
		if ('init' in $$props) $$invalidate(5, init = $$props.init);
		if ('firstInitialized' in $$props) $$invalidate(6, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(7, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(15, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(8, component = $$props.component);
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
		if ('datafileInput' in $$props) $$invalidate(2, datafileInput = $$props.datafileInput);
		if ('metadatafileInput' in $$props) metadatafileInput = $$props.metadatafileInput;
		if ('requestAnswer' in $$props) requestAnswer = $$props.requestAnswer;
		if ('isfetchingOdata' in $$props) isfetchingOdata = $$props.isfetchingOdata;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 272) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 736) {
			/////////////////////////////////////////////////////
			// Init when canReload is false
			////////////////////////////////////////////////////
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		ready,
		element,
		datafileInput,
		uploadData,
		show,
		init,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		input_binding,
		div_binding
	];
}

class UploadFile extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `.rounded{border-radius:0.25rem}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.items-baseline{-webkit-box-align:baseline;-ms-flex-align:baseline;-webkit-align-items:baseline;align-items:baseline}.font-medium{font-family:Roboto-Medium}.h-8{height:2rem}.text-2xl{font-size:1.5rem;line-height:2rem}.text-lg{font-size:1.125rem;line-height:1.75rem}.mt-2{margin-top:0.5rem}.mt-4{margin-top:1rem}.mb-4{margin-bottom:1rem}.gap-2{grid-gap:0.5rem;gap:0.5rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 4, init: 5 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init"];
	}

	get show() {
		return this.$$.ctx[4];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[5];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}
}

customElements.define("upload-data-metadata", UploadFile);
export default UploadFile;