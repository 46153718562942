/* src\components\FormElements\DatePicker\DatePickerTable.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	prop_dev,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	space,
	text,
	validate_slots,
	validate_store
} from "../../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../../bcShim.js';
import { PARAMS } from '../../../services/parameterService.js';
import { v4 as uuidv4 } from '../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../web_modules/svelte.js';
import "../../../../web_modules/svelte/internal.js";

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute
} from "../../../utilities/componentUtils.js";

import { applicationReady } from "../../../utilities/store.js";
import { getComponentText } from "../../../utilities/generalUtils.js";
const file = "src\\components\\FormElements\\DatePicker\\DatePickerTable.svelte";

// (1875:4) {#if formState === 'exact'}
function create_if_block_1(ctx) {
	let div;
	let native_date_time_picker;
	let native_date_time_picker_value_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div = element_1("div");
			native_date_time_picker = element_1("native-date-time-picker");
			set_custom_element_data(native_date_time_picker, "value", native_date_time_picker_value_value = /*datestart*/ ctx[0] ? /*datestart*/ ctx[0] : '');
			set_custom_element_data(native_date_time_picker, "type", "date");
			add_location(native_date_time_picker, file, 1876, 12, 37140);
			attr_dev(div, "class", "mt-2");
			add_location(div, file, 1875, 8, 37108);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, native_date_time_picker);

			if (!mounted) {
				dispose = listen_dev(native_date_time_picker, "change", /*change_handler*/ ctx[21], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*datestart*/ 1 && native_date_time_picker_value_value !== (native_date_time_picker_value_value = /*datestart*/ ctx[0] ? /*datestart*/ ctx[0] : '')) {
				set_custom_element_data(native_date_time_picker, "value", native_date_time_picker_value_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(1875:4) {#if formState === 'exact'}",
		ctx
	});

	return block;
}

// (1888:4) {#if formState === 'range'}
function create_if_block(ctx) {
	let div0;
	let native_date_time_picker0;
	let native_date_time_picker0_value_value;
	let t;
	let div1;
	let native_date_time_picker1;
	let native_date_time_picker1_value_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div0 = element_1("div");
			native_date_time_picker0 = element_1("native-date-time-picker");
			t = space();
			div1 = element_1("div");
			native_date_time_picker1 = element_1("native-date-time-picker");
			set_custom_element_data(native_date_time_picker0, "value", native_date_time_picker0_value_value = /*datestart*/ ctx[0] ? /*datestart*/ ctx[0] : '');
			set_custom_element_data(native_date_time_picker0, "type", "date");
			add_location(native_date_time_picker0, file, 1889, 12, 37484);
			attr_dev(div0, "class", "mt-2");
			add_location(div0, file, 1888, 8, 37452);
			set_custom_element_data(native_date_time_picker1, "value", native_date_time_picker1_value_value = /*dateend*/ ctx[1] ? /*dateend*/ ctx[1] : '');
			set_custom_element_data(native_date_time_picker1, "type", "date");
			add_location(native_date_time_picker1, file, 1899, 12, 37782);
			attr_dev(div1, "class", "mt-2");
			add_location(div1, file, 1898, 8, 37750);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div0, anchor);
			append_dev(div0, native_date_time_picker0);
			insert_dev(target, t, anchor);
			insert_dev(target, div1, anchor);
			append_dev(div1, native_date_time_picker1);

			if (!mounted) {
				dispose = [
					listen_dev(native_date_time_picker0, "change", /*change_handler_1*/ ctx[22], false, false, false, false),
					listen_dev(native_date_time_picker1, "change", /*change_handler_2*/ ctx[23], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*datestart*/ 1 && native_date_time_picker0_value_value !== (native_date_time_picker0_value_value = /*datestart*/ ctx[0] ? /*datestart*/ ctx[0] : '')) {
				set_custom_element_data(native_date_time_picker0, "value", native_date_time_picker0_value_value);
			}

			if (dirty[0] & /*dateend*/ 2 && native_date_time_picker1_value_value !== (native_date_time_picker1_value_value = /*dateend*/ ctx[1] ? /*dateend*/ ctx[1] : '')) {
				set_custom_element_data(native_date_time_picker1, "value", native_date_time_picker1_value_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div0);
			if (detaching) detach_dev(t);
			if (detaching) detach_dev(div1);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(1888:4) {#if formState === 'range'}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let select;
	let option0;
	let option1;
	let t0_value = /*generalText*/ ctx[5].today + "";
	let t0;
	let option1_selected_value;
	let option2;
	let t1_value = /*generalText*/ ctx[5].thisWeek + "";
	let t1;
	let option2_selected_value;
	let option3;
	let t2_value = /*generalText*/ ctx[5].thisMonth + "";
	let t2;
	let option3_selected_value;
	let option4;
	let t3_value = /*generalText*/ ctx[5].lastWeek + "";
	let t3;
	let option4_selected_value;
	let option5;
	let t4_value = /*generalText*/ ctx[5].lastMonth + "";
	let t4;
	let option5_selected_value;
	let option6;
	let t5_value = /*generalText*/ ctx[5].nextWeek + "";
	let t5;
	let option6_selected_value;
	let option7;
	let t6_value = /*generalText*/ ctx[5].nextMonth + "";
	let t6;
	let option7_selected_value;
	let option8;
	let t7_value = /*generalText*/ ctx[5].exactDate + "";
	let t7;
	let option8_selected_value;
	let option9;
	let t8_value = /*generalText*/ ctx[5].rangedDate + "";
	let t8;
	let option9_selected_value;
	let t9;
	let t10;
	let mounted;
	let dispose;
	let if_block0 = /*formState*/ ctx[4] === 'exact' && create_if_block_1(ctx);
	let if_block1 = /*formState*/ ctx[4] === 'range' && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			select = element_1("select");
			option0 = element_1("option");
			option1 = element_1("option");
			t0 = text(t0_value);
			option2 = element_1("option");
			t1 = text(t1_value);
			option3 = element_1("option");
			t2 = text(t2_value);
			option4 = element_1("option");
			t3 = text(t3_value);
			option5 = element_1("option");
			t4 = text(t4_value);
			option6 = element_1("option");
			t5 = text(t5_value);
			option7 = element_1("option");
			t6 = text(t6_value);
			option8 = element_1("option");
			t7 = text(t7_value);
			option9 = element_1("option");
			t8 = text(t8_value);
			t9 = space();
			if (if_block0) if_block0.c();
			t10 = space();
			if (if_block1) if_block1.c();
			this.c = noop;
			option0.__value = "none";
			option0.value = option0.__value;
			add_location(option0, file, 1862, 8, 36134);
			option1.__value = "today";
			option1.value = option1.__value;
			option1.selected = option1_selected_value = /*formState*/ ctx[4] === 'exact';
			add_location(option1, file, 1863, 8, 36174);
			option2.__value = "week";
			option2.value = option2.__value;
			option2.selected = option2_selected_value = /*formState*/ ctx[4] === 'range';
			add_location(option2, file, 1864, 8, 36267);
			option3.__value = "month";
			option3.value = option3.__value;
			option3.selected = option3_selected_value = /*formState*/ ctx[4] === 'range';
			add_location(option3, file, 1865, 8, 36362);
			option4.__value = "last week";
			option4.value = option4.__value;
			option4.selected = option4_selected_value = /*formState*/ ctx[4] === 'range';
			add_location(option4, file, 1866, 8, 36459);
			option5.__value = "last month";
			option5.value = option5.__value;
			option5.selected = option5_selected_value = /*formState*/ ctx[4] === 'range';
			add_location(option5, file, 1867, 8, 36559);
			option6.__value = "next week";
			option6.value = option6.__value;
			option6.selected = option6_selected_value = /*formState*/ ctx[4] === 'range';
			add_location(option6, file, 1868, 8, 36661);
			option7.__value = "next month";
			option7.value = option7.__value;
			option7.selected = option7_selected_value = /*formState*/ ctx[4] === 'range';
			add_location(option7, file, 1869, 8, 36761);
			option8.__value = "exact";
			option8.value = option8.__value;
			option8.selected = option8_selected_value = /*formState*/ ctx[4] === 'exact';
			add_location(option8, file, 1870, 8, 36863);
			option9.__value = "range";
			option9.value = option9.__value;
			option9.selected = option9_selected_value = /*formState*/ ctx[4] === 'range';
			add_location(option9, file, 1871, 8, 36960);
			attr_dev(select, "class", "border border-solid border-gray-300 rounded");
			add_location(select, file, 1861, 4, 36011);
			attr_dev(div, "class", "date-picker-filter-container");
			add_location(div, file, 1860, 0, 35943);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, select);
			append_dev(select, option0);
			append_dev(select, option1);
			append_dev(option1, t0);
			append_dev(select, option2);
			append_dev(option2, t1);
			append_dev(select, option3);
			append_dev(option3, t2);
			append_dev(select, option4);
			append_dev(option4, t3);
			append_dev(select, option5);
			append_dev(option5, t4);
			append_dev(select, option6);
			append_dev(option6, t5);
			append_dev(select, option7);
			append_dev(option7, t6);
			append_dev(select, option8);
			append_dev(option8, t7);
			append_dev(select, option9);
			append_dev(option9, t8);
			/*select_binding*/ ctx[20](select);
			append_dev(div, t9);
			if (if_block0) if_block0.m(div, null);
			append_dev(div, t10);
			if (if_block1) if_block1.m(div, null);
			/*div_binding*/ ctx[24](div);

			if (!mounted) {
				dispose = listen_dev(select, "change", /*onSelectChange*/ ctx[6], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*formState*/ 16 && option1_selected_value !== (option1_selected_value = /*formState*/ ctx[4] === 'exact')) {
				prop_dev(option1, "selected", option1_selected_value);
			}

			if (dirty[0] & /*formState*/ 16 && option2_selected_value !== (option2_selected_value = /*formState*/ ctx[4] === 'range')) {
				prop_dev(option2, "selected", option2_selected_value);
			}

			if (dirty[0] & /*formState*/ 16 && option3_selected_value !== (option3_selected_value = /*formState*/ ctx[4] === 'range')) {
				prop_dev(option3, "selected", option3_selected_value);
			}

			if (dirty[0] & /*formState*/ 16 && option4_selected_value !== (option4_selected_value = /*formState*/ ctx[4] === 'range')) {
				prop_dev(option4, "selected", option4_selected_value);
			}

			if (dirty[0] & /*formState*/ 16 && option5_selected_value !== (option5_selected_value = /*formState*/ ctx[4] === 'range')) {
				prop_dev(option5, "selected", option5_selected_value);
			}

			if (dirty[0] & /*formState*/ 16 && option6_selected_value !== (option6_selected_value = /*formState*/ ctx[4] === 'range')) {
				prop_dev(option6, "selected", option6_selected_value);
			}

			if (dirty[0] & /*formState*/ 16 && option7_selected_value !== (option7_selected_value = /*formState*/ ctx[4] === 'range')) {
				prop_dev(option7, "selected", option7_selected_value);
			}

			if (dirty[0] & /*formState*/ 16 && option8_selected_value !== (option8_selected_value = /*formState*/ ctx[4] === 'exact')) {
				prop_dev(option8, "selected", option8_selected_value);
			}

			if (dirty[0] & /*formState*/ 16 && option9_selected_value !== (option9_selected_value = /*formState*/ ctx[4] === 'range')) {
				prop_dev(option9, "selected", option9_selected_value);
			}

			if (/*formState*/ ctx[4] === 'exact') {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(div, t10);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*formState*/ ctx[4] === 'range') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(div, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			/*select_binding*/ ctx[20](null);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			/*div_binding*/ ctx[24](null);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(19, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('date-picker-table', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { publishevent = '' } = $$props;
	let { dataviewguid = '' } = $$props;
	let { column = '' } = $$props;
	let { datestart = '' } = $$props;
	let { dateend = '' } = $$props;
	let { selectvalue = '' } = $$props;

	// Base variables
	//================================================================
	let ready = false;

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = true;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let componentId = uuidv4();
	let selectElement;
	let formState = 'none';
	const language = bc.parameterService.get(PARAMS.application.languageName); // Language-Settings
	let dateFormat = 'dd.MM.yyyy';
	let splitCharacter = '.';
	let valueStart = '';
	let valueEnd = '';

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			console.log("DatePickerTable mounting", element, element.parentNode, element.getRootNode(), element.getRootNode().host);
			$$invalidate(18, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(17, mounted = true);
			});
		});

		return () => {
			
		};
	});

	const initComponent = (selectvalue, datestart, dateend) => {
		$$invalidate(16, firstInitialized = true);

		if (dataviewguid !== '' && column !== '' && ready === false) {
			switch (language) {
				case 'de':
					{
						dateFormat = 'dd.MM.yyyy';
						splitCharacter = '.';
						break;
					}
				case 'fr':
					{
						dateFormat = 'dd/MM/yyyy';
						splitCharacter = '/';
						break;
					}
				case 'en':
					{
						dateFormat = 'dd/MM/yyyy';
						splitCharacter = '/';
						break;
					}
			}

			if (selectvalue !== '') {
				$$invalidate(4, formState = selectvalue);
			} else {
				$$invalidate(4, formState = 'none');
			}

			ready = true;
		}
	};

	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				selectvalue,
				datestart,
				dateend
			);
		}
	};

	// Component functions
	//================================================================
	const dispatchValue = value => {
		let event = new Event('change',
		{
				bubbles: true,
				composed: true,
				cancelable: true
			});

		//@ts-ignore
		event.detail = value;

		component.dispatchEvent(event);
	};

	const onSelectChange = e => {
		console.log("DatePickerTable onSelectChange", selectElement, selectElement.options, selectElement.options.selectedIndex, selectElement.options[selectElement.options.selectedIndex].value);
		$$invalidate(4, formState = selectElement.options[selectElement.options.selectedIndex].value);

		if (publishevent !== '') {
			bc.messageService.publish(publishevent, {
				column,
				value: '',
				value2: '',
				dateFilterType: formState === 'none' ? null : formState
			});
		}

		dispatchValue({
			column,
			value: '',
			value2: '',
			dateFilterType: formState === 'none' ? null : formState
		});
	};

	const setDateExact = data => {
		if (publishevent !== '') {
			bc.messageService.publish(publishevent, {
				column,
				value: data.dateISO,
				dateFilterType: 'exact'
			});
		}

		dispatchValue({
			column,
			value: data.dateISO,
			dateFilterType: 'exact'
		});
	};

	const setDateStart = data => {
		if (publishevent !== '') {
			bc.messageService.publish(publishevent, {
				column,
				value: data.dateISO,
				value2: valueEnd,
				dateFilterType: 'range'
			});
		}

		dispatchValue({
			column,
			value: data.dateISO,
			value2: valueEnd,
			dateFilterType: 'range'
		});

		valueStart = data.dateISO;
	};

	const setDateEnd = data => {
		if (publishevent !== '') {
			bc.messageService.publish(publishevent, {
				column,
				value: valueStart,
				value2: data.dateISO,
				dateFilterType: 'range'
			});
		}

		dispatchValue({
			column,
			value: valueStart,
			value2: data.dateISO,
			dateFilterType: 'range'
		});

		valueEnd = data.dateISO;
	};

	const writable_props = [
		'show',
		'init',
		'publishevent',
		'dataviewguid',
		'column',
		'datestart',
		'dateend',
		'selectvalue'
	];

	

	function select_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			selectElement = $$value;
			$$invalidate(3, selectElement);
		});
	}

	const change_handler = e => {
		setDateExact(e.detail);
	};

	const change_handler_1 = e => {
		setDateStart(e.detail);
	};

	const change_handler_2 = e => {
		setDateEnd(e.detail);
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(2, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(10, show = $$props.show);
		if ('init' in $$props) $$invalidate(11, init = $$props.init);
		if ('publishevent' in $$props) $$invalidate(12, publishevent = $$props.publishevent);
		if ('dataviewguid' in $$props) $$invalidate(13, dataviewguid = $$props.dataviewguid);
		if ('column' in $$props) $$invalidate(14, column = $$props.column);
		if ('datestart' in $$props) $$invalidate(0, datestart = $$props.datestart);
		if ('dateend' in $$props) $$invalidate(1, dateend = $$props.dateend);
		if ('selectvalue' in $$props) $$invalidate(15, selectvalue = $$props.selectvalue);
	};

	$$self.$capture_state = () => ({
		bc,
		PARAMS,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		applicationReady,
		getComponentText,
		show,
		init,
		publishevent,
		dataviewguid,
		column,
		datestart,
		dateend,
		selectvalue,
		ready,
		firstInitialized,
		subscribeGuid,
		generalText,
		mounted,
		canReload,
		component,
		element,
		componentId,
		selectElement,
		formState,
		language,
		dateFormat,
		splitCharacter,
		valueStart,
		valueEnd,
		initComponent,
		applyProps,
		dispatchValue,
		onSelectChange,
		setDateExact,
		setDateStart,
		setDateEnd,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(10, show = $$props.show);
		if ('init' in $$props) $$invalidate(11, init = $$props.init);
		if ('publishevent' in $$props) $$invalidate(12, publishevent = $$props.publishevent);
		if ('dataviewguid' in $$props) $$invalidate(13, dataviewguid = $$props.dataviewguid);
		if ('column' in $$props) $$invalidate(14, column = $$props.column);
		if ('datestart' in $$props) $$invalidate(0, datestart = $$props.datestart);
		if ('dateend' in $$props) $$invalidate(1, dateend = $$props.dateend);
		if ('selectvalue' in $$props) $$invalidate(15, selectvalue = $$props.selectvalue);
		if ('ready' in $$props) ready = $$props.ready;
		if ('firstInitialized' in $$props) $$invalidate(16, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('mounted' in $$props) $$invalidate(17, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('component' in $$props) $$invalidate(18, component = $$props.component);
		if ('element' in $$props) $$invalidate(2, element = $$props.element);
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('selectElement' in $$props) $$invalidate(3, selectElement = $$props.selectElement);
		if ('formState' in $$props) $$invalidate(4, formState = $$props.formState);
		if ('dateFormat' in $$props) dateFormat = $$props.dateFormat;
		if ('splitCharacter' in $$props) splitCharacter = $$props.splitCharacter;
		if ('valueStart' in $$props) valueStart = $$props.valueStart;
		if ('valueEnd' in $$props) valueEnd = $$props.valueEnd;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*show, component*/ 263168) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[0] & /*init, firstInitialized, mounted, $applicationReady*/ 722944) {
			/**
 * Handle the component init attribute, for the case that the component has not been initialized yet.
 */
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty[0] & /*selectvalue, datestart, dateend*/ 32771) {
			/**
 * Handles any component attribute changes after the component is initialized.
 */
			$: (selectvalue, datestart, dateend, applyProps(true));
		}
	};

	return [
		datestart,
		dateend,
		element,
		selectElement,
		formState,
		generalText,
		onSelectChange,
		setDateExact,
		setDateStart,
		setDateEnd,
		show,
		init,
		publishevent,
		dataviewguid,
		column,
		selectvalue,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		select_binding,
		change_handler,
		change_handler_1,
		change_handler_2,
		div_binding
	];
}

class DatePickerTable extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.date-picker-filter-container{display:flex;flex-direction:column}.date-picker-filter-container select{height:29px;width:200px;outline:none}.date-picker-filter-container select:focus{outline:none;border-color:#9ecaed;box-shadow:0 0 10px #9ecaed}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.mt-2{margin-top:0.5rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 10,
				init: 11,
				publishevent: 12,
				dataviewguid: 13,
				column: 14,
				datestart: 0,
				dateend: 1,
				selectvalue: 15
			},
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return [
			"show",
			"init",
			"publishevent",
			"dataviewguid",
			"column",
			"datestart",
			"dateend",
			"selectvalue"
		];
	}

	get show() {
		return this.$$.ctx[10];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[11];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get publishevent() {
		return this.$$.ctx[12];
	}

	set publishevent(publishevent) {
		this.$$set({ publishevent });
		flush();
	}

	get dataviewguid() {
		return this.$$.ctx[13];
	}

	set dataviewguid(dataviewguid) {
		this.$$set({ dataviewguid });
		flush();
	}

	get column() {
		return this.$$.ctx[14];
	}

	set column(column) {
		this.$$set({ column });
		flush();
	}

	get datestart() {
		return this.$$.ctx[0];
	}

	set datestart(datestart) {
		this.$$set({ datestart });
		flush();
	}

	get dateend() {
		return this.$$.ctx[1];
	}

	set dateend(dateend) {
		this.$$set({ dateend });
		flush();
	}

	get selectvalue() {
		return this.$$.ctx[15];
	}

	set selectvalue(selectvalue) {
		this.$$set({ selectvalue });
		flush();
	}
}

customElements.define("date-picker-table", DatePickerTable);
export default DatePickerTable;