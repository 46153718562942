/* src\components\Popover\PopOverOption.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	bubble,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text as text_1,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\Popover\\PopOverOption.svelte";

// (93:4) {#if icon}
function create_if_block(ctx) {
	let fa_icon;

	const block = {
		c: function create() {
			fa_icon = element_1("fa-icon");
			set_custom_element_data(fa_icon, "icon", /*icon*/ ctx[0]);
			add_location(fa_icon, file, 93, 8, 2730);
		},
		m: function mount(target, anchor) {
			insert_dev(target, fa_icon, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*icon*/ 1) {
				set_custom_element_data(fa_icon, "icon", /*icon*/ ctx[0]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(fa_icon);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(93:4) {#if icon}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let t0;
	let span;
	let t1;
	let mounted;
	let dispose;
	let if_block = /*icon*/ ctx[0] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			t0 = space();
			span = element_1("span");
			t1 = text_1(/*text*/ ctx[1]);
			this.c = noop;
			add_location(span, file, 95, 4, 2778);
			attr_dev(div, "class", "p-4 flex items-center gap-2 hover:bg-gray-100 cursor-pointer");
			add_location(div, file, 91, 0, 2601);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			append_dev(div, t0);
			append_dev(div, span);
			append_dev(span, t1);
			/*div_binding*/ ctx[10](div);

			if (!mounted) {
				dispose = listen_dev(div, "click", /*click_handler*/ ctx[9], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (/*icon*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, t0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*text*/ 2) set_data_dev(t1, /*text*/ ctx[1]);
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[10](null);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(8, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('popover-option', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { icon = '' } = $$props;
	let { text = '' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(7, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(6, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(5, firstInitialized = true);
		ready = true;
	};

	const writable_props = ['show', 'init', 'icon', 'text'];

	

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(2, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(3, show = $$props.show);
		if ('init' in $$props) $$invalidate(4, init = $$props.init);
		if ('icon' in $$props) $$invalidate(0, icon = $$props.icon);
		if ('text' in $$props) $$invalidate(1, text = $$props.text);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		applicationReady,
		getComponentText,
		show,
		init,
		icon,
		text,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		component,
		element,
		initComponent,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(3, show = $$props.show);
		if ('init' in $$props) $$invalidate(4, init = $$props.init);
		if ('icon' in $$props) $$invalidate(0, icon = $$props.icon);
		if ('text' in $$props) $$invalidate(1, text = $$props.text);
		if ('firstInitialized' in $$props) $$invalidate(5, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) ready = $$props.ready;
		if ('mounted' in $$props) $$invalidate(6, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(14, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(7, component = $$props.component);
		if ('element' in $$props) $$invalidate(2, element = $$props.element);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 136) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 368) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		icon,
		text,
		element,
		show,
		init,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		click_handler,
		div_binding
	];
}

class PopOverOption extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `.hover\\:bg-gray-100:hover{--tw-bg-opacity:1;background-color:rgba(243, 244, 246, var(--tw-bg-opacity))}.cursor-pointer{cursor:pointer}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.p-4{padding:1rem}.gap-2{grid-gap:0.5rem;gap:0.5rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 3, init: 4, icon: 0, text: 1 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "icon", "text"];
	}

	get show() {
		return this.$$.ctx[3];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[4];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get icon() {
		return this.$$.ctx[0];
	}

	set icon(icon) {
		this.$$set({ icon });
		flush();
	}

	get text() {
		return this.$$.ctx[1];
	}

	set text(text) {
		this.$$set({ text });
		flush();
	}
}

customElements.define("popover-option", PopOverOption);
export default PopOverOption;