import CcUgdmMetaEnvironment from "../CcUgdmMetaEnvironmentShim.js";
import rfdc from "../../web_modules/rfdc.js";
import deepEqual from "../../web_modules/deep-equal.js";
import {constants} from "../constants.js";
let clone = rfdc();
export const PARAMS = {
  constants,
  application: {
    authenticationRequired: "authenticationRequired",
    applicationGUID: "applicationGUID",
    applicationName: "applicationName",
    domainNamePrefix: "domainName_",
    applicationDescription: "applicationDescription",
    domainGuid: "domainGuid",
    languageGuid: "languageGuid",
    languageName: "languageName",
    languageLongName: "languageLongName",
    clientCulture: "clientCulture",
    languageExplicit: "languageExplicit",
    implementationGUID: "implementationGUID",
    classificationTreeRootGUID: "classificationTreeRootGUID",
    measurementProgrammeTreeRootGuid: "measurementProgrammeTreeRootGuid",
    UITheme: "UITheme"
  },
  request: {
    CcUgdmMetaEnvironment: "CcUgdmMetaEnvironment",
    CcUgdmQueryParameter: "CcUgdmQueryParameter",
    message: "message",
    error: "error",
    returnUrl: "returnUrl",
    pageGUID: "pageGUID",
    pageName: "pageName",
    pageTitle: "pageTitle"
  },
  user: {
    userName: "userName",
    userId: "userId",
    roles: "roles",
    roleIds: "roleIds",
    bearerToken: "bearerToken"
  },
  urls: {
    applicationBaseUrl: "applicationBaseUrl",
    APIBaseUrl: "APIBaseUrl",
    OdataBaseUrl: "OdataBaseUrl",
    ApplicationDataServiceBaseUrl: "ApplicationDataServiceBaseUrl"
  },
  kendo: {
    document: "document"
  },
  metadata: "metadata"
};
const createDefaultConfig = (config) => {
  if (config) {
    return {
      authenticationRequired: config.AuthenticationRequired,
      applicationGUID: config.ApplicationGuid,
      applicationName: config.ApplicationName,
      applicationDescription: config.ApplicationDescription,
      domainGuid: config.DomainGuid,
      languageGuid: config.LanguageGuid,
      languageName: config.LanguageName,
      languageLongName: config.LanguageLongName,
      clientCulture: config.ClientCulture,
      languageExplicit: config.LanguageExplicit,
      implementationGUID: config.ImplementationGuid,
      classificationTreeRootGUID: config.ClassificationTreeRootGuid,
      measurementProgrammeTreeRootGuid: config.MeasurementProgrammeTreeRootGuid,
      UITheme: "primary",
      CcUgdmMetaEnvironment: {},
      CcUgdmQueryParameter: {},
      message: config.Message,
      error: config.Message,
      returnUrl: config.ReturnUrl,
      pageGUID: config.PageGuid,
      pageName: config.PageName,
      pageTitle: config.PageTitle,
      userName: config.UserName,
      userId: config.UserId,
      roles: config.Roles,
      roleIds: config.RoleIds,
      bearerToken: config.BearerToken ? config.BearerToken : "",
      applicationBaseUrl: config.ApplicationBaseUrl,
      APIBaseUrl: config.ApiBaseUrl,
      OdataBaseUrl: config.OdataBaseUrl,
      ApplicationDataServiceBaseUrl: config.ApplicationDataServiceBaseUrl,
      document: window.document,
      applicationSchemaName: config.ApplicationSchemaName,
      coreSchemaName: config.CoreSchemaName,
      geodataSchemaName: config.GeodataSchemaName,
      metaSchemaName: config.MetaSchemaName,
      serverVersion: config.ServerVersion,
      clientVersion: config.ClientVersion,
      metadata: []
    };
  } else {
    return {
      authenticationRequired: false,
      applicationGUID: "",
      applicationName: "",
      applicationDescription: "",
      domainGuid: "",
      languageGuid: "",
      languageName: "de",
      languageLongName: "",
      clientCulture: "",
      languageExplicit: false,
      implementationGUID: "",
      classificationTreeRootGUID: "",
      measurementProgrammeTreeRootGuid: "",
      UITheme: "primary",
      CcUgdmMetaEnvironment: {},
      CcUgdmQueryParameter: {},
      message: "",
      error: "",
      returnUrl: "",
      pageGUID: "",
      pageName: "",
      pageTitle: "",
      userName: "",
      userId: "",
      roles: [],
      roleIds: [],
      bearerToken: "",
      applicationBaseUrl: "",
      APIBaseUrl: "",
      OdataBaseUrl: "",
      ApplicationDataServiceBaseUrl: "",
      serverVersion: "1.0",
      clientVersion: "1.0",
      document: window.document,
      metadata: []
    };
  }
};
export const createParamterService = (appConfiguration) => {
  let configuration = createDefaultConfig(appConfiguration);
  console.log("createParamterService: " + configuration.OdataBaseUrl);
  let myCache = [];
  let currentRequestMap = new Map();
  return {
    constants,
    cache: myCache,
    appParameters: configuration,
    set: (parameter, parameterValue, compare, madeby) => {
      let changed = false;
      const cacheElement = myCache.find((c) => c.parameter === parameter);
      if (cacheElement) {
        if (compare && compare === true) {
          let compareValue;
          if (typeof parameterValue === "object") {
            compareValue = JSON.parse(JSON.stringify(parameterValue));
          } else {
            compareValue = parameterValue;
          }
          if (!deepEqual(compareValue, configuration[parameter], {strict: true})) {
            configuration[parameter] = compareValue;
            changed = true;
            for (let i = 0; i < cacheElement.callbacks.length; i++) {
              const callbackObject = cacheElement.callbacks[i];
              callbackObject.fn(clone(parameterValue));
            }
          }
        } else {
          configuration[parameter] = parameterValue;
          changed = true;
          let callbacks = cacheElement.callbacks.map((fn) => fn.fn);
          for (let i = 0; i < callbacks.length; i++) {
            const callbackObject = callbacks[i];
            callbackObject(parameterValue);
          }
        }
      } else {
        if (compare && compare === true) {
          configuration[parameter] = clone(parameterValue);
        } else {
          configuration[parameter] = parameterValue;
        }
        changed = true;
      }
    },
    get: (parameter, copy) => {
      if (configuration[parameter] !== null && configuration[parameter] !== void 0) {
        if (parameter === "MAP_COMPONENT") {
          return configuration[parameter];
        } else {
          if (copy && copy === true) {
            if (typeof configuration[parameter] === "object") {
              return clone(configuration[parameter]);
            } else {
              return configuration[parameter];
            }
          } else {
            return configuration[parameter];
          }
        }
      } else {
        return null;
      }
    },
    remove: (parameter) => {
      if (configuration[parameter]) {
        delete configuration[parameter];
      }
    },
    setMetadata: (parameter, value) => {
      try {
        let group = configuration["ResourceMetadata"];
        if (group) {
          group[parameter] = value;
        } else {
          configuration["ResourceMetadata"] = {};
          configuration["ResourceMetadata"][parameter] = value;
        }
      } catch (error) {
        throw error;
      }
    },
    getMetadata: async (parameter) => {
      try {
        let group = configuration["ResourceMetadata"];
        if (!group) {
          configuration["ResourceMetadata"] = {};
          group = configuration["ResourceMetadata"];
        }
        const param = group[parameter];
        if (param !== void 0 && param !== null) {
          return param;
        } else {
          let currentRequest = currentRequestMap.get(parameter);
          if (currentRequest) {
            await currentRequest;
            currentRequestMap.delete(parameter);
            return configuration["ResourceMetadata"][parameter];
          } else {
            currentRequestMap.set(parameter, getViewMetadata(parameter));
            return currentRequestMap.get(parameter).then((result) => {
              currentRequestMap.delete(parameter);
              return configuration["ResourceMetadata"][parameter];
            });
          }
        }
      } catch (error) {
        throw error;
      }
    },
    getActorAttributes: async (parameter) => {
      let metadata = await parameterService.getMetadata(parameter);
      if (!metadata) {
        return [];
      }
      let attributesArray = [];
      for (var name in metadata.viewDescription.Attributes) {
        let obj = metadata.viewDescription.Attributes[name];
        attributesArray.push(obj);
      }
      let actorAttributes = attributesArray.filter((a, index, arr) => {
        return a.Parent === parameterService.constants.entityAttributeIds.EY_CREATED_BY || a.Parent === parameterService.constants.entityAttributeIds.EY_MODIFIED_BY || a.Parent === parameterService.constants.entityAttributeIds.EY_OWNER || a.Parent === parameterService.constants.entityAttributeIds.EY_RESPONSIBLE;
      });
      return actorAttributes;
    },
    getTextAttributes: async (parameter) => {
      let metadata = await parameterService.getMetadata(parameter);
      if (!metadata) {
        return [];
      }
      let attributesArray = [];
      for (var name in metadata.viewDescription.Attributes) {
        let obj = metadata.viewDescription.Attributes[name];
        attributesArray.push(obj);
      }
      let textAttributes = attributesArray.filter((a, index, arr) => {
        return a.BcAttribute.AT_DATA_TYPE === parameterService.constants.dataType.String;
      });
      return textAttributes;
    },
    getDateAttributes: async (parameter) => {
      let metadata = await parameterService.getMetadata(parameter);
      if (!metadata) {
        return [];
      }
      let attributesArray = [];
      for (var name in metadata.viewDescription.Attributes) {
        let obj = metadata.viewDescription.Attributes[name];
        attributesArray.push(obj);
      }
      let dateTimeAttributes = attributesArray.filter((a, index, arr) => {
        return a.BcAttribute.AT_DATA_TYPE === parameterService.constants.dataType.DateTime;
      });
      return dateTimeAttributes;
    },
    tryGetMetadata: (parameter) => {
      try {
        let group = configuration["ResourceMetadata"];
        if (!group) {
          configuration["ResourceMetadata"] = {};
          group = configuration["ResourceMetadata"];
        }
        const param = group[parameter];
        if (param !== void 0 && param !== null) {
          return param;
        }
        return null;
      } catch (error) {
        throw error;
      }
    },
    subscribe: (parameter, idSubscriber, callback) => {
      if (!myCache.find((c) => c.parameter === parameter && c.callbacks.find((call) => call.id === idSubscriber))) {
        const cacheElement = myCache.find((c) => c.parameter === parameter);
        if (cacheElement) {
          cacheElement.callbacks.push({id: idSubscriber, fn: callback});
        } else {
          myCache.push({parameter, callbacks: [{id: idSubscriber, fn: callback}]});
        }
      } else {
        const cacheElement = myCache.find((c) => c.parameter === parameter && c.callbacks.find((call) => call.id === idSubscriber));
        let add = true;
        cacheElement.callbacks.forEach((elem) => {
          if (elem.id === idSubscriber && elem.fn === callback) {
            add = false;
          }
        });
        if (add) {
          cacheElement.callbacks.push({id: idSubscriber, fn: callback});
        }
      }
    },
    unsubscribe: (parameter, idSubscriber) => {
      const cacheElement = myCache.find((c) => c.parameter === parameter);
      if (cacheElement) {
        const callbackObject = cacheElement.callbacks;
        let index = 0;
        let found = false;
        for (let i = 0; i < callbackObject.length; i++) {
          if (callbackObject[i].id === idSubscriber) {
            index = i;
            found = true;
            break;
          }
        }
        if (found) {
          callbackObject.splice(index, 1);
        }
      }
    },
    unsubscribeComponent: (idSubscriber) => {
      for (let i = 0; i < myCache.length; i++) {
        let cacheElement = myCache[i];
        cacheElement.callbacks = cacheElement.callbacks.filter((cb) => cb.id !== idSubscriber);
      }
    }
  };
};
export const parameterService = createParamterService(CcUgdmMetaEnvironment);
const getViewMetadata = async (parameter) => {
  if (parameterService.appParameters.serverVersion >= "4.0") {
    return bc.metadataManager.fetchEntityDetail4(parameter);
  } else {
    let userView = await isUserView(parameter);
    if (userView) {
      return bc.metadataManager.fetchEntityDetail3(parameter);
    } else {
      console.log("isNotUserView", parameter);
      return bc.metadataManager.fetchEntityDetail(parameter);
    }
  }
};
const isUserView = async (viewGuid) => {
  let guids = await getUserViews();
  if (guids.includes(viewGuid)) {
    return true;
  }
  return false;
};
const getUserViews = async () => {
  let guids = parameterService.get("USER_VIEW_GUIDS");
  if (guids === null) {
    let userViewGuids = await bc.metadataManager.getCachedUgdmTypesByDomain(constants.domain.EY_APP_DATA_VIEW);
    guids = userViewGuids.map((m) => m.TY_ID);
    parameterService.set("USER_VIEW_GUIDS", guids);
  }
  return guids;
};
