/* src\components\ParameterManager\ParameterManager.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	attribute_to_object,
	binding_callbacks,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	globals,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots
} from "../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';
import { infoLog } from "../../utilities/consoleUtils.js";

import {
	dispatchMountedEvent,
	singletonComponentExists
} from "../../utilities/componentUtils.js";

import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\ParameterManager\\ParameterManager.svelte";

function create_fragment(ctx) {
	let div;

	const block = {
		c: function create() {
			div = element_1("div");
			this.c = noop;
			add_location(div, file, 192, 0, 8774);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			/*div_binding*/ ctx[59](div);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			/*div_binding*/ ctx[59](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('parameter-manager', slots, []);
	const generalText = getComponentText('general');
	let { init = 'true' } = $$props;
	let { layertree = '' } = $$props;
	let { themetree = '' } = $$props;
	let { themetreetitel = generalText.themeTreeTitle } = $$props;
	let { showbackrelations = 'YES' } = $$props;
	let { hidekanbanforactiontypes = 'Journal' } = $$props;
	let { showmap = 'YES' } = $$props;
	let { showsearch = 'YES' } = $$props;
	let { showdashboard = 'YES' } = $$props;
	let { showlastopened = 'YES' } = $$props;
	let { showthemesearch = 'YES' } = $$props;
	let { themesopenedatstart = 'NO' } = $$props;
	let { showmenuonstartup = 'YES' } = $$props;
	let { entrypoint = 'dashboard' } = $$props;
	let { showheaderbar = 'NO' } = $$props;
	let { headerbarcolor = 'Blue' } = $$props;
	let { logouturl = 'https://www.brytecube.com' } = $$props;
	let { uploadmaxsize = '50' } = $$props;
	let { showstatistics = 'YES' } = $$props;
	let { showcalendar = 'YES' } = $$props;
	let { showkanban = 'YES' } = $$props;
	let { showexcelexport = 'YES' } = $$props;
	let { showwordexport = 'YES' } = $$props;
	let { showgeopackageexport = 'YES' } = $$props;
	let { singlepropertyedit = 'NO' } = $$props;
	let { previewfullwidth = 'NO' } = $$props;
	let { showmouseposition = 'NO' } = $$props;
	let { quicksearchguids = '' } = $$props;
	let { quicksearchcolumns = '' } = $$props;
	let { quicksearchnames = '' } = $$props;
	let { showmapscale = 'NO' } = $$props;
	let { layerdefaultdisplay = 'list' } = $$props;
	let { navigationtype = 'intern' } = $$props;
	let { limitrelationshipstothemetree = 'NO' } = $$props;
	let { limitlayerlisttolayertree = "NO" } = $$props;
	let { formwithtabs = 'false' } = $$props;
	let { tabformviews = '' } = $$props;
	let { mapintab = 'false' } = $$props;
	let { nestedforms = 'false' } = $$props;
	let { nestedformlevel = '2' } = $$props;
	let { nestedformviews = '' } = $$props;
	let { nestedformviewlevels = '' } = $$props;
	let { synchronisemaps = 'true' } = $$props;
	let { mapsynchronisemode = 'POSITIONANDLAYERS' } = $$props;
	let { maxextent = "2380752.00,1000549.00,2938262.00,1403520.00" } = $$props;
	let { defaultcenter = "2659507.000,1187035.000" } = $$props;
	let { minscale = "100.0" } = $$props;
	let { maxscale = "1000000.0" } = $$props;
	let { scales = "100000,50000,25000,10000,5000,2500,1000,500,250,100" } = $$props;
	let { unit = "m" } = $$props;
	let { projection = "EPSG:2056" } = $$props;
	let { hasthemetree = 'true' } = $$props;
	let { showqrcode = 'false' } = $$props;
	let { qrcodeviews = '' } = $$props;
	let { showinfra3d = 'NO' } = $$props;
	let { urlinfra3d = 'https://infra3d-idp-dev.auth.eu-west-1.amazoncognito.com/oauth2/token' } = $$props;
	let { clientidinfra3d = '719hvejtvtk20pdja2bc3864nr' } = $$props;
	let { clientsecretinfra3d = '1ved2qe750ipv6roc9ahgqemuanc4k5aqbuknbkmc4j522qj5r0s' } = $$props;

	// Base Variables
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let mounted = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this

	const handleParam = (param, paramName) => {
		if (paramName !== 'ClientSecretInfra3D') {
			infoLog('Parameter-Manager', `${paramName} => ${param}`);
		} else {
			infoLog('Parameter-Manager', `${paramName} => ***`);
		}

		bc.parameterService.set(paramName, param);
	};

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			component = element.getRootNode().host || element.parentNode; // get the component/host

			// typed: (element.getRootNode() as ShadowRoot).host as HTMLElement
			tick().then(() => {
				dispatchMountedEvent(component);
				mounted = true;

				if (!singletonComponentExists('PARAMETER-MANAGER', true)) {
					infoLog('PARAMETER-MANAGER', 'Parameter intialized');
					handleParam(limitlayerlisttolayertree, 'LimitLayerListToLayerTree');
					handleParam(limitrelationshipstothemetree, 'LimitRelationshipsToThemeTree');
					handleParam(navigationtype, 'NavigationType');
					handleParam(layerdefaultdisplay, 'LayerDefaultDisplay');
					handleParam(layertree, 'LayerTree');
					handleParam(themetree, 'ThemeTree');
					handleParam(themetreetitel, 'ThemeTreeTitel');
					handleParam(showbackrelations, 'ShowBackRelations');
					handleParam(hidekanbanforactiontypes, 'HideKanbanForActionTypes');
					handleParam(showmap, 'ShowMap');
					handleParam(showsearch, 'ShowSearch');
					handleParam(showdashboard, 'ShowDashboard');
					handleParam(showthemesearch, 'ShowThemeSearch');
					handleParam(themesopenedatstart, 'ThemesOpenedAtStart');
					handleParam(showlastopened, 'ShowLastOpened');
					handleParam(entrypoint, 'EntryPoint');
					handleParam(showheaderbar, 'ShowHeaderBar');
					handleParam(headerbarcolor, 'HeaderBarColor');
					handleParam(logouturl, 'LogOutURL');
					handleParam(uploadmaxsize, 'UploadMaxSize');
					handleParam(showcalendar, 'ShowCalendar');
					handleParam(showstatistics, 'ShowStatistics');
					handleParam(showkanban, 'ShowKanban');
					handleParam(showexcelexport, 'ShowExcelExport');
					handleParam(showwordexport, 'ShowWordExport');
					handleParam(showgeopackageexport, 'ShowGeopackageExport');
					handleParam(singlepropertyedit, 'SinglePropertyEdit');
					handleParam(previewfullwidth, 'PreviewFullWidth');
					handleParam(showmenuonstartup, 'ShowMenuOnStartup');
					handleParam(showmouseposition, 'ShowMousePosition');
					handleParam(quicksearchguids, 'QuickSearchGuids');
					handleParam(quicksearchcolumns, 'QuickSearchColumns');
					handleParam(quicksearchnames, 'QuickSearchNames');
					handleParam(showmapscale, 'ShowMapScale');
					handleParam(formwithtabs, 'FormWithTabs');
					handleParam(tabformviews, 'TabFormViews');
					handleParam(mapintab, 'MapInTab');
					handleParam(nestedforms, 'NestedForms');
					handleParam(nestedformlevel, 'NestedFormLevel');
					handleParam(nestedformviews, 'NestedFormViews');
					handleParam(nestedformviewlevels, 'NestedFormViewLevels');
					handleParam(synchronisemaps, 'SynchroniseMaps');
					handleParam(mapsynchronisemode, 'MapsSynchroniseMode');
					handleParam(maxextent, 'MaxExtent');
					handleParam(defaultcenter, 'DefaultCenter');
					handleParam(minscale, 'MinScale');
					handleParam(maxscale, 'MaxScale');
					handleParam(scales, 'Scales');
					handleParam(unit, 'Unit');
					handleParam(projection, 'Projection');
					handleParam(showqrcode, 'ShowQRCode');
					handleParam(qrcodeviews, 'QRCodeViews');
					handleParam(showinfra3d, 'ShowInfra3D');
					handleParam(urlinfra3d, 'UrlInfra3D');
					handleParam(clientidinfra3d, 'ClientIdInfra3D');
					handleParam(clientsecretinfra3d, 'ClientSecretInfra3D');
					bc.parameterService.set('PARAMS_READY', true);
					console.log("ParameterManager is ready");
					bc.messageService.publish('PARAMS_READY', true);

					if (hasthemetree === 'true') {
						bc.parameterService.set('SHOW_VIEWS_NOT_IN_THEME_TREE', false);
					} else if (hasthemetree === 'false') {
						bc.parameterService.set('SHOW_VIEWS_NOT_IN_THEME_TREE', true);
					}
				}
			});
		});

		return () => {
			
		};
	});

	const writable_props = [
		'init',
		'layertree',
		'themetree',
		'themetreetitel',
		'showbackrelations',
		'hidekanbanforactiontypes',
		'showmap',
		'showsearch',
		'showdashboard',
		'showlastopened',
		'showthemesearch',
		'themesopenedatstart',
		'showmenuonstartup',
		'entrypoint',
		'showheaderbar',
		'headerbarcolor',
		'logouturl',
		'uploadmaxsize',
		'showstatistics',
		'showcalendar',
		'showkanban',
		'showexcelexport',
		'showwordexport',
		'showgeopackageexport',
		'singlepropertyedit',
		'previewfullwidth',
		'showmouseposition',
		'quicksearchguids',
		'quicksearchcolumns',
		'quicksearchnames',
		'showmapscale',
		'layerdefaultdisplay',
		'navigationtype',
		'limitrelationshipstothemetree',
		'limitlayerlisttolayertree',
		'formwithtabs',
		'tabformviews',
		'mapintab',
		'nestedforms',
		'nestedformlevel',
		'nestedformviews',
		'nestedformviewlevels',
		'synchronisemaps',
		'mapsynchronisemode',
		'maxextent',
		'defaultcenter',
		'minscale',
		'maxscale',
		'scales',
		'unit',
		'projection',
		'hasthemetree',
		'showqrcode',
		'qrcodeviews',
		'showinfra3d',
		'urlinfra3d',
		'clientidinfra3d',
		'clientsecretinfra3d'
	];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(0, element);
		});
	}

	$$self.$$set = $$props => {
		if ('init' in $$props) $$invalidate(1, init = $$props.init);
		if ('layertree' in $$props) $$invalidate(2, layertree = $$props.layertree);
		if ('themetree' in $$props) $$invalidate(3, themetree = $$props.themetree);
		if ('themetreetitel' in $$props) $$invalidate(4, themetreetitel = $$props.themetreetitel);
		if ('showbackrelations' in $$props) $$invalidate(5, showbackrelations = $$props.showbackrelations);
		if ('hidekanbanforactiontypes' in $$props) $$invalidate(6, hidekanbanforactiontypes = $$props.hidekanbanforactiontypes);
		if ('showmap' in $$props) $$invalidate(7, showmap = $$props.showmap);
		if ('showsearch' in $$props) $$invalidate(8, showsearch = $$props.showsearch);
		if ('showdashboard' in $$props) $$invalidate(9, showdashboard = $$props.showdashboard);
		if ('showlastopened' in $$props) $$invalidate(10, showlastopened = $$props.showlastopened);
		if ('showthemesearch' in $$props) $$invalidate(11, showthemesearch = $$props.showthemesearch);
		if ('themesopenedatstart' in $$props) $$invalidate(12, themesopenedatstart = $$props.themesopenedatstart);
		if ('showmenuonstartup' in $$props) $$invalidate(13, showmenuonstartup = $$props.showmenuonstartup);
		if ('entrypoint' in $$props) $$invalidate(14, entrypoint = $$props.entrypoint);
		if ('showheaderbar' in $$props) $$invalidate(15, showheaderbar = $$props.showheaderbar);
		if ('headerbarcolor' in $$props) $$invalidate(16, headerbarcolor = $$props.headerbarcolor);
		if ('logouturl' in $$props) $$invalidate(17, logouturl = $$props.logouturl);
		if ('uploadmaxsize' in $$props) $$invalidate(18, uploadmaxsize = $$props.uploadmaxsize);
		if ('showstatistics' in $$props) $$invalidate(19, showstatistics = $$props.showstatistics);
		if ('showcalendar' in $$props) $$invalidate(20, showcalendar = $$props.showcalendar);
		if ('showkanban' in $$props) $$invalidate(21, showkanban = $$props.showkanban);
		if ('showexcelexport' in $$props) $$invalidate(22, showexcelexport = $$props.showexcelexport);
		if ('showwordexport' in $$props) $$invalidate(23, showwordexport = $$props.showwordexport);
		if ('showgeopackageexport' in $$props) $$invalidate(24, showgeopackageexport = $$props.showgeopackageexport);
		if ('singlepropertyedit' in $$props) $$invalidate(25, singlepropertyedit = $$props.singlepropertyedit);
		if ('previewfullwidth' in $$props) $$invalidate(26, previewfullwidth = $$props.previewfullwidth);
		if ('showmouseposition' in $$props) $$invalidate(27, showmouseposition = $$props.showmouseposition);
		if ('quicksearchguids' in $$props) $$invalidate(28, quicksearchguids = $$props.quicksearchguids);
		if ('quicksearchcolumns' in $$props) $$invalidate(29, quicksearchcolumns = $$props.quicksearchcolumns);
		if ('quicksearchnames' in $$props) $$invalidate(30, quicksearchnames = $$props.quicksearchnames);
		if ('showmapscale' in $$props) $$invalidate(31, showmapscale = $$props.showmapscale);
		if ('layerdefaultdisplay' in $$props) $$invalidate(32, layerdefaultdisplay = $$props.layerdefaultdisplay);
		if ('navigationtype' in $$props) $$invalidate(33, navigationtype = $$props.navigationtype);
		if ('limitrelationshipstothemetree' in $$props) $$invalidate(34, limitrelationshipstothemetree = $$props.limitrelationshipstothemetree);
		if ('limitlayerlisttolayertree' in $$props) $$invalidate(35, limitlayerlisttolayertree = $$props.limitlayerlisttolayertree);
		if ('formwithtabs' in $$props) $$invalidate(36, formwithtabs = $$props.formwithtabs);
		if ('tabformviews' in $$props) $$invalidate(37, tabformviews = $$props.tabformviews);
		if ('mapintab' in $$props) $$invalidate(38, mapintab = $$props.mapintab);
		if ('nestedforms' in $$props) $$invalidate(39, nestedforms = $$props.nestedforms);
		if ('nestedformlevel' in $$props) $$invalidate(40, nestedformlevel = $$props.nestedformlevel);
		if ('nestedformviews' in $$props) $$invalidate(41, nestedformviews = $$props.nestedformviews);
		if ('nestedformviewlevels' in $$props) $$invalidate(42, nestedformviewlevels = $$props.nestedformviewlevels);
		if ('synchronisemaps' in $$props) $$invalidate(43, synchronisemaps = $$props.synchronisemaps);
		if ('mapsynchronisemode' in $$props) $$invalidate(44, mapsynchronisemode = $$props.mapsynchronisemode);
		if ('maxextent' in $$props) $$invalidate(45, maxextent = $$props.maxextent);
		if ('defaultcenter' in $$props) $$invalidate(46, defaultcenter = $$props.defaultcenter);
		if ('minscale' in $$props) $$invalidate(47, minscale = $$props.minscale);
		if ('maxscale' in $$props) $$invalidate(48, maxscale = $$props.maxscale);
		if ('scales' in $$props) $$invalidate(49, scales = $$props.scales);
		if ('unit' in $$props) $$invalidate(50, unit = $$props.unit);
		if ('projection' in $$props) $$invalidate(51, projection = $$props.projection);
		if ('hasthemetree' in $$props) $$invalidate(52, hasthemetree = $$props.hasthemetree);
		if ('showqrcode' in $$props) $$invalidate(53, showqrcode = $$props.showqrcode);
		if ('qrcodeviews' in $$props) $$invalidate(54, qrcodeviews = $$props.qrcodeviews);
		if ('showinfra3d' in $$props) $$invalidate(55, showinfra3d = $$props.showinfra3d);
		if ('urlinfra3d' in $$props) $$invalidate(56, urlinfra3d = $$props.urlinfra3d);
		if ('clientidinfra3d' in $$props) $$invalidate(57, clientidinfra3d = $$props.clientidinfra3d);
		if ('clientsecretinfra3d' in $$props) $$invalidate(58, clientsecretinfra3d = $$props.clientsecretinfra3d);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		infoLog,
		dispatchMountedEvent,
		singletonComponentExists,
		getComponentText,
		generalText,
		init,
		layertree,
		themetree,
		themetreetitel,
		showbackrelations,
		hidekanbanforactiontypes,
		showmap,
		showsearch,
		showdashboard,
		showlastopened,
		showthemesearch,
		themesopenedatstart,
		showmenuonstartup,
		entrypoint,
		showheaderbar,
		headerbarcolor,
		logouturl,
		uploadmaxsize,
		showstatistics,
		showcalendar,
		showkanban,
		showexcelexport,
		showwordexport,
		showgeopackageexport,
		singlepropertyedit,
		previewfullwidth,
		showmouseposition,
		quicksearchguids,
		quicksearchcolumns,
		quicksearchnames,
		showmapscale,
		layerdefaultdisplay,
		navigationtype,
		limitrelationshipstothemetree,
		limitlayerlisttolayertree,
		formwithtabs,
		tabformviews,
		mapintab,
		nestedforms,
		nestedformlevel,
		nestedformviews,
		nestedformviewlevels,
		synchronisemaps,
		mapsynchronisemode,
		maxextent,
		defaultcenter,
		minscale,
		maxscale,
		scales,
		unit,
		projection,
		hasthemetree,
		showqrcode,
		qrcodeviews,
		showinfra3d,
		urlinfra3d,
		clientidinfra3d,
		clientsecretinfra3d,
		firstInitialized,
		subscribeGuid,
		mounted,
		component,
		element,
		handleParam
	});

	$$self.$inject_state = $$props => {
		if ('init' in $$props) $$invalidate(1, init = $$props.init);
		if ('layertree' in $$props) $$invalidate(2, layertree = $$props.layertree);
		if ('themetree' in $$props) $$invalidate(3, themetree = $$props.themetree);
		if ('themetreetitel' in $$props) $$invalidate(4, themetreetitel = $$props.themetreetitel);
		if ('showbackrelations' in $$props) $$invalidate(5, showbackrelations = $$props.showbackrelations);
		if ('hidekanbanforactiontypes' in $$props) $$invalidate(6, hidekanbanforactiontypes = $$props.hidekanbanforactiontypes);
		if ('showmap' in $$props) $$invalidate(7, showmap = $$props.showmap);
		if ('showsearch' in $$props) $$invalidate(8, showsearch = $$props.showsearch);
		if ('showdashboard' in $$props) $$invalidate(9, showdashboard = $$props.showdashboard);
		if ('showlastopened' in $$props) $$invalidate(10, showlastopened = $$props.showlastopened);
		if ('showthemesearch' in $$props) $$invalidate(11, showthemesearch = $$props.showthemesearch);
		if ('themesopenedatstart' in $$props) $$invalidate(12, themesopenedatstart = $$props.themesopenedatstart);
		if ('showmenuonstartup' in $$props) $$invalidate(13, showmenuonstartup = $$props.showmenuonstartup);
		if ('entrypoint' in $$props) $$invalidate(14, entrypoint = $$props.entrypoint);
		if ('showheaderbar' in $$props) $$invalidate(15, showheaderbar = $$props.showheaderbar);
		if ('headerbarcolor' in $$props) $$invalidate(16, headerbarcolor = $$props.headerbarcolor);
		if ('logouturl' in $$props) $$invalidate(17, logouturl = $$props.logouturl);
		if ('uploadmaxsize' in $$props) $$invalidate(18, uploadmaxsize = $$props.uploadmaxsize);
		if ('showstatistics' in $$props) $$invalidate(19, showstatistics = $$props.showstatistics);
		if ('showcalendar' in $$props) $$invalidate(20, showcalendar = $$props.showcalendar);
		if ('showkanban' in $$props) $$invalidate(21, showkanban = $$props.showkanban);
		if ('showexcelexport' in $$props) $$invalidate(22, showexcelexport = $$props.showexcelexport);
		if ('showwordexport' in $$props) $$invalidate(23, showwordexport = $$props.showwordexport);
		if ('showgeopackageexport' in $$props) $$invalidate(24, showgeopackageexport = $$props.showgeopackageexport);
		if ('singlepropertyedit' in $$props) $$invalidate(25, singlepropertyedit = $$props.singlepropertyedit);
		if ('previewfullwidth' in $$props) $$invalidate(26, previewfullwidth = $$props.previewfullwidth);
		if ('showmouseposition' in $$props) $$invalidate(27, showmouseposition = $$props.showmouseposition);
		if ('quicksearchguids' in $$props) $$invalidate(28, quicksearchguids = $$props.quicksearchguids);
		if ('quicksearchcolumns' in $$props) $$invalidate(29, quicksearchcolumns = $$props.quicksearchcolumns);
		if ('quicksearchnames' in $$props) $$invalidate(30, quicksearchnames = $$props.quicksearchnames);
		if ('showmapscale' in $$props) $$invalidate(31, showmapscale = $$props.showmapscale);
		if ('layerdefaultdisplay' in $$props) $$invalidate(32, layerdefaultdisplay = $$props.layerdefaultdisplay);
		if ('navigationtype' in $$props) $$invalidate(33, navigationtype = $$props.navigationtype);
		if ('limitrelationshipstothemetree' in $$props) $$invalidate(34, limitrelationshipstothemetree = $$props.limitrelationshipstothemetree);
		if ('limitlayerlisttolayertree' in $$props) $$invalidate(35, limitlayerlisttolayertree = $$props.limitlayerlisttolayertree);
		if ('formwithtabs' in $$props) $$invalidate(36, formwithtabs = $$props.formwithtabs);
		if ('tabformviews' in $$props) $$invalidate(37, tabformviews = $$props.tabformviews);
		if ('mapintab' in $$props) $$invalidate(38, mapintab = $$props.mapintab);
		if ('nestedforms' in $$props) $$invalidate(39, nestedforms = $$props.nestedforms);
		if ('nestedformlevel' in $$props) $$invalidate(40, nestedformlevel = $$props.nestedformlevel);
		if ('nestedformviews' in $$props) $$invalidate(41, nestedformviews = $$props.nestedformviews);
		if ('nestedformviewlevels' in $$props) $$invalidate(42, nestedformviewlevels = $$props.nestedformviewlevels);
		if ('synchronisemaps' in $$props) $$invalidate(43, synchronisemaps = $$props.synchronisemaps);
		if ('mapsynchronisemode' in $$props) $$invalidate(44, mapsynchronisemode = $$props.mapsynchronisemode);
		if ('maxextent' in $$props) $$invalidate(45, maxextent = $$props.maxextent);
		if ('defaultcenter' in $$props) $$invalidate(46, defaultcenter = $$props.defaultcenter);
		if ('minscale' in $$props) $$invalidate(47, minscale = $$props.minscale);
		if ('maxscale' in $$props) $$invalidate(48, maxscale = $$props.maxscale);
		if ('scales' in $$props) $$invalidate(49, scales = $$props.scales);
		if ('unit' in $$props) $$invalidate(50, unit = $$props.unit);
		if ('projection' in $$props) $$invalidate(51, projection = $$props.projection);
		if ('hasthemetree' in $$props) $$invalidate(52, hasthemetree = $$props.hasthemetree);
		if ('showqrcode' in $$props) $$invalidate(53, showqrcode = $$props.showqrcode);
		if ('qrcodeviews' in $$props) $$invalidate(54, qrcodeviews = $$props.qrcodeviews);
		if ('showinfra3d' in $$props) $$invalidate(55, showinfra3d = $$props.showinfra3d);
		if ('urlinfra3d' in $$props) $$invalidate(56, urlinfra3d = $$props.urlinfra3d);
		if ('clientidinfra3d' in $$props) $$invalidate(57, clientidinfra3d = $$props.clientidinfra3d);
		if ('clientsecretinfra3d' in $$props) $$invalidate(58, clientsecretinfra3d = $$props.clientsecretinfra3d);
		if ('firstInitialized' in $$props) firstInitialized = $$props.firstInitialized;
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) $$invalidate(0, element = $$props.element);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [
		element,
		init,
		layertree,
		themetree,
		themetreetitel,
		showbackrelations,
		hidekanbanforactiontypes,
		showmap,
		showsearch,
		showdashboard,
		showlastopened,
		showthemesearch,
		themesopenedatstart,
		showmenuonstartup,
		entrypoint,
		showheaderbar,
		headerbarcolor,
		logouturl,
		uploadmaxsize,
		showstatistics,
		showcalendar,
		showkanban,
		showexcelexport,
		showwordexport,
		showgeopackageexport,
		singlepropertyedit,
		previewfullwidth,
		showmouseposition,
		quicksearchguids,
		quicksearchcolumns,
		quicksearchnames,
		showmapscale,
		layerdefaultdisplay,
		navigationtype,
		limitrelationshipstothemetree,
		limitlayerlisttolayertree,
		formwithtabs,
		tabformviews,
		mapintab,
		nestedforms,
		nestedformlevel,
		nestedformviews,
		nestedformviewlevels,
		synchronisemaps,
		mapsynchronisemode,
		maxextent,
		defaultcenter,
		minscale,
		maxscale,
		scales,
		unit,
		projection,
		hasthemetree,
		showqrcode,
		qrcodeviews,
		showinfra3d,
		urlinfra3d,
		clientidinfra3d,
		clientsecretinfra3d,
		div_binding
	];
}

class ParameterManager extends SvelteElement {
	constructor(options) {
		super();

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				init: 1,
				layertree: 2,
				themetree: 3,
				themetreetitel: 4,
				showbackrelations: 5,
				hidekanbanforactiontypes: 6,
				showmap: 7,
				showsearch: 8,
				showdashboard: 9,
				showlastopened: 10,
				showthemesearch: 11,
				themesopenedatstart: 12,
				showmenuonstartup: 13,
				entrypoint: 14,
				showheaderbar: 15,
				headerbarcolor: 16,
				logouturl: 17,
				uploadmaxsize: 18,
				showstatistics: 19,
				showcalendar: 20,
				showkanban: 21,
				showexcelexport: 22,
				showwordexport: 23,
				showgeopackageexport: 24,
				singlepropertyedit: 25,
				previewfullwidth: 26,
				showmouseposition: 27,
				quicksearchguids: 28,
				quicksearchcolumns: 29,
				quicksearchnames: 30,
				showmapscale: 31,
				layerdefaultdisplay: 32,
				navigationtype: 33,
				limitrelationshipstothemetree: 34,
				limitlayerlisttolayertree: 35,
				formwithtabs: 36,
				tabformviews: 37,
				mapintab: 38,
				nestedforms: 39,
				nestedformlevel: 40,
				nestedformviews: 41,
				nestedformviewlevels: 42,
				synchronisemaps: 43,
				mapsynchronisemode: 44,
				maxextent: 45,
				defaultcenter: 46,
				minscale: 47,
				maxscale: 48,
				scales: 49,
				unit: 50,
				projection: 51,
				hasthemetree: 52,
				showqrcode: 53,
				qrcodeviews: 54,
				showinfra3d: 55,
				urlinfra3d: 56,
				clientidinfra3d: 57,
				clientsecretinfra3d: 58
			},
			null,
			[-1, -1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return [
			"init",
			"layertree",
			"themetree",
			"themetreetitel",
			"showbackrelations",
			"hidekanbanforactiontypes",
			"showmap",
			"showsearch",
			"showdashboard",
			"showlastopened",
			"showthemesearch",
			"themesopenedatstart",
			"showmenuonstartup",
			"entrypoint",
			"showheaderbar",
			"headerbarcolor",
			"logouturl",
			"uploadmaxsize",
			"showstatistics",
			"showcalendar",
			"showkanban",
			"showexcelexport",
			"showwordexport",
			"showgeopackageexport",
			"singlepropertyedit",
			"previewfullwidth",
			"showmouseposition",
			"quicksearchguids",
			"quicksearchcolumns",
			"quicksearchnames",
			"showmapscale",
			"layerdefaultdisplay",
			"navigationtype",
			"limitrelationshipstothemetree",
			"limitlayerlisttolayertree",
			"formwithtabs",
			"tabformviews",
			"mapintab",
			"nestedforms",
			"nestedformlevel",
			"nestedformviews",
			"nestedformviewlevels",
			"synchronisemaps",
			"mapsynchronisemode",
			"maxextent",
			"defaultcenter",
			"minscale",
			"maxscale",
			"scales",
			"unit",
			"projection",
			"hasthemetree",
			"showqrcode",
			"qrcodeviews",
			"showinfra3d",
			"urlinfra3d",
			"clientidinfra3d",
			"clientsecretinfra3d"
		];
	}

	get init() {
		return this.$$.ctx[1];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get layertree() {
		return this.$$.ctx[2];
	}

	set layertree(layertree) {
		this.$$set({ layertree });
		flush();
	}

	get themetree() {
		return this.$$.ctx[3];
	}

	set themetree(themetree) {
		this.$$set({ themetree });
		flush();
	}

	get themetreetitel() {
		return this.$$.ctx[4];
	}

	set themetreetitel(themetreetitel) {
		this.$$set({ themetreetitel });
		flush();
	}

	get showbackrelations() {
		return this.$$.ctx[5];
	}

	set showbackrelations(showbackrelations) {
		this.$$set({ showbackrelations });
		flush();
	}

	get hidekanbanforactiontypes() {
		return this.$$.ctx[6];
	}

	set hidekanbanforactiontypes(hidekanbanforactiontypes) {
		this.$$set({ hidekanbanforactiontypes });
		flush();
	}

	get showmap() {
		return this.$$.ctx[7];
	}

	set showmap(showmap) {
		this.$$set({ showmap });
		flush();
	}

	get showsearch() {
		return this.$$.ctx[8];
	}

	set showsearch(showsearch) {
		this.$$set({ showsearch });
		flush();
	}

	get showdashboard() {
		return this.$$.ctx[9];
	}

	set showdashboard(showdashboard) {
		this.$$set({ showdashboard });
		flush();
	}

	get showlastopened() {
		return this.$$.ctx[10];
	}

	set showlastopened(showlastopened) {
		this.$$set({ showlastopened });
		flush();
	}

	get showthemesearch() {
		return this.$$.ctx[11];
	}

	set showthemesearch(showthemesearch) {
		this.$$set({ showthemesearch });
		flush();
	}

	get themesopenedatstart() {
		return this.$$.ctx[12];
	}

	set themesopenedatstart(themesopenedatstart) {
		this.$$set({ themesopenedatstart });
		flush();
	}

	get showmenuonstartup() {
		return this.$$.ctx[13];
	}

	set showmenuonstartup(showmenuonstartup) {
		this.$$set({ showmenuonstartup });
		flush();
	}

	get entrypoint() {
		return this.$$.ctx[14];
	}

	set entrypoint(entrypoint) {
		this.$$set({ entrypoint });
		flush();
	}

	get showheaderbar() {
		return this.$$.ctx[15];
	}

	set showheaderbar(showheaderbar) {
		this.$$set({ showheaderbar });
		flush();
	}

	get headerbarcolor() {
		return this.$$.ctx[16];
	}

	set headerbarcolor(headerbarcolor) {
		this.$$set({ headerbarcolor });
		flush();
	}

	get logouturl() {
		return this.$$.ctx[17];
	}

	set logouturl(logouturl) {
		this.$$set({ logouturl });
		flush();
	}

	get uploadmaxsize() {
		return this.$$.ctx[18];
	}

	set uploadmaxsize(uploadmaxsize) {
		this.$$set({ uploadmaxsize });
		flush();
	}

	get showstatistics() {
		return this.$$.ctx[19];
	}

	set showstatistics(showstatistics) {
		this.$$set({ showstatistics });
		flush();
	}

	get showcalendar() {
		return this.$$.ctx[20];
	}

	set showcalendar(showcalendar) {
		this.$$set({ showcalendar });
		flush();
	}

	get showkanban() {
		return this.$$.ctx[21];
	}

	set showkanban(showkanban) {
		this.$$set({ showkanban });
		flush();
	}

	get showexcelexport() {
		return this.$$.ctx[22];
	}

	set showexcelexport(showexcelexport) {
		this.$$set({ showexcelexport });
		flush();
	}

	get showwordexport() {
		return this.$$.ctx[23];
	}

	set showwordexport(showwordexport) {
		this.$$set({ showwordexport });
		flush();
	}

	get showgeopackageexport() {
		return this.$$.ctx[24];
	}

	set showgeopackageexport(showgeopackageexport) {
		this.$$set({ showgeopackageexport });
		flush();
	}

	get singlepropertyedit() {
		return this.$$.ctx[25];
	}

	set singlepropertyedit(singlepropertyedit) {
		this.$$set({ singlepropertyedit });
		flush();
	}

	get previewfullwidth() {
		return this.$$.ctx[26];
	}

	set previewfullwidth(previewfullwidth) {
		this.$$set({ previewfullwidth });
		flush();
	}

	get showmouseposition() {
		return this.$$.ctx[27];
	}

	set showmouseposition(showmouseposition) {
		this.$$set({ showmouseposition });
		flush();
	}

	get quicksearchguids() {
		return this.$$.ctx[28];
	}

	set quicksearchguids(quicksearchguids) {
		this.$$set({ quicksearchguids });
		flush();
	}

	get quicksearchcolumns() {
		return this.$$.ctx[29];
	}

	set quicksearchcolumns(quicksearchcolumns) {
		this.$$set({ quicksearchcolumns });
		flush();
	}

	get quicksearchnames() {
		return this.$$.ctx[30];
	}

	set quicksearchnames(quicksearchnames) {
		this.$$set({ quicksearchnames });
		flush();
	}

	get showmapscale() {
		return this.$$.ctx[31];
	}

	set showmapscale(showmapscale) {
		this.$$set({ showmapscale });
		flush();
	}

	get layerdefaultdisplay() {
		return this.$$.ctx[32];
	}

	set layerdefaultdisplay(layerdefaultdisplay) {
		this.$$set({ layerdefaultdisplay });
		flush();
	}

	get navigationtype() {
		return this.$$.ctx[33];
	}

	set navigationtype(navigationtype) {
		this.$$set({ navigationtype });
		flush();
	}

	get limitrelationshipstothemetree() {
		return this.$$.ctx[34];
	}

	set limitrelationshipstothemetree(limitrelationshipstothemetree) {
		this.$$set({ limitrelationshipstothemetree });
		flush();
	}

	get limitlayerlisttolayertree() {
		return this.$$.ctx[35];
	}

	set limitlayerlisttolayertree(limitlayerlisttolayertree) {
		this.$$set({ limitlayerlisttolayertree });
		flush();
	}

	get formwithtabs() {
		return this.$$.ctx[36];
	}

	set formwithtabs(formwithtabs) {
		this.$$set({ formwithtabs });
		flush();
	}

	get tabformviews() {
		return this.$$.ctx[37];
	}

	set tabformviews(tabformviews) {
		this.$$set({ tabformviews });
		flush();
	}

	get mapintab() {
		return this.$$.ctx[38];
	}

	set mapintab(mapintab) {
		this.$$set({ mapintab });
		flush();
	}

	get nestedforms() {
		return this.$$.ctx[39];
	}

	set nestedforms(nestedforms) {
		this.$$set({ nestedforms });
		flush();
	}

	get nestedformlevel() {
		return this.$$.ctx[40];
	}

	set nestedformlevel(nestedformlevel) {
		this.$$set({ nestedformlevel });
		flush();
	}

	get nestedformviews() {
		return this.$$.ctx[41];
	}

	set nestedformviews(nestedformviews) {
		this.$$set({ nestedformviews });
		flush();
	}

	get nestedformviewlevels() {
		return this.$$.ctx[42];
	}

	set nestedformviewlevels(nestedformviewlevels) {
		this.$$set({ nestedformviewlevels });
		flush();
	}

	get synchronisemaps() {
		return this.$$.ctx[43];
	}

	set synchronisemaps(synchronisemaps) {
		this.$$set({ synchronisemaps });
		flush();
	}

	get mapsynchronisemode() {
		return this.$$.ctx[44];
	}

	set mapsynchronisemode(mapsynchronisemode) {
		this.$$set({ mapsynchronisemode });
		flush();
	}

	get maxextent() {
		return this.$$.ctx[45];
	}

	set maxextent(maxextent) {
		this.$$set({ maxextent });
		flush();
	}

	get defaultcenter() {
		return this.$$.ctx[46];
	}

	set defaultcenter(defaultcenter) {
		this.$$set({ defaultcenter });
		flush();
	}

	get minscale() {
		return this.$$.ctx[47];
	}

	set minscale(minscale) {
		this.$$set({ minscale });
		flush();
	}

	get maxscale() {
		return this.$$.ctx[48];
	}

	set maxscale(maxscale) {
		this.$$set({ maxscale });
		flush();
	}

	get scales() {
		return this.$$.ctx[49];
	}

	set scales(scales) {
		this.$$set({ scales });
		flush();
	}

	get unit() {
		return this.$$.ctx[50];
	}

	set unit(unit) {
		this.$$set({ unit });
		flush();
	}

	get projection() {
		return this.$$.ctx[51];
	}

	set projection(projection) {
		this.$$set({ projection });
		flush();
	}

	get hasthemetree() {
		return this.$$.ctx[52];
	}

	set hasthemetree(hasthemetree) {
		this.$$set({ hasthemetree });
		flush();
	}

	get showqrcode() {
		return this.$$.ctx[53];
	}

	set showqrcode(showqrcode) {
		this.$$set({ showqrcode });
		flush();
	}

	get qrcodeviews() {
		return this.$$.ctx[54];
	}

	set qrcodeviews(qrcodeviews) {
		this.$$set({ qrcodeviews });
		flush();
	}

	get showinfra3d() {
		return this.$$.ctx[55];
	}

	set showinfra3d(showinfra3d) {
		this.$$set({ showinfra3d });
		flush();
	}

	get urlinfra3d() {
		return this.$$.ctx[56];
	}

	set urlinfra3d(urlinfra3d) {
		this.$$set({ urlinfra3d });
		flush();
	}

	get clientidinfra3d() {
		return this.$$.ctx[57];
	}

	set clientidinfra3d(clientidinfra3d) {
		this.$$set({ clientidinfra3d });
		flush();
	}

	get clientsecretinfra3d() {
		return this.$$.ctx[58];
	}

	set clientsecretinfra3d(clientsecretinfra3d) {
		this.$$set({ clientsecretinfra3d });
		flush();
	}
}

customElements.define("parameter-manager", ParameterManager);
export default ParameterManager;