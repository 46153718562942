/* src\components\Map\MapTools\FeatureSearchPoint\FeatureSearchPoint.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	globals,
	init,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	toggle_class,
	validate_slots
} from "../../../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../../../bcShim.js';
import Feature from '../../../../../web_modules/ol/Feature.js';
import Point from '../../../../../web_modules/ol/geom/Point.js';
import Polygon from '../../../../../web_modules/ol/geom/Polygon.js';
import Overlay from '../../../../../web_modules/ol/Overlay.js';
import { Fill, RegularShape, Stroke, Style } from '../../../../../web_modules/ol/style.js';

import {
	createVectorLayer,
	getMaxZIndex,
	searchDatabaseFeaturesByWKT,
	searchFeatureInfosByWKT
} from '../../map-utils.js';

import { v4 as uuidv4 } from "../../../../../web_modules/uuid.js";
import { wktHelper } from "../../../../utilities/WKTHelper.js";
import { onMount, tick } from "../../../../../web_modules/svelte.js";

import {
	attachNormalize,
	dispatchMountedEvent
} from "../../../../utilities/componentUtils.js";

import { handleErrorInAlert } from "../../../../utilities/alertNotificationUtils.js";
import { getComponentText } from "../../../../utilities/generalUtils.js";
import "../../../../../web_modules/ol/style/Circle.js";
const file = "src\\components\\Map\\MapTools\\FeatureSearchPoint\\FeatureSearchPoint.svelte";

// (2062:4) {#if ready}
function create_if_block(ctx) {
	let div;
	let i;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div = element_1("div");
			i = element_1("i");
			attr_dev(i, "class", "far fa-lg fa-dot-circle !w-10 !h-10 flex items-center justify-center text-white rounded");
			toggle_class(i, "hover:text-orange-500", /*maptool*/ ctx[0] !== 'FeatureSearch-Point');
			toggle_class(i, "bg-blue-500", /*maptool*/ ctx[0] === 'FeatureSearch-Point');
			toggle_class(i, "!text-red-600", /*maptool*/ ctx[0] === 'FeatureSearch-Point' && /*redIcon*/ ctx[3]);
			add_location(i, file, 2063, 12, 44507);
			attr_dev(div, "class", "flex relative !w-10 !h-10 cursor-pointer rounded ");
			add_location(div, file, 2062, 8, 44430);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, i);

			if (!mounted) {
				dispose = listen_dev(i, "click", /*click_handler*/ ctx[7], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*maptool*/ 1) {
				toggle_class(i, "hover:text-orange-500", /*maptool*/ ctx[0] !== 'FeatureSearch-Point');
			}

			if (dirty[0] & /*maptool*/ 1) {
				toggle_class(i, "bg-blue-500", /*maptool*/ ctx[0] === 'FeatureSearch-Point');
			}

			if (dirty[0] & /*maptool, redIcon*/ 9) {
				toggle_class(i, "!text-red-600", /*maptool*/ ctx[0] === 'FeatureSearch-Point' && /*redIcon*/ ctx[3]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2062:4) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[1] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 2060, 0, 44378);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[8](div);
		},
		p: function update(ctx, dirty) {
			if (/*ready*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[8](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('map-tool-feature-search-point', slots, []);
	let { maptool } = $$props;

	// Base variables
	//================================================================
	const generalText = getComponentText('general');

	const componentText = getComponentText('featureSearchPoint');
	let mounted = false;
	let ready = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let vectorLayer;
	let vectorSource;
	let drawInteraction;
	let mouseCerclePoint;
	let mousePointerDiv;
	let noFeatureFound = false;
	let searchGeometryFeature;
	let wmsFeaturesLayerNames = [];
	let wmsFeaturesHTML = [];
	let hasWMSFeatures = false;
	let featureItems = [];
	let featureNameColumns = [];
	let featureIdColumns = [];
	let concernedViewIdArray = [];
	let layersWithResuls = [];
	let wktFeatures = [];
	let redIcon = false;
	let map;
	let componentId = uuidv4();
	let hasBeenSetOnce = false;
	let setMapTool;
	let showMapSidebar;
	let resultComponent;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			component = element.getRootNode().host || element.parentNode; // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				mounted = true;
			});
		});

		return () => {
			
		};
	});

	const initComponent = (newMap, mapToolFunc, showSidebarFunc, resComp) => {
		console.log('init feature search point', newMap, mapToolFunc, showSidebarFunc, resComp);
		map = newMap;
		$$invalidate(4, setMapTool = mapToolFunc);
		showMapSidebar = showSidebarFunc;
		resultComponent = resComp;
		$$invalidate(1, ready = true);
	};

	// Component functions
	//================================================================
	const handleMapTool = tool => {
		if (maptool !== 'FeatureSearch-Point') {
			if (hasBeenSetOnce) {
				cleanup();

				if (maptool !== 'DBSearch-Square' && maptool !== 'DBSearch-Polygon' && maptool !== 'DBSearch-Circle' && maptool !== 'FeatureSearch-Point' && maptool !== 'GeoSearch' && maptool !== 'Map-Content') {
					showMapSidebar(false);
				}
			}
		}

		if (maptool === 'FeatureSearch-Point') {
			hasBeenSetOnce = true;
			showMapSidebar(true);
			resultComponent.clearFeatures();

			// Add VectorLayer on top
			if (!vectorLayer) {
				vectorLayer = createVectorLayer('DB_FEATURES_POINT_SEARCH' + componentId);
				vectorSource = vectorLayer.getSource();
				let zIndex = getMaxZIndex(map);
				vectorLayer.setZIndex(zIndex + 100);
				map.addLayer(vectorLayer);

				if (drawInteraction) {
					map.removeInteraction(drawInteraction);
				}

				mousePointerDiv = createMousePointerDiv();
				document.body.appendChild(mousePointerDiv);
				mouseCerclePoint = new Overlay({ element: mousePointerDiv });
				map.on('pointermove', setMousePointerCercle);
				map.on('click', searchFeaturesByPoint);
				map.addOverlay(mouseCerclePoint);
			}
		}
	};

	const setMousePointerCercle = evt => {
		mouseCerclePoint.setPosition([evt.coordinate[0], evt.coordinate[1]]);
		return false;
	};

	const cleanUpPreviousSearch = () => {
		if (vectorSource) {
			vectorSource.clear();
		}

		noFeatureFound = false;
		searchGeometryFeature = undefined;
		wmsFeaturesLayerNames = [];
		wmsFeaturesHTML = [];
		hasWMSFeatures = false;
		featureItems = [];
		featureNameColumns = [];
		featureIdColumns = [];
		concernedViewIdArray = [];
		layersWithResuls = [];

		// objectRendererProperties = []
		// featureIsExpanded = []
		wktFeatures = [];
	};

	const searchFeaturesByPoint = async evt => {
		try {
			cleanUpPreviousSearch();

			// Show "Searching overlay"
			noFeatureFound = false;

			resultComponent.toggleSearchIcon(true);
			resultComponent.clearFeatures();

			// Get coordinates and pixel from click
			const coordinates = evt.coordinate;

			const pixel = map.getPixelFromCoordinate(coordinates);
			let mapSearchRoute = bc.utils.getRoute('mapSearch');
			console.log('feature search point mapSearchRoute', mapSearchRoute);

			if (mapSearchRoute) {
				let coordinateString = coordinates.join(',');
				coordinateString = coordinateString.slice(0, -1);
				let mapComponent = map.get('HTML');
				let activeLayers = mapComponent.getActiveLayers();
				let layerNames = activeLayers.map(l => l.name).join(',');

				let link = mapSearchRoute.create({
					search: 'point',
					layers: layerNames,
					coordinates: coordinateString,
					zoom: map.getView().getZoom() + '',
					distance: '2.5'
				});

				console.log('link', link);
				bc.utils.navigateTo(link);
			} else {
				// Set point on map
				const point = new Point(coordinates);

				const searchPointFeature = new Feature({ geometry: point });
				const fill = new Fill({ color: '#ff71003d' });
				const stroke = new Stroke({ color: 'orange', width: 2 });

				searchPointFeature.setStyle(new Style({
						image: new RegularShape({
								fill,
								stroke,
								points: 4,
								radius: 5,
								angle: Math.PI / 4
							})
					}));

				vectorSource.addFeature(searchPointFeature);

				// Calculate pixels in a 10 x 10  square
				const pixelLeftTop = [pixel[0] - 10, pixel[1] + 10];

				const pixelLeftBottom = [pixel[0] - 10, pixel[1] - 10];
				const pixelRightTop = [pixel[0] + 10, pixel[1] + 10];
				const pixelRightBottom = [pixel[0] + 10, pixel[1] - 10];

				// Get coordinates from pixels
				const coordinatesLeftTop = map.getCoordinateFromPixel(pixelLeftTop);

				const coordinatesLeftBottom = map.getCoordinateFromPixel(pixelLeftBottom);
				const coordiantesRightTop = map.getCoordinateFromPixel(pixelRightTop);
				const coordiantesRightBottom = map.getCoordinateFromPixel(pixelRightBottom);

				// Create Feature
				searchGeometryFeature = new Feature({
						geometry: new Polygon([
								[
									coordinatesLeftTop,
									coordiantesRightTop,
									coordiantesRightBottom,
									coordinatesLeftBottom,
									coordinatesLeftTop
								]
							])
					});

				vectorSource.addFeature(searchGeometryFeature);

				// Create WKT from searchGeometryFeature
				let wkt = wktHelper.writeFeature(searchGeometryFeature);

				wkt = wkt.replace(/ /g, '+');

				try {
					const dbSearchResult = await searchDatabaseFeaturesByWKT(map, wkt, bc.parameterService.get('PROJECTION'), true);
					const featureInfoResult = await searchFeatureInfosByWKT(map, coordinates);

					// Hide "Searching overlay"
					resultComponent.toggleSearchIcon(false);

					console.log('search launched!!', dbSearchResult, featureInfoResult);

					if (dbSearchResult.layersWithResults.length > 0 || featureInfoResult.featureInfoLayerNames.length > 0) {
						resultComponent.showResults({
							infoSearch: featureInfoResult,
							dbSearch: dbSearchResult,
							wkt,
							wktType: 'square'
						});
					}
				} catch(e) {
					console.error('Error white searching features by point/database features');
					console.log('error', e);
				}
			}

			return false;
		} catch(error) {
			handleErrorInAlert(error, componentText.unableToSearch);
			console.error(componentText.unableToSearch, error);
		}
	};

	const createMousePointerDiv = () => {
		let div = document.createElement('div');
		div.style.pointerEvents = 'none';
		div.style.position = 'absolute';
		div.style.width = '8px';
		div.style.height = '8px';
		div.style.borderRadius = '50%';
		div.style.border = 'solid thin red';
		div.style.backgroundColor = 'red';
		div.style.marginLeft = '-4px';
		div.style.marginRight = '-4px';
		div.style.marginTop = '-4px';
		return div;
	};

	const cleanup = () => {
		cleanUpPreviousSearch();
		map.removeEventListener('pointermove', setMousePointerCercle);
		map.removeEventListener('click', searchFeaturesByPoint);

		if (mouseCerclePoint) {
			map.removeOverlay(mouseCerclePoint);
		}

		mousePointerDiv = undefined;

		if (vectorSource) {
			vectorSource.clear();
			vectorSource = undefined;
		}

		if (vectorLayer) {
			map.removeLayer(vectorLayer);
			vectorLayer = undefined;
		}
	};

	const setSearch = async (type, coordinates, layers, zoom, distance) => {
		let mapComponent = map.get('HTML');
		let layerSwitcher = mapComponent.getLayerSwitcher();

		if (layerSwitcher) {
			let layerNames = layers.split(',');

			for (let i = 0; i < layerNames.length; i++) {
				layerSwitcher.showMapLayerByName(layerNames[i]);
			}
		}

		let pointArray = coordinates.split(',');
		let pointCoordinates = [Number.parseFloat(pointArray[0]), Number.parseFloat(pointArray[1])];
		const point = new Point(pointCoordinates);
		const searchPointFeature = new Feature({ geometry: point });
		let searchDistance = 2.5; // 2.5 m radius/box as default search distance on database point

		if (distance && !Number.isNaN(Number.parseFloat(distance))) {
			searchDistance = Number.parseFloat(distance);

			if (searchDistance <= 0) {
				searchDistance = 2.5;
			}
		}

		map.getView().setCenter(pointCoordinates);
		map.getView().setZoom(Number.parseFloat(zoom));
		const fill = new Fill({ color: '#ff71003d' });
		const stroke = new Stroke({ color: 'orange', width: 2 });

		searchPointFeature.setStyle(new Style({
				image: new RegularShape({
						fill,
						stroke,
						points: 4,
						radius: 5,
						angle: Math.PI / 4
					})
			}));

		vectorSource.addFeature(searchPointFeature);

		// Calculate pixels in a searchDistance x searchDistance  square
		const pixelLeftTop = [pointCoordinates[0] - searchDistance, pointCoordinates[1] + searchDistance];

		const pixelLeftBottom = [pointCoordinates[0] - searchDistance, pointCoordinates[1] - searchDistance];
		const pixelRightTop = [pointCoordinates[0] + searchDistance, pointCoordinates[1] + searchDistance];
		const pixelRightBottom = [pointCoordinates[0] + searchDistance, pointCoordinates[1] - searchDistance];

		// Create Feature
		searchGeometryFeature = new Feature({
				geometry: new Polygon([
						[
							pixelLeftTop,
							pixelRightTop,
							pixelRightBottom,
							pixelLeftBottom,
							pixelLeftTop
						]
					])
			});

		searchGeometryFeature.setStyle(new Style({
				stroke: new Stroke({ color: 'red', width: 2 })
			}));

		// Create WKT from searchGeometryFeature
		let wkt = wktHelper.writeFeature(searchGeometryFeature);

		wkt = wkt.replace(/ /g, '+');
		const dbSearchResult = await searchDatabaseFeaturesByWKT(map, wkt, bc.parameterService.get('PROJECTION'), true);
		const featureInfoResult = await searchFeatureInfosByWKT(map, pointCoordinates);

		// Hide "Searching overlay"
		resultComponent.toggleSearchIcon(false);

		if (dbSearchResult.layersWithResults.length > 0 || featureInfoResult.featureInfoLayerNames.length > 0) {
			resultComponent.showResults({
				infoSearch: featureInfoResult,
				dbSearch: dbSearchResult,
				wkt,
				wktType: 'square'
			});
		}
	};

	$$self.$$.on_mount.push(function () {
		if (maptool === undefined && !('maptool' in $$props || $$self.$$.bound[$$self.$$.props['maptool']])) {
			console_1.warn("<map-tool-feature-search-point> was created without expected prop 'maptool'");
		}
	});

	const writable_props = ['maptool'];

	

	const click_handler = e => {
		if (maptool === 'FeatureSearch-Point' && redIcon) {
			setMapTool('FeatureSearch-Point');
		} else {
			$$invalidate(3, redIcon = false);

			maptool !== 'FeatureSearch-Point'
			? setMapTool('FeatureSearch-Point')
			: setMapTool('');
		}
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(2, element);
		});
	}

	$$self.$$set = $$props => {
		if ('maptool' in $$props) $$invalidate(0, maptool = $$props.maptool);
	};

	$$self.$capture_state = () => ({
		bc,
		Feature,
		Point,
		Polygon,
		Overlay,
		Fill,
		RegularShape,
		Stroke,
		Style,
		createVectorLayer,
		getMaxZIndex,
		searchDatabaseFeaturesByWKT,
		searchFeatureInfosByWKT,
		uuidv4,
		wktHelper,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		handleErrorInAlert,
		getComponentText,
		maptool,
		generalText,
		componentText,
		mounted,
		ready,
		component,
		element,
		vectorLayer,
		vectorSource,
		drawInteraction,
		mouseCerclePoint,
		mousePointerDiv,
		noFeatureFound,
		searchGeometryFeature,
		wmsFeaturesLayerNames,
		wmsFeaturesHTML,
		hasWMSFeatures,
		featureItems,
		featureNameColumns,
		featureIdColumns,
		concernedViewIdArray,
		layersWithResuls,
		wktFeatures,
		redIcon,
		map,
		componentId,
		hasBeenSetOnce,
		setMapTool,
		showMapSidebar,
		resultComponent,
		initComponent,
		handleMapTool,
		setMousePointerCercle,
		cleanUpPreviousSearch,
		searchFeaturesByPoint,
		createMousePointerDiv,
		cleanup,
		setSearch
	});

	$$self.$inject_state = $$props => {
		if ('maptool' in $$props) $$invalidate(0, maptool = $$props.maptool);
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('ready' in $$props) $$invalidate(1, ready = $$props.ready);
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) $$invalidate(2, element = $$props.element);
		if ('vectorLayer' in $$props) vectorLayer = $$props.vectorLayer;
		if ('vectorSource' in $$props) vectorSource = $$props.vectorSource;
		if ('drawInteraction' in $$props) drawInteraction = $$props.drawInteraction;
		if ('mouseCerclePoint' in $$props) mouseCerclePoint = $$props.mouseCerclePoint;
		if ('mousePointerDiv' in $$props) mousePointerDiv = $$props.mousePointerDiv;
		if ('noFeatureFound' in $$props) noFeatureFound = $$props.noFeatureFound;
		if ('searchGeometryFeature' in $$props) searchGeometryFeature = $$props.searchGeometryFeature;
		if ('wmsFeaturesLayerNames' in $$props) wmsFeaturesLayerNames = $$props.wmsFeaturesLayerNames;
		if ('wmsFeaturesHTML' in $$props) wmsFeaturesHTML = $$props.wmsFeaturesHTML;
		if ('hasWMSFeatures' in $$props) hasWMSFeatures = $$props.hasWMSFeatures;
		if ('featureItems' in $$props) featureItems = $$props.featureItems;
		if ('featureNameColumns' in $$props) featureNameColumns = $$props.featureNameColumns;
		if ('featureIdColumns' in $$props) featureIdColumns = $$props.featureIdColumns;
		if ('concernedViewIdArray' in $$props) concernedViewIdArray = $$props.concernedViewIdArray;
		if ('layersWithResuls' in $$props) layersWithResuls = $$props.layersWithResuls;
		if ('wktFeatures' in $$props) wktFeatures = $$props.wktFeatures;
		if ('redIcon' in $$props) $$invalidate(3, redIcon = $$props.redIcon);
		if ('map' in $$props) map = $$props.map;
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('hasBeenSetOnce' in $$props) hasBeenSetOnce = $$props.hasBeenSetOnce;
		if ('setMapTool' in $$props) $$invalidate(4, setMapTool = $$props.setMapTool);
		if ('showMapSidebar' in $$props) showMapSidebar = $$props.showMapSidebar;
		if ('resultComponent' in $$props) resultComponent = $$props.resultComponent;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*maptool*/ 1) {
			$: handleMapTool(maptool);
		}
	};

	return [
		maptool,
		ready,
		element,
		redIcon,
		setMapTool,
		initComponent,
		setSearch,
		click_handler,
		div_binding
	];
}

class FeatureSearchPoint extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `.fa-lg{font-size:1.25em;line-height:0.05em;vertical-align:-0.075em}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.fa-dot-circle::before{content:""}.far{font-weight:400 !important}.far{font-family:"Font Awesome 6 Pro" !important;font-style:normal !important}100%{left:0;right:0}.bg-blue-500{--tw-bg-opacity:1;background-color:rgba(59, 130, 246, var(--tw-bg-opacity))}.rounded{border-radius:0.25rem}.cursor-pointer{cursor:pointer}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.\\!h-10{height:2.5rem !important}.relative{position:relative}.text-white{--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))}.hover\\:text-orange-500:hover{--tw-text-opacity:1;color:rgba(249, 115, 22, var(--tw-text-opacity))}.\\!text-red-600{--tw-text-opacity:1 !important;color:rgba(220, 38, 38, var(--tw-text-opacity)) !important}.\\!w-10{width:2.5rem !important}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				maptool: 0,
				initComponent: 5,
				setSearch: 6
			},
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["maptool", "initComponent", "setSearch"];
	}

	get maptool() {
		return this.$$.ctx[0];
	}

	set maptool(maptool) {
		this.$$set({ maptool });
		flush();
	}

	get initComponent() {
		return this.$$.ctx[5];
	}

	set initComponent(value) {
		throw new Error("<map-tool-feature-search-point>: Cannot set read-only property 'initComponent'");
	}

	get setSearch() {
		return this.$$.ctx[6];
	}

	set setSearch(value) {
		throw new Error("<map-tool-feature-search-point>: Cannot set read-only property 'setSearch'");
	}
}

customElements.define("map-tool-feature-search-point", FeatureSearchPoint);
export default FeatureSearchPoint;