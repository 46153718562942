/* src\components\Tabs\TabPanel\TabPanel.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	empty,
	flush,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	toggle_class,
	validate_slots,
	validate_store
} from "../../../../web_modules/svelte/internal.js";

import bc from '../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../web_modules/svelte.js';

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute
} from "../../../utilities/componentUtils.js";

import { applicationReady } from "../../../utilities/store.js";
import { getComponentText } from "../../../utilities/generalUtils.js";
const file = "src\\components\\Tabs\\TabPanel\\TabPanel.svelte";

// (2085:4) {:else}
function create_else_block(ctx) {
	let loading_spinner;
	let loading_spinner_text_value;

	const block = {
		c: function create() {
			loading_spinner = element_1("loading-spinner");
			set_custom_element_data(loading_spinner, "text", loading_spinner_text_value = /*generalText*/ ctx[4].loading);
			add_location(loading_spinner, file, 2085, 8, 39577);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2085:4) {:else}",
		ctx
	});

	return block;
}

// (2081:4) {#if ready}
function create_if_block(ctx) {
	let if_block_anchor;
	let if_block = /*currenttab*/ ctx[3] === /*tabid*/ ctx[0] && create_if_block_1(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*currenttab*/ ctx[3] === /*tabid*/ ctx[0]) {
				if (if_block) {
					
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2081:4) {#if ready}",
		ctx
	});

	return block;
}

// (2082:8) {#if currenttab === tabid}
function create_if_block_1(ctx) {
	let slot;

	const block = {
		c: function create() {
			slot = element_1("slot");
			add_location(slot, file, 2082, 12, 39526);
		},
		m: function mount(target, anchor) {
			insert_dev(target, slot, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(slot);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2082:8) {#if currenttab === tabid}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*ready*/ ctx[1]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if_block.c();
			this.c = noop;
			attr_dev(div, "class", "w-full h-full overflow-y-auto pb-2");
			toggle_class(div, "hidden", /*currenttab*/ ctx[3] !== /*tabid*/ ctx[0]);
			add_location(div, file, 2078, 0, 39353);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if_block.m(div, null);
			/*div_binding*/ ctx[12](div);
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}

			if (dirty & /*currenttab, tabid*/ 9) {
				toggle_class(div, "hidden", /*currenttab*/ ctx[3] !== /*tabid*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
			/*div_binding*/ ctx[12](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(11, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('tab-panel', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { tabgroupid = '' } = $$props;
	let { tabid = '' } = $$props;

	// Base variables
	//================================================================
	const generalText = getComponentText('general');

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	let ready = false;
	let mounted = false;
	let canReload = true;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let currenttab = '';

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(10, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(9, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = groupid => {
		$$invalidate(8, firstInitialized = true);

		if (groupid !== '') {
			bc.messageService.subscribe('SET_TAB', subscribeGuid, args => {
				const { tab, groupId } = args;

				if (groupId === tabgroupid && tab) {
					$$invalidate(3, currenttab = tab);
					handleVisible();
				}
			});

			$$invalidate(3, currenttab = bc.parameterService.get('CURRENT_TAB_' + tabgroupid));
			bc.parameterService.subscribe('CURRENT_TAB_' + tabgroupid, subscribeGuid, setCurrentTab);
			handleVisible();
			$$invalidate(1, ready = true);
		}
	};

	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				tabgroupid
			);
		}
	};

	// Component functions
	//================================================================
	const setCurrentTab = tab => {
		$$invalidate(3, currenttab = tab);
		handleVisible();
	};

	const handleVisible = () => {
		if (currenttab !== tabid) {
			$$invalidate(5, show = 'false');
		} else {
			$$invalidate(5, show = 'true');
		}
	};

	const writable_props = ['show', 'init', 'tabgroupid', 'tabid'];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(2, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(5, show = $$props.show);
		if ('init' in $$props) $$invalidate(6, init = $$props.init);
		if ('tabgroupid' in $$props) $$invalidate(7, tabgroupid = $$props.tabgroupid);
		if ('tabid' in $$props) $$invalidate(0, tabid = $$props.tabid);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		applicationReady,
		getComponentText,
		show,
		init,
		tabgroupid,
		tabid,
		generalText,
		firstInitialized,
		subscribeGuid,
		ready,
		mounted,
		canReload,
		component,
		element,
		currenttab,
		initComponent,
		applyProps,
		setCurrentTab,
		handleVisible,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(5, show = $$props.show);
		if ('init' in $$props) $$invalidate(6, init = $$props.init);
		if ('tabgroupid' in $$props) $$invalidate(7, tabgroupid = $$props.tabgroupid);
		if ('tabid' in $$props) $$invalidate(0, tabid = $$props.tabid);
		if ('firstInitialized' in $$props) $$invalidate(8, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(1, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(9, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('component' in $$props) $$invalidate(10, component = $$props.component);
		if ('element' in $$props) $$invalidate(2, element = $$props.element);
		if ('currenttab' in $$props) $$invalidate(3, currenttab = $$props.currenttab);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 1056) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*init, firstInitialized, mounted, $applicationReady*/ 2880) {
			/**
 * Handle the component init attribute, for the case that the component has not been initialized yet.
 */
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty & /*tabgroupid*/ 128) {
			/**
 * Handles any component attribute changes after the component is initialized.
 */
			$: (tabgroupid, applyProps(true));
		}
	};

	return [
		tabid,
		ready,
		element,
		currenttab,
		generalText,
		show,
		init,
		tabgroupid,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		div_binding
	];
}

class TabPanel extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.hidden{display:none}.h-full{height:100%}.overflow-y-auto{overflow-y:auto}.pb-2{padding-bottom:0.5rem}.w-full{width:100%}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 5,
				init: 6,
				tabgroupid: 7,
				tabid: 0
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "tabgroupid", "tabid"];
	}

	get show() {
		return this.$$.ctx[5];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[6];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get tabgroupid() {
		return this.$$.ctx[7];
	}

	set tabgroupid(tabgroupid) {
		this.$$set({ tabgroupid });
		flush();
	}

	get tabid() {
		return this.$$.ctx[0];
	}

	set tabid(tabid) {
		this.$$set({ tabid });
		flush();
	}
}

customElements.define("tab-panel", TabPanel);
export default TabPanel;