/* src\components\Backend\ReloadViewDescription\ReloadViewDescription.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	empty,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	validate_slots,
	validate_store
} from "../../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../web_modules/svelte.js';
import { requestHeaders } from "../../../services/ajaxService.js";

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute
} from "../../../utilities/componentUtils.js";

import { errorToast, success } from "../../../utilities/alertNotificationUtils.js";
import { applicationReady } from "../../../utilities/store.js";
import { getComponentText } from "../../../utilities/generalUtils.js";
const file = "src\\components\\Backend\\ReloadViewDescription\\ReloadViewDescription.svelte";

// (2265:4) {:else}
function create_else_block_1(ctx) {
	let loading_spinner;

	const block = {
		c: function create() {
			loading_spinner = element_1("loading-spinner");
			set_custom_element_data(loading_spinner, "text", "Lade...");
			add_location(loading_spinner, file, 2265, 8, 43184);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block_1.name,
		type: "else",
		source: "(2265:4) {:else}",
		ctx
	});

	return block;
}

// (2258:4) {#if ready}
function create_if_block(ctx) {
	let if_block_anchor;

	function select_block_type_1(ctx, dirty) {
		if (/*isLoading*/ ctx[2]) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type_1(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type_1(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d: function destroy(detaching) {
			if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2258:4) {#if ready}",
		ctx
	});

	return block;
}

// (2261:8) {:else}
function create_else_block(ctx) {
	let bc_button;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			bc_button = element_1("bc-button");
			set_custom_element_data(bc_button, "text", "Metdaten neu generieren");
			add_location(bc_button, file, 2261, 12, 43064);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button, anchor);

			if (!mounted) {
				dispose = listen_dev(bc_button, "click", /*reloadMetadata*/ ctx[4], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2261:8) {:else}",
		ctx
	});

	return block;
}

// (2259:8) {#if isLoading}
function create_if_block_1(ctx) {
	let loading_spinner;
	let loading_spinner_text_value;

	const block = {
		c: function create() {
			loading_spinner = element_1("loading-spinner");
			set_custom_element_data(loading_spinner, "text", loading_spinner_text_value = "Regeneriere Metadaten der Datensicht:" + /*dataviewguid*/ ctx[0]);
			add_location(loading_spinner, file, 2259, 12, 42953);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*dataviewguid*/ 1 && loading_spinner_text_value !== (loading_spinner_text_value = "Regeneriere Metadaten der Datensicht:" + /*dataviewguid*/ ctx[0])) {
				set_custom_element_data(loading_spinner, "text", loading_spinner_text_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2259:8) {#if isLoading}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let span;

	function select_block_type(ctx, dirty) {
		if (/*ready*/ ctx[1]) return create_if_block;
		return create_else_block_1;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			span = element_1("span");
			if_block.c();
			this.c = noop;
			add_location(span, file, 2256, 0, 42871);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, span, anchor);
			if_block.m(span, null);
			/*span_binding*/ ctx[11](span);
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(span, null);
				}
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(span);
			if_block.d();
			/*span_binding*/ ctx[11](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(10, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('reload-view-description', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { dataviewguid = '' } = $$props;

	// Base Variables
	//==================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let isLoading = false;
	let mounted = false;
	let canReload = true;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this

	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(9, component = element.getRootNode().host || element.parentNode); // get the component/host

			// typed: (element.getRootNode() as ShadowRoot).host as HTMLElement
			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(8, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = viewId => {
		$$invalidate(7, firstInitialized = true);

		if (dataviewguid !== '') {
			$$invalidate(1, ready = true);
		}
	};

	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				dataviewguid
			);
		}
	};

	const reloadMetadata = async e => {
		try {
			$$invalidate(2, isLoading = true);
			let mainCallURL = bc.parameterService.appParameters.applicationBaseUrl + 'BcOdataApp/WebApi/ApplicationOdata/ReloadViewDescription/' + dataviewguid + '/' + bc.parameterService.appParameters.languageGuid;
			const data = await bc.ajaxService.fetchRaw(requestHeaders.jsonGet, mainCallURL);

			if (data.status === 200) {
				success('Metadata regeneriert!');
			} else {
				errorToast('Fehler beim Generieren der Metadata.');
				console.log('Fehler beim Generieren der Metadata', data);
			}

			$$invalidate(2, isLoading = false);
		} catch(error) {
			console.log(error);
			alert(error);
		}
	};

	const writable_props = ['show', 'init', 'dataviewguid'];

	

	function span_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(3, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(5, show = $$props.show);
		if ('init' in $$props) $$invalidate(6, init = $$props.init);
		if ('dataviewguid' in $$props) $$invalidate(0, dataviewguid = $$props.dataviewguid);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		requestHeaders,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		errorToast,
		success,
		applicationReady,
		getComponentText,
		show,
		init,
		dataviewguid,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		isLoading,
		mounted,
		canReload,
		component,
		element,
		initComponent,
		applyProps,
		reloadMetadata,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(5, show = $$props.show);
		if ('init' in $$props) $$invalidate(6, init = $$props.init);
		if ('dataviewguid' in $$props) $$invalidate(0, dataviewguid = $$props.dataviewguid);
		if ('firstInitialized' in $$props) $$invalidate(7, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(1, ready = $$props.ready);
		if ('isLoading' in $$props) $$invalidate(2, isLoading = $$props.isLoading);
		if ('mounted' in $$props) $$invalidate(8, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('component' in $$props) $$invalidate(9, component = $$props.component);
		if ('element' in $$props) $$invalidate(3, element = $$props.element);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 544) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*init, firstInitialized, mounted, $applicationReady*/ 1472) {
			/**
 * Handle the component init attribute, for the case that the component has not been initialized yet.
 */
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty & /*dataviewguid*/ 1) {
			/**
 * Handles any component attribute changes after the component is initialized.
 */
			$: (dataviewguid, applyProps(true));
		}
	};

	return [
		dataviewguid,
		ready,
		isLoading,
		element,
		reloadMetadata,
		show,
		init,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		span_binding
	];
}

class ReloadViewDescription extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `*,::before,::after{-webkit-box-sizing:border-box;box-sizing:border-box;border-width:0;border-style:solid;border-color:#e5e7eb}*{--tw-ring-inset:var(--tw-empty,/*!*/ /*!*/);--tw-ring-offset-width:0px;--tw-ring-offset-color:#fff;--tw-ring-color:rgba(59, 130, 246, 0.5);--tw-ring-offset-shadow:0 0 #0000;--tw-ring-shadow:0 0 #0000;--tw-shadow:0 0 #0000}:root{-moz-tab-size:4;-o-tab-size:4;tab-size:4}:-moz-focusring{outline:1px dotted ButtonText}:-moz-ui-invalid{box-shadow:none}::moz-focus-inner{border-style:none;padding:0}::-webkit-inner-spin-button,::-webkit-outer-spin-button{height:auto}::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 5, init: 6, dataviewguid: 0 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "dataviewguid"];
	}

	get show() {
		return this.$$.ctx[5];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[6];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get dataviewguid() {
		return this.$$.ctx[0];
	}

	set dataviewguid(dataviewguid) {
		this.$$set({ dataviewguid });
		flush();
	}
}

customElements.define("reload-view-description", ReloadViewDescription);
export default ReloadViewDescription;