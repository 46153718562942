/* src\components\Map\MapTools\ZoomOut\ZoomOut.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	validate_slots
} from "../../../../../web_modules/svelte/internal.js";

import '../../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../../web_modules/uuid.js';
import { onMount, tick } from "../../../../../web_modules/svelte.js";

import {
	attachNormalize,
	dispatchMountedEvent
} from "../../../../utilities/componentUtils.js";

import { getComponentText } from "../../../../utilities/generalUtils.js";
const file = "src\\components\\Map\\MapTools\\ZoomOut\\ZoomOut.svelte";

// (1775:4) {#if ready}
function create_if_block(ctx) {
	let div;
	let i;
	let div_title_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div = element_1("div");
			i = element_1("i");
			attr_dev(i, "class", "far fa-search-minus fa-lg text-white !h-10 !w-10 hover:text-orange-500 flex items-center justify-center rounded");
			add_location(i, file, 1781, 12, 32526);
			attr_dev(div, "class", "relative !h-10 !w-10 cursor-pointer");
			attr_dev(div, "title", div_title_value = /*componentText*/ ctx[2].zoomOut);
			add_location(div, file, 1775, 8, 32323);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, i);

			if (!mounted) {
				dispose = listen_dev(div, "click", /*click_handler*/ ctx[5], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(1775:4) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[0] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 1773, 0, 32271);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[6](div);
		},
		p: function update(ctx, [dirty]) {
			if (/*ready*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[6](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('map-tool-zoom-out', slots, []);
	const generalText = getComponentText('general');
	const componentText = getComponentText('zoomOut');
	let mounted = false;
	let ready = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let map;
	let componentId = uuidv4();

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			component = element.getRootNode().host || element.parentNode; // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				mounted = true;
			});
		});

		return () => {
			
		};
	});

	const initComponent = newMap => {
		map = newMap;
		$$invalidate(0, ready = true);
	};

	// Component functions
	//================================================================
	const zoom = () => {
		map.getView().animate({
			zoom: map.getView().getZoom() - 1,
			duration: 250
		});
	};

	const cleanup = () => {
		
	};

	const writable_props = [];

	

	const click_handler = e => {
		zoom();
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$capture_state = () => ({
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		getComponentText,
		generalText,
		componentText,
		mounted,
		ready,
		component,
		element,
		map,
		componentId,
		initComponent,
		zoom,
		cleanup
	});

	$$self.$inject_state = $$props => {
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
		if ('map' in $$props) map = $$props.map;
		if ('componentId' in $$props) componentId = $$props.componentId;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [ready, element, componentText, zoom, initComponent, click_handler, div_binding];
}

class ZoomOut extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `.fa-lg{font-size:1.25em;line-height:0.05em;vertical-align:-0.075em}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.fa-search-minus::before{content:""}.far{font-weight:400 !important}.far{font-family:"Font Awesome 6 Pro" !important;font-style:normal !important}100%{left:0;right:0}.rounded{border-radius:0.25rem}.cursor-pointer{cursor:pointer}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.\\!h-10{height:2.5rem !important}.relative{position:relative}.text-white{--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))}.hover\\:text-orange-500:hover{--tw-text-opacity:1;color:rgba(249, 115, 22, var(--tw-text-opacity))}.\\!w-10{width:2.5rem !important}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ initComponent: 4 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["initComponent"];
	}

	get initComponent() {
		return this.$$.ctx[4];
	}

	set initComponent(value) {
		throw new Error("<map-tool-zoom-out>: Cannot set read-only property 'initComponent'");
	}
}

customElements.define("map-tool-zoom-out", ZoomOut);
export default ZoomOut;