/* src\components\Spinner\Spinner.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	attr_dev,
	attribute_to_object,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	toggle_class,
	validate_slots
} from "../../../web_modules/svelte/internal.js";

import "../../utilities/iconUtils.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\Spinner\\Spinner.svelte";

function create_fragment(ctx) {
	let span;

	const block = {
		c: function create() {
			span = element_1("span");
			this.c = noop;
			attr_dev(span, "class", "animate-spin");
			toggle_class(span, "loader", /*size*/ ctx[0] === 'big');
			toggle_class(span, "loader1", /*size*/ ctx[0] === 'small');
			add_location(span, file, 94, 0, 2160);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, span, anchor);
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*size*/ 1) {
				toggle_class(span, "loader", /*size*/ ctx[0] === 'big');
			}

			if (dirty & /*size*/ 1) {
				toggle_class(span, "loader1", /*size*/ ctx[0] === 'small');
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(span);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('bc-spinner', slots, []);
	let { size = 'small' } = $$props;

	// Base variables
	//================================================================
	const generalText = getComponentText('general');

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this    
	const writable_props = ['size'];

	

	$$self.$$set = $$props => {
		if ('size' in $$props) $$invalidate(0, size = $$props.size);
	};

	$$self.$capture_state = () => ({
		getComponentText,
		size,
		generalText,
		component,
		element
	});

	$$self.$inject_state = $$props => {
		if ('size' in $$props) $$invalidate(0, size = $$props.size);
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) element = $$props.element;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [size];
}

class Spinner extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `.loader{width:2rem;height:2rem;border-radius:50%;display:inline-block;border-top:3px solid #484848;border-right:3px solid transparent;box-sizing:border-box;animation:rotation 1s linear infinite}.loader1{width:1rem;height:1rem;border-radius:50%;display:inline-block;border-top:3px solid #5a5a5a;border-right:3px solid transparent;box-sizing:border-box;animation:rotation 1s linear infinite}@keyframes spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@-webkit-keyframes spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}.animate-spin{-webkit-animation:spin 1s linear infinite;animation:spin 1s linear infinite}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ size: 0 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["size"];
	}

	get size() {
		return this.$$.ctx[0];
	}

	set size(size) {
		this.$$set({ size });
		flush();
	}
}

customElements.define("bc-spinner", Spinner);
export default Spinner;