/* src\components\Dashboard\DashboardTabCreator\DashboardFavoriteCreator.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	empty,
	flush,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	space,
	validate_slots,
	validate_store
} from "../../../../web_modules/svelte/internal.js";

import bc from '../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../web_modules/svelte.js';
import { constants } from '../../../constants.js';

import {
	allUserSettingManager,
	handleDataviewSearchSettings,
	userSettingManager
} from "../../../utilities/userSettingsManager.js";

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute
} from "../../../utilities/componentUtils.js";

import { notify } from "../../../utilities/alertNotificationUtils.js";
import { applicationReady } from "../../../utilities/store.js";
import { getComponentText } from "../../../utilities/generalUtils.js";
const file = "src\\components\\Dashboard\\DashboardTabCreator\\DashboardFavoriteCreator.svelte";

// (2226:4) {#if ready}
function create_if_block(ctx) {
	let div0;
	let i;
	let t0;
	let h3;
	let t2;
	let p;
	let t4;
	let div1;
	let label0;
	let t6;
	let input;
	let t7;
	let div2;
	let label1;
	let t9;
	let select;
	let option;
	let if_block0_anchor;
	let if_block1_anchor;
	let t11;
	let div3;
	let bc_button;
	let bc_button_text_value;
	let t12;
	let show_if = bc.parameterService.appParameters.roleIds && bc.parameterService.appParameters.roleIds.includes(constants.role.Ugdm_Application_Administrator);
	let mounted;
	let dispose;
	let if_block0 = /*hasKanban*/ ctx[3] && create_if_block_4(ctx);
	let if_block1 = /*hasStatistics*/ ctx[5] && create_if_block_3(ctx);
	let if_block2 = /*hasCalendar*/ ctx[4] && create_if_block_2(ctx);
	let if_block3 = show_if && create_if_block_1(ctx);

	const block = {
		c: function create() {
			div0 = element_1("div");
			i = element_1("i");
			t0 = space();
			h3 = element_1("h3");
			h3.textContent = `${/*componentText*/ ctx[8].addAsFavorite}`;
			t2 = space();
			p = element_1("p");
			p.textContent = `${/*componentText*/ ctx[8].pinExplanation}`;
			t4 = space();
			div1 = element_1("div");
			label0 = element_1("label");
			label0.textContent = `${/*componentText*/ ctx[8].favoriteName}`;
			t6 = space();
			input = element_1("input");
			t7 = space();
			div2 = element_1("div");
			label1 = element_1("label");
			label1.textContent = `${/*componentText*/ ctx[8].startTab}`;
			t9 = space();
			select = element_1("select");
			option = element_1("option");
			option.textContent = `${/*generalText*/ ctx[7].table}`;
			if (if_block0) if_block0.c();
			if_block0_anchor = empty();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
			if (if_block2) if_block2.c();
			t11 = space();
			div3 = element_1("div");
			bc_button = element_1("bc-button");
			t12 = space();
			if (if_block3) if_block3.c();
			attr_dev(i, "class", "far fa-thumbtack");
			add_location(i, file, 2227, 12, 44767);
			attr_dev(h3, "class", "text-lg font-medium roboto-medium");
			add_location(h3, file, 2228, 12, 44810);
			attr_dev(div0, "class", "flex items-center gap-2");
			add_location(div0, file, 2226, 8, 44716);
			attr_dev(p, "class", "py-2");
			add_location(p, file, 2231, 8, 44918);
			attr_dev(label0, "class", "font-medium roboto-medium");
			add_location(label0, file, 2233, 12, 45030);
			attr_dev(input, "type", "text");
			attr_dev(input, "class", "h-10 border border-solid border-gray-300 p-2 rounded");
			add_location(input, file, 2234, 12, 45121);
			attr_dev(div1, "class", "flex flex-col gap-2 my-2");
			add_location(div1, file, 2232, 8, 44978);
			attr_dev(label1, "class", "font-medium roboto-medium");
			add_location(label1, file, 2238, 12, 45360);
			option.__value = "table";
			option.value = option.__value;
			add_location(option, file, 2240, 16, 45556);
			attr_dev(select, "class", "h-10 border border-solid border-gray-300 p-2 rounded");
			add_location(select, file, 2239, 12, 45447);
			attr_dev(div2, "class", "flex flex-col gap-2 my-2");
			add_location(div2, file, 2237, 8, 45308);
			set_custom_element_data(bc_button, "type", "secondary");
			set_custom_element_data(bc_button, "color", "blue");
			set_custom_element_data(bc_button, "size", "normal");
			set_custom_element_data(bc_button, "icon", "fas fa-save");
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*generalText*/ ctx[7].add);
			add_location(bc_button, file, 2256, 12, 46200);
			attr_dev(div3, "class", "flex justify-end mt-4 gap-2");
			add_location(div3, file, 2255, 8, 46145);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div0, anchor);
			append_dev(div0, i);
			append_dev(div0, t0);
			append_dev(div0, h3);
			insert_dev(target, t2, anchor);
			insert_dev(target, p, anchor);
			insert_dev(target, t4, anchor);
			insert_dev(target, div1, anchor);
			append_dev(div1, label0);
			append_dev(div1, t6);
			append_dev(div1, input);
			/*input_binding*/ ctx[18](input);
			insert_dev(target, t7, anchor);
			insert_dev(target, div2, anchor);
			append_dev(div2, label1);
			append_dev(div2, t9);
			append_dev(div2, select);
			append_dev(select, option);
			if (if_block0) if_block0.m(select, null);
			append_dev(select, if_block0_anchor);
			if (if_block1) if_block1.m(select, null);
			append_dev(select, if_block1_anchor);
			if (if_block2) if_block2.m(select, null);
			/*select_binding*/ ctx[19](select);
			insert_dev(target, t11, anchor);
			insert_dev(target, div3, anchor);
			append_dev(div3, bc_button);
			append_dev(div3, t12);
			if (if_block3) if_block3.m(div3, null);

			if (!mounted) {
				dispose = [
					listen_dev(input, "click", click_handler, false, false, false, false),
					listen_dev(bc_button, "click", /*saveDashboardTab*/ ctx[9], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (/*hasKanban*/ ctx[3]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_4(ctx);
					if_block0.c();
					if_block0.m(select, if_block0_anchor);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*hasStatistics*/ ctx[5]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_3(ctx);
					if_block1.c();
					if_block1.m(select, if_block1_anchor);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*hasCalendar*/ ctx[4]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_2(ctx);
					if_block2.c();
					if_block2.m(select, null);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (show_if) if_block3.p(ctx, dirty);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div0);
			if (detaching) detach_dev(t2);
			if (detaching) detach_dev(p);
			if (detaching) detach_dev(t4);
			if (detaching) detach_dev(div1);
			/*input_binding*/ ctx[18](null);
			if (detaching) detach_dev(t7);
			if (detaching) detach_dev(div2);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			/*select_binding*/ ctx[19](null);
			if (detaching) detach_dev(t11);
			if (detaching) detach_dev(div3);
			if (if_block3) if_block3.d();
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2226:4) {#if ready}",
		ctx
	});

	return block;
}

// (2242:16) {#if hasKanban}
function create_if_block_4(ctx) {
	let option;

	const block = {
		c: function create() {
			option = element_1("option");
			option.textContent = `${/*generalText*/ ctx[7].kanban}`;
			option.__value = "kanban";
			option.value = option.__value;
			add_location(option, file, 2242, 20, 45661);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_4.name,
		type: "if",
		source: "(2242:16) {#if hasKanban}",
		ctx
	});

	return block;
}

// (2245:16) {#if hasStatistics}
function create_if_block_3(ctx) {
	let option;

	const block = {
		c: function create() {
			option = element_1("option");
			option.textContent = `${/*generalText*/ ctx[7].statistics}`;
			option.__value = "statistics";
			option.value = option.__value;
			add_location(option, file, 2245, 20, 45795);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_3.name,
		type: "if",
		source: "(2245:16) {#if hasStatistics}",
		ctx
	});

	return block;
}

// (2248:16) {#if hasCalendar}
function create_if_block_2(ctx) {
	let option0;
	let option1;

	const block = {
		c: function create() {
			option0 = element_1("option");
			option0.textContent = `${/*generalText*/ ctx[7].calendar}`;
			option1 = element_1("option");
			option1.textContent = `${/*generalText*/ ctx[7].timeline}`;
			option0.__value = "calendar";
			option0.value = option0.__value;
			add_location(option0, file, 2248, 20, 45935);
			option1.__value = "timeline";
			option1.value = option1.__value;
			add_location(option1, file, 2249, 20, 46013);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option0, anchor);
			insert_dev(target, option1, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(option0);
			if (detaching) detach_dev(option1);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(2248:16) {#if hasCalendar}",
		ctx
	});

	return block;
}

// (2259:12) {#if (bc.parameterService.appParameters.roleIds && bc.parameterService.appParameters.roleIds.includes(constants.role.Ugdm_Application_Administrator))}
function create_if_block_1(ctx) {
	let bc_button;
	let bc_button_text_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			bc_button = element_1("bc-button");
			set_custom_element_data(bc_button, "type", "secondary");
			set_custom_element_data(bc_button, "color", "blue");
			set_custom_element_data(bc_button, "size", "normal");
			set_custom_element_data(bc_button, "icon", "fas fa-save");
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*generalText*/ ctx[7].saveForAll);
			add_location(bc_button, file, 2259, 16, 46543);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button, anchor);

			if (!mounted) {
				dispose = listen_dev(bc_button, "click", /*saveDashboardTabForAllUsers*/ ctx[10], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2259:12) {#if (bc.parameterService.appParameters.roleIds && bc.parameterService.appParameters.roleIds.includes(constants.role.Ugdm_Application_Administrator))}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[0] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 2224, 0, 44664);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[20](div);
		},
		p: function update(ctx, dirty) {
			if (/*ready*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[20](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

const click_handler = e => {
	e.stopPropagation();
};

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(17, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('dashboard-favorite-creator', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { dataviewguid = '' } = $$props;

	// Base Variables
	//===========================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	const componentText = getComponentText('dashboardTabCreator');
	let mounted = false;
	let canReload = true;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let searchParameterString = '';
	let dataviewSearch;
	let componentId = uuidv4();
	let favoritesSetting;
	let inputName;
	let hasKanban = true;
	let hasCalendar = true;
	let hasStatistics = true;
	let tabSelect;
	let allUsersFavoritesSetting;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(16, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(15, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
			bc.messageService.unsubscribeComponent(componentId);
		};
	});

	const initComponent = async dataviewguid => {
		$$invalidate(14, firstInitialized = true);

		if (dataviewguid !== '') {
			// Get DataviewSearch-Object
			let searchObject = await handleDataviewSearchSettings({
				dataviewguid,
				searchguid: '',
				contentType: 'Entity',
				sourceviewguid: ''
			});

			dataviewSearch = searchObject.dataviewSearch;
			searchParameterString = searchObject.searchParameterString;

			//Subscribe to Search-Updates
			bc.messageService.subscribe(searchParameterString, componentId, reconstructFilters);

			await getViewInfos();
			favoritesSetting = await userSettingManager.getUserSetting('FAVORITES', []);

			if (bc.parameterService.appParameters.roleIds && bc.parameterService.appParameters.roleIds.includes(constants.role.Ugdm_Application_Administrator)) {
				allUsersFavoritesSetting = await allUserSettingManager.getUserSetting('FAVORITES', []);
			}

			$$invalidate(0, ready = true);
		}
	};

	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				dataviewguid
			);
		}
	};

	// Component functions
	//================================================================
	const reconstructFilters = newDataviewSearch => {
		dataviewSearch = bc.parameterService.get(searchParameterString, true);
	};

	const saveDashboardTab = async () => {
		if (inputName !== null && inputName.value !== '') {
			let existingFavorites = favoritesSetting.UG_VALUE;

			if (existingFavorites.find(et => et.name === inputName.value)) {
				alert(componentText.sameNameAlreadyExisting);
				return;
			} else {
				dataviewSearch.filters.forEach(f => f.filterExpression = 0);

				existingFavorites = [
					...existingFavorites,
					{
						name: inputName.value,
						guid: dataviewguid,
						tab: tabSelect.value,
						color: '#ffffff',
						search: dataviewSearch
					}
				];

				await userSettingManager.setSetting(favoritesSetting, existingFavorites);
				bc.messageService.publish('ADDED_FAVORITE');
				notify(componentText.dashboardTabAdded);
				$$invalidate(2, inputName.value = '', inputName);
				bc.messageService.publish('CLOSE_POPOVER_DATAVIEWMANAGER');
			}
		}
	};

	const saveDashboardTabForAllUsers = async () => {
		if (inputName !== null && inputName.value !== '') {
			let existingFavorites = allUsersFavoritesSetting.UG_VALUE;

			if (existingFavorites.find(et => et.name === inputName.value)) {
				alert(componentText.sameNameAlreadyExisting);
				return;
			} else {
				dataviewSearch.filters.forEach(f => f.filterExpression = 0);

				existingFavorites = [
					...existingFavorites,
					{
						name: inputName.value,
						guid: dataviewguid,
						tab: tabSelect.value,
						color: '#ffffff',
						search: dataviewSearch
					}
				];

				await allUserSettingManager.setSetting(allUsersFavoritesSetting, existingFavorites);
				bc.messageService.publish('ADDED_FAVORITE');
				notify(componentText.dashboardTabAdded);
				$$invalidate(2, inputName.value = '', inputName);
				bc.messageService.publish('CLOSE_POPOVER_DATAVIEWMANAGER');
			}
		}
	};

	const getViewInfos = async () => {
		const showCalendar = bc.parameterService.get('ShowCalendar');
		const showStatistics = bc.parameterService.get('ShowStatistics');
		const showKanban = bc.parameterService.get('ShowKanban');
		let viewMetadata = await bc.parameterService.getMetadata(dataviewguid);

		// Test KANBAN
		if (showKanban === 'YES') {
			const statusColumn = viewMetadata.getBaseField('EY_STATUS');

			if (statusColumn) {
				let listStatusMapping = viewMetadata.properties.listMapping.find(m => m.fieldName === statusColumn.name);

				if (!listStatusMapping) {
					$$invalidate(3, hasKanban = false);
				}
			}
		} else {
			$$invalidate(3, hasKanban = false);
		}

		// Test Calendar
		if (showCalendar === 'NO') {
			$$invalidate(4, hasCalendar = false);
		} else {
			let startDatumField;
			let endDatumField;
			startDatumField = viewMetadata.getBaseField('EY_START');
			endDatumField = viewMetadata.getBaseField('EY_END');

			if (startDatumField && endDatumField) {
				$$invalidate(4, hasCalendar = startDatumField.visible === true && endDatumField.visible === true);
			} else {
				$$invalidate(4, hasCalendar = false);
			}
		}

		// Test Statistics
		$$invalidate(5, hasStatistics = showStatistics === 'YES');
	};

	const writable_props = ['show', 'init', 'dataviewguid'];

	

	function input_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			inputName = $$value;
			$$invalidate(2, inputName);
		});
	}

	function select_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			tabSelect = $$value;
			$$invalidate(6, tabSelect);
		});
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(11, show = $$props.show);
		if ('init' in $$props) $$invalidate(12, init = $$props.init);
		if ('dataviewguid' in $$props) $$invalidate(13, dataviewguid = $$props.dataviewguid);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		constants,
		allUserSettingManager,
		handleDataviewSearchSettings,
		userSettingManager,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		notify,
		applicationReady,
		getComponentText,
		show,
		init,
		dataviewguid,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		componentText,
		mounted,
		canReload,
		component,
		element,
		searchParameterString,
		dataviewSearch,
		componentId,
		favoritesSetting,
		inputName,
		hasKanban,
		hasCalendar,
		hasStatistics,
		tabSelect,
		allUsersFavoritesSetting,
		initComponent,
		applyProps,
		reconstructFilters,
		saveDashboardTab,
		saveDashboardTabForAllUsers,
		getViewInfos,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(11, show = $$props.show);
		if ('init' in $$props) $$invalidate(12, init = $$props.init);
		if ('dataviewguid' in $$props) $$invalidate(13, dataviewguid = $$props.dataviewguid);
		if ('firstInitialized' in $$props) $$invalidate(14, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(15, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('component' in $$props) $$invalidate(16, component = $$props.component);
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
		if ('searchParameterString' in $$props) searchParameterString = $$props.searchParameterString;
		if ('dataviewSearch' in $$props) dataviewSearch = $$props.dataviewSearch;
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('favoritesSetting' in $$props) favoritesSetting = $$props.favoritesSetting;
		if ('inputName' in $$props) $$invalidate(2, inputName = $$props.inputName);
		if ('hasKanban' in $$props) $$invalidate(3, hasKanban = $$props.hasKanban);
		if ('hasCalendar' in $$props) $$invalidate(4, hasCalendar = $$props.hasCalendar);
		if ('hasStatistics' in $$props) $$invalidate(5, hasStatistics = $$props.hasStatistics);
		if ('tabSelect' in $$props) $$invalidate(6, tabSelect = $$props.tabSelect);
		if ('allUsersFavoritesSetting' in $$props) allUsersFavoritesSetting = $$props.allUsersFavoritesSetting;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*show, component*/ 67584) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[0] & /*init, firstInitialized, mounted, $applicationReady*/ 184320) {
			/**
 * Handle the component init attribute, for the case that the component has not been initialized yet.
 */
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty[0] & /*dataviewguid*/ 8192) {
			/**
 * Handles any component attribute changes after the component is initialized.
 */
			$: (dataviewguid, applyProps(true));
		}
	};

	return [
		ready,
		element,
		inputName,
		hasKanban,
		hasCalendar,
		hasStatistics,
		tabSelect,
		generalText,
		componentText,
		saveDashboardTab,
		saveDashboardTabForAllUsers,
		show,
		init,
		dataviewguid,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		input_binding,
		select_binding,
		div_binding
	];
}

class DashboardFavoriteCreator extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.fa-thumbtack::before{content:""}.far{font-weight:400 !important}.far{font-family:"Font Awesome 6 Pro" !important;font-style:normal !important}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-end{-webkit-box-pack:end;-ms-flex-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end}.font-medium{font-family:Roboto-Medium}.h-10{height:2.5rem}.text-lg{font-size:1.125rem;line-height:1.75rem}.my-2{margin-top:0.5rem;margin-bottom:0.5rem}.mt-4{margin-top:1rem}.p-2{padding:0.5rem}.py-2{padding-top:0.5rem;padding-bottom:0.5rem}.gap-2{grid-gap:0.5rem;gap:0.5rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 11, init: 12, dataviewguid: 13 },
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "dataviewguid"];
	}

	get show() {
		return this.$$.ctx[11];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[12];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get dataviewguid() {
		return this.$$.ctx[13];
	}

	set dataviewguid(dataviewguid) {
		this.$$set({ dataviewguid });
		flush();
	}
}

customElements.define("dashboard-favorite-creator", DashboardFavoriteCreator);
export default DashboardFavoriteCreator;