/* src\components\LoadingSpinner\LoadingSpinner.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	set_data_dev,
	space,
	text as text_1,
	toggle_class,
	validate_slots
} from "../../../web_modules/svelte/internal.js";

import { getSharedNormalize } from "../../utilities/iconUtils.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\LoadingSpinner\\LoadingSpinner.svelte";

// (156:33) 
function create_if_block_1(ctx) {
	let div;
	let span;
	let t0;
	let p;
	let t1;

	const block = {
		c: function create() {
			div = element_1("div");
			span = element_1("span");
			t0 = space();
			p = element_1("p");
			t1 = text_1(/*text*/ ctx[0]);
			attr_dev(span, "class", "loader1 animate-spin");
			add_location(span, file, 157, 12, 3625);
			add_location(p, file, 159, 12, 3684);
			attr_dev(div, "class", "container p-2 flex gap-2 items-center justify-center");
			add_location(div, file, 156, 8, 3545);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, span);
			append_dev(div, t0);
			append_dev(div, p);
			append_dev(p, t1);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*text*/ 1) set_data_dev(t1, /*text*/ ctx[0]);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(156:33) ",
		ctx
	});

	return block;
}

// (150:4) {#if oneline === 'false'}
function create_if_block(ctx) {
	let div;
	let p;
	let t0;
	let t1;
	let span;

	const block = {
		c: function create() {
			div = element_1("div");
			p = element_1("p");
			t0 = text_1(/*text*/ ctx[0]);
			t1 = space();
			span = element_1("span");
			attr_dev(p, "class", "mb-2");
			add_location(p, file, 151, 12, 3401);
			attr_dev(span, "class", "loader animate-spin");
			add_location(span, file, 152, 12, 3441);
			attr_dev(div, "class", "container p-2");
			add_location(div, file, 150, 8, 3360);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, p);
			append_dev(p, t0);
			append_dev(div, t1);
			append_dev(div, span);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*text*/ 1) set_data_dev(t0, /*text*/ ctx[0]);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(150:4) {#if oneline === 'false'}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*oneline*/ ctx[1] === 'false') return create_if_block;
		if (/*oneline*/ ctx[1] === 'true') return create_if_block_1;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type && current_block_type(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			attr_dev(div, "class", "spinning-loader");
			toggle_class(div, "hidden", /*show*/ ctx[2] !== 'true');
			add_location(div, file, 148, 0, 3258);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if (if_block) if_block.d(1);
				if_block = current_block_type && current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}

			if (dirty & /*show*/ 4) {
				toggle_class(div, "hidden", /*show*/ ctx[2] !== 'true');
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);

			if (if_block) {
				if_block.d();
			}
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('loading-spinner', slots, []);
	let { text = 'Laden' } = $$props;
	let { oneline = 'false' } = $$props;
	let { show = 'true' } = $$props;

	// Base variables
	//================================================================
	const generalText = getComponentText('general');

	let notAdded = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this

	const setText = txt => {
		$$invalidate(0, text = txt);
	};

	const writable_props = ['text', 'oneline', 'show'];

	

	$$self.$$set = $$props => {
		if ('text' in $$props) $$invalidate(0, text = $$props.text);
		if ('oneline' in $$props) $$invalidate(1, oneline = $$props.oneline);
		if ('show' in $$props) $$invalidate(2, show = $$props.show);
	};

	$$self.$capture_state = () => ({
		getSharedNormalize,
		getComponentText,
		text,
		oneline,
		show,
		generalText,
		notAdded,
		component,
		element,
		setText
	});

	$$self.$inject_state = $$props => {
		if ('text' in $$props) $$invalidate(0, text = $$props.text);
		if ('oneline' in $$props) $$invalidate(1, oneline = $$props.oneline);
		if ('show' in $$props) $$invalidate(2, show = $$props.show);
		if ('notAdded' in $$props) $$invalidate(3, notAdded = $$props.notAdded);
		if ('component' in $$props) $$invalidate(4, component = $$props.component);
		if ('element' in $$props) element = $$props.element;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*component, notAdded*/ 24) {
			// HTML Mount & Component initialization
			//================================================================
			$: if (component && component.shadowRoot && notAdded === false) {
				getSharedNormalize().then(value => {
					$$invalidate(4, component.shadowRoot.adoptedStyleSheets = [value], component);
					$$invalidate(3, notAdded = true);
				});
			}
		}

		if ($$self.$$.dirty & /*text*/ 1) {
			$: setText(text);
		}
	};

	return [text, oneline, show, notAdded, component];
}

class LoadingSpinner extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `.spinning-loader{width:100%;height:100%;margin-top:20px}.spinning-loader .container{width:50%;margin-left:25%;text-align:center;background-color:whitesmoke;border:solid thin #ccc;border-radius:3px}.loader{width:2rem;height:2rem;border-radius:50%;display:inline-block;border-top:3px solid #484848;border-right:3px solid transparent;box-sizing:border-box;animation:rotation 1s linear infinite}.loader1{width:1rem;height:1rem;border-radius:50%;display:inline-block;border-top:3px solid #5a5a5a;border-right:3px solid transparent;box-sizing:border-box;animation:rotation 1s linear infinite}.container{width:100%}@media(min-width: 1536px){.container{max-width:1536px}}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.hidden{display:none}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.mb-2{margin-bottom:0.5rem}.p-2{padding:0.5rem}.gap-2{grid-gap:0.5rem;gap:0.5rem}@keyframes spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@-webkit-keyframes spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}.animate-spin{-webkit-animation:spin 1s linear infinite;animation:spin 1s linear infinite}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ text: 0, oneline: 1, show: 2 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["text", "oneline", "show"];
	}

	get text() {
		return this.$$.ctx[0];
	}

	set text(text) {
		this.$$set({ text });
		flush();
	}

	get oneline() {
		return this.$$.ctx[1];
	}

	set oneline(oneline) {
		this.$$set({ oneline });
		flush();
	}

	get show() {
		return this.$$.ctx[2];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}
}

customElements.define("loading-spinner", LoadingSpinner);
export default LoadingSpinner;