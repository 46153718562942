/* src\components\Map\MapTools\MapObjectPreview\MapObjectPreview.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	space,
	toggle_class,
	validate_slots
} from "../../../../../web_modules/svelte/internal.js";

import bc from '../../../../bcShim.js';
import { v4 as uuidv4 } from "../../../../../web_modules/uuid.js";
import { onMount, tick } from "../../../../../web_modules/svelte.js";

import {
	createVectorLayer,
	getMaxZIndex,
	mapToolsWithSidebarOpen
} from "../../map-utils.js";

import "../../../../../web_modules/ol/Feature.js";
import { wktHelper } from "../../../../utilities/WKTHelper.js";
import { Fill, Stroke, Style } from "../../../../../web_modules/ol/style.js";
import CircleStyle from "../../../../../web_modules/ol/style/Circle.js";
import { buffer } from "../../../../../web_modules/ol/extent.js";
import "../../../../../web_modules/ol/layer/Vector.js";
import "../../../../../web_modules/ol/source/Vector.js";
import "../../../../../web_modules/ol/style/Style.js";

import {
	attachNormalize,
	dispatchMountedEvent
} from "../../../../utilities/componentUtils.js";

import "../../../../utilities/entityUtils.js";
import { getComponentText } from "../../../../utilities/generalUtils.js";
const file = "src\\components\\Map\\MapTools\\MapObjectPreview\\MapObjectPreview.svelte";

function create_fragment(ctx) {
	let div1;
	let div0;
	let icon_button;
	let t;
	let simple_object_renderer;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div1 = element_1("div");
			div0 = element_1("div");
			icon_button = element_1("icon-button");
			t = space();
			simple_object_renderer = element_1("simple-object-renderer");
			this.c = noop;
			set_custom_element_data(icon_button, "size", "xs");
			set_custom_element_data(icon_button, "icon", "far fa-times");
			set_custom_element_data(icon_button, "color", "white");
			add_location(icon_button, file, 1900, 8, 36395);
			attr_dev(div0, "class", "flex justify-end mb-2");
			add_location(div0, file, 1899, 4, 36350);
			set_custom_element_data(simple_object_renderer, "class", "mb-2 w-full");
			set_custom_element_data(simple_object_renderer, "inmappreview", "true");
			set_custom_element_data(simple_object_renderer, "ingeosearch", "NO");
			set_custom_element_data(simple_object_renderer, "deleteobject", "NO");
			set_custom_element_data(simple_object_renderer, "onmap", "YES");
			set_custom_element_data(simple_object_renderer, "open", "YES");
			set_custom_element_data(simple_object_renderer, "dataviewguid", /*dataviewGuid*/ ctx[2]);
			set_custom_element_data(simple_object_renderer, "objectid", /*entityGuid*/ ctx[1]);
			set_custom_element_data(simple_object_renderer, "modification", "NO");
			set_custom_element_data(simple_object_renderer, "showbuttons", "false");
			set_custom_element_data(simple_object_renderer, "displaymenuopen", "true");
			set_custom_element_data(simple_object_renderer, "displaymenuedit", "true");
			set_custom_element_data(simple_object_renderer, "displaymenushowonmap", "false");
			set_custom_element_data(simple_object_renderer, "displaymenufileupload", "true");
			set_custom_element_data(simple_object_renderer, "showbuttonmore", "true");
			add_location(simple_object_renderer, file, 1902, 4, 36508);
			attr_dev(div1, "class", "p-2");
			toggle_class(div1, "hidden", !/*entityGuid*/ ctx[1] || /*entityGuid*/ ctx[1] === '');
			add_location(div1, file, 1897, 0, 36252);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div0, icon_button);
			append_dev(div1, t);
			append_dev(div1, simple_object_renderer);
			/*div1_binding*/ ctx[9](div1);

			if (!mounted) {
				dispose = listen_dev(icon_button, "click", /*closePreview*/ ctx[3], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*dataviewGuid*/ 4) {
				set_custom_element_data(simple_object_renderer, "dataviewguid", /*dataviewGuid*/ ctx[2]);
			}

			if (dirty & /*entityGuid*/ 2) {
				set_custom_element_data(simple_object_renderer, "objectid", /*entityGuid*/ ctx[1]);
			}

			if (dirty & /*entityGuid*/ 2) {
				toggle_class(div1, "hidden", !/*entityGuid*/ ctx[1] || /*entityGuid*/ ctx[1] === '');
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			/*div1_binding*/ ctx[9](null);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('map-tool-object-preview', slots, []);
	let { maptool } = $$props;

	// Base variables
	//================================================================
	const generalText = getComponentText('general');

	let mounted = false;
	let ready = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let entityGuid = '';
	let dataviewGuid = '';
	let vectorLayer;
	let vectorSource;
	let bufferedExtend;
	let refitInViewCounter = 0;
	let map;
	let componentId = uuidv4();
	let setShowPreview;
	let showSidebar;
	let previewActive = false;
	let setMapTool;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			component = element.getRootNode().host || element.parentNode; // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				mounted = true;
			});
		});

		return () => {
			
		};
	});

	const initComponent = (newMap, setSidebar, setShowPrev, setMapTooFunction) => {
		map = newMap;
		showSidebar = setSidebar;
		setShowPreview = setShowPrev;
		setMapTool = setMapTooFunction;
		ready = true;
	};

	const closePreview = goToMap => {
		setMapTool('');
		showSidebar(false);
		cleanup();
		let mapRoute = bc.utils.getRoute('map');

		if (map) {
			bc.utils.navigateTo(mapRoute.create());
		}
	};

	const setObjectPreview = data => {
		if (data && data !== null) {
			$$invalidate(1, entityGuid = data.entityGuid);
			$$invalidate(2, dataviewGuid = data.dataviewGuid);
			showSidebar(true);
		} else {
			$$invalidate(1, entityGuid = '');
			$$invalidate(2, dataviewGuid = '');
		}
	};

	const checkVectorLayer = () => {
		if (!vectorLayer) {
			vectorLayer = createVectorLayer('MAP_PREVIEW' + componentId);
			let zIndex = getMaxZIndex(map);
			vectorLayer.setZIndex(zIndex + 100);
			vectorSource = vectorLayer.getSource();
			map.addLayer(vectorLayer);
		}
	};

	const cleanup = () => {
		if (previewActive) {
			if (vectorLayer) {
				map.removeLayer(vectorLayer);
			}

			if (vectorSource) {
				vectorSource.clear();
			}

			vectorLayer = undefined;
			vectorSource = undefined;

			if (setShowPreview) {
				setShowPreview(false);
			}

			if (showSidebar) {
				if (!mapToolsWithSidebarOpen.includes(maptool)) {
					showSidebar(false);
				}
			}

			previewActive = false;
		}
	};

	const showMapPreview = data => {
		previewActive = true;
		checkVectorLayer();
		let { wkt, viewGuid, entityGuid, strokeColor, zIndex, fillColor, centerOn, showObjectPreview, clearObjects, main } = data;

		if (!strokeColor) {
			strokeColor = 'red';
		}

		if (!zIndex) {
			zIndex = 100;
		}

		if (!fillColor) {
			fillColor = '#a400ffa1';
		}

		let feature = wktHelper.readFeature(wkt);

		if (main) {
			feature.set('TYPE', 'MAIN');
		}

		feature.setStyle(new Style({
				fill: new Fill({ color: fillColor }),
				stroke: new Stroke({ color: strokeColor, width: 2 }),
				image: new CircleStyle({
						radius: 8,
						fill: new Fill({ color: fillColor }),
						stroke: new Stroke({ color: strokeColor, width: 2 })
					}),
				zIndex
			}));

		feature.set('id', viewGuid + "_" + entityGuid);

		if (clearObjects) {
			vectorSource.clear();
		}

		vectorSource.addFeature(feature);

		if (entityGuid && showObjectPreview) {
			setObjectPreview({ entityGuid, dataviewGuid: viewGuid });
			setShowPreview(true);
		}

		if (centerOn) {
			const wktExtent = feature.getGeometry().getExtent();
			bufferedExtend = buffer(wktExtent, 100);

			setTimeout(
				() => {
					map.getView().fit(bufferedExtend, {
						size: map.getSize(),
						callback: checkPosition
					});
				},
				100
			);
		}
	};

	const checkPosition = () => {
		const mapExtent = map.getView().calculateExtent();
		let mapX = (mapExtent[0] + mapExtent[2]) / 2;
		let mapY = (mapExtent[1] + mapExtent[3]) / 2;
		let buffX = (bufferedExtend[0] + bufferedExtend[2]) / 2;
		let buffY = (bufferedExtend[1] + bufferedExtend[3]) / 2;

		if (mapX !== buffX || mapY !== buffY) {
			refitInViewCounter++;

			if (refitInViewCounter < 300) {
				map.getView().fit(bufferedExtend, {
					size: map.getSize(),
					callback: checkPosition
				});
			} else {
				refitInViewCounter = 0;
			}
		}
	};

	const handleMapTool = tool => {
		cleanup();
	};

	$$self.$$.on_mount.push(function () {
		if (maptool === undefined && !('maptool' in $$props || $$self.$$.bound[$$self.$$.props['maptool']])) {
			console.warn("<map-tool-object-preview> was created without expected prop 'maptool'");
		}
	});

	const writable_props = ['maptool'];

	

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(0, element);
		});
	}

	$$self.$$set = $$props => {
		if ('maptool' in $$props) $$invalidate(4, maptool = $$props.maptool);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		createVectorLayer,
		getMaxZIndex,
		mapToolsWithSidebarOpen,
		wktHelper,
		Fill,
		Stroke,
		Style,
		CircleStyle,
		buffer,
		attachNormalize,
		dispatchMountedEvent,
		getComponentText,
		maptool,
		generalText,
		mounted,
		ready,
		component,
		element,
		entityGuid,
		dataviewGuid,
		vectorLayer,
		vectorSource,
		bufferedExtend,
		refitInViewCounter,
		map,
		componentId,
		setShowPreview,
		showSidebar,
		previewActive,
		setMapTool,
		initComponent,
		closePreview,
		setObjectPreview,
		checkVectorLayer,
		cleanup,
		showMapPreview,
		checkPosition,
		handleMapTool
	});

	$$self.$inject_state = $$props => {
		if ('maptool' in $$props) $$invalidate(4, maptool = $$props.maptool);
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('ready' in $$props) ready = $$props.ready;
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) $$invalidate(0, element = $$props.element);
		if ('entityGuid' in $$props) $$invalidate(1, entityGuid = $$props.entityGuid);
		if ('dataviewGuid' in $$props) $$invalidate(2, dataviewGuid = $$props.dataviewGuid);
		if ('vectorLayer' in $$props) vectorLayer = $$props.vectorLayer;
		if ('vectorSource' in $$props) vectorSource = $$props.vectorSource;
		if ('bufferedExtend' in $$props) bufferedExtend = $$props.bufferedExtend;
		if ('refitInViewCounter' in $$props) refitInViewCounter = $$props.refitInViewCounter;
		if ('map' in $$props) map = $$props.map;
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('setShowPreview' in $$props) setShowPreview = $$props.setShowPreview;
		if ('showSidebar' in $$props) showSidebar = $$props.showSidebar;
		if ('previewActive' in $$props) previewActive = $$props.previewActive;
		if ('setMapTool' in $$props) setMapTool = $$props.setMapTool;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*maptool*/ 16) {
			$: handleMapTool(maptool);
		}
	};

	return [
		element,
		entityGuid,
		dataviewGuid,
		closePreview,
		maptool,
		initComponent,
		setObjectPreview,
		cleanup,
		showMapPreview,
		div1_binding
	];
}

class MapObjectPreview extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{left:0;right:0}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.hidden{display:none}.justify-end{-webkit-box-pack:end;-ms-flex-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end}.mb-2{margin-bottom:0.5rem}.p-2{padding:0.5rem}.w-full{width:100%}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				maptool: 4,
				initComponent: 5,
				setObjectPreview: 6,
				cleanup: 7,
				showMapPreview: 8
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["maptool", "initComponent", "setObjectPreview", "cleanup", "showMapPreview"];
	}

	get maptool() {
		return this.$$.ctx[4];
	}

	set maptool(maptool) {
		this.$$set({ maptool });
		flush();
	}

	get initComponent() {
		return this.$$.ctx[5];
	}

	set initComponent(value) {
		throw new Error("<map-tool-object-preview>: Cannot set read-only property 'initComponent'");
	}

	get setObjectPreview() {
		return this.$$.ctx[6];
	}

	set setObjectPreview(value) {
		throw new Error("<map-tool-object-preview>: Cannot set read-only property 'setObjectPreview'");
	}

	get cleanup() {
		return this.$$.ctx[7];
	}

	set cleanup(value) {
		throw new Error("<map-tool-object-preview>: Cannot set read-only property 'cleanup'");
	}

	get showMapPreview() {
		return this.$$.ctx[8];
	}

	set showMapPreview(value) {
		throw new Error("<map-tool-object-preview>: Cannot set read-only property 'showMapPreview'");
	}
}

customElements.define("map-tool-object-preview", MapObjectPreview);
export default MapObjectPreview;