/* src\components\EventDispatcher\EventDispatcher.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	attribute_to_object,
	binding_callbacks,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	dispatchMountedEvent
} from "../../utilities/componentUtils.js";

import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\EventDispatcher\\EventDispatcher.svelte";

function create_fragment(ctx) {
	let span;

	const block = {
		c: function create() {
			span = element_1("span");
			this.c = noop;
			add_location(span, file, 63, 0, 2019);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, span, anchor);
			/*span_binding*/ ctx[5](span);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(span);
			/*span_binding*/ ctx[5](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('event-dispatcher', slots, []);
	let { event = '' } = $$props;
	let { eventvalue = '' } = $$props;
	let { listentoevent = '' } = $$props;
	let { passvalue = 'false' } = $$props;
	let firstInitialized = false;
	let isHTMLHandled = false;
	let componentId = uuidv4();
	let subscribeGuid = uuidv4();
	const generalText = getComponentText('general');
	let mounted = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this

	const handleListenEvent = listenevent => {
		if (!firstInitialized) {
			if (listenevent !== '') {
				firstInitialized = true;
				bc.messageService.subscribe(listenevent, componentId, dispatchEvent);
			}
		}
	};

	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			component = element.getRootNode().host || element.parentNode; // get the component/host

			// typed: (element.getRootNode() as ShadowRoot).host as HTMLElement
			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				mounted = true;
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const dispatchEvent = args => {
		if (eventvalue !== '') {
			bc.messageService.publish(event, eventvalue);
		} else {
			if (passvalue === 'true') {
				bc.messageService.publish(event, args);
			} else {
				bc.messageService.publish(event);
			}
		}
	};

	const writable_props = ['event', 'eventvalue', 'listentoevent', 'passvalue'];

	

	function span_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(0, element);
		});
	}

	$$self.$$set = $$props => {
		if ('event' in $$props) $$invalidate(1, event = $$props.event);
		if ('eventvalue' in $$props) $$invalidate(2, eventvalue = $$props.eventvalue);
		if ('listentoevent' in $$props) $$invalidate(3, listentoevent = $$props.listentoevent);
		if ('passvalue' in $$props) $$invalidate(4, passvalue = $$props.passvalue);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		getComponentText,
		event,
		eventvalue,
		listentoevent,
		passvalue,
		firstInitialized,
		isHTMLHandled,
		componentId,
		subscribeGuid,
		generalText,
		mounted,
		component,
		element,
		handleListenEvent,
		dispatchEvent
	});

	$$self.$inject_state = $$props => {
		if ('event' in $$props) $$invalidate(1, event = $$props.event);
		if ('eventvalue' in $$props) $$invalidate(2, eventvalue = $$props.eventvalue);
		if ('listentoevent' in $$props) $$invalidate(3, listentoevent = $$props.listentoevent);
		if ('passvalue' in $$props) $$invalidate(4, passvalue = $$props.passvalue);
		if ('firstInitialized' in $$props) firstInitialized = $$props.firstInitialized;
		if ('isHTMLHandled' in $$props) isHTMLHandled = $$props.isHTMLHandled;
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) $$invalidate(0, element = $$props.element);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*listentoevent*/ 8) {
			$: handleListenEvent(listentoevent);
		}
	};

	return [element, event, eventvalue, listentoevent, passvalue, span_binding];
}

class EventDispatcher extends SvelteElement {
	constructor(options) {
		super();

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				event: 1,
				eventvalue: 2,
				listentoevent: 3,
				passvalue: 4
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["event", "eventvalue", "listentoevent", "passvalue"];
	}

	get event() {
		return this.$$.ctx[1];
	}

	set event(event) {
		this.$$set({ event });
		flush();
	}

	get eventvalue() {
		return this.$$.ctx[2];
	}

	set eventvalue(eventvalue) {
		this.$$set({ eventvalue });
		flush();
	}

	get listentoevent() {
		return this.$$.ctx[3];
	}

	set listentoevent(listentoevent) {
		this.$$set({ listentoevent });
		flush();
	}

	get passvalue() {
		return this.$$.ctx[4];
	}

	set passvalue(passvalue) {
		this.$$set({ passvalue });
		flush();
	}
}

customElements.define("event-dispatcher", EventDispatcher);
export default EventDispatcher;