/* src\components\ViewChoiceOverlay\ViewChoiceOverlay.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element as element_1,
	empty,
	flush,
	globals,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text,
	toggle_class,
	validate_each_argument,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../bcShim.js';
import { onMount, tick } from '../../../web_modules/svelte.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import "../../interfaces.js";

import {
	addValuesToAdvancedFilter,
	createFilter
} from "../../utilities/filterUtils.js";

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute,
	singletonComponentExists
} from "../../utilities/componentUtils.js";

import { notify } from "../../utilities/alertNotificationUtils.js";
import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\ViewChoiceOverlay\\ViewChoiceOverlay.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[34] = list[i];
	return child_ctx;
}

// (2229:4) {#if ready}
function create_if_block(ctx) {
	let if_block_anchor;
	let if_block = /*showViewSelection*/ ctx[3] && create_if_block_1(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*showViewSelection*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2229:4) {#if ready}",
		ctx
	});

	return block;
}

// (2230:8) {#if showViewSelection}
function create_if_block_1(ctx) {
	let full_overlay;
	let mounted;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (/*possibleViews*/ ctx[2].length > 1) return create_if_block_2;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, [-1, -1]);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			full_overlay = element_1("full-overlay");
			if_block.c();
			set_custom_element_data(full_overlay, "fullscreen", "false");
			add_location(full_overlay, file, 2230, 12, 44516);
		},
		m: function mount(target, anchor) {
			insert_dev(target, full_overlay, anchor);
			if_block.m(full_overlay, null);

			if (!mounted) {
				dispose = listen_dev(full_overlay, "close", /*close_handler*/ ctx[16], false, false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(full_overlay, null);
				}
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(full_overlay);
			if_block.d();
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2230:8) {#if showViewSelection}",
		ctx
	});

	return block;
}

// (2250:16) {:else}
function create_else_block(ctx) {
	let loading_spinner;
	let loading_spinner_text_value;

	const block = {
		c: function create() {
			loading_spinner = element_1("loading-spinner");
			set_custom_element_data(loading_spinner, "text", loading_spinner_text_value = /*componentText*/ ctx[4].loadingTagetViewChoice);
			add_location(loading_spinner, file, 2250, 20, 45478);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2250:16) {:else}",
		ctx
	});

	return block;
}

// (2238:16) {#if possibleViews.length > 1}
function create_if_block_2(ctx) {
	let ul;
	let each_value = /*possibleViews*/ ctx[2];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			ul = element_1("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr_dev(ul, "class", "m-0 p-0 overflow-auto h-full");
			attr_dev(ul, "slot", "content");
			add_location(ul, file, 2238, 20, 44810);
		},
		m: function mount(target, anchor) {
			insert_dev(target, ul, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(ul, null);
				}
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*moveToEntity, possibleViews*/ 68) {
				each_value = /*possibleViews*/ ctx[2];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(ul);
			destroy_each(each_blocks, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(2238:16) {#if possibleViews.length > 1}",
		ctx
	});

	return block;
}

// (2240:24) {#each possibleViews as view}
function create_each_block(ctx) {
	let li;
	let t0_value = /*view*/ ctx[34].TY_LONGNAME + "";
	let t0;
	let t1;
	let mounted;
	let dispose;

	function click_handler(...args) {
		return /*click_handler*/ ctx[15](/*view*/ ctx[34], ...args);
	}

	const block = {
		c: function create() {
			li = element_1("li");
			t0 = text(t0_value);
			t1 = space();
			attr_dev(li, "class", "list-none p-2 border-b border-r-0 border-t-0 border-l-0 border-solid border-gray-200 hover:bg-gray-50 cursor-pointer font-bold");
			add_location(li, file, 2240, 28, 44951);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, t0);
			append_dev(li, t1);

			if (!mounted) {
				dispose = listen_dev(li, "click", click_handler, false, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty[0] & /*possibleViews*/ 4 && t0_value !== (t0_value = /*view*/ ctx[34].TY_LONGNAME + "")) set_data_dev(t0, t0_value);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(2240:24) {#each possibleViews as view}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[0] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			toggle_class(div, "hidden", !/*showViewSelection*/ ctx[3]);
			add_location(div, file, 2227, 0, 44393);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[17](div);
		},
		p: function update(ctx, dirty) {
			if (/*ready*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty[0] & /*showViewSelection*/ 8) {
				toggle_class(div, "hidden", !/*showViewSelection*/ ctx[3]);
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[17](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(14, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('view-choice-overlay', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { entityactionguid = '' } = $$props;
	let { navigationtopic = 'default' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	const generalText = getComponentText('general');
	const componentText = getComponentText('viewChoiceOverlay');
	let mounted = false;
	let ready = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let possibleViews = [];
	let showViewSelection = false;
	let entity;
	let componentId;
	let entityguid = '';
	let actionGuid = '';
	let actionViewGuid = '';
	let guidsArray = [];

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(13, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(12, mounted = true);
			});
		});

		return () => {
			
		};
	});

	const initComponent = async () => {
		$$invalidate(11, firstInitialized = true);

		if (!singletonComponentExists('VIEW-CHOICE-OVERLAY', true)) {
			// Reset variables
			$$invalidate(0, ready = false);

			$$invalidate(2, possibleViews = []);
			$$invalidate(3, showViewSelection = false);
			componentId = uuidv4();
			bc.messageService.subscribe('ANALYSE_ENTITY_GUIDS', componentId, analyseEntityGuids);
			$$invalidate(0, ready = true);
		}
	};

	// Component functions
	//================================================================
	const hideViewChoiceOverlay = () => {
		$$invalidate(3, showViewSelection = false);
		guidsArray = [];
	};

	// For navigation in Actionview in Dashboard
	const analyseEntityActionGuid = async entityactionguid => {
		if (entityactionguid !== '') {
			let entityGuid = entityactionguid.split(';')[0];
			actionGuid = entityactionguid.split(';')[1];
			actionViewGuid = entityactionguid.split(';')[2];
			await analyseEntityGuids(entityGuid);
		}
	};

	const analyseEntityGuids = async guids => {
		guidsArray = guids.split(';');
		entityguid = guidsArray[0];
		let entityRequest = await bc.metadataManager.fetchUgdmCoreUgdmEntitiesById(entityguid);
		console.log('entityguid', entityguid, entityRequest);

		if (entityRequest && entityRequest.value) {
			entity = entityRequest.value[0];
			let views = await bc.metadataManager.fetchPrimaryViewsForEntityType(entity['EY_TYPE']);
			views = filterGoToViews(views);
			console.log('views', views);

			if (views.length === 1) {
				await navigate(views[0].TY_ID, entity['EY_ID']);
			} else {
				await analysePossibleViews(views);
			}
		} else {
			console.error('View-Choice-Overlay', componentText.noEntityFound, entityguid);
			notify(componentText.noEntityFound, componentText.noEntityDetails, 5000);
		}
	};

	const filterGoToViews = viewsArray => {
		const dataViews = bc.parameterService.get('DATAVIEWS');

		return viewsArray.filter(v => {
			if (dataViews.find(dv => dv.TY_ID === v.TY_ID && dv.canRead === true)) {
				return v;
			}
		});
	};

	const getCountForViews = async (views, entityGuid) => {
		const countRequests = views.map(v => bc.metadataManager.fetchJsonData({
			filters: [
				{
					filterExpression: -1,
					filterExpressionJoinType: 'and',
					joinType: 'and',
					column: v.TY_ACRONYM + '_ID',
					frontName: 'Entitätsidentificator',
					comparator: '=',
					comparatorFront: '=',
					intable: false,
					type: 'guid',
					values: [{ name: entityGuid, value: entityGuid }],
					valid: true,
					current: false,
					filterString: ''
				}
			],
			resourceName: v.TY_NAME,
			inlinecount: 'allpages',
			top: 1,
			offset: 0,
			orderBy: [],
			typeDomainOrDmParent: null,
			entityGuid: null,
			rlType: null,
			rlFromItemguid: null,
			rlDirection: null
		}));

		return Promise.all(countRequests);
	};

	const analysePossibleViews = async views => {
		$$invalidate(2, possibleViews = []);
		const countResponses = await getCountForViews(views, entityguid);

		for (let i = 0; i < views.length; i++) {
			if (countResponses[i]) {
				let count = countResponses[i]['Count'];

				if (count > 0) {
					$$invalidate(2, possibleViews = [...possibleViews, views[i]]);
				}
			}
		}

		if (possibleViews.length === 0) {
			console.error('View-Choice-Overlay', componentText.noDataviewFound, entityguid);
			notify(componentText.noDataviewFound, componentText.noDataviewDetails, 5000);
		}

		if (possibleViews.length === 1) {
			await navigate(possibleViews[0].TY_ID, entityguid);
		}

		if (possibleViews.length > 1) {
			$$invalidate(3, showViewSelection = true);
		}
	};

	const navigate = async (viewId, entityId) => {
		if (guidsArray.length === 1) {
			let router = bc.utils.getRouter();
			let routes = router.getRoutes();
			let viewName = bc.utils.getViewName(viewId);
			let newRoute = routes.find(r => r.type === 'entity').create({ viewName, entityId });
			router.navigateTo(newRoute);
		} else {
			let viewMetadata = await bc.parameterService.getMetadata(viewId);
			let filters = [];

			for (let i = 0; i < guidsArray.length; i++) {
				let filter = createFilter({
					filterExpressionNumber: 1,
					filterExpressionJoinType: 'or',
					columnName: viewMetadata.idProperty,
					joinType: 'or',
					metadata: viewMetadata,
					comparator: '=',
					intable: false
				});

				addValuesToAdvancedFilter(filter, [
					{
						name: guidsArray[i],
						value: guidsArray[i]
					}
				]);

				filters.push(filter);
			}

			let router = bc.utils.getRouter();
			let routes = router.getRoutes();
			let viewName = bc.utils.getViewName(viewId);
			let newRoute = routes.find(r => r.type === 'view').create({ viewName });
			router.navigateTo(newRoute, { filters, orderBys: [] });
		}
	};

	const moveToEntity = (e, viewId) => {
		navigate(viewId, entityguid);
		$$invalidate(3, showViewSelection = false);
	};

	const writable_props = ['show', 'init', 'entityactionguid', 'navigationtopic'];

	

	const click_handler = (view, e) => {
		moveToEntity(e, view.TY_ID);
	};

	const close_handler = e => {
		e.stopPropagation();
		hideViewChoiceOverlay();
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(7, show = $$props.show);
		if ('init' in $$props) $$invalidate(8, init = $$props.init);
		if ('entityactionguid' in $$props) $$invalidate(9, entityactionguid = $$props.entityactionguid);
		if ('navigationtopic' in $$props) $$invalidate(10, navigationtopic = $$props.navigationtopic);
	};

	$$self.$capture_state = () => ({
		bc,
		onMount,
		tick,
		uuidv4,
		addValuesToAdvancedFilter,
		createFilter,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		singletonComponentExists,
		notify,
		applicationReady,
		getComponentText,
		show,
		init,
		entityactionguid,
		navigationtopic,
		firstInitialized,
		subscribeGuid,
		generalText,
		componentText,
		mounted,
		ready,
		canReload,
		component,
		element,
		possibleViews,
		showViewSelection,
		entity,
		componentId,
		entityguid,
		actionGuid,
		actionViewGuid,
		guidsArray,
		initComponent,
		hideViewChoiceOverlay,
		analyseEntityActionGuid,
		analyseEntityGuids,
		filterGoToViews,
		getCountForViews,
		analysePossibleViews,
		navigate,
		moveToEntity,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(7, show = $$props.show);
		if ('init' in $$props) $$invalidate(8, init = $$props.init);
		if ('entityactionguid' in $$props) $$invalidate(9, entityactionguid = $$props.entityactionguid);
		if ('navigationtopic' in $$props) $$invalidate(10, navigationtopic = $$props.navigationtopic);
		if ('firstInitialized' in $$props) $$invalidate(11, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('mounted' in $$props) $$invalidate(12, mounted = $$props.mounted);
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('canReload' in $$props) $$invalidate(26, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(13, component = $$props.component);
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
		if ('possibleViews' in $$props) $$invalidate(2, possibleViews = $$props.possibleViews);
		if ('showViewSelection' in $$props) $$invalidate(3, showViewSelection = $$props.showViewSelection);
		if ('entity' in $$props) entity = $$props.entity;
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('entityguid' in $$props) entityguid = $$props.entityguid;
		if ('actionGuid' in $$props) actionGuid = $$props.actionGuid;
		if ('actionViewGuid' in $$props) actionViewGuid = $$props.actionViewGuid;
		if ('guidsArray' in $$props) guidsArray = $$props.guidsArray;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*show, component*/ 8320) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[0] & /*$applicationReady, mounted, init, firstInitialized*/ 22784) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}

		if ($$self.$$.dirty[0] & /*entityactionguid*/ 512) {
			$: analyseEntityActionGuid(entityactionguid);
		}
	};

	return [
		ready,
		element,
		possibleViews,
		showViewSelection,
		componentText,
		hideViewChoiceOverlay,
		moveToEntity,
		show,
		init,
		entityactionguid,
		navigationtopic,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		click_handler,
		close_handler,
		div_binding
	];
}

class ViewChoiceOverlay extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.hover\\:bg-gray-50:hover{--tw-bg-opacity:1;background-color:rgba(249, 250, 251, var(--tw-bg-opacity))}.border-gray-200{--tw-border-opacity:1;border-color:rgba(229, 231, 235, var(--tw-border-opacity))}.border-solid{border-style:solid}.border-b{border-bottom-width:1px}.border-r-0{border-right-width:0px}.border-t-0{border-top-width:0px}.border-l-0{border-left-width:0px}.cursor-pointer{cursor:pointer}.hidden{display:none}.font-bold{font-weight:700}.h-full{height:100%}.list-none{list-style-type:none}.m-0{margin:0px}.overflow-auto{overflow:auto}.p-0{padding:0px}.p-2{padding:0.5rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 7,
				init: 8,
				entityactionguid: 9,
				navigationtopic: 10
			},
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "entityactionguid", "navigationtopic"];
	}

	get show() {
		return this.$$.ctx[7];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[8];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get entityactionguid() {
		return this.$$.ctx[9];
	}

	set entityactionguid(entityactionguid) {
		this.$$set({ entityactionguid });
		flush();
	}

	get navigationtopic() {
		return this.$$.ctx[10];
	}

	set navigationtopic(navigationtopic) {
		this.$$set({ navigationtopic });
		flush();
	}
}

customElements.define("view-choice-overlay", ViewChoiceOverlay);
export default ViewChoiceOverlay;