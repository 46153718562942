import {PARAMS, parameterService, ParameterServiceInterface} from './parameterService'
import {messageService, MessageServiceInterface} from './messageService'

export interface RequestHeaderList {
    jsonHead: Request;
    imageHead: Request;
    jsonGet: Request;
    xmlGet: Request;
    htmlGet: Request;
    imageGet: Request;
    jsonPost: Request;
    jsonDelete: Request;
}

export const requestHeaders: RequestHeaderList = {

    jsonHead: {
        method: 'HEAD',
        headers: {
            Accept: 'application/json; charset=utf-8',
        },
    },

    imageHead: {
        method: 'HEAD',
        headers: {
            Accept: 'image/png,image/jpeg,image/jpg,image/svg,image/svg+xml,image/tif,image/tiff,image/bmp,image/gif',
        },
    },

    jsonGet: {
        method: 'GET',
        headers: {
            Accept: 'application/json; charset=utf-8',
        },
    },

    xmlGet: {
        method: 'GET',
        // dataType: 'text',
        headers: {
            Accept: 'text/xml; application/xml;',
            // 'Content-Type': 'application/xml; charset=utf-8'
            // 'Accept': 'text/xml; charset=utf-8'
        },
    },

    htmlGet: {
        method: 'GET',
        dataType: 'html',
        headers: {
            Accept: 'text/html; charset=utf-8, text/plain; charset=utf-8, text/html, text/plain',
        },
    },

    imageGet: {
        method: 'GET',
        headers: {
            Accept: 'image/png,image/jpeg,image/jpg,image/svg,image/svg+xml,image/tif,image/tiff,image/bmp,image/gif',
        },
    },

    jsonPost: {
        method: 'POST',
        headers: {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8',
        },
    },

    jsonDelete: {
        method: 'DELETE',
        headers: {
            Accept: 'application/json; charset=utf-8',
        },
    },
}

export interface Request {
    method: 'GET' | 'POST' | 'DELETE' | 'HEAD'
    dataType?: 'text' | 'html'
    body?: string
    headers: {
        [key: string]: any
    }
    signal?: AbortSignal
}

export interface AjaxServiceInterface {
    requestHeaders: RequestHeaderList;
    controler: AbortController;
    setTokenHeader: (request: Request) => void;
    fetchWithCallback: (header: Request, url: string, callback: Function, errorCallback: Function) => void;
    fetch: (header: Request, url: string) => Promise<any>; // await!
    fetchHead: (header: Request, url: string) => Promise<any>; // await!

    fetchRawWithCallback: (header: Request, url: string, callback: Function, errorCallback: Function) => void;
    fetchRaw: (header: Request, url: string) => Promise<any>; // await!

    // launch an OData query to a BryteCube data service and fetch result as an array
    fetchViewData: (viewName: string, filter: string, relationshipFilter: string, spatialFilter: string, orderBy: string, top: number, skip: number, countOnly: boolean) => Promise<any>;
    
    fetchParameter: (parameter: string, header: Request, url: string) => Promise<any>; // await!;
    fetchPost: (header: Request, url: string, data: string) => Promise<any>; // await!
    fetchDelete: (header: Request, url: string) => Promise<any>; // await!
    fetchNextId: (keyName, keyGroup, length) => Promise<string>; // await!
    fetchPostAny: (header: Request, url: string, data: any) => Promise<any>; // await!
}

const createAjaxService = function (
    parameterService: ParameterServiceInterface,
    messageService: MessageServiceInterface,
    reqHeaders: RequestHeaderList,
): AjaxServiceInterface {
    const setTokenHeader = (request: Request) => {
        let token = parameterService.get(PARAMS.user.bearerToken)
        if (token) {
            if (!request.headers) {
                request.headers = {}
            }
            request.headers.Authorization = 'Bearer ' + token
        }
        // Chrome does not allow CORS on localhost sites
        request.headers.origin = ''
    }


    const controler = new AbortController()
    const signal = controler.signal

    return {
        controler: controler,
        requestHeaders: reqHeaders,
        setTokenHeader: setTokenHeader,
        fetchWithCallback: (header: Request, url: string, callback: Function, errorCallback: Function) => {
            setTokenHeader(header)
            fetch(url, header)
                .then((response) => {
                    callback(response['data'])
                })
                .catch((error) => {
                    errorCallback(error)
                })
        },

        fetch: async (header: Request, url: string): Promise<any> => {

            try {
                await fetch(url, header)
                let result = await fetch(url, header)
                if (!result.ok) {
                    await throwErrow(result)
                }
                return result['data']
            } catch (error) {
                throw error
            }
        },

        fetchHead: async (header: Request, url: string): Promise<any> => {

            try {
                await fetch(url, header)
                let result = await fetch(url, header);
                return result;
            } catch (error) {
                throw error
            }
        },

        fetchPost: async (header: Request, url: string, data: string): Promise<any> => {
            setTokenHeader(header)
            header.body = data
            try {
                let result = await fetch(url, header)
                if (!result.ok) {
                    await throwErrow(result)
                }
                return result
            } catch (error) {
                throw error
            }
        },

        fetchPostAny: async (header: Request, url: string, data: any): Promise<any> => {
            setTokenHeader(header)
            header.body = data
            try {
                let result = await fetch(url, header)
                if (!result.ok) {
                    await throwErrow(result)
                }
                return result
            } catch (error) {
                throw error
            }
        },

        fetchDelete: async (header: Request, url: string): Promise<any> => {
            setTokenHeader(header)
            try {
                let result = await fetch(url, header)
                if (!result.ok) {
                    await throwErrow(result)
                }
                return result
            } catch (error) {
                throw error
            }
        },

        fetchRawWithCallback: async (header: Request, url: string, callback: Function, errorCallback: Function): Promise<any> => {
            setTokenHeader(header)

            fetch(url, header)
                .then((response) => {
                    callback(response)
                })
                .catch((error) => {
                    errorCallback(error)
                })
        },

        fetchRaw: async (header: Request, url: string): Promise<any> => {

            console.log("fetchRaw: " + url)
            
            setTokenHeader(header)
            header.signal = controler.signal
            try {
                const result = await fetch(url, header)
                if (!result.ok && result.status !== 404) {
                    await throwErrow(result)
                }
                if (result.status !== 200 && result.status !== 404) {
                    await throwErrow(result)
                }
                return result
            } catch (error) {
                throw error
            }
        },

        fetchViewData: async (viewName: string, filter: string, relationshipFilter: string, spatialFilter: string, orderBy: string, top: number = null, skip: number = null, countOnly: boolean = false): Promise<any>  => {

            // the URL of the data service and the view
            let url = parameterService.appParameters.ApplicationDataServiceBaseUrl + viewName;
       
            // first ?
            if (countOnly === true) {
                top = 1;
                skip = null;
                url += '?$count=true'
            } else {
                if(parameterService.appParameters.serverVersion >= "4.0") {

                } else {
                    url += '?$inlinecount=allpages';
                }
                
            }
       
            if (filter && filter.length > 0) {
                url += '&$filter=' + filter;
            }

            if (relationshipFilter && relationshipFilter.length > 0) {
                url += '&$relationshipfilter=' + relationshipFilter;
            }

            if (spatialFilter && spatialFilter.length > 0) {
                url += '&$spatialfilter=' + spatialFilter;
            }
       
            if (orderBy && orderBy.length > 0) {
                url += '&$orderby=' + orderBy;
            }
            
            if (top !== undefined && top !== null) {
                if (skip !== undefined && skip !== null) {
                    url += '&$top=' + top + '&$skip=' + skip;
                } else {
                    url += '&$top=' + top;
                }
            }
       
            // fetch the data
            let response = await ajaxService.fetchRaw(ajaxService.requestHeaders.jsonGet, url)

            if (response && response.ok) {
                console.log(response)
                // transform JSON to Javascript object
                let jsonAnswer = await response.json()

                if (countOnly === true) {
                    return jsonAnswer.Count;
                }
       
                if (jsonAnswer.Items) { // TODO Items could change with new protocol!!!
                    
                    if (jsonAnswer.Items.length > 0) {
                        return jsonAnswer.Items;
                    }
                }
            } else {
                // 404 for "not found"
                if (countOnly === true) {
                    return 0;
                }

                console.log("Problems with data query " + url, response)
            }
       
            return [];
        },

        fetchNextId: async (keyName, keyGroup, length): Promise<string> => {
            let urlCall = parameterService.get(PARAMS.urls.OdataBaseUrl) + 'NextId?KeyName=' + keyName + '&KeyGroup=' + keyGroup + '&length=' + length
            try {
                const result = await fetch(urlCall, requestHeaders.jsonGet)
                if (!result.ok) {
                    await throwErrow(result)
                }
                let nextId = await result.json();
                return nextId as string
            } catch (error) {
                throw error
            }
        },

        fetchParameter: async (parameter: string, header: Request, url: string) => {
            setTokenHeader(header)
            const result = await fetch(url, header);
            if (!result.ok) {
                parameterService.set(parameter, null)
                await throwErrow(result)
            }
            let jsonResponse = await result.json();
            console.log(parameter, jsonResponse);
            parameterService.set(parameter, jsonResponse);
                
        },


    }
}

const throwErrow = async (result: Response) => {
    try {
        const contentType = result.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            let body = await result.json()
            if (!body) {
                throw {
                    status: result.status,
                    message: result.statusText,
                    url: result.url
                }
            } else {
                body.custom = result.status + ' \n' + result.statusText + ' \n' + result.url
                throw body
            }
        }else{
            let body = await result.text()
            if (!body) {
                throw {
                    status: result.status,
                    message: result.statusText,
                    url: result.url
                }
            } else {
                throw result.status + ' \n' + result.statusText + ' \n' + result.url+'\n'+ body
            }
        }
    } catch (error) {
        throw error
    }
}
export const ajaxService = createAjaxService(parameterService, messageService, requestHeaders)
