/* src\components\BackDrop\BackDrop.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text as text_1,
	validate_slots
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	dispatchMountedEvent,
	singletonComponentExists
} from "../../utilities/componentUtils.js";

import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\BackDrop\\BackDrop.svelte";

// (2089:0) {#if showBackdrop}
function create_if_block(ctx) {
	let div2;
	let div1;
	let div0;
	let fa_icon;
	let t0;
	let p;
	let t1;

	const block = {
		c: function create() {
			div2 = element_1("div");
			div1 = element_1("div");
			div0 = element_1("div");
			fa_icon = element_1("fa-icon");
			t0 = space();
			p = element_1("p");
			t1 = text_1(/*text*/ ctx[2]);
			set_custom_element_data(fa_icon, "icon", "fas fa-circle-notch fa-spin");
			set_custom_element_data(fa_icon, "size", "1");
			set_custom_element_data(fa_icon, "lineheight", "0.8");
			add_location(fa_icon, file, 2092, 16, 39056);
			add_location(p, file, 2093, 16, 39154);
			attr_dev(div0, "class", "container p-2 flex gap-2 items-center justify-center");
			add_location(div0, file, 2091, 12, 38972);
			attr_dev(div1, "class", "bg-white rounded p-4");
			add_location(div1, file, 2090, 8, 38924);
			attr_dev(div2, "class", "backdrop fixed top-0 left-0 w-screen h-screen flex items-center justify-center");
			add_location(div2, file, 2089, 4, 38822);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div2, anchor);
			append_dev(div2, div1);
			append_dev(div1, div0);
			append_dev(div0, fa_icon);
			append_dev(div0, t0);
			append_dev(div0, p);
			append_dev(p, t1);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*text*/ 4) set_data_dev(t1, /*text*/ ctx[2]);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div2);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2089:0) {#if showBackdrop}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*showBackdrop*/ ctx[1] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 2087, 0, 38771);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[5](div);
		},
		p: function update(ctx, [dirty]) {
			if (/*showBackdrop*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[5](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('back-drop', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;

	// Base Variables
	//==============================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let showBackdrop = false;
	let text = generalText.loading;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			component = element.getRootNode().host || element.parentNode; // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				mounted = true;

				// individual code
				if (!singletonComponentExists('BACK-DROP', true)) {
					bc.messageService.subscribe('SHOW_BACKDROP', subscribeGuid, handleShowBackdrop);
					bc.messageService.subscribe('HIDE_BACKDROP', subscribeGuid, hideShowBackdrop);
				}
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	// Component functions
	//================================================================
	const handleShowBackdrop = newText => {
		if (newText) {
			$$invalidate(2, text = newText);
		} else {
			$$invalidate(2, text = generalText.loading);
		}

		$$invalidate(1, showBackdrop = true);
	};

	const hideShowBackdrop = () => {
		$$invalidate(1, showBackdrop = false);
	};

	const writable_props = ['show', 'init'];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(0, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(3, show = $$props.show);
		if ('init' in $$props) $$invalidate(4, init = $$props.init);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		dispatchMountedEvent,
		singletonComponentExists,
		getComponentText,
		show,
		init,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		component,
		element,
		showBackdrop,
		text,
		handleShowBackdrop,
		hideShowBackdrop
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(3, show = $$props.show);
		if ('init' in $$props) $$invalidate(4, init = $$props.init);
		if ('firstInitialized' in $$props) firstInitialized = $$props.firstInitialized;
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) ready = $$props.ready;
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) $$invalidate(0, element = $$props.element);
		if ('showBackdrop' in $$props) $$invalidate(1, showBackdrop = $$props.showBackdrop);
		if ('text' in $$props) $$invalidate(2, text = $$props.text);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [element, showBackdrop, text, show, init, div_binding];
}

class BackDrop extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.backdrop{background-color:#424242bf;z-index:999}.container{width:100%}@media(min-width: 1536px){.container{max-width:1536px}}.bg-white{--tw-bg-opacity:1;background-color:rgba(255, 255, 255, var(--tw-bg-opacity))}.rounded{border-radius:0.25rem}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.h-screen{height:100vh}.p-4{padding:1rem}.p-2{padding:0.5rem}.fixed{position:fixed}.top-0{top:0px}.left-0{left:0px}.w-screen{width:100vw}.gap-2{grid-gap:0.5rem;gap:0.5rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 3, init: 4 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init"];
	}

	get show() {
		return this.$$.ctx[3];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[4];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}
}

customElements.define("back-drop", BackDrop);
export default BackDrop;