/* src\components\Infra3D\Infra3D.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	globals,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	set_style,
	space,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\Infra3D\\Infra3D.svelte";

// (2214:37) 
function create_if_block_2(ctx) {
	let div1;
	let div0;
	let p;

	const block = {
		c: function create() {
			div1 = element_1("div");
			div0 = element_1("div");
			p = element_1("p");
			p.textContent = `${/*generalText*/ ctx[5].componentInitError}`;
			add_location(p, file, 2216, 20, 44271);
			attr_dev(div0, "class", "flex items-center justify-center bg-gray-50 border border-solid border-gray-300 rounded p-2");
			add_location(div0, file, 2215, 16, 44144);
			attr_dev(div1, "class", "p-2");
			add_location(div1, file, 2214, 12, 44109);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div0, p);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(2214:37) ",
		ctx
	});

	return block;
}

// (2212:38) 
function create_if_block_1(ctx) {
	let loading_spinner;
	let loading_spinner_text_value;

	const block = {
		c: function create() {
			loading_spinner = element_1("loading-spinner");
			set_custom_element_data(loading_spinner, "text", loading_spinner_text_value = /*generalText*/ ctx[5].loading);
			add_location(loading_spinner, file, 2212, 12, 44011);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2212:38) ",
		ctx
	});

	return block;
}

// (2210:8) {#if ready}
function create_if_block(ctx) {
	const block = { c: noop, m: noop, p: noop, d: noop };

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2210:8) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div6;
	let div5;
	let div4;
	let p;
	let t1;
	let div3;
	let div1;
	let div0;
	let span;
	let label;
	let t3;
	let select;
	let option0;
	let option1;
	let option2;
	let t7;
	let input;
	let t8;
	let div2;
	let t9;

	function select_block_type(ctx, dirty) {
		if (/*ready*/ ctx[1]) return create_if_block;
		if (/*initError*/ ctx[2] === false) return create_if_block_1;
		if (/*initError*/ ctx[2] === true) return create_if_block_2;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type && current_block_type(ctx);

	const block = {
		c: function create() {
			div6 = element_1("div");
			div5 = element_1("div");
			div4 = element_1("div");
			p = element_1("p");
			p.textContent = `${/*componentText*/ ctx[6].name}`;
			t1 = space();
			div3 = element_1("div");
			div1 = element_1("div");
			div0 = element_1("div");
			span = element_1("span");
			label = element_1("label");
			label.textContent = "Geometry type:";
			t3 = space();
			select = element_1("select");
			option0 = element_1("option");
			option0.textContent = "Point";
			option1 = element_1("option");
			option1.textContent = "LineString";
			option2 = element_1("option");
			option2.textContent = "Polygon";
			t7 = space();
			input = element_1("input");
			t8 = space();
			div2 = element_1("div");
			t9 = space();
			if (if_block) if_block.c();
			this.c = noop;
			add_location(p, file, 2191, 12, 43028);
			attr_dev(label, "class", "input-group-text");
			attr_dev(label, "for", "type");
			add_location(label, file, 2196, 24, 43273);
			option0.__value = "Point";
			option0.value = option0.__value;
			add_location(option0, file, 2198, 24, 43429);
			option1.__value = "LineString";
			option1.value = option1.__value;
			add_location(option1, file, 2199, 24, 43491);
			option2.__value = "Polygon";
			option2.value = option2.__value;
			add_location(option2, file, 2200, 24, 43563);
			attr_dev(select, "class", "form-select");
			attr_dev(select, "id", "type");
			add_location(select, file, 2197, 24, 43364);
			attr_dev(input, "class", "form-control");
			attr_dev(input, "type", "button");
			input.value = "Clear";
			attr_dev(input, "id", "draw");
			add_location(input, file, 2202, 24, 43664);
			attr_dev(span, "class", "input-group");
			add_location(span, file, 2195, 20, 43221);
			attr_dev(div0, "class", "col-auto");
			add_location(div0, file, 2194, 20, 43177);
			attr_dev(div1, "class", "row");
			set_style(div1, "width", "700px");
			add_location(div1, file, 2193, 16, 43116);
			add_location(div2, file, 2206, 16, 43829);
			attr_dev(div3, "id", "viewer-container");
			add_location(div3, file, 2192, 12, 43069);
			attr_dev(div4, "class", "p-2");
			add_location(div4, file, 2190, 8, 42997);
			attr_dev(div5, "class", "flex flex-col h-full bg-white");
			add_location(div5, file, 2189, 4, 42922);
			attr_dev(div6, "class", "h-full");
			add_location(div6, file, 2188, 0, 42876);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div6, anchor);
			append_dev(div6, div5);
			append_dev(div5, div4);
			append_dev(div4, p);
			append_dev(div4, t1);
			append_dev(div4, div3);
			append_dev(div3, div1);
			append_dev(div1, div0);
			append_dev(div0, span);
			append_dev(span, label);
			append_dev(span, t3);
			append_dev(span, select);
			append_dev(select, option0);
			append_dev(select, option1);
			append_dev(select, option2);
			append_dev(span, t7);
			append_dev(span, input);
			append_dev(div3, t8);
			append_dev(div3, div2);
			/*div2_binding*/ ctx[16](div2);
			append_dev(div5, t9);
			if (if_block) if_block.m(div5, null);
			/*div5_binding*/ ctx[17](div5);
			/*div6_binding*/ ctx[18](div6);
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if (if_block) if_block.d(1);
				if_block = current_block_type && current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div5, null);
				}
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div6);
			/*div2_binding*/ ctx[16](null);

			if (if_block) {
				if_block.d();
			}

			/*div5_binding*/ ctx[17](null);
			/*div6_binding*/ ctx[18](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(15, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('infra-3d', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { url = "https://auth-classic.infra3d.com/oauth2/token" } = $$props;
	let { clientid = "1ipc8lbk3o95v7ipe10akb3rj7" } = $$props;
	let { clientsecret = "703ag5lc3pd2b3aj0o2q4ud4umt4urmaoop1rl5s2qo653p6ncu" } = $$props;
	let { viewerelementid = 'infra3d-viewer-id' } = $$props;

	// Base variables
	//================================================================
	let container;

	let firstInitialized = false;
	let subscribeGuid = uuidv4();
	let ready = false;
	let mounted = false;
	let canReload = false;
	let initError = false;
	const generalText = getComponentText('general');
	const componentText = getComponentText('infra3d'); // TODO set compoent text in component_texts.js
	let viewerElement;
	let viewerParentElement;
	let thisParentElement;
	let viewerManager = null;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this

	// HTML Mount & Component initialization
	//================================================================
	onMount(async () => {
		attachNormalize(component);
		getViewerElement();

		try {
			viewerManager = await loadInfra3dViewer();

			if (!viewerManager) {
				$$invalidate(2, initError = true);
				$$invalidate(1, ready = false);
				return;
			} else {
				$$invalidate(1, ready = true);
				$$invalidate(2, initError = false);
			}
		} catch(ex) {
			console.error(ex);
			$$invalidate(2, initError = true);
			$$invalidate(1, ready = false);
		} // return;

		tick().then(() => {
			dispatchMountedEvent(component);
			$$invalidate(14, mounted = true);
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = async () => {
		$$invalidate(13, firstInitialized = true);
	}; /*
initError = false;
ready = true;
*/

	// Component functions
	//================================================================
	const getViewerElement = () => {
		if (!viewerElement) {
			console.log("Element not bound yet");
			viewerElement = document.getElementById(viewerelementid);

			if (!viewerElement) {
				console.error("Element not found");
			}
		}

		if (viewerElement) {
			if (!viewerParentElement) {
				viewerParentElement = viewerElement.parentElement;
			}

			if (show !== 'true') {
				viewerElement.classList.remove("h-full");
				viewerElement.classList.remove("w-full");
				viewerParentElement.appendChild(viewerElement);
			} else {
				viewerParentElement = viewerElement.parentElement;

				if (thisParentElement) {
					thisParentElement.appendChild(viewerElement);
				}

				viewerElement.classList.add("h-full");
				viewerElement.classList.add("w-full");
			}
		}
	};

	const loadInfra3dViewer = async () => {
		const mytokenresponse = await infra3dapi.getGuestAccessToken();
		console.log("Infra3D Access Token", mytokenresponse);
		getViewerElement(); // must be called also on init (and show?)

		if (!viewerElement) {
			console.error("Element for Infra3D viewer not found");
			return null;
		}

		const manager = await infra3dapi.init(viewerelementid, mytokenresponse.access_token, {
			username: "Guest",
			email: "support@inovitas.ch"
		});

		const fetchedProjects = await manager.getProjects();
		const projectUID = fetchedProjects[0].uid;

		const init_viewer_options = {
			project_uid: projectUID,
			show_toolbar: false,
			show_mapWindow: false,
			show_cockpit: false,
			show_topbar: false,
			toolbar: "view"
		}; // toolbar: "edit"

		return await manager.initViewer(init_viewer_options);
	};

	const writable_props = ['show', 'init', 'url', 'clientid', 'clientsecret', 'viewerelementid'];

	

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			thisParentElement = $$value;
			$$invalidate(3, thisParentElement);
		});
	}

	function div5_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			container = $$value;
			$$invalidate(0, container);
		});
	}

	function div6_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(4, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(7, show = $$props.show);
		if ('init' in $$props) $$invalidate(8, init = $$props.init);
		if ('url' in $$props) $$invalidate(9, url = $$props.url);
		if ('clientid' in $$props) $$invalidate(10, clientid = $$props.clientid);
		if ('clientsecret' in $$props) $$invalidate(11, clientsecret = $$props.clientsecret);
		if ('viewerelementid' in $$props) $$invalidate(12, viewerelementid = $$props.viewerelementid);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		applicationReady,
		getComponentText,
		show,
		init,
		url,
		clientid,
		clientsecret,
		viewerelementid,
		container,
		firstInitialized,
		subscribeGuid,
		ready,
		mounted,
		canReload,
		initError,
		generalText,
		componentText,
		viewerElement,
		viewerParentElement,
		thisParentElement,
		viewerManager,
		component,
		element,
		initComponent,
		getViewerElement,
		loadInfra3dViewer,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(7, show = $$props.show);
		if ('init' in $$props) $$invalidate(8, init = $$props.init);
		if ('url' in $$props) $$invalidate(9, url = $$props.url);
		if ('clientid' in $$props) $$invalidate(10, clientid = $$props.clientid);
		if ('clientsecret' in $$props) $$invalidate(11, clientsecret = $$props.clientsecret);
		if ('viewerelementid' in $$props) $$invalidate(12, viewerelementid = $$props.viewerelementid);
		if ('container' in $$props) $$invalidate(0, container = $$props.container);
		if ('firstInitialized' in $$props) $$invalidate(13, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(1, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(14, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(23, canReload = $$props.canReload);
		if ('initError' in $$props) $$invalidate(2, initError = $$props.initError);
		if ('viewerElement' in $$props) viewerElement = $$props.viewerElement;
		if ('viewerParentElement' in $$props) viewerParentElement = $$props.viewerParentElement;
		if ('thisParentElement' in $$props) $$invalidate(3, thisParentElement = $$props.thisParentElement);
		if ('viewerManager' in $$props) viewerManager = $$props.viewerManager;
		if ('component' in $$props) $$invalidate(24, component = $$props.component);
		if ('element' in $$props) $$invalidate(4, element = $$props.element);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show*/ 128) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
				getViewerElement();
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 57600) {
			/////////////////////////////////////////////////////
			// Init when canReload is true
			////////////////////////////////////////////////////
			/*
    const applyProps = (afterInit?: boolean) =>{
        let shouldInit = (afterInit && firstInitialized) || (!afterInit && !firstInitialized)
        if(shouldInit){
            handleComponentProps({
                ready: $applicationReady,
                componentMounted: mounted,
                init: init,
                firstInitialized: firstInitialized,
                canReload: canReload,
                handler: initComponent,
            }, tabgroupid)
        }
    }

    $: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps)

    $: tabgroupid, applyProps(true)

*/
			/////////////////////////////////////////////////////
			// Init when canReload is false
			////////////////////////////////////////////////////
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		container,
		ready,
		initError,
		thisParentElement,
		element,
		generalText,
		componentText,
		show,
		init,
		url,
		clientid,
		clientsecret,
		viewerelementid,
		firstInitialized,
		mounted,
		$applicationReady,
		div2_binding,
		div5_binding,
		div6_binding
	];
}

class Infra3D extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.bg-white{--tw-bg-opacity:1;background-color:rgba(255, 255, 255, var(--tw-bg-opacity))}.bg-gray-50{--tw-bg-opacity:1;background-color:rgba(249, 250, 251, var(--tw-bg-opacity))}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.h-full{height:100%}.p-2{padding:0.5rem}.col-auto{grid-column:auto}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 7,
				init: 8,
				url: 9,
				clientid: 10,
				clientsecret: 11,
				viewerelementid: 12
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "url", "clientid", "clientsecret", "viewerelementid"];
	}

	get show() {
		return this.$$.ctx[7];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[8];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get url() {
		return this.$$.ctx[9];
	}

	set url(url) {
		this.$$set({ url });
		flush();
	}

	get clientid() {
		return this.$$.ctx[10];
	}

	set clientid(clientid) {
		this.$$set({ clientid });
		flush();
	}

	get clientsecret() {
		return this.$$.ctx[11];
	}

	set clientsecret(clientsecret) {
		this.$$set({ clientsecret });
		flush();
	}

	get viewerelementid() {
		return this.$$.ctx[12];
	}

	set viewerelementid(viewerelementid) {
		this.$$set({ viewerelementid });
		flush();
	}
}

customElements.define("infra-3d", Infra3D);
export default Infra3D;