/* src\components\Backend\AddDatabaseProcedure\AddDatabaseProcedure.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	space,
	validate_slots,
	validate_store
} from "../../../../web_modules/svelte/internal.js";

import bc from '../../../bcShim.js';
import { v4 as uuidv4 } from '../../../../web_modules/uuid.js';
import { onMount, tick } from '../../../../web_modules/svelte.js';
import { constants } from "../../../constants.js";
import { requestHeaders } from "../../../services/ajaxService.js";

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../../utilities/componentUtils.js";

import { createEvent, getComponentText } from "../../../utilities/generalUtils.js";
import { applicationReady } from "../../../utilities/store.js";
const file = "src\\components\\Backend\\AddDatabaseProcedure\\AddDatabaseProcedure.svelte";

// (2355:4) {:else}
function create_else_block(ctx) {
	let loading_spinner;

	const block = {
		c: function create() {
			loading_spinner = element_1("loading-spinner");
			set_custom_element_data(loading_spinner, "text", "Lade...");
			add_location(loading_spinner, file, 2355, 8, 45838);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2355:4) {:else}",
		ctx
	});

	return block;
}

// (2310:4) {#if ready}
function create_if_block(ctx) {
	let div2;
	let div0;
	let text_field0;
	let text_field0_value_value;
	let text_field0_isvalid_value;
	let t0;
	let text_field1;
	let text_field1_value_value;
	let text_field1_isvalid_value;
	let t1;
	let artifact_text_area;
	let artifact_text_area_value_value;
	let artifact_text_area_isvalid_value;
	let t2;
	let div1;
	let bc_button0;
	let t3;
	let bc_button1;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div2 = element_1("div");
			div0 = element_1("div");
			text_field0 = element_1("text-field");
			t0 = space();
			text_field1 = element_1("text-field");
			t1 = space();
			artifact_text_area = element_1("artifact-text-area");
			t2 = space();
			div1 = element_1("div");
			bc_button0 = element_1("bc-button");
			t3 = space();
			bc_button1 = element_1("bc-button");
			set_custom_element_data(text_field0, "value", text_field0_value_value = /*formData*/ ctx[4]['TY_NAME']);
			set_custom_element_data(text_field0, "isvalid", text_field0_isvalid_value = /*nameValid*/ ctx[2].toString());
			set_custom_element_data(text_field0, "fieldtype", "text");
			set_custom_element_data(text_field0, "label", "Name");
			set_custom_element_data(text_field0, "column", "TY_NAME");
			set_custom_element_data(text_field0, "iseditable", "true");
			set_custom_element_data(text_field0, "maxlength", "100");
			set_custom_element_data(text_field0, "isnullable", "false");
			add_location(text_field0, file, 2313, 16, 43984);
			set_custom_element_data(text_field1, "value", text_field1_value_value = /*formData*/ ctx[4]['TY_LONGNAME']);
			set_custom_element_data(text_field1, "isvalid", text_field1_isvalid_value = /*nameValid*/ ctx[2].toString());
			set_custom_element_data(text_field1, "fieldtype", "text");
			set_custom_element_data(text_field1, "label", "Bezeichnung");
			set_custom_element_data(text_field1, "column", "TY_LONGNAME");
			set_custom_element_data(text_field1, "iseditable", "true");
			set_custom_element_data(text_field1, "maxlength", "100");
			set_custom_element_data(text_field1, "isnullable", "false");
			add_location(text_field1, file, 2324, 16, 44461);
			set_custom_element_data(artifact_text_area, "value", artifact_text_area_value_value = /*formData*/ ctx[4]['TY_ARTIFACT']);
			set_custom_element_data(artifact_text_area, "isvalid", artifact_text_area_isvalid_value = /*artifactValid*/ ctx[3].toString());
			set_custom_element_data(artifact_text_area, "haschanged", "false");
			set_custom_element_data(artifact_text_area, "padding", "true");
			set_custom_element_data(artifact_text_area, "label", "Datenbankprozedur");
			set_custom_element_data(artifact_text_area, "column", "TY_ARTIFACT");
			set_custom_element_data(artifact_text_area, "display", "stack");
			set_custom_element_data(artifact_text_area, "isnullable", "false");
			set_custom_element_data(artifact_text_area, "iseditable", "true");
			add_location(artifact_text_area, file, 2335, 16, 44953);
			attr_dev(div0, "class", "flex flex-col");
			add_location(div0, file, 2312, 12, 43939);
			set_custom_element_data(bc_button0, "color", "white");
			set_custom_element_data(bc_button0, "type", "secondary");
			set_custom_element_data(bc_button0, "text", "Abbrechen");
			set_custom_element_data(bc_button0, "icon", "far fa-times");
			add_location(bc_button0, file, 2349, 16, 45575);
			set_custom_element_data(bc_button1, "text", "Speichern");
			set_custom_element_data(bc_button1, "icon", "far fa-save");
			add_location(bc_button1, file, 2350, 16, 45702);
			attr_dev(div1, "class", "flex justify-end gap-2");
			add_location(div1, file, 2348, 12, 45521);
			attr_dev(div2, "class", "flex flex-col gap-4");
			add_location(div2, file, 2311, 8, 43892);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div2, anchor);
			append_dev(div2, div0);
			append_dev(div0, text_field0);
			append_dev(div0, t0);
			append_dev(div0, text_field1);
			append_dev(div0, t1);
			append_dev(div0, artifact_text_area);
			append_dev(div2, t2);
			append_dev(div2, div1);
			append_dev(div1, bc_button0);
			append_dev(div1, t3);
			append_dev(div1, bc_button1);

			if (!mounted) {
				dispose = [
					listen_dev(text_field0, "change", /*change_handler*/ ctx[16], false, false, false, false),
					listen_dev(text_field1, "change", /*change_handler_1*/ ctx[17], false, false, false, false),
					listen_dev(artifact_text_area, "change", /*change_handler_2*/ ctx[18], false, false, false, false),
					listen_dev(bc_button0, "click", /*cancel*/ ctx[6], false, false, false, false),
					listen_dev(bc_button1, "click", /*save*/ ctx[7], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty & /*formData*/ 16 && text_field0_value_value !== (text_field0_value_value = /*formData*/ ctx[4]['TY_NAME'])) {
				set_custom_element_data(text_field0, "value", text_field0_value_value);
			}

			if (dirty & /*nameValid*/ 4 && text_field0_isvalid_value !== (text_field0_isvalid_value = /*nameValid*/ ctx[2].toString())) {
				set_custom_element_data(text_field0, "isvalid", text_field0_isvalid_value);
			}

			if (dirty & /*formData*/ 16 && text_field1_value_value !== (text_field1_value_value = /*formData*/ ctx[4]['TY_LONGNAME'])) {
				set_custom_element_data(text_field1, "value", text_field1_value_value);
			}

			if (dirty & /*nameValid*/ 4 && text_field1_isvalid_value !== (text_field1_isvalid_value = /*nameValid*/ ctx[2].toString())) {
				set_custom_element_data(text_field1, "isvalid", text_field1_isvalid_value);
			}

			if (dirty & /*formData*/ 16 && artifact_text_area_value_value !== (artifact_text_area_value_value = /*formData*/ ctx[4]['TY_ARTIFACT'])) {
				set_custom_element_data(artifact_text_area, "value", artifact_text_area_value_value);
			}

			if (dirty & /*artifactValid*/ 8 && artifact_text_area_isvalid_value !== (artifact_text_area_isvalid_value = /*artifactValid*/ ctx[3].toString())) {
				set_custom_element_data(artifact_text_area, "isvalid", artifact_text_area_isvalid_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div2);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2310:4) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*ready*/ ctx[0]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if_block.c();
			this.c = noop;
			add_location(div, file, 2308, 0, 43838);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if_block.m(div, null);
			/*div_binding*/ ctx[19](div);
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
			/*div_binding*/ ctx[19](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(15, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('add-db-procedure', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { saveevent = "" } = $$props;
	let { cancelevent = "" } = $$props;

	// Base Variables
	const subscribeGuid = uuidv4();

	const generalText = getComponentText('general');
	let firstInitialized = false;
	let mounted = false;
	let ready = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let nameValid = true;
	let longNameValid = true;
	let artifactValid = true;

	const getDefaultFormData = () => {
		return {
			TY_ACRONYM: "",
			TY_APPLICATION: "",
			TY_ARTIFACT: "",
			TY_CREATED: "",
			TY_CULTURE: "",
			TY_DECIMAL: 0,
			TY_DESCRIPTION: "",
			TY_DOMAIN: constants.domain.DATABASE_PROCEDURE,
			TY_ID: uuidv4(),
			TY_IMPLEMENTATION: "",
			TY_LONGNAME: "",
			TY_MODIFIED: "",
			TY_NAME: "",
			TY_NUMERIC: 0,
			TY_PARENT: "",
			TY_REMARKS: ""
		};
	};

	let formData = getDefaultFormData();

	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(14, component = element.getRootNode().host || element.parentNode); // get the component/host

			// typed: (element.getRootNode() as ShadowRoot).host as HTMLElement
			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(13, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const handleOnChange = data => {
		$$invalidate(4, formData[data.column] = data.value, formData);
	};

	const initComponent = () => {
		$$invalidate(12, firstInitialized = true);
		$$invalidate(0, ready = true);
	};

	const cancel = e => {
		if (cancelevent !== '') {
			bc.messageService.publish(cancelevent);
		}

		let cancelEvent = createEvent('cancel', null);
		component.dispatchEvent(cancelEvent);
		$$invalidate(4, formData = getDefaultFormData());
		$$invalidate(2, nameValid = true);
		longNameValid = true;
		$$invalidate(3, artifactValid = true);
	};

	const save = async e => {
		$$invalidate(2, nameValid = formData.TY_NAME !== '');
		longNameValid = formData.TY_LONGNAME !== '';
		$$invalidate(3, artifactValid = formData.TY_ARTIFACT !== '');

		if (formData.TY_NAME !== '' && formData.TY_LONGNAME !== '' && formData.TY_ARTIFACT !== '') {
			const urlAndResourceParams = bc.metadataManager.getResourceUrlAndParameters('UgdmTypes', '');
			let url = urlAndResourceParams.url;
			await bc.ajaxService.fetchPost(requestHeaders.jsonPost, url, JSON.stringify(formData));

			if (saveevent !== '') {
				bc.messageService.publish(saveevent);
			}

			let saveEvent = createEvent('save', null);
			component.dispatchEvent(saveEvent);
			$$invalidate(4, formData = getDefaultFormData());
			$$invalidate(2, nameValid = true);
			longNameValid = true;
			$$invalidate(3, artifactValid = true);
		} else {
			alert('Sie müssen alle notwendigen Felder ausfüllen');
		}
	};

	const writable_props = ['show', 'init', 'saveevent', 'cancelevent'];

	

	const change_handler = e => {
		handleOnChange(e.detail);
	};

	const change_handler_1 = e => {
		handleOnChange(e.detail);
	};

	const change_handler_2 = e => {
		handleOnChange(e.detail);
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(8, show = $$props.show);
		if ('init' in $$props) $$invalidate(9, init = $$props.init);
		if ('saveevent' in $$props) $$invalidate(10, saveevent = $$props.saveevent);
		if ('cancelevent' in $$props) $$invalidate(11, cancelevent = $$props.cancelevent);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		constants,
		requestHeaders,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		createEvent,
		getComponentText,
		applicationReady,
		show,
		init,
		saveevent,
		cancelevent,
		subscribeGuid,
		generalText,
		firstInitialized,
		mounted,
		ready,
		canReload,
		component,
		element,
		nameValid,
		longNameValid,
		artifactValid,
		getDefaultFormData,
		formData,
		handleOnChange,
		initComponent,
		cancel,
		save,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(8, show = $$props.show);
		if ('init' in $$props) $$invalidate(9, init = $$props.init);
		if ('saveevent' in $$props) $$invalidate(10, saveevent = $$props.saveevent);
		if ('cancelevent' in $$props) $$invalidate(11, cancelevent = $$props.cancelevent);
		if ('firstInitialized' in $$props) $$invalidate(12, firstInitialized = $$props.firstInitialized);
		if ('mounted' in $$props) $$invalidate(13, mounted = $$props.mounted);
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('canReload' in $$props) $$invalidate(23, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(14, component = $$props.component);
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
		if ('nameValid' in $$props) $$invalidate(2, nameValid = $$props.nameValid);
		if ('longNameValid' in $$props) longNameValid = $$props.longNameValid;
		if ('artifactValid' in $$props) $$invalidate(3, artifactValid = $$props.artifactValid);
		if ('formData' in $$props) $$invalidate(4, formData = $$props.formData);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 16640) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 45568) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		ready,
		element,
		nameValid,
		artifactValid,
		formData,
		handleOnChange,
		cancel,
		save,
		show,
		init,
		saveevent,
		cancelevent,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		change_handler,
		change_handler_1,
		change_handler_2,
		div_binding
	];
}

class AddDatabaseProcedure extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `*,::before,::after{-webkit-box-sizing:border-box;box-sizing:border-box;border-width:0;border-style:solid;border-color:#e5e7eb}*{--tw-ring-inset:var(--tw-empty,/*!*/ /*!*/);--tw-ring-offset-width:0px;--tw-ring-offset-color:#fff;--tw-ring-color:rgba(59, 130, 246, 0.5);--tw-ring-offset-shadow:0 0 #0000;--tw-ring-shadow:0 0 #0000;--tw-shadow:0 0 #0000}:root{-moz-tab-size:4;-o-tab-size:4;tab-size:4}:-moz-focusring{outline:1px dotted ButtonText}:-moz-ui-invalid{box-shadow:none}::moz-focus-inner{border-style:none;padding:0}::-webkit-inner-spin-button,::-webkit-outer-spin-button{height:auto}::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.justify-end{-webkit-box-pack:end;-ms-flex-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end}.gap-4{grid-gap:1rem;gap:1rem}.gap-2{grid-gap:0.5rem;gap:0.5rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 8,
				init: 9,
				saveevent: 10,
				cancelevent: 11
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "saveevent", "cancelevent"];
	}

	get show() {
		return this.$$.ctx[8];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[9];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get saveevent() {
		return this.$$.ctx[10];
	}

	set saveevent(saveevent) {
		this.$$set({ saveevent });
		flush();
	}

	get cancelevent() {
		return this.$$.ctx[11];
	}

	set cancelevent(cancelevent) {
		this.$$set({ cancelevent });
		flush();
	}
}

customElements.define("add-db-procedure", AddDatabaseProcedure);
export default AddDatabaseProcedure;