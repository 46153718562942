/* src\components\AddUserForm\AddUserForm.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element as element_1,
	empty,
	flush,
	init as init_1,
	insert_dev,
	listen_dev,
	noop,
	prop_dev,
	run_all,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text,
	validate_each_argument,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	allUserSettingManager,
	userSettingManager
} from "../../utilities/userSettingsManager.js";

import "../../../web_modules/svelte/internal.js";

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
import { constants } from '../../constants.js';
const file = "src\\components\\AddUserForm\\AddUserForm.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[27] = list[i];
	return child_ctx;
}

// (2214:4) {:else}
function create_else_block_2(ctx) {
	let loading_spinner;
	let loading_spinner_text_value;

	const block = {
		c: function create() {
			loading_spinner = element_1("loading-spinner");
			set_custom_element_data(loading_spinner, "text", loading_spinner_text_value = /*generalText*/ ctx[6].loading);
			add_location(loading_spinner, file, 2214, 8, 44713);
		},
		m: function mount(target, anchor) {
			insert_dev(target, loading_spinner, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(loading_spinner);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block_2.name,
		type: "else",
		source: "(2214:4) {:else}",
		ctx
	});

	return block;
}

// (2172:4) {#if ready && bc.parameterService.appParameters.userId}
function create_if_block(ctx) {
	let h3;
	let t1;
	let div2;
	let label0;
	let t4;
	let select;
	let t5;
	let div0;
	let label1;
	let t8;
	let input;
	let t9;
	let div1;
	let bc_button;
	let bc_button_text_value;
	let t10;
	let t11;
	let show_if = bc.parameterService.appParameters.roleIds && bc.parameterService.appParameters.roleIds.includes(constants.role.Ugdm_Application_Administrator);
	let mounted;
	let dispose;
	let each_value = /*dataViews*/ ctx[4];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	function select_block_type_1(ctx, dirty) {
		if (/*hasText*/ ctx[5]) return create_if_block_3;
		return create_else_block_1;
	}

	let current_block_type = select_block_type_1(ctx, -1);
	let if_block0 = current_block_type(ctx);
	let if_block1 = show_if && create_if_block_1(ctx);

	const block = {
		c: function create() {
			h3 = element_1("h3");
			h3.textContent = `${/*componentText*/ ctx[7].addForm}`;
			t1 = space();
			div2 = element_1("div");
			label0 = element_1("label");
			label0.textContent = `${/*componentText*/ ctx[7].dataview}:`;
			t4 = space();
			select = element_1("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t5 = space();
			div0 = element_1("div");
			label1 = element_1("label");
			label1.textContent = `${/*componentText*/ ctx[7].actionName}:`;
			t8 = space();
			input = element_1("input");
			t9 = space();
			div1 = element_1("div");
			bc_button = element_1("bc-button");
			t10 = space();
			if_block0.c();
			t11 = space();
			if (if_block1) if_block1.c();
			attr_dev(h3, "class", "text-2xl mb-2");
			add_location(h3, file, 2172, 8, 42322);
			attr_dev(label0, "class", "font-medium roboto-medium");
			add_location(label0, file, 2174, 12, 42433);
			attr_dev(select, "class", "h-10 rounded border border-solid border-gray-300 focus:outline-none p-1");
			add_location(select, file, 2175, 12, 42521);
			attr_dev(label1, "class", "font-medium roboto-medium mb-2");
			add_location(label1, file, 2184, 16, 42938);
			attr_dev(input, "type", "text");
			attr_dev(input, "class", "h-10 rounded border border-solid border-gray-300 p-2 focus:outline-none");
			add_location(input, file, 2185, 16, 43037);
			attr_dev(div0, "class", "flex flex-col mt-2");
			add_location(div0, file, 2183, 12, 42888);
			set_custom_element_data(bc_button, "type", "secondary");
			set_custom_element_data(bc_button, "color", "white");
			set_custom_element_data(bc_button, "size", "normal");
			set_custom_element_data(bc_button, "icon", "fas fa-times");
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*generalText*/ ctx[6].cancel);
			add_location(bc_button, file, 2190, 16, 43348);
			attr_dev(div1, "class", "flex items-center justify-end gap-2 flex-wrap");
			add_location(div1, file, 2188, 12, 43269);
			attr_dev(div2, "class", "flex flex-col gap-2");
			add_location(div2, file, 2173, 8, 42386);
		},
		m: function mount(target, anchor) {
			insert_dev(target, h3, anchor);
			insert_dev(target, t1, anchor);
			insert_dev(target, div2, anchor);
			append_dev(div2, label0);
			append_dev(div2, t4);
			append_dev(div2, select);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(select, null);
				}
			}

			/*select_binding*/ ctx[16](select);
			append_dev(div2, t5);
			append_dev(div2, div0);
			append_dev(div0, label1);
			append_dev(div0, t8);
			append_dev(div0, input);
			/*input_binding*/ ctx[17](input);
			append_dev(div2, t9);
			append_dev(div2, div1);
			append_dev(div1, bc_button);
			append_dev(div1, t10);
			if_block0.m(div1, null);
			append_dev(div1, t11);
			if (if_block1) if_block1.m(div1, null);

			if (!mounted) {
				dispose = [
					listen_dev(input, "keyup", /*keyup_handler*/ ctx[18], false, false, false, false),
					listen_dev(bc_button, "click", /*cancel*/ ctx[9], false, false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty & /*dataViews*/ 16) {
				each_value = /*dataViews*/ ctx[4];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (current_block_type === (current_block_type = select_block_type_1(ctx, dirty)) && if_block0) {
				if_block0.p(ctx, dirty);
			} else {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(div1, t11);
				}
			}

			if (show_if) if_block1.p(ctx, dirty);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(h3);
			if (detaching) detach_dev(t1);
			if (detaching) detach_dev(div2);
			destroy_each(each_blocks, detaching);
			/*select_binding*/ ctx[16](null);
			/*input_binding*/ ctx[17](null);
			if_block0.d();
			if (if_block1) if_block1.d();
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2172:4) {#if ready && bc.parameterService.appParameters.userId}",
		ctx
	});

	return block;
}

// (2178:20) {#if view.canCreate}
function create_if_block_4(ctx) {
	let option;
	let t_value = /*view*/ ctx[27].TY_LONGNAME + "";
	let t;
	let option_value_value;

	const block = {
		c: function create() {
			option = element_1("option");
			t = text(t_value);
			option.__value = option_value_value = /*view*/ ctx[27].TY_ID;
			option.value = option.__value;
			add_location(option, file, 2178, 24, 42743);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
			append_dev(option, t);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*dataViews*/ 16 && t_value !== (t_value = /*view*/ ctx[27].TY_LONGNAME + "")) set_data_dev(t, t_value);

			if (dirty & /*dataViews*/ 16 && option_value_value !== (option_value_value = /*view*/ ctx[27].TY_ID)) {
				prop_dev(option, "__value", option_value_value);
				option.value = option.__value;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_4.name,
		type: "if",
		source: "(2178:20) {#if view.canCreate}",
		ctx
	});

	return block;
}

// (2177:16) {#each dataViews as view}
function create_each_block(ctx) {
	let if_block_anchor;
	let if_block = /*view*/ ctx[27].canCreate && create_if_block_4(ctx);

	const block = {
		c: function create() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*view*/ ctx[27].canCreate) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_4(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d: function destroy(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(2177:16) {#each dataViews as view}",
		ctx
	});

	return block;
}

// (2197:16) {:else}
function create_else_block_1(ctx) {
	let bc_button;
	let bc_button_text_value;

	const block = {
		c: function create() {
			bc_button = element_1("bc-button");
			set_custom_element_data(bc_button, "type", "primary");
			set_custom_element_data(bc_button, "color", "blue");
			set_custom_element_data(bc_button, "size", "normal");
			set_custom_element_data(bc_button, "disabled", "true");
			set_custom_element_data(bc_button, "icon", "fas fa-save");
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*generalText*/ ctx[6].save);
			add_location(bc_button, file, 2197, 20, 43786);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block_1.name,
		type: "else",
		source: "(2197:16) {:else}",
		ctx
	});

	return block;
}

// (2194:16) {#if hasText}
function create_if_block_3(ctx) {
	let bc_button;
	let bc_button_text_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			bc_button = element_1("bc-button");
			set_custom_element_data(bc_button, "type", "primary");
			set_custom_element_data(bc_button, "color", "blue");
			set_custom_element_data(bc_button, "size", "normal");
			set_custom_element_data(bc_button, "icon", "fas fa-save");
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*generalText*/ ctx[6].save);
			add_location(bc_button, file, 2194, 20, 43563);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button, anchor);

			if (!mounted) {
				dispose = listen_dev(bc_button, "click", /*click_handler*/ ctx[19], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_3.name,
		type: "if",
		source: "(2194:16) {#if hasText}",
		ctx
	});

	return block;
}

// (2202:16) {#if (bc.parameterService.appParameters.roleIds && bc.parameterService.appParameters.roleIds.includes(constants.role.Ugdm_Application_Administrator))}
function create_if_block_1(ctx) {
	let if_block_anchor;

	function select_block_type_2(ctx, dirty) {
		if (/*hasText*/ ctx[5]) return create_if_block_2;
		return create_else_block;
	}

	let current_block_type = select_block_type_2(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			if_block.c();
			if_block_anchor = empty();
		},
		m: function mount(target, anchor) {
			if_block.m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type_2(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d: function destroy(detaching) {
			if_block.d(detaching);
			if (detaching) detach_dev(if_block_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2202:16) {#if (bc.parameterService.appParameters.roleIds && bc.parameterService.appParameters.roleIds.includes(constants.role.Ugdm_Application_Administrator))}",
		ctx
	});

	return block;
}

// (2206:20) {:else}
function create_else_block(ctx) {
	let bc_button;
	let bc_button_text_value;

	const block = {
		c: function create() {
			bc_button = element_1("bc-button");
			set_custom_element_data(bc_button, "type", "primary");
			set_custom_element_data(bc_button, "color", "blue");
			set_custom_element_data(bc_button, "size", "normal");
			set_custom_element_data(bc_button, "disabled", "true");
			set_custom_element_data(bc_button, "icon", "fas fa-save");
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*generalText*/ ctx[6].saveForAll);
			add_location(bc_button, file, 2206, 24, 44436);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(2206:20) {:else}",
		ctx
	});

	return block;
}

// (2203:20) {#if hasText}
function create_if_block_2(ctx) {
	let bc_button;
	let bc_button_text_value;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			bc_button = element_1("bc-button");
			set_custom_element_data(bc_button, "type", "primary");
			set_custom_element_data(bc_button, "color", "blue");
			set_custom_element_data(bc_button, "size", "normal");
			set_custom_element_data(bc_button, "icon", "fas fa-save");
			set_custom_element_data(bc_button, "text", bc_button_text_value = /*generalText*/ ctx[6].saveForAll);
			add_location(bc_button, file, 2203, 24, 44196);
		},
		m: function mount(target, anchor) {
			insert_dev(target, bc_button, anchor);

			if (!mounted) {
				dispose = listen_dev(bc_button, "click", /*click_handler_1*/ ctx[20], false, false, false, false);
				mounted = true;
			}
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(bc_button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(2203:20) {#if hasText}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*ready*/ ctx[0] && bc.parameterService.appParameters.userId) return create_if_block;
		return create_else_block_2;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if_block.c();
			this.c = noop;
			attr_dev(div, "class", "w-full");
			add_location(div, file, 2170, 0, 42211);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if_block.m(div, null);
			/*div_binding*/ ctx[21](div);
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
			/*div_binding*/ ctx[21](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(15, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('add-user-form', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	let mounted = false;
	const generalText = getComponentText('general');
	const componentText = getComponentText('addUserAction');
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let viewSelect;
	let nameInput;
	let dataViews;
	let userActions;
	let userActionsAllUsers;
	let hasText = false;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(14, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(13, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = async c => {
		$$invalidate(12, firstInitialized = true);
		$$invalidate(4, dataViews = bc.parameterService.get('DATAVIEWS'));
		userActions = await userSettingManager.getUserSetting('USER_ACTIONS', []);
		userActionsAllUsers = await allUserSettingManager.getUserSetting('USER_ACTIONS', []);
		$$invalidate(0, ready = true);
	};

	// Component functions
	//================================================================
	const addElement = async allUsers => {
		if (nameInput && nameInput.value !== '') {
			let newUserAction = {
				name: nameInput.value,
				type: 'addForm',
				dataviewGuid: viewSelect.value
			};

			if (!allUsers) {
				let existingValues = userActions.UG_VALUE;
				let existingUserAction = existingValues.find(a => a.type === newUserAction.type && a.dataviewGuid === newUserAction.dataviewGuid);

				if (existingUserAction) {
					if (confirm('Dieselbe Useraction existiert schon, überschreiben?')) {
						let newUserActionsArray = [
							...userActions.UG_VALUE.filter(f => f.dataviewGuid !== newUserAction.dataviewGuid),
							newUserAction
						];

						await userSettingManager.setSetting(userActions, newUserActionsArray);
					}
				} else {
					let newUserActionsArray = [...userActions.UG_VALUE, newUserAction];
					await userSettingManager.setSetting(userActions, newUserActionsArray);
				}
			} else {
				let existingValues = userActionsAllUsers.UG_VALUE;
				let existingUserAction = existingValues.find(a => a.type === newUserAction.type && a.dataviewGuid === newUserAction.dataviewGuid);

				if (existingUserAction) {
					if (confirm('Dieselbe Useraction existiert schon, überschreiben?')) {
						let newUserActionsArray = [
							...userActionsAllUsers.UG_VALUE.filter(f => f.dataviewGuid !== newUserAction.dataviewGuid),
							newUserAction
						];

						await allUserSettingManager.setSetting(userActionsAllUsers, newUserActionsArray);
					}
				} else {
					let newUserActionsArray = [...userActionsAllUsers.UG_VALUE, newUserAction];
					await allUserSettingManager.setSetting(userActionsAllUsers, newUserActionsArray);
				}
			}

			bc.messageService.publish('TOAST', {
				infoType: 'success',
				title: componentText.userActionSaved,
				message: '',
				timeout: 2000
			});

			let event = new Event('save',
			{
					bubbles: true,
					composed: true,
					cancelable: true
				});

			component.dispatchEvent(event);
		}
	};

	const cancel = e => {
		let event = new Event('cancel',
		{
				bubbles: true,
				composed: true,
				cancelable: true
			});

		component.dispatchEvent(event);
	};

	const writable_props = ['show', 'init'];

	

	function select_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			viewSelect = $$value;
			$$invalidate(2, viewSelect);
			$$invalidate(4, dataViews);
		});
	}

	function input_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			nameInput = $$value;
			$$invalidate(3, nameInput);
		});
	}

	const keyup_handler = e => {
		$$invalidate(5, hasText = e.target.value !== '');
	};

	const click_handler = e => {
		addElement(false);
	};

	const click_handler_1 = e => {
		addElement(true);
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(10, show = $$props.show);
		if ('init' in $$props) $$invalidate(11, init = $$props.init);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		allUserSettingManager,
		userSettingManager,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		applicationReady,
		getComponentText,
		constants,
		show,
		init,
		firstInitialized,
		subscribeGuid,
		ready,
		mounted,
		generalText,
		componentText,
		canReload,
		component,
		element,
		viewSelect,
		nameInput,
		dataViews,
		userActions,
		userActionsAllUsers,
		hasText,
		initComponent,
		addElement,
		cancel,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(10, show = $$props.show);
		if ('init' in $$props) $$invalidate(11, init = $$props.init);
		if ('firstInitialized' in $$props) $$invalidate(12, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) $$invalidate(0, ready = $$props.ready);
		if ('mounted' in $$props) $$invalidate(13, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(25, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(14, component = $$props.component);
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
		if ('viewSelect' in $$props) $$invalidate(2, viewSelect = $$props.viewSelect);
		if ('nameInput' in $$props) $$invalidate(3, nameInput = $$props.nameInput);
		if ('dataViews' in $$props) $$invalidate(4, dataViews = $$props.dataViews);
		if ('userActions' in $$props) userActions = $$props.userActions;
		if ('userActionsAllUsers' in $$props) userActionsAllUsers = $$props.userActionsAllUsers;
		if ('hasText' in $$props) $$invalidate(5, hasText = $$props.hasText);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 17408) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 47104) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		ready,
		element,
		viewSelect,
		nameInput,
		dataViews,
		hasText,
		generalText,
		componentText,
		addElement,
		cancel,
		show,
		init,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		select_binding,
		input_binding,
		keyup_handler,
		click_handler,
		click_handler_1,
		div_binding
	];
}

class AddUserForm extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.border-gray-300{--tw-border-opacity:1;border-color:rgba(209, 213, 219, var(--tw-border-opacity))}.rounded{border-radius:0.25rem}.border-solid{border-style:solid}.border{border-width:1px}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.flex-wrap{-ms-flex-wrap:wrap;-webkit-flex-wrap:wrap;flex-wrap:wrap}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-end{-webkit-box-pack:end;-ms-flex-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end}.font-medium{font-family:Roboto-Medium}.h-10{height:2.5rem}.text-2xl{font-size:1.5rem;line-height:2rem}.mb-2{margin-bottom:0.5rem}.mt-2{margin-top:0.5rem}.focus\\:outline-none:focus{outline:2px solid transparent;outline-offset:2px}.p-1{padding:0.25rem}.p-2{padding:0.5rem}.w-full{width:100%}.gap-2{grid-gap:0.5rem;gap:0.5rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ show: 10, init: 11 },
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init"];
	}

	get show() {
		return this.$$.ctx[10];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[11];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}
}

customElements.define("add-user-form", AddUserForm);
export default AddUserForm;