/* src\components\Title\Title.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	set_custom_element_data,
	set_data_dev,
	space,
	text as text_1,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { applicationReady } from "../../utilities/store.js";
import { getComponentText } from "../../utilities/generalUtils.js";
const file = "src\\components\\Title\\Title.svelte";

// (2050:4) {#if icon !== ''}
function create_if_block_6(ctx) {
	let fa_icon;

	const block = {
		c: function create() {
			fa_icon = element_1("fa-icon");
			set_custom_element_data(fa_icon, "icon", /*icon*/ ctx[2]);
			set_custom_element_data(fa_icon, "size", /*size*/ ctx[3]);
			set_custom_element_data(fa_icon, "color", /*color*/ ctx[4]);
			add_location(fa_icon, file, 2050, 8, 38458);
		},
		m: function mount(target, anchor) {
			insert_dev(target, fa_icon, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*icon*/ 4) {
				set_custom_element_data(fa_icon, "icon", /*icon*/ ctx[2]);
			}

			if (dirty & /*size*/ 8) {
				set_custom_element_data(fa_icon, "size", /*size*/ ctx[3]);
			}

			if (dirty & /*color*/ 16) {
				set_custom_element_data(fa_icon, "color", /*color*/ ctx[4]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(fa_icon);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_6.name,
		type: "if",
		source: "(2050:4) {#if icon !== ''}",
		ctx
	});

	return block;
}

// (2053:4) {#if tag ==='h1'}
function create_if_block_5(ctx) {
	let h1;
	let t;
	let h1_style_value;

	const block = {
		c: function create() {
			h1 = element_1("h1");
			t = text_1(/*text*/ ctx[1]);
			attr_dev(h1, "style", h1_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`);
			add_location(h1, file, 2053, 8, 38559);
		},
		m: function mount(target, anchor) {
			insert_dev(target, h1, anchor);
			append_dev(h1, t);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*text*/ 2) set_data_dev(t, /*text*/ ctx[1]);

			if (dirty & /*color, size*/ 24 && h1_style_value !== (h1_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`)) {
				attr_dev(h1, "style", h1_style_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(h1);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_5.name,
		type: "if",
		source: "(2053:4) {#if tag ==='h1'}",
		ctx
	});

	return block;
}

// (2057:4) {#if tag ==='h2'}
function create_if_block_4(ctx) {
	let h2;
	let t;
	let h2_style_value;

	const block = {
		c: function create() {
			h2 = element_1("h2");
			t = text_1(/*text*/ ctx[1]);
			attr_dev(h2, "style", h2_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`);
			add_location(h2, file, 2057, 8, 38669);
		},
		m: function mount(target, anchor) {
			insert_dev(target, h2, anchor);
			append_dev(h2, t);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*text*/ 2) set_data_dev(t, /*text*/ ctx[1]);

			if (dirty & /*color, size*/ 24 && h2_style_value !== (h2_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`)) {
				attr_dev(h2, "style", h2_style_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(h2);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_4.name,
		type: "if",
		source: "(2057:4) {#if tag ==='h2'}",
		ctx
	});

	return block;
}

// (2061:4) {#if tag ==='h3'}
function create_if_block_3(ctx) {
	let h3;
	let t;
	let h3_style_value;

	const block = {
		c: function create() {
			h3 = element_1("h3");
			t = text_1(/*text*/ ctx[1]);
			attr_dev(h3, "style", h3_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`);
			add_location(h3, file, 2061, 8, 38779);
		},
		m: function mount(target, anchor) {
			insert_dev(target, h3, anchor);
			append_dev(h3, t);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*text*/ 2) set_data_dev(t, /*text*/ ctx[1]);

			if (dirty & /*color, size*/ 24 && h3_style_value !== (h3_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`)) {
				attr_dev(h3, "style", h3_style_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(h3);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_3.name,
		type: "if",
		source: "(2061:4) {#if tag ==='h3'}",
		ctx
	});

	return block;
}

// (2065:4) {#if tag ==='h4'}
function create_if_block_2(ctx) {
	let h4;
	let t;
	let h4_style_value;

	const block = {
		c: function create() {
			h4 = element_1("h4");
			t = text_1(/*text*/ ctx[1]);
			attr_dev(h4, "style", h4_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`);
			add_location(h4, file, 2065, 8, 38889);
		},
		m: function mount(target, anchor) {
			insert_dev(target, h4, anchor);
			append_dev(h4, t);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*text*/ 2) set_data_dev(t, /*text*/ ctx[1]);

			if (dirty & /*color, size*/ 24 && h4_style_value !== (h4_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`)) {
				attr_dev(h4, "style", h4_style_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(h4);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(2065:4) {#if tag ==='h4'}",
		ctx
	});

	return block;
}

// (2069:4) {#if tag ==='h5'}
function create_if_block_1(ctx) {
	let h5;
	let t;
	let h5_style_value;

	const block = {
		c: function create() {
			h5 = element_1("h5");
			t = text_1(/*text*/ ctx[1]);
			attr_dev(h5, "style", h5_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`);
			add_location(h5, file, 2069, 8, 38999);
		},
		m: function mount(target, anchor) {
			insert_dev(target, h5, anchor);
			append_dev(h5, t);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*text*/ 2) set_data_dev(t, /*text*/ ctx[1]);

			if (dirty & /*color, size*/ 24 && h5_style_value !== (h5_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`)) {
				attr_dev(h5, "style", h5_style_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(h5);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(2069:4) {#if tag ==='h5'}",
		ctx
	});

	return block;
}

// (2073:4) {#if tag ==='h6'}
function create_if_block(ctx) {
	let h6;
	let t;
	let h6_style_value;

	const block = {
		c: function create() {
			h6 = element_1("h6");
			t = text_1(/*text*/ ctx[1]);
			attr_dev(h6, "style", h6_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`);
			add_location(h6, file, 2073, 8, 39109);
		},
		m: function mount(target, anchor) {
			insert_dev(target, h6, anchor);
			append_dev(h6, t);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*text*/ 2) set_data_dev(t, /*text*/ ctx[1]);

			if (dirty & /*color, size*/ 24 && h6_style_value !== (h6_style_value = `color: ${/*color*/ ctx[4]}; font-size: ${/*size*/ ctx[3]}rem`)) {
				attr_dev(h6, "style", h6_style_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(h6);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2073:4) {#if tag ==='h6'}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let t0;
	let t1;
	let t2;
	let t3;
	let t4;
	let t5;
	let if_block0 = /*icon*/ ctx[2] !== '' && create_if_block_6(ctx);
	let if_block1 = /*tag*/ ctx[0] === 'h1' && create_if_block_5(ctx);
	let if_block2 = /*tag*/ ctx[0] === 'h2' && create_if_block_4(ctx);
	let if_block3 = /*tag*/ ctx[0] === 'h3' && create_if_block_3(ctx);
	let if_block4 = /*tag*/ ctx[0] === 'h4' && create_if_block_2(ctx);
	let if_block5 = /*tag*/ ctx[0] === 'h5' && create_if_block_1(ctx);
	let if_block6 = /*tag*/ ctx[0] === 'h6' && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			t2 = space();
			if (if_block3) if_block3.c();
			t3 = space();
			if (if_block4) if_block4.c();
			t4 = space();
			if (if_block5) if_block5.c();
			t5 = space();
			if (if_block6) if_block6.c();
			this.c = noop;
			attr_dev(div, "class", "flex items-center gap-2");
			add_location(div, file, 2048, 0, 38368);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block0) if_block0.m(div, null);
			append_dev(div, t0);
			if (if_block1) if_block1.m(div, null);
			append_dev(div, t1);
			if (if_block2) if_block2.m(div, null);
			append_dev(div, t2);
			if (if_block3) if_block3.m(div, null);
			append_dev(div, t3);
			if (if_block4) if_block4.m(div, null);
			append_dev(div, t4);
			if (if_block5) if_block5.m(div, null);
			append_dev(div, t5);
			if (if_block6) if_block6.m(div, null);
			/*div_binding*/ ctx[12](div);
		},
		p: function update(ctx, [dirty]) {
			if (/*icon*/ ctx[2] !== '') {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_6(ctx);
					if_block0.c();
					if_block0.m(div, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*tag*/ ctx[0] === 'h1') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_5(ctx);
					if_block1.c();
					if_block1.m(div, t1);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*tag*/ ctx[0] === 'h2') {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_4(ctx);
					if_block2.c();
					if_block2.m(div, t2);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (/*tag*/ ctx[0] === 'h3') {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block_3(ctx);
					if_block3.c();
					if_block3.m(div, t3);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (/*tag*/ ctx[0] === 'h4') {
				if (if_block4) {
					if_block4.p(ctx, dirty);
				} else {
					if_block4 = create_if_block_2(ctx);
					if_block4.c();
					if_block4.m(div, t4);
				}
			} else if (if_block4) {
				if_block4.d(1);
				if_block4 = null;
			}

			if (/*tag*/ ctx[0] === 'h5') {
				if (if_block5) {
					if_block5.p(ctx, dirty);
				} else {
					if_block5 = create_if_block_1(ctx);
					if_block5.c();
					if_block5.m(div, t5);
				}
			} else if (if_block5) {
				if_block5.d(1);
				if_block5 = null;
			}

			if (/*tag*/ ctx[0] === 'h6') {
				if (if_block6) {
					if_block6.p(ctx, dirty);
				} else {
					if_block6 = create_if_block(ctx);
					if_block6.c();
					if_block6.m(div, null);
				}
			} else if (if_block6) {
				if_block6.d(1);
				if_block6 = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (if_block4) if_block4.d();
			if (if_block5) if_block5.d();
			if (if_block6) if_block6.d();
			/*div_binding*/ ctx[12](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(11, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('bc-title', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { tag = 'h1' } = $$props;
	let { text = '' } = $$props;
	let { icon = '' } = $$props;
	let { size = '1' } = $$props;
	let { color = 'black' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let ready = false;
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(10, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(9, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(8, firstInitialized = true);
		ready = true;
	};

	const writable_props = ['show', 'init', 'tag', 'text', 'icon', 'size', 'color'];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(5, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(6, show = $$props.show);
		if ('init' in $$props) $$invalidate(7, init = $$props.init);
		if ('tag' in $$props) $$invalidate(0, tag = $$props.tag);
		if ('text' in $$props) $$invalidate(1, text = $$props.text);
		if ('icon' in $$props) $$invalidate(2, icon = $$props.icon);
		if ('size' in $$props) $$invalidate(3, size = $$props.size);
		if ('color' in $$props) $$invalidate(4, color = $$props.color);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		applicationReady,
		getComponentText,
		show,
		init,
		tag,
		text,
		icon,
		size,
		color,
		firstInitialized,
		subscribeGuid,
		ready,
		generalText,
		mounted,
		canReload,
		component,
		element,
		initComponent,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(6, show = $$props.show);
		if ('init' in $$props) $$invalidate(7, init = $$props.init);
		if ('tag' in $$props) $$invalidate(0, tag = $$props.tag);
		if ('text' in $$props) $$invalidate(1, text = $$props.text);
		if ('icon' in $$props) $$invalidate(2, icon = $$props.icon);
		if ('size' in $$props) $$invalidate(3, size = $$props.size);
		if ('color' in $$props) $$invalidate(4, color = $$props.color);
		if ('firstInitialized' in $$props) $$invalidate(8, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('ready' in $$props) ready = $$props.ready;
		if ('mounted' in $$props) $$invalidate(9, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(16, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(10, component = $$props.component);
		if ('element' in $$props) $$invalidate(5, element = $$props.element);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 1088) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 2944) {
			/////////////////////////////////////////////////////
			// Init when canReload is false
			////////////////////////////////////////////////////
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}
	};

	return [
		tag,
		text,
		icon,
		size,
		color,
		element,
		show,
		init,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		div_binding
	];
}

class Title extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.gap-2{grid-gap:0.5rem;gap:0.5rem}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 6,
				init: 7,
				tag: 0,
				text: 1,
				icon: 2,
				size: 3,
				color: 4
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "tag", "text", "icon", "size", "color"];
	}

	get show() {
		return this.$$.ctx[6];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[7];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get tag() {
		return this.$$.ctx[0];
	}

	set tag(tag) {
		this.$$set({ tag });
		flush();
	}

	get text() {
		return this.$$.ctx[1];
	}

	set text(text) {
		this.$$set({ text });
		flush();
	}

	get icon() {
		return this.$$.ctx[2];
	}

	set icon(icon) {
		this.$$set({ icon });
		flush();
	}

	get size() {
		return this.$$.ctx[3];
	}

	set size(size) {
		this.$$set({ size });
		flush();
	}

	get color() {
		return this.$$.ctx[4];
	}

	set color(color) {
		this.$$set({ color });
		flush();
	}
}

customElements.define("bc-title", Title);
export default Title;