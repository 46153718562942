import bc from "../bcShim.js";
import {getSharedNormalize} from "./iconUtils.js";
export const handleComponentInitAttribute = (init, firstInitialized, mounted, ready, callback) => {
  if (ready && mounted && !firstInitialized && init === "true") {
    callback();
  }
};
export const handleComponentShowAttribute = (attribute, component, componentName) => {
  if (componentName) {
    console.log("componentName", componentName);
  }
  if (component && attribute) {
    component.hidden = attribute === "false";
  }
};
export const handleComponentProps = (data, ...args) => {
  const {ready, componentMounted, handler, firstInitialized, canReload, init} = data;
  if (ready && componentMounted) {
    componentInitAndAttributeHandler({
      mounted: componentMounted,
      handler,
      init,
      firstInitialized,
      canReload
    }, ...args);
  }
};
export const componentInitAndAttributeHandler = (data, ...args) => {
  const {mounted, init, firstInitialized, handler, canReload, calledBy} = data;
  if (mounted) {
    if (init === "true" && (firstInitialized === false || firstInitialized && canReload === true)) {
      handler(...args);
    }
  }
};
export const updateComponentAttribute = (element, attribute, attributeValue) => {
  try {
    let htmlElement = element.getRootNode().host;
    htmlElement.setAttribute(attribute, attributeValue);
  } catch (error) {
    console.error("Error updating attribute value", element, attribute, attributeValue);
  }
};
export const dispatchMountedEvent = (component) => {
  let event = new Event("mounted", {
    bubbles: false,
    cancelable: true,
    composed: false
  });
  event.detail = component;
  component.dispatchEvent(event);
  if (component.hasAttribute("router") && component.getAttribute("router") !== "") {
    let routerComponents = bc.parameterService.get("routerComponents");
    if (routerComponents) {
      let existingComponent = routerComponents.find((item) => {
        return item.name === component.getAttribute("router");
      });
      if (existingComponent) {
        existingComponent.component = component;
      } else {
        routerComponents.push({name: component.getAttribute("router"), component});
      }
      bc.parameterService.set("routerComponents", routerComponents);
    } else {
      bc.parameterService.set("routerComponents", [{name: component.getAttribute("router"), component}]);
    }
  }
  if (component.hasAttribute("register") && component.getAttribute("register") !== "") {
    console.log("Registering component in window.components as " + component.getAttribute("register"), component);
    if (!window["components"]) {
      window["components"] = [];
    }
    window["components"][component.getAttribute("register")] = component;
  }
};
export const dispatchReadyEvent = (component) => {
  let event = new Event("ready", {
    bubbles: false,
    cancelable: true,
    composed: false
  });
  event.detail = component;
  component.dispatchEvent(event);
};
export const singletonComponentExists = (parameterServiceName, warn) => {
  if (!bc.parameterService.get(parameterServiceName)) {
    bc.parameterService.set(parameterServiceName, true);
    return false;
  } else {
    if (warn === true) {
      console.warn(parameterServiceName + " is a singleton component and can only be used once");
    }
    return true;
  }
};
export const getSingleTonComponent = (parameterServiceName) => {
  if (!bc.parameterService.get(parameterServiceName)) {
    return false;
  } else {
    return true;
  }
};
export const attachNormalize = (component) => {
  getSharedNormalize().then((value) => {
    if (component.shadowRoot) {
      component.shadowRoot.adoptedStyleSheets = [value];
    } else {
      console.error("attachNormalize: component does not have a shadow root", component, component.shadowRoot);
    }
  });
};
export const constructHTMLTag = (component) => {
  if (component.name && checkRights(component)) {
    let comp = document.createElement(component.htmlTag);
    comp.setAttribute("data-name", component.name);
    if (component.componentattributes) {
      let keys = Object.keys(component.componentattributes);
      for (let key of keys) {
        comp.setAttribute(key, component.componentattributes[key]);
      }
    }
    if (component.events) {
      for (let i = 0; i < component.events.length; i++) {
        comp.addEventListener(component.events[i].type, component.events[i].callBack);
      }
    }
    if (component.componentchildren) {
      for (let i = 0; i < component.componentchildren.length; i++) {
        if (component.componentchildren[i].component) {
          let slotComp = constructHTMLTag(component.componentchildren[i].component);
          if (slotComp) {
            comp.append(slotComp);
          }
        }
        if (component.componentchildren[i].html) {
          let slotComp = document.createElement("div");
          slotComp.innerHTML = component.componentchildren[i].html;
          if (slotComp) {
            comp.append(slotComp);
          }
        }
      }
    }
    if (component.showKeys) {
      comp.setAttribute("showon", component.showKeys.join(";"));
    }
    if (component.hideKeys) {
      comp.setAttribute("hideon", component.hideKeys.join(";"));
    }
    if (component.name) {
      if (!window["components"]) {
        window["components"] = [];
      }
      window["components"][component.name] = comp;
    }
    if (component.style) {
      comp.setAttribute("style", component.style);
    }
    return comp;
  } else {
    console.log("component does not have a name", component);
    return null;
  }
};
const checkRights = (def) => {
  if (!def.groups || def.groups.length === 0) {
    return true;
  }
  if (bc.parameterService.appParameters.userId) {
    for (let i = 0; i < bc.parameterService.appParameters.roles.length; i++) {
      let role = bc.parameterService.appParameters.roles[i];
      if (def.groups.includes(role)) {
        return true;
      }
    }
    return false;
  } else {
    return false;
  }
};
