/* src\components\Switch\Switch.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init as init_1,
	insert_dev,
	noop,
	prop_dev,
	safe_not_equal,
	space,
	toggle_class,
	validate_slots,
	validate_store
} from "../../../web_modules/svelte/internal.js";

import bc from '../../bcShim.js';
import { v4 as uuidv4 } from '../../../web_modules/uuid.js';
import { onMount, tick } from '../../../web_modules/svelte.js';

import {
	attachNormalize,
	componentInitAndAttributeHandler,
	dispatchMountedEvent,
	handleComponentShowAttribute
} from "../../utilities/componentUtils.js";

import { applicationReady } from "../../utilities/store.js";
const file = "src\\components\\Switch\\Switch.svelte";

function create_fragment(ctx) {
	let div;
	let label;
	let input;
	let input_disabled_value;
	let t;
	let span;

	const block = {
		c: function create() {
			div = element_1("div");
			label = element_1("label");
			input = element_1("input");
			t = space();
			span = element_1("span");
			this.c = noop;
			attr_dev(input, "type", "checkbox");
			input.checked = /*isChecked*/ ctx[2];
			input.disabled = input_disabled_value = /*disabled*/ ctx[0] === 'true';
			add_location(input, file, 126, 8, 3259);
			attr_dev(span, "class", "slider round");
			add_location(span, file, 127, 8, 3343);
			attr_dev(label, "class", "switch");
			add_location(label, file, 125, 4, 3227);
			toggle_class(div, "cursor-pointer", /*disabled*/ ctx[0] === 'false');
			add_location(div, file, 124, 0, 3152);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, label);
			append_dev(label, input);
			append_dev(label, t);
			append_dev(label, span);
			/*div_binding*/ ctx[10](div);
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*isChecked*/ 4) {
				prop_dev(input, "checked", /*isChecked*/ ctx[2]);
			}

			if (dirty & /*disabled*/ 1 && input_disabled_value !== (input_disabled_value = /*disabled*/ ctx[0] === 'true')) {
				prop_dev(input, "disabled", input_disabled_value);
			}

			if (dirty & /*disabled*/ 1) {
				toggle_class(div, "cursor-pointer", /*disabled*/ ctx[0] === 'false');
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			/*div_binding*/ ctx[10](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $applicationReady;
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(9, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('bc-switch', slots, []);
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;
	let { checked = 'false' } = $$props;
	let { disabled = 'false' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	let mounted = false;
	let canReload = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let ready = false;
	let isChecked = false;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(8, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(7, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = () => {
		$$invalidate(6, firstInitialized = true);
		ready = true;
	};

	const writable_props = ['show', 'init', 'checked', 'disabled'];

	

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(1, element);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(3, show = $$props.show);
		if ('init' in $$props) $$invalidate(4, init = $$props.init);
		if ('checked' in $$props) $$invalidate(5, checked = $$props.checked);
		if ('disabled' in $$props) $$invalidate(0, disabled = $$props.disabled);
	};

	$$self.$capture_state = () => ({
		bc,
		uuidv4,
		onMount,
		tick,
		attachNormalize,
		componentInitAndAttributeHandler,
		dispatchMountedEvent,
		handleComponentShowAttribute,
		applicationReady,
		show,
		init,
		checked,
		disabled,
		firstInitialized,
		subscribeGuid,
		mounted,
		canReload,
		component,
		element,
		ready,
		isChecked,
		initComponent,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('show' in $$props) $$invalidate(3, show = $$props.show);
		if ('init' in $$props) $$invalidate(4, init = $$props.init);
		if ('checked' in $$props) $$invalidate(5, checked = $$props.checked);
		if ('disabled' in $$props) $$invalidate(0, disabled = $$props.disabled);
		if ('firstInitialized' in $$props) $$invalidate(6, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('mounted' in $$props) $$invalidate(7, mounted = $$props.mounted);
		if ('canReload' in $$props) $$invalidate(13, canReload = $$props.canReload);
		if ('component' in $$props) $$invalidate(8, component = $$props.component);
		if ('element' in $$props) $$invalidate(1, element = $$props.element);
		if ('ready' in $$props) ready = $$props.ready;
		if ('isChecked' in $$props) $$invalidate(2, isChecked = $$props.isChecked);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*show, component*/ 264) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty & /*$applicationReady, mounted, init, firstInitialized*/ 720) {
			$: if ($applicationReady && mounted && init) {
				componentInitAndAttributeHandler({
					mounted,
					handler: initComponent,
					init,
					firstInitialized,
					canReload
				});
			}
		}

		if ($$self.$$.dirty & /*checked*/ 32) {
			// Component functions
			//================================================================
			$: if (checked) {
				$$invalidate(2, isChecked = checked === 'true');
			}
		}
	};

	return [
		disabled,
		element,
		isChecked,
		show,
		init,
		checked,
		firstInitialized,
		mounted,
		component,
		$applicationReady,
		div_binding
	];
}

class Switch extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `.switch{position:relative;display:inline-block;min-width:35px;width:35px;height:17px;pointer-events:none}.switch input{opacity:0;width:0;height:0}.slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:0.4s;transition:0.4s}.slider:before{position:absolute;content:"";height:13px;width:13px;left:2px;bottom:2px;background-color:white;-webkit-transition:0.2s;transition:0.2s}input:checked+.slider{background-color:#2196f3}input:focus+.slider{box-shadow:0 0 1px #2196f3}input:checked+.slider:before{-webkit-transform:translateX(18px);-ms-transform:translateX(18px);transform:translateX(18px)}.slider.round{border-radius:34px}.slider.round:before{border-radius:50%}.cursor-pointer{cursor:pointer}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				show: 3,
				init: 4,
				checked: 5,
				disabled: 0
			},
			null
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["show", "init", "checked", "disabled"];
	}

	get show() {
		return this.$$.ctx[3];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[4];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}

	get checked() {
		return this.$$.ctx[5];
	}

	set checked(checked) {
		this.$$set({ checked });
		flush();
	}

	get disabled() {
		return this.$$.ctx[0];
	}

	set disabled(disabled) {
		this.$$set({ disabled });
		flush();
	}
}

customElements.define("bc-switch", Switch);
export default Switch;