/* src\components\FormElements\ArtifactRichText\ArtifactRichText.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	component_subscribe,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	globals,
	init as init_1,
	insert_dev,
	noop,
	safe_not_equal,
	set_data_dev,
	space,
	text,
	toggle_class,
	validate_slots,
	validate_store
} from "../../../../web_modules/svelte/internal.js";

const { console: console_1 } = globals;
import bc from '../../../bcShim.js';
import { onMount, tick } from '../../../../web_modules/svelte.js';
import { v4 as uuidv4 } from '../../../../web_modules/uuid.js';
import "../../../../web_modules/svelte/internal.js";
import Quill from '../../../../web_modules/quill.js';

import {
	attachNormalize,
	dispatchMountedEvent,
	handleComponentInitAttribute,
	handleComponentProps,
	handleComponentShowAttribute
} from "../../../utilities/componentUtils.js";

import { applicationReady, isMobileStore } from "../../../utilities/store.js";
import { getComponentText } from "../../../utilities/generalUtils.js";
const file = "src\\components\\FormElements\\ArtifactRichText\\ArtifactRichText.svelte";

// (2298:4) {#if showlabel === 'true'}
function create_if_block(ctx) {
	let div;
	let label_1;
	let t;

	const block = {
		c: function create() {
			div = element_1("div");
			label_1 = element_1("label");
			t = text(/*label*/ ctx[0]);
			attr_dev(label_1, "for", /*column*/ ctx[1]);
			attr_dev(label_1, "class", "font-label text-gray-700");
			toggle_class(label_1, "required", /*isnullable*/ ctx[3] === 'false');
			add_location(label_1, file, 2299, 12, 45951);
			toggle_class(div, "col-span-12", /*display*/ ctx[2] === 'stack');
			toggle_class(div, "col-span-4", /*display*/ ctx[2] === 'inline');
			toggle_class(div, "textRight", /*display*/ ctx[2] === 'inline');
			add_location(div, file, 2298, 8, 45813);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, label_1);
			append_dev(label_1, t);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*label*/ 1) set_data_dev(t, /*label*/ ctx[0]);

			if (dirty[0] & /*column*/ 2) {
				attr_dev(label_1, "for", /*column*/ ctx[1]);
			}

			if (dirty[0] & /*isnullable*/ 8) {
				toggle_class(label_1, "required", /*isnullable*/ ctx[3] === 'false');
			}

			if (dirty[0] & /*display*/ 4) {
				toggle_class(div, "col-span-12", /*display*/ ctx[2] === 'stack');
			}

			if (dirty[0] & /*display*/ 4) {
				toggle_class(div, "col-span-4", /*display*/ ctx[2] === 'inline');
			}

			if (dirty[0] & /*display*/ 4) {
				toggle_class(div, "textRight", /*display*/ ctx[2] === 'inline');
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2298:4) {#if showlabel === 'true'}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div3;
	let t0;
	let div2;
	let div0;
	let t1;
	let div1;
	let t2;
	let textarea;
	let t3;
	let div4;
	let t4;
	let link;
	let if_block = /*showlabel*/ ctx[4] === 'true' && create_if_block(ctx);

	const block = {
		c: function create() {
			div3 = element_1("div");
			if (if_block) if_block.c();
			t0 = space();
			div2 = element_1("div");
			div0 = element_1("div");
			t1 = space();
			div1 = element_1("div");
			t2 = space();
			textarea = element_1("textarea");
			t3 = space();
			div4 = element_1("div");
			t4 = space();
			link = element_1("link");
			this.c = noop;
			attr_dev(div0, "id", "toolbar-container");
			add_location(div0, file, 2308, 8, 46315);
			attr_dev(div1, "id", "editor");
			add_location(div1, file, 2309, 8, 46379);
			attr_dev(textarea, "id", "editor2");
			add_location(textarea, file, 2311, 8, 46442);
			attr_dev(div2, "class", "relative rounded p-1px min-h-100px ");
			toggle_class(div2, "col-span-12", /*display*/ ctx[2] === 'stack');
			toggle_class(div2, "col-span-8", /*display*/ ctx[2] === 'inline');
			add_location(div2, file, 2302, 4, 46093);
			attr_dev(div3, "class", "grid grid-cols-12 formElement");
			toggle_class(div3, "px-4", /*padding*/ ctx[5] === 'true');
			toggle_class(div3, "!mb-0", /*padding*/ ctx[5] === 'false');
			toggle_class(div3, "inline", /*display*/ ctx[2] === 'inline');
			toggle_class(div3, "stack", /*display*/ ctx[2] === 'stack');
			toggle_class(div3, "special", /*showlabel*/ ctx[4] === 'false');
			toggle_class(div3, "text-left", /*display*/ ctx[2] === 'stack');
			toggle_class(div3, "gap-4", /*display*/ ctx[2] === 'inline');
			toggle_class(div3, "gap-2", /*display*/ ctx[2] === 'stack');
			add_location(div3, file, 2286, 0, 45336);
			div4.hidden = true;
			attr_dev(div4, "class", "ql-tooltip");
			add_location(div4, file, 2315, 0, 46521);
			attr_dev(link, "href", "//cdn.quilljs.com/1.3.6/quill.snow.css");
			attr_dev(link, "rel", "stylesheet");
			add_location(link, file, 2316, 0, 46560);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div3, anchor);
			if (if_block) if_block.m(div3, null);
			append_dev(div3, t0);
			append_dev(div3, div2);
			append_dev(div2, div0);
			/*div0_binding*/ ctx[24](div0);
			append_dev(div2, t1);
			append_dev(div2, div1);
			/*div1_binding*/ ctx[25](div1);
			append_dev(div2, t2);
			append_dev(div2, textarea);
			/*textarea_binding*/ ctx[26](textarea);
			/*div2_binding*/ ctx[27](div2);
			/*div3_binding*/ ctx[28](div3);
			insert_dev(target, t3, anchor);
			insert_dev(target, div4, anchor);
			insert_dev(target, t4, anchor);
			insert_dev(target, link, anchor);
		},
		p: function update(ctx, dirty) {
			if (/*showlabel*/ ctx[4] === 'true') {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div3, t0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty[0] & /*display*/ 4) {
				toggle_class(div2, "col-span-12", /*display*/ ctx[2] === 'stack');
			}

			if (dirty[0] & /*display*/ 4) {
				toggle_class(div2, "col-span-8", /*display*/ ctx[2] === 'inline');
			}

			if (dirty[0] & /*padding*/ 32) {
				toggle_class(div3, "px-4", /*padding*/ ctx[5] === 'true');
			}

			if (dirty[0] & /*padding*/ 32) {
				toggle_class(div3, "!mb-0", /*padding*/ ctx[5] === 'false');
			}

			if (dirty[0] & /*display*/ 4) {
				toggle_class(div3, "inline", /*display*/ ctx[2] === 'inline');
			}

			if (dirty[0] & /*display*/ 4) {
				toggle_class(div3, "stack", /*display*/ ctx[2] === 'stack');
			}

			if (dirty[0] & /*showlabel*/ 16) {
				toggle_class(div3, "special", /*showlabel*/ ctx[4] === 'false');
			}

			if (dirty[0] & /*display*/ 4) {
				toggle_class(div3, "text-left", /*display*/ ctx[2] === 'stack');
			}

			if (dirty[0] & /*display*/ 4) {
				toggle_class(div3, "gap-4", /*display*/ ctx[2] === 'inline');
			}

			if (dirty[0] & /*display*/ 4) {
				toggle_class(div3, "gap-2", /*display*/ ctx[2] === 'stack');
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div3);
			if (if_block) if_block.d();
			/*div0_binding*/ ctx[24](null);
			/*div1_binding*/ ctx[25](null);
			/*textarea_binding*/ ctx[26](null);
			/*div2_binding*/ ctx[27](null);
			/*div3_binding*/ ctx[28](null);
			if (detaching) detach_dev(t3);
			if (detaching) detach_dev(div4);
			if (detaching) detach_dev(t4);
			if (detaching) detach_dev(link);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $isMobileStore;
	let $applicationReady;
	validate_store(isMobileStore, 'isMobileStore');
	component_subscribe($$self, isMobileStore, $$value => $$invalidate(30, $isMobileStore = $$value));
	validate_store(applicationReady, 'applicationReady');
	component_subscribe($$self, applicationReady, $$value => $$invalidate(23, $applicationReady = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('artifact-rich-text', slots, []);
	let { value = '' } = $$props;
	let { label = '' } = $$props;
	let { column = '' } = $$props;
	let { display = 'inline' } = $$props;
	let { isnullable = 'true' } = $$props;
	let { iseditable = 'true' } = $$props;
	let { showlabel = 'true' } = $$props;
	let { padding = 'true' } = $$props;
	let { changedevent = '' } = $$props;
	let { isvalid = 'true' } = $$props;
	let { haschanged = 'false' } = $$props;
	let { show = 'true' } = $$props;
	let { init = 'true' } = $$props;

	// Base variables
	//================================================================
	let firstInitialized = false;

	let subscribeGuid = uuidv4();
	const generalText = getComponentText('general');
	let mounted = false;
	let canReload = true;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let divContainer;
	let initialized = false;
	let textAreaElement;
	let element2;
	let displayValue = '';
	let quill;
	let toolbar;
	let componentId = uuidv4();

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			$$invalidate(20, component = element.getRootNode().host || element.parentNode); // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				$$invalidate(19, mounted = true);
			});
		});

		return () => {
			bc.messageService.unsubscribeComponent(subscribeGuid);
		};
	});

	const initComponent = col => {
		$$invalidate(18, firstInitialized = true);

		if (col !== '') {
			if (!value) {
				$$invalidate(11, value = '');
			}

			if (value) {
				displayValue = atob(value);
				console.log('displayValue', displayValue);
			}
		}
	};

	const applyProps = afterInit => {
		let shouldInit = afterInit && firstInitialized || !afterInit && !firstInitialized;

		if (shouldInit) {
			handleComponentProps(
				{
					ready: $applicationReady,
					componentMounted: mounted,
					init,
					firstInitialized,
					canReload,
					handler: initComponent
				},
				column
			);
		}
	};

	// Component functions
	//================================================================
	const dispatchValue = data => {
		let event = new Event('change',
		{
				bubbles: true,
				composed: true,
				cancelable: true
			});

		//@ts-ignore
		event.detail = data;

		component.dispatchEvent(event);
	};

	//     bc.messageService.publish(changedevent, {column: column, value: value})
	// }
	// dispatchValue({column: column, value: value})
	// });
	const handleSelectionChange = e => {
		try {
			quill.selection.update();
		} catch(error) {
			
		} // console.log('error')
	}; // console.log('args', e)
	//const dom = quill.root.getRootNode();
	//const selection = dom.getSelection();
	//const range = quill.selection.normalizeNative(selection);

	const cssPath = function (el) {
		if (!(el instanceof Element)) return;
		var path = [];

		while (el.nodeType === Node.ELEMENT_NODE) {
			var selector = el.nodeName.toLowerCase();

			if (el.id) {
				selector += '#' + el.id;
			} else {
				var sib = el, nth = 1;
				while (sib.nodeType === Node.ELEMENT_NODE && (sib = sib.previousSibling) && nth++) ;
				selector += ":nth-child(" + nth + ")";
			}

			path.unshift(selector);
			el = el.parentNode;
		}

		return path.join(" > ");
	};

	const centerOnElement = e => {
		if ($isMobileStore) {
			let userAgent = window.navigator.userAgent.toLowerCase();
			let ios = (/iphone|ipod/).test(userAgent);

			if (!ios) {
				textAreaElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	};

	const writable_props = [
		'value',
		'label',
		'column',
		'display',
		'isnullable',
		'iseditable',
		'showlabel',
		'padding',
		'changedevent',
		'isvalid',
		'haschanged',
		'show',
		'init'
	];

	

	function div0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			toolbar = $$value;
			$$invalidate(10, toolbar);
		});
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			textAreaElement = $$value;
			$$invalidate(6, textAreaElement);
		});
	}

	function textarea_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element2 = $$value;
			$$invalidate(9, element2);
		});
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			divContainer = $$value;
			$$invalidate(8, divContainer);
		});
	}

	function div3_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(7, element);
		});
	}

	$$self.$$set = $$props => {
		if ('value' in $$props) $$invalidate(11, value = $$props.value);
		if ('label' in $$props) $$invalidate(0, label = $$props.label);
		if ('column' in $$props) $$invalidate(1, column = $$props.column);
		if ('display' in $$props) $$invalidate(2, display = $$props.display);
		if ('isnullable' in $$props) $$invalidate(3, isnullable = $$props.isnullable);
		if ('iseditable' in $$props) $$invalidate(12, iseditable = $$props.iseditable);
		if ('showlabel' in $$props) $$invalidate(4, showlabel = $$props.showlabel);
		if ('padding' in $$props) $$invalidate(5, padding = $$props.padding);
		if ('changedevent' in $$props) $$invalidate(13, changedevent = $$props.changedevent);
		if ('isvalid' in $$props) $$invalidate(14, isvalid = $$props.isvalid);
		if ('haschanged' in $$props) $$invalidate(15, haschanged = $$props.haschanged);
		if ('show' in $$props) $$invalidate(16, show = $$props.show);
		if ('init' in $$props) $$invalidate(17, init = $$props.init);
	};

	$$self.$capture_state = () => ({
		bc,
		onMount,
		tick,
		uuidv4,
		Quill,
		attachNormalize,
		dispatchMountedEvent,
		handleComponentInitAttribute,
		handleComponentProps,
		handleComponentShowAttribute,
		applicationReady,
		isMobileStore,
		getComponentText,
		value,
		label,
		column,
		display,
		isnullable,
		iseditable,
		showlabel,
		padding,
		changedevent,
		isvalid,
		haschanged,
		show,
		init,
		firstInitialized,
		subscribeGuid,
		generalText,
		mounted,
		canReload,
		component,
		element,
		divContainer,
		initialized,
		textAreaElement,
		element2,
		displayValue,
		quill,
		toolbar,
		componentId,
		initComponent,
		applyProps,
		dispatchValue,
		handleSelectionChange,
		cssPath,
		centerOnElement,
		$isMobileStore,
		$applicationReady
	});

	$$self.$inject_state = $$props => {
		if ('value' in $$props) $$invalidate(11, value = $$props.value);
		if ('label' in $$props) $$invalidate(0, label = $$props.label);
		if ('column' in $$props) $$invalidate(1, column = $$props.column);
		if ('display' in $$props) $$invalidate(2, display = $$props.display);
		if ('isnullable' in $$props) $$invalidate(3, isnullable = $$props.isnullable);
		if ('iseditable' in $$props) $$invalidate(12, iseditable = $$props.iseditable);
		if ('showlabel' in $$props) $$invalidate(4, showlabel = $$props.showlabel);
		if ('padding' in $$props) $$invalidate(5, padding = $$props.padding);
		if ('changedevent' in $$props) $$invalidate(13, changedevent = $$props.changedevent);
		if ('isvalid' in $$props) $$invalidate(14, isvalid = $$props.isvalid);
		if ('haschanged' in $$props) $$invalidate(15, haschanged = $$props.haschanged);
		if ('show' in $$props) $$invalidate(16, show = $$props.show);
		if ('init' in $$props) $$invalidate(17, init = $$props.init);
		if ('firstInitialized' in $$props) $$invalidate(18, firstInitialized = $$props.firstInitialized);
		if ('subscribeGuid' in $$props) subscribeGuid = $$props.subscribeGuid;
		if ('mounted' in $$props) $$invalidate(19, mounted = $$props.mounted);
		if ('canReload' in $$props) canReload = $$props.canReload;
		if ('component' in $$props) $$invalidate(20, component = $$props.component);
		if ('element' in $$props) $$invalidate(7, element = $$props.element);
		if ('divContainer' in $$props) $$invalidate(8, divContainer = $$props.divContainer);
		if ('initialized' in $$props) $$invalidate(21, initialized = $$props.initialized);
		if ('textAreaElement' in $$props) $$invalidate(6, textAreaElement = $$props.textAreaElement);
		if ('element2' in $$props) $$invalidate(9, element2 = $$props.element2);
		if ('displayValue' in $$props) displayValue = $$props.displayValue;
		if ('quill' in $$props) $$invalidate(22, quill = $$props.quill);
		if ('toolbar' in $$props) $$invalidate(10, toolbar = $$props.toolbar);
		if ('componentId' in $$props) componentId = $$props.componentId;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*show, component*/ 1114112) {
			$: if (show) {
				handleComponentShowAttribute(show, component);
			}
		}

		if ($$self.$$.dirty[0] & /*value*/ 2048) {
			// Allow reset to empty string with value attribute
			$: if (value === '') {
				displayValue = '';
			}
		}

		if ($$self.$$.dirty[0] & /*init, firstInitialized, mounted, $applicationReady*/ 9306112) {
			/**
 * Handle the component init attribute, for the case that the component has not been initialized yet.
 */
			$: handleComponentInitAttribute(init, firstInitialized, mounted, $applicationReady, applyProps);
		}

		if ($$self.$$.dirty[0] & /*column*/ 2) {
			/**
 * Handles any component attribute changes after the component is initialized.
 */
			$: (column, applyProps(true));
		}

		if ($$self.$$.dirty[0] & /*textAreaElement, initialized, quill*/ 6291520) {
			$: if (textAreaElement && initialized === false) {
				let toolbarOptions = [
					[{ 'font': [] }, { 'size': [] }],
					['bold', 'italic', 'underline', 'strike'],
					[{ 'color': [] }, { 'background': [] }],
					[{ 'script': 'super' }, { 'script': 'sub' }],
					[{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
					[
						{ 'list': 'ordered' },
						{ 'list': 'bullet' },
						{ 'indent': '-1' },
						{ 'indent': '+1' }
					],
					['direction', { 'align': [] }],
					['link', 'image'],
					['clean']
				];

				$$invalidate(22, quill = new Quill(textAreaElement,
				{
						theme: 'snow',
						modules: { toolbar: toolbarOptions }
					}));

				const normalizeNative = nativeRange => {
					if (nativeRange) {
						const range = nativeRange;

						if (range.baseNode) {
							range.startContainer = nativeRange.baseNode;
							range.endContainer = nativeRange.focusNode;
							range.startOffset = nativeRange.baseOffset;
							range.endOffset = nativeRange.focusOffset;

							if (range.endOffset < range.startOffset) {
								range.startContainer = nativeRange.focusNode;
								range.endContainer = nativeRange.baseNode;
								range.startOffset = nativeRange.focusOffset;
								range.endOffset = nativeRange.baseOffset;
							}
						}

						if (range.startContainer) {
							return {
								start: {
									node: range.startContainer,
									offset: range.startOffset
								},
								end: {
									node: range.endContainer,
									offset: range.endOffset
								},
								native: range
							};
						}
					}

					return null;
				};

				$$invalidate(
					22,
					quill.selection.getNativeRange = () => {
						const dom = quill.root.getRootNode();
						const selection = dom.getSelection();
						return normalizeNative(selection);
					},
					quill
				);

				$$invalidate(21, initialized = true);

				quill.on('text-change', function (delta, oldDelta, source) {
					console.log('delta', delta);
					console.log('oldDelta', oldDelta);
					console.log('source', source);
					console.log(quill.getContents());
					console.log(quill.root.innerHTML);
				}); // if (changedevent !== '') {
				//     bc.messageService.publish(changedevent, {column: column, value: value})
				// }
				// dispatchValue({column: column, value: value})

				let child = textAreaElement.firstChild;
				console.log(textAreaElement.ownerDocument);

				textAreaElement.addEventListener("mouseenter", () => {
					document.addEventListener("selectionchange", handleSelectionChange);
				});

				textAreaElement.addEventListener("mouseleave", () => {
					document.removeEventListener("selectionchange", handleSelectionChange);
				});
			} // quill.on('selection-change', function(delta, oldDelta, source) {
			// console.log('oki')
			// if (changedevent !== '') {
		}
	};

	return [
		label,
		column,
		display,
		isnullable,
		showlabel,
		padding,
		textAreaElement,
		element,
		divContainer,
		element2,
		toolbar,
		value,
		iseditable,
		changedevent,
		isvalid,
		haschanged,
		show,
		init,
		firstInitialized,
		mounted,
		component,
		initialized,
		quill,
		$applicationReady,
		div0_binding,
		div1_binding,
		textarea_binding,
		div2_binding,
		div3_binding
	];
}

class ArtifactRichText extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `100%{left:0;right:0}100%{left:0;right:0}100%{opacity:1}100%{opacity:1}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.inline{display:flex;flex-direction:row;justify-content:space-between}.stack{display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start}div{width:100%}.formElement{margin-bottom:10px;letter-spacing:0.01em}.formElement .textRight{text-align:right}.formElement .textRight label{margin:0px;margin-top:5px}.required:after{color:red;content:" *"}.ql-tooltip{left:0 !important}.rounded{border-radius:0.25rem}.inline{display:inline}.grid{display:-ms-grid;display:grid}.\\!mb-0{margin-bottom:0px !important}.min-h-100px{min-height:100px}.p-1px{padding:1px}.px-4{padding-left:1rem;padding-right:1rem}.relative{position:relative}.text-left{text-align:left}.text-gray-700{--tw-text-opacity:1;color:rgba(55, 65, 81, var(--tw-text-opacity))}.gap-4{grid-gap:1rem;gap:1rem}.gap-2{grid-gap:0.5rem;gap:0.5rem}.grid-cols-12{grid-template-columns:repeat(12, minmax(0, 1fr))}.col-span-12{-ms-grid-column-span:span 12 / span 12;grid-column:span 12 / span 12}.col-span-4{-ms-grid-column-span:span 4 / span 4;grid-column:span 4 / span 4}.col-span-8{-ms-grid-column-span:span 8 / span 8;grid-column:span 8 / span 8}`;
		this.shadowRoot.appendChild(style);

		init_1(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{
				value: 11,
				label: 0,
				column: 1,
				display: 2,
				isnullable: 3,
				iseditable: 12,
				showlabel: 4,
				padding: 5,
				changedevent: 13,
				isvalid: 14,
				haschanged: 15,
				show: 16,
				init: 17
			},
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return [
			"value",
			"label",
			"column",
			"display",
			"isnullable",
			"iseditable",
			"showlabel",
			"padding",
			"changedevent",
			"isvalid",
			"haschanged",
			"show",
			"init"
		];
	}

	get value() {
		return this.$$.ctx[11];
	}

	set value(value) {
		this.$$set({ value });
		flush();
	}

	get label() {
		return this.$$.ctx[0];
	}

	set label(label) {
		this.$$set({ label });
		flush();
	}

	get column() {
		return this.$$.ctx[1];
	}

	set column(column) {
		this.$$set({ column });
		flush();
	}

	get display() {
		return this.$$.ctx[2];
	}

	set display(display) {
		this.$$set({ display });
		flush();
	}

	get isnullable() {
		return this.$$.ctx[3];
	}

	set isnullable(isnullable) {
		this.$$set({ isnullable });
		flush();
	}

	get iseditable() {
		return this.$$.ctx[12];
	}

	set iseditable(iseditable) {
		this.$$set({ iseditable });
		flush();
	}

	get showlabel() {
		return this.$$.ctx[4];
	}

	set showlabel(showlabel) {
		this.$$set({ showlabel });
		flush();
	}

	get padding() {
		return this.$$.ctx[5];
	}

	set padding(padding) {
		this.$$set({ padding });
		flush();
	}

	get changedevent() {
		return this.$$.ctx[13];
	}

	set changedevent(changedevent) {
		this.$$set({ changedevent });
		flush();
	}

	get isvalid() {
		return this.$$.ctx[14];
	}

	set isvalid(isvalid) {
		this.$$set({ isvalid });
		flush();
	}

	get haschanged() {
		return this.$$.ctx[15];
	}

	set haschanged(haschanged) {
		this.$$set({ haschanged });
		flush();
	}

	get show() {
		return this.$$.ctx[16];
	}

	set show(show) {
		this.$$set({ show });
		flush();
	}

	get init() {
		return this.$$.ctx[17];
	}

	set init(init) {
		this.$$set({ init });
		flush();
	}
}

customElements.define("artifact-rich-text", ArtifactRichText);
export default ArtifactRichText;