/* src\components\Map\MapTools\MapMeasureTools\MapMeasureTools.svelte generated by Svelte v3.59.2 */
import {
	SvelteElement,
	action_destroyer,
	add_location,
	append_dev,
	attr_dev,
	attribute_to_object,
	binding_callbacks,
	detach_dev,
	dispatch_dev,
	element as element_1,
	flush,
	init,
	insert_dev,
	listen_dev,
	noop,
	run_all,
	safe_not_equal,
	space,
	toggle_class,
	validate_slots
} from "../../../../../web_modules/svelte/internal.js";

import bc from '../../../../bcShim.js';
import Feature from '../../../../../web_modules/ol/Feature.js';
import LineString from '../../../../../web_modules/ol/geom/LineString.js';
import Polygon from '../../../../../web_modules/ol/geom/Polygon.js';
import { Draw } from '../../../../../web_modules/ol/interaction.js';
import VectorLayer from '../../../../../web_modules/ol/layer/Vector.js';
import Overlay from '../../../../../web_modules/ol/Overlay.js';

// import OverlayPositioning from 'ol/OverlayPositioning'
import VectorSource from '../../../../../web_modules/ol/source/Vector.js';

import { getLength, getArea } from '../../../../../web_modules/ol/sphere.js';
import { Fill, Stroke, Style } from '../../../../../web_modules/ol/style.js';
import CircleStyle from '../../../../../web_modules/ol/style/Circle.js';
import { v4 as uuidv4 } from "../../../../../web_modules/uuid.js";
import { onMount, tick } from "../../../../../web_modules/svelte.js";
import { GeometryType } from "../../../../interfaces.js";

import {
	attachNormalize,
	dispatchMountedEvent
} from "../../../../utilities/componentUtils.js";

import { getComponentText, tooltip } from "../../../../utilities/generalUtils.js";
const file = "src\\components\\Map\\MapTools\\MapMeasureTools\\MapMeasureTools.svelte";

// (2098:4) {#if ready}
function create_if_block(ctx) {
	let div2;
	let div0;
	let i0;
	let div0_title_value;
	let tooltip_action;
	let t;
	let div1;
	let i1;
	let div1_title_value;
	let tooltip_action_1;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div2 = element_1("div");
			div0 = element_1("div");
			i0 = element_1("i");
			t = space();
			div1 = element_1("div");
			i1 = element_1("i");
			attr_dev(i0, "class", "far fa-ruler fa-lg !h-10 !w-10 flex items-center justify-center rounded");
			toggle_class(i0, "hover:text-orange-500", /*maptool*/ ctx[0] !== 'line');
			toggle_class(i0, "bg-blue-500", /*maptool*/ ctx[0] === 'line');
			toggle_class(i0, "text-white", /*maptool*/ ctx[0] === 'line');
			add_location(i0, file, 2100, 16, 43664);
			attr_dev(div0, "class", "relative cursor-pointer !h-10 !w-10");
			attr_dev(div0, "title", div0_title_value = /*componentText*/ ctx[4].mesureLenght);
			add_location(div0, file, 2099, 12, 43550);
			attr_dev(i1, "class", "far fa-ruler-combined fa-lg !h-10 !w-10 flex items-center justify-center rounded");
			toggle_class(i1, "hover:text-orange-500", /*maptool*/ ctx[0] !== 'polygon');
			toggle_class(i1, "bg-blue-500", /*maptool*/ ctx[0] === 'polygon');
			toggle_class(i1, "text-white", /*maptool*/ ctx[0] === 'polygon');
			add_location(i1, file, 2110, 16, 44272);
			attr_dev(div1, "class", "relative cursor-pointer !h-10 !w-10");
			attr_dev(div1, "title", div1_title_value = /*componentText*/ ctx[4].mesureArea);
			add_location(div1, file, 2109, 12, 44160);
			attr_dev(div2, "class", "flex flex-col text-white");
			add_location(div2, file, 2098, 8, 43498);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div2, anchor);
			append_dev(div2, div0);
			append_dev(div0, i0);
			append_dev(div2, t);
			append_dev(div2, div1);
			append_dev(div1, i1);

			if (!mounted) {
				dispose = [
					listen_dev(i0, "click", /*click_handler*/ ctx[6], false, false, false, false),
					action_destroyer(tooltip_action = tooltip.call(null, div0)),
					listen_dev(i1, "click", /*click_handler_1*/ ctx[7], false, false, false, false),
					action_destroyer(tooltip_action_1 = tooltip.call(null, div1))
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*maptool*/ 1) {
				toggle_class(i0, "hover:text-orange-500", /*maptool*/ ctx[0] !== 'line');
			}

			if (dirty[0] & /*maptool*/ 1) {
				toggle_class(i0, "bg-blue-500", /*maptool*/ ctx[0] === 'line');
			}

			if (dirty[0] & /*maptool*/ 1) {
				toggle_class(i0, "text-white", /*maptool*/ ctx[0] === 'line');
			}

			if (dirty[0] & /*maptool*/ 1) {
				toggle_class(i1, "hover:text-orange-500", /*maptool*/ ctx[0] !== 'polygon');
			}

			if (dirty[0] & /*maptool*/ 1) {
				toggle_class(i1, "bg-blue-500", /*maptool*/ ctx[0] === 'polygon');
			}

			if (dirty[0] & /*maptool*/ 1) {
				toggle_class(i1, "text-white", /*maptool*/ ctx[0] === 'polygon');
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div2);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(2098:4) {#if ready}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let if_block = /*ready*/ ctx[1] && create_if_block(ctx);

	const block = {
		c: function create() {
			div = element_1("div");
			if (if_block) if_block.c();
			this.c = noop;
			add_location(div, file, 2096, 0, 43446);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[8](div);
		},
		p: function update(ctx, dirty) {
			if (/*ready*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[8](null);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('map-tool-measure', slots, []);
	let { maptool = '' } = $$props;

	// Base variables
	//================================================================
	const generalText = getComponentText('general');

	const componentText = getComponentText('mapMeasureTools');
	let ready = false;
	let componentId = uuidv4();
	let mounted = false;

	// Component variables
	//================================================================
	let component; // bind parent node to this

	let element; // bind root element to this
	let vectorLayer;
	let vectorSource;
	let drawInfoTooltipElement;
	let mesureTooltipOverlay;
	let drawInfoTooltipOverlay;
	let currentDrawnFeature;
	let lengthOrSurface = undefined;
	let drawInteraction;
	let map;
	let setMapTools;

	// HTML Mount & Component initialization
	//================================================================
	onMount(() => {
		// here nothing is ready
		tick().then(() => {
			component = element.getRootNode().host || element.parentNode; // get the component/host

			if (component.shadowRoot) {
				attachNormalize(component);
			}

			tick().then(() => {
				dispatchMountedEvent(component);
				mounted = true;
			});
		});

		return () => {
			
		};
	});

	const initComponent = (newMap, mapToolFunc) => {
		map = newMap;
		$$invalidate(3, setMapTools = mapToolFunc);

		// bc.messageService.subscribe('MAP_TOOL', 'MAP_MEASURE_TOOL', setActifMapTools)
		$$invalidate(1, ready = true);
	};

	// Component functions
	//================================================================
	const handleMapTool = tool => {
		if (maptool !== 'line' && maptool !== 'polygon') {
			cleanup();
		}

		if (maptool === 'line') {
			cleanUpMeasureFeatures();
			vectorLayer = createVectorLayer();
			let zIndex = getMaxZIndex();
			vectorLayer.setZIndex(zIndex + 100);
			map.addLayer(vectorLayer);

			if (drawInteraction) {
				map.removeInteraction(drawInteraction);
			}

			drawInteraction = createDrawLineInteraction();
			map.addInteraction(drawInteraction);
		}

		if (maptool === 'polygon') {
			cleanUpMeasureFeatures();
			vectorLayer = createVectorLayer();
			let zIndex = getMaxZIndex();
			vectorLayer.setZIndex(zIndex + 100);
			map.addLayer(vectorLayer);

			if (drawInteraction) {
				map.removeInteraction(drawInteraction);
			}

			drawInteraction = createDrawPolygonInteraction();
			map.addInteraction(drawInteraction);
		}
	};

	const getMaxZIndex = () => {
		let zIndex = 0;

		map.getLayers().forEach(l => {
			if (l.getZIndex() > zIndex) {
				zIndex = l.getZIndex();
			}
		});

		return zIndex;
	};

	const cleanup = () => {
		if (vectorSource) {
			vectorSource.clear();
		}

		if (mesureTooltipOverlay) {
			map.removeOverlay(mesureTooltipOverlay);
			mesureTooltipOverlay = undefined;
		}

		if (drawInfoTooltipOverlay) {
			map.removeOverlay(drawInfoTooltipOverlay);
			drawInfoTooltipOverlay = undefined;
		}

		currentDrawnFeature = undefined;

		if (vectorLayer) {
			map.removeLayer(vectorLayer);
		}

		if (drawInteraction) {
			map.removeInteraction(drawInteraction);
		}

		bc.parameterService.remove('LENGTH_OR_AREA');
	};

	const createVectorLayer = () => {
		const width = 2;
		const styles = [];

		styles[GeometryType.POLYGON] = [
			new Style({
					fill: new Fill({ color: '#1976d217' }),
					stroke: new Stroke({ color: '#0039dc', width: 2 })
				})
		];

		styles[GeometryType.CIRCLE] = [
			new Style({
					fill: new Fill({ color: '#1976d217' }),
					stroke: new Stroke({ color: '#0039dc', width: 2 })
				})
		];

		styles[GeometryType.MULTI_POLYGON] = styles[GeometryType.POLYGON];

		styles[GeometryType.LINE_STRING] = [
			new Style({
					stroke: new Stroke({
							color: '#0039dc',
							// lineDash: [10, 10],
							width
						})
				})
		];

		styles[GeometryType.MULTI_LINE_STRING] = styles[GeometryType.LINE_STRING];

		styles[GeometryType.POINT] = [
			new Style({
					image: new CircleStyle({
							radius: 10,
							fill: new Fill({ color: '#ffaa117d' }),
							stroke: new Stroke({ color: '#ffaa11', width: 2 })
						})
				})
		];

		styles[GeometryType.MULTI_POINT] = styles[GeometryType.POINT];
		styles[GeometryType.GEOMETRY_COLLECTION] = styles[GeometryType.POLYGON].concat(styles[GeometryType.LINE_STRING], styles[GeometryType.POINT]);

		const styleFunction = function (feature, resolution) {
			const featureStyleFunction = feature.getStyleFunction();

			if (featureStyleFunction) {
				return featureStyleFunction.call(feature, resolution);
			} else {
				return styles[feature.getGeometry().getType()];
			}
		};

		vectorSource = new VectorSource();

		return new VectorLayer({
				source: vectorSource,
				style: styleFunction
			});
	};

	const formatLength = function (line) {
		const length = getLength(line, {
			projection: map.getView().getProjection()
		});

		let output;

		if (length > 1000) {
			output = Math.round(length / 1000 * 100) / 100 + ' ' + 'km';
		} else {
			output = Math.round(length * 100) / 100 + ' ' + 'm';
		}

		return output;
	};

	const formatArea = function (polygon) {
		const area = getArea(polygon, {
			projection: map.getView().getProjection()
		});

		let output;

		if (area > 100000) {
			output = Math.round(area / 1000000 * 100) / 100 + ' ' + 'km<sup>2</sup>';
		} else {
			output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
		}

		return output;
	};

	const createDrawPolygonInteraction = () => {
		const drawPolygonInteraction = new Draw({
				source: vectorSource,
				type: GeometryType.POLYGON,
				style: new Style({
						fill: new Fill({ color: '#00a7f787' }),
						stroke: new Stroke({ color: '#0039dc', width: 2 }),
						image: new CircleStyle({
								radius: 3,
								fill: new Fill({ color: '#ff9346' })
							})
					})
			});

		drawPolygonInteraction.on('drawstart', function (evt) {
			cleanUpMeasureFeatures();
			currentDrawnFeature = evt.feature;

			currentDrawnFeature.on('change', function (evt) {
				const polygon = currentDrawnFeature.getGeometry();
				let output = formatArea(polygon);
				let tooltipCoord = polygon.getInteriorPoint().getCoordinates();
				drawInfoTooltipElement.innerHTML = output;
				drawInfoTooltipOverlay.setPosition(tooltipCoord);
			});
		});

		drawPolygonInteraction.on('drawend', function (evt) {
			const geometry = evt.feature.getGeometry();
			const coordinates = geometry.getCoordinates();

			vectorSource.addFeature(new Feature({
					geometry: new Polygon(coordinates),
					name: 'line'
				}));

			const measure = document.createElement('div');
			measure.classList.add('ol-tooltip');
			measure.classList.add('ol-tooltip-measure');
			measure.innerHTML = formatArea(geometry);
			lengthOrSurface = formatArea(geometry);

			mesureTooltipOverlay = new Overlay({
					element: measure,
					offset: [0, -15],
					position: geometry.getInteriorPoint().getCoordinates(),
					positioning: 'bottom-center'
				});

			bc.parameterService.set('LENGTH_OR_AREA', { type: 'Area', value: lengthOrSurface });
			map.addOverlay(mesureTooltipOverlay);
		});

		return drawPolygonInteraction;
	};

	const createDrawLineInteraction = () => {
		const drawLineInteraction = new Draw({
				source: vectorSource,
				type: GeometryType.LINE_STRING,
				style: new Style({
						stroke: new Stroke({
								color: '#0039dc',
								lineDash: [10, 10],
								width: 2
							}),
						image: new CircleStyle({
								radius: 3,
								fill: new Fill({ color: '#ff9346' })
							})
					})
			});

		drawLineInteraction.on('drawstart', function (evt) {
			// Clean up existing features
			cleanUpMeasureFeatures();

			currentDrawnFeature = evt.feature;

			currentDrawnFeature.on('change', function (evt) {
				const lineString = currentDrawnFeature.getGeometry();
				let output = formatLength(lineString);
				let tooltipCoord = lineString.getLastCoordinate();
				drawInfoTooltipElement.innerHTML = output;
				drawInfoTooltipOverlay.setPosition(tooltipCoord);
			});
		});

		drawLineInteraction.on('drawend', function (evt) {
			const geometry = evt.feature.getGeometry();
			const coordinates = geometry.getCoordinates();

			vectorSource.addFeature(new Feature({
					geometry: new LineString(coordinates),
					name: 'line'
				}));

			const measure = document.createElement('div');
			measure.classList.add('ol-tooltip');
			measure.classList.add('ol-tooltip-measure');
			measure.innerHTML = formatLength(geometry);
			lengthOrSurface = formatLength(geometry);

			mesureTooltipOverlay = new Overlay({
					element: measure,
					offset: [0, -15],
					position: coordinates[coordinates.length - 1],
					positioning: 'bottom-center'
				});

			bc.parameterService.set('LENGTH_OR_AREA', { type: 'Line', value: lengthOrSurface });
			map.addOverlay(mesureTooltipOverlay);
		});

		return drawLineInteraction;
	};

	const cleanUpMeasureFeatures = () => {
		if (vectorSource) {
			vectorSource.clear();
		}

		if (mesureTooltipOverlay) {
			map.removeOverlay(mesureTooltipOverlay);
			mesureTooltipOverlay = undefined;
		}

		createMeasureTooltip();
		bc.parameterService.remove('LENGTH_OR_AREA');
	};

	const createMeasureTooltip = () => {
		if (drawInfoTooltipElement && drawInfoTooltipElement.parentNode) {
			drawInfoTooltipElement.parentNode.removeChild(drawInfoTooltipElement);
		}

		drawInfoTooltipElement = document.createElement('div');
		drawInfoTooltipElement.classList.add('ol-tooltip', 'ol-tooltip-measure');

		drawInfoTooltipOverlay = new Overlay({
				element: drawInfoTooltipElement,
				offset: [0, -15],
				positioning: 'bottom-center'
			});

		map.addOverlay(drawInfoTooltipOverlay);
	};

	const writable_props = ['maptool'];

	

	const click_handler = e => {
		maptool !== 'line'
		? setMapTools('line')
		: setMapTools('');
	};

	const click_handler_1 = e => {
		maptool !== 'polygon'
		? setMapTools('polygon')
		: setMapTools('');
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			element = $$value;
			$$invalidate(2, element);
		});
	}

	$$self.$$set = $$props => {
		if ('maptool' in $$props) $$invalidate(0, maptool = $$props.maptool);
	};

	$$self.$capture_state = () => ({
		bc,
		Feature,
		LineString,
		Polygon,
		Draw,
		VectorLayer,
		Overlay,
		VectorSource,
		getLength,
		getArea,
		Fill,
		Stroke,
		Style,
		CircleStyle,
		uuidv4,
		onMount,
		tick,
		GeometryType,
		attachNormalize,
		dispatchMountedEvent,
		getComponentText,
		tooltip,
		maptool,
		generalText,
		componentText,
		ready,
		componentId,
		mounted,
		component,
		element,
		vectorLayer,
		vectorSource,
		drawInfoTooltipElement,
		mesureTooltipOverlay,
		drawInfoTooltipOverlay,
		currentDrawnFeature,
		lengthOrSurface,
		drawInteraction,
		map,
		setMapTools,
		initComponent,
		handleMapTool,
		getMaxZIndex,
		cleanup,
		createVectorLayer,
		formatLength,
		formatArea,
		createDrawPolygonInteraction,
		createDrawLineInteraction,
		cleanUpMeasureFeatures,
		createMeasureTooltip
	});

	$$self.$inject_state = $$props => {
		if ('maptool' in $$props) $$invalidate(0, maptool = $$props.maptool);
		if ('ready' in $$props) $$invalidate(1, ready = $$props.ready);
		if ('componentId' in $$props) componentId = $$props.componentId;
		if ('mounted' in $$props) mounted = $$props.mounted;
		if ('component' in $$props) component = $$props.component;
		if ('element' in $$props) $$invalidate(2, element = $$props.element);
		if ('vectorLayer' in $$props) vectorLayer = $$props.vectorLayer;
		if ('vectorSource' in $$props) vectorSource = $$props.vectorSource;
		if ('drawInfoTooltipElement' in $$props) drawInfoTooltipElement = $$props.drawInfoTooltipElement;
		if ('mesureTooltipOverlay' in $$props) mesureTooltipOverlay = $$props.mesureTooltipOverlay;
		if ('drawInfoTooltipOverlay' in $$props) drawInfoTooltipOverlay = $$props.drawInfoTooltipOverlay;
		if ('currentDrawnFeature' in $$props) currentDrawnFeature = $$props.currentDrawnFeature;
		if ('lengthOrSurface' in $$props) lengthOrSurface = $$props.lengthOrSurface;
		if ('drawInteraction' in $$props) drawInteraction = $$props.drawInteraction;
		if ('map' in $$props) map = $$props.map;
		if ('setMapTools' in $$props) $$invalidate(3, setMapTools = $$props.setMapTools);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*maptool*/ 1) {
			$: handleMapTool(maptool);
		}
	};

	return [
		maptool,
		ready,
		element,
		setMapTools,
		componentText,
		initComponent,
		click_handler,
		click_handler_1,
		div_binding
	];
}

class MapMeasureTools extends SvelteElement {
	constructor(options) {
		super();
		const style = document.createElement('style');
		style.textContent = `.fa-lg{font-size:1.25em;line-height:0.05em;vertical-align:-0.075em}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}45%{-webkit-transform:scale(var(--fa-beat-scale, 1.25));transform:scale(var(--fa-beat-scale, 1.25))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}50%{opacity:1;-webkit-transform:scale(var(--fa-beat-fade-scale, 1.125));transform:scale(var(--fa-beat-fade-scale, 1.125))}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}.fa-ruler::before{content:""}.fa-ruler-combined::before{content:""}.far{font-weight:400 !important}.far{font-family:"Font Awesome 6 Pro" !important;font-style:normal !important}100%{left:0;right:0}.bg-blue-500{--tw-bg-opacity:1;background-color:rgba(59, 130, 246, var(--tw-bg-opacity))}.rounded{border-radius:0.25rem}.cursor-pointer{cursor:pointer}.flex{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}.items-center{-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center}.justify-center{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}.\\!h-10{height:2.5rem !important}.relative{position:relative}.text-white{--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))}.hover\\:text-orange-500:hover{--tw-text-opacity:1;color:rgba(249, 115, 22, var(--tw-text-opacity))}.\\!w-10{width:2.5rem !important}`;
		this.shadowRoot.appendChild(style);

		init(
			this,
			{
				target: this.shadowRoot,
				props: attribute_to_object(this.attributes),
				customElement: true
			},
			instance,
			create_fragment,
			safe_not_equal,
			{ maptool: 0, initComponent: 5 },
			null,
			[-1, -1]
		);

		if (options) {
			if (options.target) {
				insert_dev(options.target, this, options.anchor);
			}

			if (options.props) {
				this.$set(options.props);
				flush();
			}
		}
	}

	static get observedAttributes() {
		return ["maptool", "initComponent"];
	}

	get maptool() {
		return this.$$.ctx[0];
	}

	set maptool(maptool) {
		this.$$set({ maptool });
		flush();
	}

	get initComponent() {
		return this.$$.ctx[5];
	}

	set initComponent(value) {
		throw new Error("<map-tool-measure>: Cannot set read-only property 'initComponent'");
	}
}

customElements.define("map-tool-measure", MapMeasureTools);
export default MapMeasureTools;